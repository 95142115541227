import React from 'react'
import Banner from '../../components/lms/Banner'
import ContactForm from '../../components/Contact/ContactForm'
import Details from '../../components/lms/Details'
import { Helmet } from "react-helmet"

function LMS() {
    return (
        <>
            <Helmet>
                <title>Best LMS platform - Stack-Ed Learning Management System</title>
                <meta
                    name="description"
                    content="Stack-Ed. LMS is the best LMS platform designed to revolutionize the way online courses are created, managed, and delivered to enhance learning experiences."
                />
            </Helmet>

            <Banner />
            <Details />
            <ContactForm title="Get a quote" />
        </>
    )
}

export default LMS