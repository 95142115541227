import React from "react";
import { Helmet } from "react-helmet";
import Blockabout from "../../components/BlockChain/Blockabout";
import Blockban from "../../components/BlockChain/Blockban";
import BlockProducts from "../../components/BlockChain/Blockproducts";
import ContactForm from "../../components/Contact/ContactForm";

const Blockchain = () => {
  return (
    <>

      <Helmet>
        <title>Stackroots | Blockchain</title>
      </Helmet>
      <div>
        <Blockban />
        <Blockabout />
        <BlockProducts />
        <ContactForm />
      </div>
    </>
  );
};

export default Blockchain;
