import {
  Box,
  Stack,
  Typography
} from "@mui/material";
import React from "react";
import design from "../../assets/img/products/design.png";
import adaptivebg from "../../assets/img/services/adaptivebg.png";
import crmbg from "../../assets/img/services/crmbg.png";
import crmbg1 from "../../assets/img/services/crmbg1.png";
import crmfeatures from "../../assets/img/services/crmfeatures.png";
import crmfeatures1 from "../../assets/img/services/crmfeatures1.png";

import angular from "../../assets/img/services/logo/angularLogo.png";
import express from "../../assets/img/services/logo/expressLogo.png";
import mongo from "../../assets/img/services/logo/mongoLogo.png";
import mui from "../../assets/img/services/logo/muilogo.png";
import node from "../../assets/img/services/logo/nodelogo.png";
import react from "../../assets/img/services/logo/reactlogo.png";

import crm from "../../assets/img/services/vectors/crm.png";
import quality from "../../assets/img/services/vectors/quality.png";
import whyCr from "../../assets/img/services/vectors/whyCr.png";


import Marquee from "react-fast-marquee";
import { useStyles } from "../../styles";
import Techstack from "../Techstack";

const Crm1 = () => {
  const classes = useStyles();
  const icons = [
    {
      img: react
    },
    {
      img: angular
    },
    {
      img: node
    },
    {
      img: mongo
    },
    {
      img: mui
    },
    {
      img: express
    },
  ]
  return (
    <div style={{ background: "#131331" }}>
      <div
        className="adaptivebg"
        style={{
          backgroundImage: `url(${adaptivebg})`,
        }}
      >
        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={12}
          >
            <Box
              sx={{
                display: "flex",

                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
              >
                CRM Applications
              </Typography>

              <Box
                sx={{
                  marginTop: { xs: "0px", md: "40px" },
                  // p:10,
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={design} alt="banner" />
              </Box>

              <Typography
                mt={2}
                className={classes.mainContent}
              >
                Customer Relationship Management (CRM) is an essential function across businesses. It automates and unifies the various tasks and processes in managing customers, from lead generation to prospect management to the eventual sale of products and services. The right CRM system can supercharge your small business like nothing else, especially for Small and Medium Enterprises (SMEs), which is very important. At Stackroots, we specialize in developing customized CRM applications that are tailored to meet the unique needs of our clients. Our team of experts understands the importance of customer relationship management and works diligently to ensure that your CRM application is user-friendly and optimized for maximum efficiency.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                // p:10,
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={design} alt="banner" />
            </Box>
          </Stack>
        </Box>
      </div>

      <Box component={"div"}
        className="adaptivebg"
        sx={{
          background: { xs: `url(${crmbg1})`, md: `url(${crmbg})` },
        }}
      >
        <Box sx={{ marginTop: { xs: "12px ", md: "60px" }, px: { xs: "12px", md: "0px" } }}>
          <Typography
            className={classes.mainHeader}
            textAlign="center"
            sx={{
              marginBottom: "50px",
              marginTop: { xs: "20px", md: 0, sm: 0 },
            }}
          >
            Features CRM Applications
          </Typography>
          <Box component="img" src={crmfeatures1} alt="banner" sx={{ margin: "0 auto", display: { xs: "block", md: "none", paddingBottom: "50px" } }} />
          <Box component="img" src={crmfeatures} alt="banner" sx={{ margin: "0 auto", display: { xs: "none", md: "block", paddingBottom: "50px" } }} />
        </Box>
      </Box>

      <Box
        sx={{
          width: "100%",
          // height: "100vh",
          display: "flex",
          flexWrap: "wrap",
          p: { xs: 3, md: 6 },
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          sx={{
            alignItems: "center",
            justifyContent: "center",
          }}
          mt={12}
        >
          <Box
            sx={{
              display: "flex",

              flexDirection: "column",
              flex: 1,
            }}
          >
            <Typography
              className={classes.mainHeader}
              textAlign={{ xs: "center", md: "initial" }}
              mb={3}
            >
              Customized Solutions
            </Typography>
            <Box
              sx={{
                marginTop: { xs: "20px", md: "40px" },
                flex: 1,
                display: { xs: "flex", md: "none" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={crm} />
            </Box>
            <Typography
              className={classes.mainContent}
              marginTop="20px"
            >
              We understand that every business has unique requirements when it comes to CRM. Our dedicated team works closely with you to understand your goals and objectives, allowing us to develop a CRM application that perfectly suits your specific needs. Whether you need a basic CRM system or a complex solution with advanced features, we have the expertise to deliver outstanding results. We prioritize usability in our CRM applications, ensuring that they are intuitive and easy to navigate. Our user-friendly interfaces make it simple for your team to access and update customer information, track sales activities, and generate reports. This saves time and reduces the learning curve for new users.
            </Typography>
          </Box>

          <Box
            sx={{
              marginTop: { xs: "0px", md: "40px" },
              flex: 1,
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img alt="vector" src={crm} />
          </Box>
        </Stack>
      </Box>


      {/*  */}

      <Box
        sx={{
          width: "100%",
          // height: "100vh",
          display: "flex",
          flexWrap: "wrap",
          p: { xs: 3, md: 6 },
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row-reverse" }}
          sx={{
            alignItems: "center",
            justifyContent: "center",
          }}
          mt={12}
        >
          <Box
            sx={{
              display: "flex",

              flexDirection: "column",
              flex: 1,
            }}
          >
            <Typography
              className={classes.mainHeader}
              textAlign={{ xs: "center", md: "initial" }}
              mb={3}
            >
              High-Quality Result and Support:
            </Typography>
            <Box
              sx={{
                marginTop: { xs: "20px", md: "40px" },
                flex: 1,
                display: { xs: "flex", md: "none" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={quality} />
            </Box>
            <Typography
              className={classes.mainContent}
              marginTop="20px"
            >
              At Stackroots, we are committed to delivering high-quality results. Our team follows industry best practices and uses cutting-edge technologies to develop robust and scalable CRM applications. We also provide ongoing support and maintenance to ensure that your application continues to perform optimally. When it comes to CRM application development, Stackroots is your trusted partner. We offer customized solutions that increase efficiency, improve customer satisfaction, and enhance sales performance.
            </Typography>
          </Box>

          <Box
            sx={{
              marginTop: { xs: "0px", md: "40px" },
              flex: 1,
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img alt="vector" src={quality} width={"80%"} />
          </Box>
        </Stack>
      </Box>


      {/*  */}

      <Box
        sx={{
          width: "100%",
          // height: "100vh",
          display: "flex",
          flexWrap: "wrap",
          p: { xs: 3, md: 6 },
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          sx={{
            alignItems: "center",
            justifyContent: "center",
          }}
          mt={12}
        >
          <Box
            sx={{
              display: "flex",

              flexDirection: "column",
              flex: 1,
            }}
          >
            <Typography
              className={classes.mainHeader}
              textAlign={{ xs: "center", md: "initial" }}
              mb={3}
            >
              Why to choose Stackroots CRM Applications?
            </Typography>
            <Box
              sx={{
                marginTop: { xs: "20px", md: "40px" },
                flex: 1,
                display: { xs: "flex", md: "none" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={whyCr} />
            </Box>
            <Typography
              className={classes.mainContent}
              marginTop="20px"
            >
              Stackroots creates the best CRM software. Stackroots CRM software is easy to operate and will integrate smoothly. With the Stackroots CRM software, you can automate and streamline tasks and workflows, which can boost your teams’ efficiency. Intelligently storing and managing customer and prospect information can help your marketing team find new customers faster by boosting your lead volume. It can win you more customers by boosting your sales team’s ability to close deals. It can help your service team make more customers happy with smart help desk solutions.
            </Typography>
          </Box>

          <Box
            sx={{
              marginTop: { xs: "0px", md: "40px" },
              flex: 1,
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img alt="vector" src={whyCr} />
          </Box>
        </Stack>
      </Box>




      <Box sx={{ marginTop: "50px", display: { xs: "none", md: "block", sm: "block" } }}>
        <Techstack />
      </Box>

      <Box sx={{ marginTop: "50px", p: 3, mb: "60px", display: { xs: "block", md: "none", sm: "none" } }} >
        <Typography
          sx={
            { "fontStyle": "normal", "fontWeight": "600", "fontSize": "24px", "lineHeight": "29px", "color": "#FFFFFF", textAlign: "center" }
          }
        >
          Expertise And Technical Skills
        </Typography>

        <Stack>
          <Marquee gradient={false}>
            {icons.map((icon) => (
              <Box component={"img"}
                sx={{
                  mt: "25px",
                  width: "105px"
                }}
                src={icon.img}
                alt={"tech stack"}
              />
            ))
            }
          </Marquee>

          <Marquee gradient={false} direction={"right"}>
            {icons.map((icon) => (
              <Box component={"img"}
                sx={{
                  mt: "25px",
                  width: "105px"
                }}
                src={icon.img}
                alt={"tech stack"}
              />
            ))
            }
          </Marquee>
        </Stack>

      </Box>
    </div>
  );
};

export default Crm1;
