import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import adaptivebg from "../../assets/img/services/adaptivebg.png";
import enterprisebg from "../../assets/img/services/enterprisebg.png";
import entrprise12 from "../../assets/img/services/entrprise12.png";
import Techstack from "../Techstack";

import angular from "../../assets/img/services/logo/angularLogo.png";
import express from "../../assets/img/services/logo/expressLogo.png";
import mongo from "../../assets/img/services/logo/mongoLogo.png";
import mui from "../../assets/img/services/logo/muilogo.png";
import node from "../../assets/img/services/logo/nodelogo.png";
import react from "../../assets/img/services/logo/reactlogo.png";

import erp from "../../assets/img/services/vectors/erp.png";

import Marquee from "react-fast-marquee";
import { useStyles } from "../../styles";

const Enterprise1 = () => {
  const classes = useStyles();
  const icons = [
    {
      img: react
    },
    {
      img: angular
    },
    {
      img: node
    },
    {
      img: mongo
    },
    {
      img: mui
    },
    {
      img: express
    },
  ]
  return (
    <div style={{ background: "#131331" }}>
      <div
        className="adaptivebg"
        style={{
          backgroundImage: `url(${adaptivebg})`,
        }}
      >
        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={12}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}
              >
                Enterprise Applications
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "0px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={entrprise12} alt="banner" />
              </Box>
              <Typography
                marginTop="10px"
                className={classes.mainContent}
              >
                At STACKROOTS, we specialize in customizing ERP software to streamline all your business operations. Whether you are a large-scale enterprise or a small business, our ERP software can help you manage your business more efficiently. By combining your company's business processes on one powerful platform, our ERP system saves you time and effort in data entry and administration.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={entrprise12} alt="banner" />
            </Box>
          </Stack>
        </Box>

        {/*  */}

        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row-reverse" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={12}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}
              >
                Customize Your ERP Software with STACKROOTS
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "0px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img alt="vector" src={erp} />
              </Box>
              <Typography
                marginTop="10px"
                className={classes.mainContent}
              >
                We offer custom-made ERP solutions for various industries, including production and manufacturing, supply chain management, human resource management, and finance. Our ERP software is powerful and reliable, loaded with unique features that can be scaled to meet your specific needs. With the STACKROOTS ERP system, you will have an integrated and continuously updated view of your core business processes. This is made possible through common databases maintained by a robust database management system. By having a comprehensive view of your business, you will be able to make better decisions and discover new methods to boost operational performance.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={erp} />
            </Box>
          </Stack>
        </Box>

        <div
          className="adaptivebg"
          style={{
            backgroundImage: `url(${enterprisebg})`,
          }}
        >
          <Box sx={{ marginTop: "60px", p: { xs: 3, md: 6 } }}>
            <Typography
              className={classes.mainHeader}
              textAlign={"center"}
              mb={2}
              sx={{
                marginTop: { xs: "20px", md: 0, sm: 0 },
              }}
            >
              Why Choose STACKROOTS for ERP Development?
            </Typography>
            <Typography
              className={classes.mainContent}
              // textAlign="center"
              sx={{
                p: { md: 0, xs: 0, sm: 5 },
              }}
            >
              When it comes to ERP development services, STACKROOTS is the right choice for your business. Our team of experienced developers will work closely with you to understand your unique requirements and create a customized ERP solution that exceeds your expectations. We ensure that our ERP software is user-friendly, scalable, and tailored to meet the specific needs of your industry. If you are looking for reliable and efficient ERP development services, look no further than STACKROOTS. Contact us today to discuss your ERP needs and let us help you streamline your business operations for greater success.
            </Typography>
          </Box>
        </div>
      </div>
      <Box sx={{ marginTop: "50px", display: { xs: "none", md: "block", sm: "block" } }}>
        <Techstack />
      </Box>

      <Box p={3} sx={{ marginTop: "50px", display: { xs: "block", md: "none", sm: "none" } }} >
        <Typography
          sx={
            { "fontStyle": "normal", "fontWeight": "600", "fontSize": "24px", "lineHeight": "29px", "color": "#FFFFFF", textAlign: "center" }
          }
        >
          Expertise And Technical Skills
        </Typography>
        <Stack>
          <Marquee gradient={false}>
            {icons.map((icon) => (
              <Box component={"img"}
                sx={{
                  mt: "25px",
                  width: "105px"
                }}
                src={icon.img}
                alt={"tech stack"}
              />
            ))
            }
          </Marquee>

          <Marquee gradient={false} direction={"right"}>
            {icons.map((icon) => (
              <Box component={"img"}
                sx={{
                  mt: "25px",
                  width: "105px"
                }}
                src={icon.img}
                alt={"tech stack"}
              />
            ))
            }
          </Marquee>
        </Stack>
      </Box>
    </div>
  );
}

export default Enterprise1