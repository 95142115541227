import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Formik, Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Container } from "@mui/system";
import easytouse from "../../assets/img/products/easytouse.png";
import card from "../../assets/img/products/card.png";
import collaboration from "../../assets/img/products/collaboration.png";
import { Fade } from "react-awesome-reveal";
import FreeDemo from "../FreeDemo";
import PopUp from "../PopUp";
import { useStyles } from "../../styles";
import driving from "../../assets/img/new/driving.png"
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    bgcolor: "background.paper",

    boxShadow: 24,
    p: 4,
};

const Banner = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const classes = useStyles();
    return (
        <>
            <Box
                sx={{
                    background: `url(${driving})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "100%",
                    paddingBottom: { xs: "150px", md: "50px" },
                    // height: "470px",
                    height: { xs: "auto", md: "100vh" },
                    display: "flex",
                    flexWrap: "wrap",
                    // margin: { xs: "10px", md: "30px" },
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        padding: { xs: "20px", md: "50px" },
                        marginTop: { xs: "100px", md: "80px" },
                        marginLeft: { xs: "0px", md: "70px" },
                        maxWidth: "900px"
                    }}
                >
                    <Typography
                        className={classes.mainHeader}
                        sx={{

                            marginTop: { md: "100px", xs: "0px" },
                        }}
                    >
                        AutoStack - Online service management
                        application for Automobile service stations
                    </Typography>

                    <Typography
                        className={classes.mainContent}
                        sx={{
                            marginTop: { md: "25px", xs: "10px" },
                        }}
                    >
                        AutoStack is an innovative online service management application specifically designed for automobile service stations.
                    </Typography>
                    <PopUp margin={"50px"} title={"AutoStack"} background={"#e6f7ff"} color={"#4F46E5"} />
                </Box>
            </Box>

        </>
    );

    // return (
    //   // <Container >
    //   <>
    //     <Box
    //       className="banner"
    //       sx={{
    //         width: "100%",
    //         paddingBottom: "150px",
    //         // height: "470px",
    //         // height: "100vh", 
    //         display: "flex",
    //         flexWrap: "wrap",
    //         // margin: { xs: "10px", md: "30px" },
    //       }}
    //     >
    //       <Box
    //         sx={{
    //           flex: 1,
    //           padding: { xs: "20px", md: "50px" },
    //           marginTop: "150px",
    //           marginLeft: { xs: "0px", md: "70px" },
    //         }}
    //       >
    //         <Typography
    //           marginTop={{ md: "50px", xs: "0px" }}
    //           className={classes.mainHeader}
    //         // variant="h3"
    //         // fontWeight="bold"
    //         // textAlign="initial"
    //         // sx={{
    //         //   fontSize: {
    //         //     xs: 25,
    //         //     md: 64,
    //         //     sm: 32,
    //         //     xs: "28px",
    //         //     color: "white",
    //         //   },
    //         // }}
    //         >
    //           Fast & Visual All-in-One Project Management ERP Software for
    //           Architects
    //         </Typography>
    //         {/* <Typography variant="h3"
    //         fontSize="64px"
    //         fontWeight="bold"
    //         color="white"
    //         textAlign="initial"
    //         lineHeight="60px"
    //         sx={{ fontSize: { xs: 42, md: 64, sm: 32, color:'white' } }}>
    //         Construction Management
    //         </Typography> */}

    //         <Typography
    //           className={classes.boldMainContent}
    //           sx={{
    //             // color: "white",
    //             // fontSize: { md: "22px", xs: '15px', sm: '22px' },
    //             marginTop: { md: "25px", xs: '10px' },
    //             // display: { xs: "none", md: "block" },
    //           }}
    //         >
    //           A complete architectural project management ERP software available
    //           in web & mobile platform for Architects
    //         </Typography>
    //         {/* <Typography variant='body1' sx={{color:'white', fontSize:'24px'}}>

    //         Construction ERP software that covers Construction

    //         </Typography>
    //         <Typography variant='body1' sx={{color:'white', fontSize:'24px'}}>
    //         and Project Execution, Purchase & Procurement.
    //         </Typography> */}
    //         {/* <Button
    //           variant="contained"
    //           sx={{
    //             color: "#4F46E5",
    //             background: "white",
    //             "&:hover": {
    //               background: "#e6f7ff",
    //             },
    //             marginTop: "50px",
    //             borderRadius: "10px",
    //             fontSize: { xs: "15px", md: "20px" },
    //             fontWeight: "bold",
    //           }}
    //           onClick={handleOpen}
    //         >
    //           BOOK FOR FREE DEMO <ArrowForwardIcon />
    //         </Button>

    //         <FreeDemo open={open} title="stacharch" handleClose={handleClose} /> */}

    //         <PopUp title="Stacharch" />
    //       </Box>
    //     </Box>

    //     {/* <Box
    //       maxWidth="xl"
    //       sx={{
    //         background: "#F5F5F5",
    //       }}
    //     >
    //       {/* <Fade triggerOnce="true" direction="up"> */}
    //     {/* <Typography
    //         variant="h6"
    //         fontWeight="bold"
    //         textAlign="center"
    //         sx={{
    //           paddingTop: "48px",
    //           fontSize: { xs: 22, md: 32, sm: 32 },
    //           color: "#1F1F1F",
    //           p: { md: 2, xs: 1 },
    //         }}
    //       >
    //         StackArch has the modern tools you need to efficiently manage
    //         projects,while dramatically improving profitability.
    //       </Typography> */}
    //     {/* <Typography
    //           variant="h6"
    //           fontWeight="bold"
    //           textAlign="center"
    //           sx={{ fontSize: { xs: 25, md: 32, sm: 32 }, color: "#1F1F1F" }}
    //         >
    //           while dramatically improving profitability.
    //         </Typography> */}
    //     {/* <Container sx={{ display: "flex", marginTop: "20px" }}>
    //         <Grid container spacing={3}>
    //           <Grid item md={4} sm={4} xs={12}>
    //             <Card sx={{ boxShadow: "none", background: "#F5F5F5" }}>
    //               <CardContent>
    //                 <img
    //                   src={collaboration}
    //                   alt=""
    //                   style={{ height: "100px", textAlign: "center" }}
    //                 />
    //                 <Typography fontSize="24px" sx={{ color: "#4F46E5" }}>
    //                   Improve Collaboration
    //                 </Typography>

    //                 <Typography fontSize="17px" sx={{ marginTop: "20px" }}>
    //                   All team collaboration possible with Cloud base StackArch
    //                 </Typography>
    //               </CardContent>
    //             </Card>
    //           </Grid>

    //           <Grid item md={4} sm={4} xs={12}>
    //             <Card sx={{ boxShadow: "none", background: "#F5F5F5" }}>
    //               <CardContent>
    //                 <img src={easytouse} alt="" style={{ height: "100px" }} />
    //                 <Typography fontSize="24px" sx={{ color: "#4F46E5" }}>
    //                   Easy Task creations
    //                 </Typography>
    //                 <Typography fontSize="17px" sx={{ marginTop: "20px" }}>
    //                   Fast visualisation task under each projects, easy to create
    //                 </Typography>
    //               </CardContent>
    //             </Card>
    //           </Grid>

    //           <Grid item md={4} sm={4} xs={12}>
    //             <Card sx={{ boxShadow: "none", background: "#F5F5F5" }}>
    //               <CardContent>
    //                 <img src={card} alt="" style={{ height: "100px" }} />
    //                 <Typography fontSize="24px" sx={{ color: "#4F46E5" }}>
    //                   Keep all documents safe
    //                 </Typography>
    //                 <Typography fontSize="17px" sx={{ marginTop: "20px" }}>
    //                   Cloud base StackArch ERP keeps file safe & access
    //                   anywhere,any time.
    //                 </Typography>
    //               </CardContent>
    //             </Card>
    //           </Grid>
    //         </Grid>
    //       </Container> */}
    //     {/* </Fade> */}
    //     {/* </Box> */}
    //   </>
    //   // </Container>
    // );
};

export default Banner;
