import React from 'react'
import { useStyles } from '../../styles';
import { Box, Button, Typography, Stack, Grid, Card, CardContent, IconButton } from "@mui/material";
import { Container } from "@mui/system";
import entrprise12 from "../../assets/img/services/entrprise12.png";
import Techstack from "../Techstack";
import enterprisebg from "../../assets/img/services/enterprisebg.png";
import adaptivebg from "../../assets/img/services/adaptivebg.png";


import react from "../../assets/img/services/logo/reactlogo.png";
import angular from "../../assets/img/services/logo/angularLogo.png";
import node from "../../assets/img/services/logo/nodelogo.png";
import mongo from "../../assets/img/services/logo/mongoLogo.png";
import mui from "../../assets/img/services/logo/muilogo.png";
import express from "../../assets/img/services/logo/expressLogo.png";
import appDevBanner from "../../assets/img/services/appDevBanner.png";
import planning from "../../assets/img/services/planning.png";
import appDevFlow from "../../assets/img/services/appDevFlow.png";
import appDevSettings from "../../assets/img/services/appDevSettings.png";
import radioButton from "../../assets/img/services/radioButton.png";

import fintech from "../../assets/img/services/fintech.png";
import ecom from "../../assets/img/services/ecom.png";
import education from "../../assets/img/services/education.png";
import health from "../../assets/img/services/health.png";

import multimedia from "../../assets/img/services/multimedia.png";

import apple from "../../assets/img/services/apple.png";
import android from "../../assets/img/services/android.png";
import cross from "../../assets/img/services/cross.png";
import watch from "../../assets/img/services/watch.png";

import appDevFlowMob from "../../assets/img/services/appDevFlowMob.png"

import Marquee from "react-fast-marquee";

function ApplicationDev() {
    const classes = useStyles();
    const icons = [
        {
            img: react
        },
        {
            img: angular
        },
        {
            img: node
        },
        {
            img: mongo
        },
        {
            img: mui
        },
        {
            img: express
        },
    ]
    const industries = [
        {
            img: fintech,
            title: "Fintech",
            content: "We customize Mobile apps for managing finances, investments, and stock trading"
        },
        {
            img: ecom,
            title: "E-commerce",
            content: "Ecommerce Mobile apps makes your online shopping, payment processing, and order tracking easier."
        },
        {
            img: education,
            title: "Education",
            content: "Develop mobile apps that offer e-learning solutions, online courses, and other educational materials."
        },
        {
            img: health,
            title: "Health",
            content: "Develop mobile apps that track physical activity, monitor nutrition, and provide personalized workout plans."
        },
        {
            img: fintech,
            title: "Food & Beverage",
            content: "Mobile apps for ordering food, making reservations, and leaving reviews"
        },
        {
            img: ecom,
            title: "Travel",
            content: "We Create mobile apps that provide users with information about hotels, flights, car rentals, and other travel-related services."
        },
        {
            img: education,
            title: "Real Estate",
            content: "Create mobile apps that enable users to search for properties, schedule visits, and make offers.  "
        },
        {
            img: health,
            title: "Social Networking",
            content: "Create mobile apps that enable users to connect and interact with each other through social media platforms. "
        },


    ]
    const platforms = [
        {
            img: apple,
            title: "Ios",
            content: "We create mobile apps for devices with the iOS operating system including iPhones, iPads, Apple TVs, and App Clips."
        },
        {
            img: android,
            title: "Android",
            content: "We offers custom Android mobile app development services to clients looking to create innovative and user-friendly mobile applications for the Android platform."
        },
        {
            img: cross,
            title: "Cross Platform",
            content: "Stackroots offers cross-platform mobile app development services to clients looking to create mobile applications that can run on multiple operating systems such as Android and iOS."
        },
        {
            img: watch,
            title: "Wearable Devices",
            content: "Our team of experienced developers has the expertise to create custom mobile applications that are compatible with wearable devices such as Android Wear and Apple Watch."
        },

    ]
    return (
        <div style={{ background: "#131331" }}>
            <div
                className="adaptivebg"
                style={{
                    backgroundImage: `url(${adaptivebg})`,
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        // height: "100vh",
                        display: "flex",
                        flexWrap: "wrap",
                        p: { xs: 3, md: 6 },
                    }}
                >
                    <Stack
                        direction={{ xs: "column", md: "row" }}
                        sx={{
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                        mt={{ xs: 5, md: 0 }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                flex: 1,
                            }}
                        >
                            <Typography
                                className={classes.mainHeader}
                                textAlign={{ xs: "center", md: "initial" }}
                                mb={3}
                            >
                                Mobile Application Development
                            </Typography>
                            <Box
                                sx={{
                                    marginTop: { xs: "0px", md: "40px" },
                                    flex: 1,
                                    display: { xs: "flex", md: "none" },
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Box component="img" width={{ sm: "70%" }} src={appDevBanner} alt="banner" />
                            </Box>
                            <Typography
                                marginTop="10px"
                                className={classes.mainContent}
                            >
                                STACKROOTS specialize in creating top-notch mobile applications using the latest technologies. Our team of experienced developers, designers, and project managers work together to bring your ideas to life. We pride ourselves on staying up-to-date with the latest trends, technologies and advancements in the world of mobile app development, and we are committed to delivering high-quality apps that exceed expectations.
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                marginTop: { xs: "0px", md: "0px" },
                                flex: 1,
                                display: { xs: "none", md: "flex" },
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <img src={appDevBanner} width="70%" alt="banner" />
                        </Box>
                    </Stack>
                </Box>

                <Stack
                    className="adaptivebg"
                    sx={{
                        backgroundImage: `url(${planning})`,
                        backgroundSize: { xs: "cover", md: "contain" }
                    }}
                >
                    <Stack sx={{ marginTop: { xs: 0, md: "60px" }, p: { xs: 2, md: 6 } }} alignItems="center" justifyContent="center">
                        <Typography
                            textAlign={"center"}
                            maxWidth={"600px"}
                            className={classes.mainHeader}
                            mb={2}
                            sx={{
                                marginTop: { xs: "20px", md: 0, sm: 0 },
                            }}
                        >
                            Why Mobile App Development
                            Is Important For Businesses?
                        </Typography>
                        <Box component={"img"} src={appDevFlow} width={{ xs: "100%", md: "70%" }} display={{ xs: "none", sm: "flex" }} />
                        <Box component={"img"} src={appDevFlowMob} width={{ xs: "50%", md: "70%" }} display={{ xs: "flex", sm: "none" }} />

                    </Stack>
                </Stack>

                <Stack mt={{ xs: 3, sm: 5 }}>
                    <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>

                        <Box component={"img"} src={appDevSettings} width={{ xs: "70%", sm: "50%", md: "30%" }} />

                        <Stack maxWidth={"485px"} spacing={3}>
                            <Typography className={classes.mainHeader}>
                                WHAT WE OFFER?
                            </Typography>

                            <Typography className={classes.mainContent}>
                                STACKROOTS being a mobile app development company, we provide several services that help  our  clients to help them build high-quality, user-friendly, and scalable mobile applications. Here are some of the key services that we offer:
                            </Typography>

                            <Stack spacing={1}>
                                <Stack direction={"row"} alignItems="center" spacing={2}>
                                    <Box component={"img"} src={radioButton} width={"24px"} />
                                    <Typography className={classes.mainContent}>
                                        UI\UX design
                                    </Typography>
                                </Stack>
                                <Stack direction={"row"} alignItems="center" spacing={2}>
                                    <Box component={"img"} src={radioButton} width={"24px"} />
                                    <Typography className={classes.mainContent}>
                                        Quality assurance and testing
                                    </Typography>
                                </Stack>
                                <Stack direction={"row"} alignItems="center" spacing={2}>
                                    <Box component={"img"} src={radioButton} width={"24px"} />
                                    <Typography className={classes.mainContent}>
                                        App maintenance
                                    </Typography>
                                </Stack>
                                <Stack direction={"row"} alignItems="center" spacing={2}>
                                    <Box component={"img"} src={radioButton} width={"24px"} />
                                    <Typography className={classes.mainContent}>
                                        Technical support
                                    </Typography>
                                </Stack>
                                <Stack direction={"row"} alignItems="center" spacing={2}>
                                    <Box component={"img"} src={radioButton} width={"24px"} />
                                    <Typography className={classes.mainContent}>
                                        Consultation and strategy
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>

                    </Stack>
                </Stack>

                <Stack>
                    <Stack p={{ xs: 2, md: 6 }} mt={{ xs: 2, sm: 5 }} alignItems="center" justifyContent="center" spacing={{ xs: 1, md: 3 }}>
                        <Typography className={classes.mainHeader}>Industries We Serve</Typography>
                        <Grid container align="center" justify="center" rowSpacing={10}>
                            {industries.map((item) => (

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Stack sx={{
                                        width: "280px",
                                        height: "280px"
                                    }} alignItems="flex-start" spacing={2}>

                                        <Box component="img" src={item.img} width={"80px"} />

                                        <Typography className={classes.subMainHeader}> {item.title}</Typography>
                                        <Typography textAlign={"left"} className={classes.mainContent}>
                                            {item.content}
                                        </Typography>

                                    </Stack>
                                </Grid>
                            ))
                            }
                        </Grid>
                    </Stack>
                </Stack>

                <Stack mt={5}>
                    <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row-reverse" }} alignItems={"center"} justifyContent={"space-around"} spacing={{ xs: 3, md: 0 }}>

                        <Box component={"img"} src={multimedia} width={{ xs: "90%", sm: "50%", md: "30%" }} />

                        <Stack maxWidth={"485px"} spacing={3}>
                            <Typography textAlign={{ xs: "center", md: "left" }} className={classes.mainHeader}>
                                Our scope of mobile app
                                development services
                            </Typography>

                            <Typography className={classes.mainContent}>
                                STACKROOTS is an expert in delivering high-performance and scalable enterprise mobile apps in Android and iOS. Our team of skilled professional mobile app developers designs high-quality, user-friendly, and scalable mobile applications.
                            </Typography>

                        </Stack>

                    </Stack>
                </Stack>

                <Stack>
                    <Stack p={{ xs: 2, md: 6 }} mt={{ xs: 2, sm: 5 }} alignItems="center" justifyContent="center" spacing={{ xs: 0, md: 3 }}>
                        <Typography className={classes.mainHeader} textAlign={{ xs: "center" }}>Building apps on All Mobile platforms</Typography>
                        <Grid container align="center" justify="center" rowSpacing={{ xs: 5, md: 10 }}>
                            {platforms.map((item) => (

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Stack sx={{
                                        width: "280px",
                                        height: "280px"
                                    }} alignItems="flex-start" spacing={2}>

                                        <Box component="img" src={item.img} width={"80px"} />

                                        <Typography className={classes.subMainHeader}> {item.title}</Typography>
                                        <Typography textAlign={"left"} className={classes.mainContent}>
                                            {item.content}
                                        </Typography>

                                    </Stack>
                                </Grid>
                            ))
                            }
                        </Grid>
                    </Stack>
                </Stack>

            </div>


            <Box sx={{ marginTop: "50px", display: { xs: "none", md: "block", sm: "block" } }}>
                <Techstack />
            </Box>

            <Box p={3} sx={{ marginTop: "50px", display: { xs: "block", md: "none", sm: "none" } }} >
                <Typography
                    sx={
                        { "fontStyle": "normal", "fontWeight": "600", "fontSize": "24px", "lineHeight": "29px", "color": "#FFFFFF", textAlign: "center" }
                    }
                >
                    Expertise And Technical Skills
                </Typography>
                <Stack>
                    <Marquee gradient={false}>
                        {icons.map((icon) => (
                            <Box component={"img"}
                                sx={{
                                    mt: "25px",
                                    width: "105px"
                                }}
                                src={icon.img}
                                alt={"tech stack"}
                            />
                        ))
                        }
                    </Marquee>

                    <Marquee gradient={false} direction={"right"}>
                        {icons.map((icon) => (
                            <Box component={"img"}
                                sx={{
                                    mt: "25px",
                                    width: "105px"
                                }}
                                src={icon.img}
                                alt={"tech stack"}
                            />
                        ))
                        }
                    </Marquee>
                </Stack>
            </Box>
        </div>
    )
}

export default ApplicationDev