import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FreeDemo from "./FreeDemo";
import { Fade } from "react-awesome-reveal";
import { Stack } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialog-paper": {
    backgroundColor: "#131331 !important",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function PopUp({ title, className, buttonTitle, button = true }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="">
      <Fade triggerOnce="true" direction="up">
        <Button
          variant="contained"
          sx={{
            color: "#4F46E5",
            background: "white",
            "&:hover": {
              background: "#e6f7ff",
            },
            marginTop: "50px",
            borderRadius: "10px",
            fontSize: { xs: "15px", md: "20px" },
            fontWeight: "bold",
            display: `${button ? "block" : "none"}`
          }}
          className="bg-white"
          onClick={handleClickOpen}
        >
          {`${buttonTitle ? buttonTitle : "BOOK FOR FREE DEMO"}`} <ArrowForwardIcon />
        </Button>
      </Fade>
      <div className="h-16 w-16 fixed z-30 right-0 top-80">
        <Stack sx={{
          position: 'absolute',
          transform: 'rotate(90deg)',
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: { xs: "0.2rem", md: '1.2rem' },
          padding: { xs: "0rem", md: ' 0.5rem' },
          left: { xs: '-20px', md: "-34px" },
          top: '32px',
          width: { xs: "140px", md: '170px' },
          cursor: 'pointer',
          background: 'white',
          color: ' #2e52a4',
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }} onClick={handleClickOpen}>
          <Typography sx={{
            mt: { xs: 0, md: 1 },
            fontFamily: "inherit",
            fontWeight: 'bold',
            fontSize: { xs: "1rem", md: '1.2rem' }
          }}>

            Book for a Demo
          </Typography>
        </Stack>
      </div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Book For {title} Demo
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <FreeDemo title={title} handleClose={handleClose} />
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose}>
            Save changes
          </Button> */}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
