import React from "react";
import { Box, Button, Typography, Stack, Grid, Card, CardContent, IconButton } from "@mui/material";
import { Container } from "@mui/system";
import Bigphone from "../../assets/img/services/Bigphone.png";
import trolley from "../../assets/img/services/trolley.png";
import Techstack from "../Techstack";
import ecomfeatures from "../../assets/img/services/ecomfeatures.png";
import ecomfeatures2 from "../../assets/img/services/crmfeatures1.png";
import ecomfeatures1 from "../../assets/img/services/crmbg1.png";
import adaptivebg from "../../assets/img/services/adaptivebg.png";
import crmbg from "../../assets/img/services/crmbg.png";
import creative from "../../assets/img/services/vectors/creative.png";
import storefront from "../../assets/img/services/vectors/storefront.png";
import support from "../../assets/img/services/vectors/support.png";

import react from "../../assets/img/services/logo/reactlogo.png";
import angular from "../../assets/img/services/logo/angularLogo.png";
import node from "../../assets/img/services/logo/nodelogo.png";
import mongo from "../../assets/img/services/logo/mongoLogo.png";
import mui from "../../assets/img/services/logo/muilogo.png";
import express from "../../assets/img/services/logo/expressLogo.png";
import Marquee from "react-fast-marquee";
import { useStyles } from "../../styles";

const EcommerceApp = () => {
  const classes = useStyles();
  const icons = [
    {
      img: react
    },
    {
      img: angular
    },
    {
      img: node
    },
    {
      img: mongo
    },
    {
      img: mui
    },
    {
      img: express
    },
  ]
  return (
    <div style={{ background: "#131331" }}>
      <div
        className="adaptivebg"
        style={{
          backgroundImage: `url(${adaptivebg})`,
        }}
      >
        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={12}
          >
            <Box
              sx={{
                display: "flex",

                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}
              >
                E-Commerce Application
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "20px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={Bigphone} alt="banner" />
              </Box>
              <Typography
                className={classes.mainContent}
                marginTop="20px"
              >
                At Stackroots, we specialize in creating customized e-commerce applications that are tailored to meet the unique needs of our clients. Stackroots e-commerce application development ensures your virtual showroom is easy to use and boosts your sales. with e-commerce, your business will be visible all day to customers wishing to make a purchase from all across the world. We provide you with a creative, virtual store offering all the services you want. All the projects done by Stackroots are unique in their creation because customization is intrinsic to what we do. Our team of experts understands the evolving landscape of e-commerce and works diligently to ensure that your virtual showroom is user-friendly and optimized for maximum sales.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={Bigphone} alt="banner" />
            </Box>
          </Stack>
        </Box>

        <Box component={"div"}
          className="adaptivebg"
          sx={{
            background: { xs: `url(${ecomfeatures1})`, md: `url(${crmbg})` },
          }}
        >
          <Box sx={{ marginTop: { xs: "0px", md: "50px" } }} p={{ xs: 3, md: 0 }}>
            <Typography
              className={classes.mainHeader}
              textAlign="center"
              sx={{

                marginBottom: { xs: "20px", md: "50px" },
                marginTop: { xs: "10px", md: 0, sm: 0 },
              }}
            >
              Features of E-commerce application
            </Typography>
            <Box component="img"
              src={ecomfeatures2}
              alt="banner"
              sx={{ margin: "0 auto", display: { xs: "block", md: "none" } }} />
            <Box component="img"
              src={ecomfeatures}
              alt="banner"
              sx={{ margin: "0 auto", display: { xs: "none", md: "block" } }} />
          </Box>
        </Box>
      </div>

      <Box
        sx={{
          width: "100%",
          // height: "100vh",
          display: "flex",
          flexWrap: "wrap",
          p: { xs: 3, md: 6 },
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          sx={{
            alignItems: "center",
            justifyContent: "center",
          }}
          mt={12}
        >
          <Box
            sx={{
              display: "flex",

              flexDirection: "column",
              flex: 1,
            }}
          >
            <Typography
              className={classes.mainHeader}
              textAlign={{ xs: "center", md: "initial" }}
              mb={3}
            >
              Creative and Customized Solutions
            </Typography>
            <Box
              sx={{
                marginTop: { xs: "20px", md: "40px" },
                flex: 1,
                display: { xs: "flex", md: "none" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={creative} />
            </Box>
            <Typography
              className={classes.mainContent}
              marginTop="20px"
            >
              We believe in the power of customization. Our dedicated team of experts works closely with you to understand your business goals and objectives, allowing us to develop an e-commerce application that perfectly suits your specific needs. We create unique and innovative solutions that set your business apart from the competition. Whether you need a simple online store or a complex multi-vendor marketplace, we have the expertise to deliver outstanding results. We ensure that your e-commerce application is designed to attract and engage customers, making it easy for them to browse products, make purchases, and provide feedback.
            </Typography>
          </Box>

          <Box
            sx={{
              marginTop: { xs: "0px", md: "40px" },
              flex: 1,
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img alt="vector" src={creative} />
          </Box>
        </Stack>
      </Box>


      <Box
        sx={{
          width: "100%",
          // height: "100vh",
          display: "flex",
          flexWrap: "wrap",
          p: { xs: 3, md: 6 },
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row-reverse" }}
          sx={{
            alignItems: "center",
            justifyContent: "center",
          }}
          mt={12}
        >
          <Box
            sx={{
              display: "flex",

              flexDirection: "column",
              flex: 1,
            }}
          >
            <Typography
              className={classes.mainHeader}
              textAlign={{ xs: "center", md: "initial" }}
              mb={3}
            >
              User-Friendly Virtual Storefront
            </Typography>
            <Box
              sx={{
                marginTop: { xs: "20px", md: "40px" },
                flex: 1,
                display: { xs: "flex", md: "none" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={storefront} />
            </Box>
            <Typography
              className={classes.mainContent}
              marginTop="20px"
            >
              A key aspect of successful e-commerce is providing a user-friendly experience for customers. Our team of designers focuses on creating intuitive and visually appealing virtual storefronts that make it easy for customers to find and purchase products. We prioritize usability, ensuring that your e-commerce application is responsive, fast-loading, and optimized for mobile devices. By providing a seamless and enjoyable shopping experience, we help drive customer engagement and increase conversions.
            </Typography>
          </Box>

          <Box
            sx={{
              marginTop: { xs: "0px", md: "40px" },
              flex: 1,
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img alt="vector" src={storefront} />
          </Box>
        </Stack>
      </Box>

      {/*  */}

      <Box
        sx={{
          width: "100%",
          // height: "100vh",
          display: "flex",
          flexWrap: "wrap",
          p: { xs: 3, md: 6 },
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          sx={{
            alignItems: "center",
            justifyContent: "center",
          }}
          mt={12}
        >
          <Box
            sx={{
              display: "flex",

              flexDirection: "column",
              flex: 1,
            }}
          >
            <Typography
              className={classes.mainHeader}
              textAlign={{ xs: "center", md: "initial" }}
              mb={3}
            >
              High-Quality Result and Support
            </Typography>
            <Box
              sx={{
                marginTop: { xs: "20px", md: "40px" },
                flex: 1,
                display: { xs: "flex", md: "none" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={support} />
            </Box>
            <Typography
              className={classes.mainContent}
              marginTop="20px"
            >
              At Stackroots, we are committed to delivering high-quality results. Our team follows industry best practices and uses cutting-edge technologies to develop robust and scalable e-commerce applications. We also provide ongoing support and maintenance to ensure that your application continues to perform optimally. Our goal is to help your business thrive in the competitive e-commerce market.
            </Typography>
          </Box>

          <Box
            sx={{
              marginTop: { xs: "0px", md: "40px" },
              flex: 1,
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img alt="vector" src={support} />
          </Box>
        </Stack>
      </Box>

      <Box sx={{ marginTop: "50px", display: { xs: "none", md: "block", sm: "block" } }}>
        <Techstack />
      </Box>

      <Box sx={{ marginTop: "50px", p: 3, mb: "60px", display: { xs: "block", md: "none", sm: "none" } }}>
        <Typography
          sx={
            { "fontStyle": "normal", "fontWeight": "600", "fontSize": "24px", "lineHeight": "29px", "color": "#FFFFFF", textAlign: "center" }
          }
        >
          Expertise And Technical Skills
        </Typography>

        <Stack>
          <Marquee gradient={false}>
            {icons.map((icon) => (
              <Box component={"img"}
                sx={{
                  mt: "25px",
                  width: "105px"
                }}
                src={icon.img}
                alt={"tech stack"}
              />
            ))
            }
          </Marquee>

          <Marquee gradient={false} direction={"right"}>
            {icons.map((icon) => (
              <Box component={"img"}
                sx={{
                  mt: "25px",
                  width: "105px"
                }}
                src={icon.img}
                alt={"tech stack"}
              />
            ))
            }
          </Marquee>
        </Stack>

      </Box>
    </div>
  );
}

export default EcommerceApp