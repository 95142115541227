import heroBanner from "../assets/img/banner.png";
import Brochure from "../assets/img/brochure.pdf";

import ReactTyped from "react-typed";

import { Stack, Typography } from "@mui/material";
import { useStyles } from "../styles";
import { CustomButton } from "../utils/Button";


export default function Hero() {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = Brochure;
    link.download = Brochure;
    link.click();
  };
  const classes = useStyles();

  return (
    <>
      <Stack
        // className="flex flex-wrap w-full  pt-36 pb-14 lg:pb-96 lg:pt-48"
        alignItems={"flex-start"} justifyContent={"center"}
        sx={{
          backgroundImage: `url(${heroBanner})`,
          backgroundPosition: "50% 100%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: { xs: "70vh", md: "90vh" },
          px: { xs: 1, sm: 5 }
        }}
      >
        <div className="flex items-center justify-center w-full lg:w-1/2">
          <Stack className=" mb-8  ml-4" style={{ maxWidth: "700px", width: "700px", }}>
            <Typography height={{ xs: "180px", sm: "150px" }}
              className={classes.mainHeader}
            >
              <ReactTyped
                loop={false}
                typeSpeed={40}
                strings={["CREATOR OF BLOCKCHAIN APPLICATIONS FOR ALL BUSINESS. BEST WEB APPLICATION DEVELOPMENT COMPANY. "]}
                shuffle={false}
                fadeOut={false}
                fadeOutDelay={100}
                loopCount={0}
                showCursor
                cursorChar="|"
              />
            </Typography>
            <Stack direction={"row"} mt={{ xs: 5, lg: 8, md: 16, xl: 5 }}>

              {/* <CustomButton
                sx={{ padding: "10px 30px" }}
                onClick={handleDownload}
              >
                Download
              </CustomButton> */}

            </Stack>
          </Stack>

        </div>

      </Stack>
    </>
  );
}
