import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";

import architech from "../../assets/img/products/archBuilding.png";
import { useStyles } from "../../styles";

const Descriptions1 = () => {
  const classes = useStyles();
  return (
    // <div className="container mx-auto px-10 m-5 sm:flex justify-between items-stretch ">
    //   <div className="py-10">
    <>
      <div
        className="hero_about_bg"
        style={{
          // backgroundImage: `url(${aboutLink})`,
          background: `url(${architech})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundBlendMode: "hard-light",


        }}
      >
        <Container maxWidth="xl">
          <Grid
            container
            padding="60px 2px 60px 20px"
            alignItems={"center"}
            spacing={2}
          >
            <Grid item xs={12} sm={6}>
              <Box sx={{ flex: 1 }}>
                <Typography
                  // variant="h4"
                  // className="text-white"
                  // fontWeight={{ xs: "600", md: "bold" }}
                  // fontSize={{ xs: "28px", md: 32 }}
                  // gutterBottom
                  className={classes.mainHeader}
                >
                  StackArch project management software built for architects, &
                  contractors etc.
                </Typography>
                <Typography mt={2} className={classes.mainContent}>
                  Deliver every project on time, StackArch architectural ERP
                  comes up with hierarchical control of each project, Managerial
                  module control over each project management. Also StackArch
                  ERP consists of task wise listing in project modules that help
                  architects to monitor each task completion related to that
                  project.
                  <br />
                  Control over each task status update that can help the team to
                  understand each task status under the projects. Each project
                  forecasting can help with the help of retrieved data from the
                  system.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default Descriptions1;
