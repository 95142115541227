import {
  Box,
  Stack,
  Typography
} from "@mui/material";
import React from "react";
import degree1 from "../../assets/img/products/degree1.png";
import adaptivebg from "../../assets/img/services/adaptivebg.png";
import crmbg from "../../assets/img/services/crmbg.png";
import Techstack from "../Techstack";

import Marquee from "react-fast-marquee";
import angular from "../../assets/img/services/logo/angularLogo.png";
import express from "../../assets/img/services/logo/expressLogo.png";
import mongo from "../../assets/img/services/logo/mongoLogo.png";
import mui from "../../assets/img/services/logo/muilogo.png";
import node from "../../assets/img/services/logo/nodelogo.png";
import react from "../../assets/img/services/logo/reactlogo.png";
import { useStyles } from "../../styles";


import provenResults from "../../assets/img/services/vectors/provenResults.png";
import personalized from "../../assets/img/services/vectors/personalized.png";
import welet from "../../assets/img/services/vectors/welet.png";

const Seo1 = () => {
  const classes = useStyles();

  const icons = [
    {
      img: react
    },
    {
      img: angular
    },
    {
      img: node
    },
    {
      img: mongo
    },
    {
      img: mui
    },
    {
      img: express
    },
  ]
  return (
    <div style={{ background: "#131331" }}>
      <div
        className="adaptivebg"
        style={{
          backgroundImage: `url(${adaptivebg})`,
        }}
      >
        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={12}
          >
            <Box
              sx={{
                display: "flex",

                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}
              >
                SEO and Digital Marketing Services
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "0px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={degree1} alt="banner" />
              </Box>
              <Typography
                mt={2}
                className={classes.mainContent}
              >
                We offer the best SEO and digital marketing services to our clientele. Our team takes your digital presence to the next level by implementing a unique digital marketing strategy tailored to your business needs and goals. With our expertise in digital marketing and innovative strategies, we ensure continuous business growth for our clients. Our goal is to ensure that your website ranks on the first page of search results. We use effective SEO techniques to optimize your website and increase its visibility to potential customers. By improving your site's ranking, we increase the chances of potential customers finding your website and engaging with your brand.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={degree1} alt="banner" />
            </Box>
          </Stack>
        </Box>



        {/*  */}

        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row-reverse" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={12}
          >
            <Box
              sx={{
                display: "flex",

                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}
              >
                Proven Results and Measurable Success
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "20px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img alt="vector" src={provenResults} />
              </Box>
              <Typography
                className={classes.mainContent}
                marginTop="20px"
              >
                At Stackroots, We have a proven track record of delivering page-1 rankings for our clients' websites. Our team uses advanced analytics tools to track and measure the success of our SEO and digital marketing campaigns. This allows us to make data-driven decisions and optimize our efforts for better return on investment (ROI). Comprehensive Digital Marketing Solutions In addition to SEO, we offer a range of digital marketing services to ensure a comprehensive approach. This includes social media marketing, content marketing, paid advertising, and more. We integrate all aspects of digital marketing seamlessly to maximize their impact and drive more sales for your business.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={provenResults} width={"80%"} />
            </Box>
          </Stack>
        </Box>


        {/*  */}

        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={12}
          >
            <Box
              sx={{
                display: "flex",

                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}
              >
                Personalized Approach and Customer Satisfaction
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "20px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img alt="vector" src={personalized} />
              </Box>
              <Typography
                className={classes.mainContent}
                marginTop="20px"
              >
                We understand the importance of delivering a personalized customer experience. Our team tracks customer interactions and preferences, enabling us to tailor marketing messages and offers to individual customers. By providing a personalized experience, we enhance customer satisfaction and loyalty, ultimately driving business growth. We focus on increasing organic visibility and driving organic traffic to your website. Our team conducts thorough keyword research to identify the most relevant and high-performing keywords for your business. By optimizing your website with these keywords, we attract targeted traffic and increase the chances of conversions.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={personalized} />
            </Box>
          </Stack>
        </Box>


        {/*  */}

        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row-reverse" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={12}
          >
            <Box
              sx={{
                display: "flex",

                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}
              >
                We let our work do the talking
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "20px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img alt="vector" src={welet} />
              </Box>
              <Typography
                className={classes.mainContent}
                marginTop="20px"
              >
                If you're looking to enhance your digital marketing efforts and achieve long-term growth, contact Stackroots today. Our team of experts will work closely with you to understand your business goals and develop a customized digital marketing strategy that delivers results. Let us help you reach your marketing goals and drive business growth. We let our work do the talking and assure your website is optimized to get the best search result it deserves.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={welet} />
            </Box>
          </Stack>
        </Box>

      </div>

      <Box sx={{ marginTop: "50px", display: { xs: "none", md: "block", sm: "block" } }}>
        <Techstack />
      </Box>

      <Box p={3} sx={{ marginTop: "50px", display: { xs: "block", md: "none", sm: "none" } }} >
        <Typography
          sx={
            { "fontStyle": "normal", "fontWeight": "600", "fontSize": "24px", "lineHeight": "29px", "color": "#FFFFFF", textAlign: "center" }
          }
        >
          Expertise And Technical Skills
        </Typography>
        <Stack>
          <Marquee gradient={false}>
            {icons.map((icon) => (
              <Box component={"img"}
                sx={{
                  mt: "25px",
                  width: "105px"
                }}
                src={icon.img}
                alt={"tech stack"}
              />
            ))
            }
          </Marquee>

          <Marquee gradient={false} direction={"right"}>
            {icons.map((icon) => (
              <Box component={"img"}
                sx={{
                  mt: "25px",
                  width: "105px"
                }}
                src={icon.img}
                alt={"tech stack"}
              />
            ))
            }
          </Marquee>
        </Stack>
      </Box>

      <div
        className="adaptivebg"
        style={{
          backgroundImage: `url(${crmbg})`,
        }}
      >
        <Box sx={{ marginTop: "50px", p: { xs: 3, md: 12 } }}>
          <Typography
            className={classes.mainHeader}
            mb={2}
          >
            Grow your business with Stackroots
          </Typography>
          <Typography className={classes.mainContent}
            sx={{
              color: "white",
              textAlign: { md: "initial", sm: "initial", xs: "initial" },
            }}
          >
            STACKROOTS is one of the foremost digital marketing agency.
            STACKROOTS helps in promoting your business, boosting the digital
            presence of your business, and increasing sales through modern
            digital techniques. We promise you the best quality service with
            zero assurance. We will give you a leg up on your competition and
            make it easier for people to find you when they're searching online.
          </Typography>
        </Box>
      </div>
    </div>
  );
};

export default Seo1;
