// import { Box, Grid, Paper, Typography } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import Frame from "../../assets/img/products/Frame.png";
import {
  Box,
  Card,
  Grid,
  Paper,
  Typography,
  IconButton,
  Stack,
} from "@mui/material";
import framestack from "../../assets/img/products/framestack.png";
import hospitalLab from '../../assets/img/new/hospitalLab.png'
import { useStyles } from '../../styles';
import icon1 from "../../assets/img/new/icon1.png";
import icon2 from "../../assets/img/new/icon2.png";
import icon3 from "../../assets/img/new/icon3.png";
import icon4 from "../../assets/img/new/icon4.png";
import hospitalChats from "../../assets/img/new/hospitalChats.png";

const Cards = () => {
  const classes = useStyles();
  return (
    <div style={{ backgroundImage: `url(${framestack})`, marginTop: "40px" }}>
      <Stack sx={{ px: { xs: 3, md: 10 } }} gap={10}>
        <Stack
          sx={{
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
          }}
        >
          <div className="flex flex-1 items-center w-full lg:w-1/2">
            <div className="max-w-2xl mb-8">
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  fontSize: { xs: 20, md: 32, sm: 32 },
                  color: "#ffff",
                  textAlign: { xs: "center", md: "initial", sm: "initial" },
                }}
              >
                Efficient Hospital Management with HospitStack
              </Typography>

              <Typography
                variant="body2"
                textAlign="justify"
                sx={{
                  color: "#ffff",
                  marginTop: "20px",
                  fontSize: { md: "18px", xs: "16px", sm: "18px" },
                }}
              >
                HospitStack by Stackroots offers a feature-rich hospital information management system with an online hospital booking and consulting application. It caters to the needs of hospitals and medical clinics by providing seamless appointment booking, robust EHR capabilities, efficient billing and invoicing, inventory management, and insightful reporting and analytics. With its comprehensive features, HospitStack is an excellent solution for healthcare providers looking to enhance patient care and streamline their operations.
              </Typography>
              {/* <Typography
                variant="body2"
                textAlign="justify"
                marginTop="20px"
                sx={{
                  color: "#ffff",
                  fontSize: { md: "18px", xs: "16px", sm: "18px" },
                }}
              >
                StackArch has an easily controllable dashboard to handle the
                project management very accurately. From the dashboard itself
                admin can manage the team productivity analysis, analytical
                project management, forecasting the project timeline and
              </Typography> */}
            </div>
          </div>
          <div className="flex flex-1 items-center justify-center w-full lg:w-1/2 mb-5">
            <div className="">
              <img
                src={hospitalLab}
                width="416"
                height="417"
                alt="Hero Illustration"
                layout="intrinsic"
                loading="eager"
                placeholder="blur"
              />
            </div>
          </div>
        </Stack>


        <Stack mt={5}
          sx={{
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
          }}
        >
          <div className="flex flex-1 items-center w-full lg:w-1/2">
            <div className="max-w-2xl mb-8">
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  fontSize: { xs: 20, md: 32, sm: 32 },
                  color: "#ffff",
                  textAlign: { xs: "center", md: "initial", sm: "initial" },
                }}
              >
                Features of Hospistack
              </Typography>

              <Typography
                variant="body2"
                textAlign="justify"
                sx={{
                  color: "#ffff",
                  marginTop: "20px",
                  fontSize: { md: "18px", xs: "16px", sm: "18px" },
                }}
              >
                HospitStack offers a wide range of features to optimize hospital operations and enhance patient care. Its key features include an intuitive online hospital booking and consulting application, which allows patients to conveniently book appointments and consult with healthcare professionals from anywhere. The system also includes a robust electronic health records (EHR) system for secure storage and easy access to patient information, as well as billing and invoicing functionalities for streamlined financial management. HospitStack further facilitates efficient inventory management, providing real-time tracking and optimization of medical supplies and pharmaceuticals. Additionally, the system offers comprehensive reporting and analytics tools for valuable insights into hospital operations and performance.
              </Typography>
            </div>
          </div>
          <div className="flex flex-1 items-center justify-center w-full lg:w-1/2 mb-5">
            <Stack direction={"row"} flexWrap={"wrap"} gap={3} alignItems={"center"} justifyContent={"center"}>

              <Stack sx={{
                width: "280px",
                height: "240px",
                borderRadius: "5px",
                background: "#1A163E",
                alignItems: "center",
                justifyContent: "center",
                padding: "20px",
                gap: "20px"
              }}>
                <Box component={"img"} src={icon1} width={"80px"} />
                <Typography textAlign={"center"} sx={{

                  fontSize: "25px",
                  fontWeight: "600"
                }} variant={"h6"}>
                  Efficient Hospital Management
                </Typography>

              </Stack>

              <Stack direction={"row"} flexWrap={"wrap"}>
                <Stack sx={{
                  width: "280px",
                  height: "240px",
                  borderRadius: "5px",
                  background: "#1A163E",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "20px",
                  gap: "20px"
                }}>
                  <Box component={"img"} src={icon2} width={"80px"} />
                  <Typography textAlign={"center"} sx={{

                    fontSize: "25px",
                    fontWeight: "600"
                  }} variant={"h6"}>
                    Online Booking and Consultation
                  </Typography>

                </Stack>
              </Stack>
              <Stack direction={"row"} flexWrap={"wrap"}>
                <Stack sx={{
                  width: "280px",
                  height: "240px",
                  borderRadius: "5px",
                  background: "#1A163E",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "20px",
                  gap: "20px"
                }}>
                  <Box component={"img"} src={icon3} width={"80px"} />
                  <Typography textAlign={"center"} sx={{

                    fontSize: "25px",
                    fontWeight: "600"
                  }} variant={"h6"}>
                    Comprehensive Patient Management
                  </Typography>

                </Stack>
              </Stack>
              <Stack direction={"row"} flexWrap={"wrap"}>
                <Stack sx={{
                  width: "280px",
                  height: "240px",
                  borderRadius: "5px",
                  background: "#1A163E",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "20px",
                  gap: "20px"
                }}>
                  <Box component={"img"} src={icon4} width={"80px"} />
                  <Typography textAlign={"center"} sx={{

                    fontSize: "25px",
                    fontWeight: "600"
                  }} variant={"h6"}>
                    Powerful Analytics and Reporting
                  </Typography>

                </Stack>
              </Stack>


            </Stack>

          </div>
        </Stack>

        <Stack
          sx={{
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
          }}
        >
          <div className="flex flex-1 items-center justify-center w-full lg:w-1/2 mb-5">
            <div className="">
              <img
                src={hospitalChats}
                width="416"
                height="417"
                alt="Hero Illustration"
                layout="intrinsic"
                loading="eager"
                placeholder="blur"
              />
            </div>
          </div>
          <div className="flex flex-1 items-center w-full lg:w-1/2">
            <div className="max-w-2xl mb-8">
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  fontSize: { xs: 20, md: 32, sm: 32 },
                  color: "#ffff",
                  textAlign: { xs: "center", md: "initial", sm: "initial" },
                }}
              >
                Why HospiStack?
              </Typography>

              <Typography
                variant="body2"
                textAlign="justify"
                sx={{
                  color: "#ffff",
                  marginTop: "20px",
                  fontSize: { md: "18px", xs: "16px", sm: "18px" },
                }}
              >
                The need for HospitStack, the hospital information management system developed by Stackroots, arises from several crucial aspects of modern healthcare delivery. First and foremost, the healthcare industry is rapidly evolving, and hospitals and medical clinics need efficient and streamlined solutions to cope with the increasing complexity of patient care. HospitStack addresses this need by providing a centralized platform for managing critical hospital operations, resulting in improved efficiency, enhanced patient care, and optimized workflows.
              </Typography>

            </div>
          </div>

        </Stack>

      </Stack>
    </div>
  )
}

export default Cards