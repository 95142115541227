import React from "react";
import BlogSlider from "../components/Blogs/BlogSlider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Container, Stack } from "@mui/material";
import BlogCard from "../components/Blogs/BlogCard";
import RecentBlog from "../components/Blogs/RecentBlog";
import BlogSliderImage from "../components/Blogs/BlogSliderImage";
import { Helmet } from "react-helmet";
import ContactForm from "../components/Contact/ContactForm";
import GetAQuotePopUp from "../components/GetAQuotePopUp";

const Blog = () => {
  return (
    <>
      <Helmet>
        <title>Stackroots | Blog</title>
      </Helmet>
      <BlogSliderImage />
      {/* <ContactForm /> */}
      <GetAQuotePopUp button={false} />


    </>
  );
};

export default Blog;
