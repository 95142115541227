import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import CareerPopup from "./Carreer/CareerPopup";
import "./DropUs.css";

const CareerCard = ({ job }) => {
  const [open, setOpen] = useState();
  console.log("job", job);

  const handleChange = (panel) => (event, isExpanded) => {
    setOpen(isExpanded ? panel : false);
  };
  //   const summary = () => {
  //     for (const [key, value] of Object.entries(job.summary)) {
  //       return `${key}: ${value}`;
  //     }
  //   };
  //   summary();
  // const renderers = {
  //   p: (props) => <p className="markdown">{props.children}</p>,
  // };
  return (
    // <div
    //   tabIndex={0}
    //   className="collapse collapse-plus  bg-transparent rounded-box  border-[2px] border-dashed border-white"
    // >
    //   <input type="checkbox" class="peer" />
    //   <div class="collapse-title p-10  text-white peer-checked:bg-transparent peer-checked:text-white ">
    //     <h6 className=" text-xl font-bold text-ellipsis"> {job.title}</h6>
    //     <h6> Experience {job.summary.experience}</h6>
    //   </div>

    //   <div class="collapse-content  text-white peer-checked:bg-black/25 peer-checked:text-white">
    //     <div className="sm:flex justify-between block">
    //       <div class="card w-full mt-4 shadow-xl bg-[#131331]">
    //         <div className="flex">
    //           <div class="card-body">
    //             <h2 class="card-title">Job Summary</h2>
    //             <Typography
    //               variant="h6"
    //               sx={{
    //                 fontSize: { xs: "12px", md: "16px" },
    //                 letterSpacing: "1px",
    //               }}
    //             >
    //               Job Time: {job.jobType?.replace("_", " ")}
    //               <br />
    //               Experience: {job.summary.experience} <br />
    //               Qualification:
    //               {job.summary.qualification}
    //             </Typography>
    //           </div>
    //         </div>
    //       </div>
    //       {/* {job.file[0]?.file && (
    //         <div class="card w-80 shadow-xl">
    //           <div className="flex">
    //             <div class="card-body">
    //               <h2 class="card-title">Assignments</h2>
    //               <p>
    //                 {job.title} Assignments
    //                 <br />

    //               </p>
    //               <div class="card-actions justify-center">
    //                 <a
    //                   download
    //                   href={job.file[0]?.file}
    //                   class="btn btn-secondary"
    //                 >
    //                   Download
    //                 </a>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       )} */}
    //     </div>

    //     <div class="card w-full shadow-xl mt-4  bg-[#131331] ">
    //       <div className="flex">
    //         <div class="card-body">
    //           <ReactMarkdown rehypePlugins={rehypeRaw} className="markdown">
    //             {job.description}
    //           </ReactMarkdown>
    //         </div>
    //       </div>
    //     </div>
    //     <div class="card-actions justify-center mt-4">
    //       <CareerPopup title={job.title} titleId={job._id} />
    //     </div>
    //   </div>
    // </div>

    <Accordion
      className="bg-transparent rounded-box  border-[2px] border-dashed border-white p-2 "
      expanded={open === "panel1"}
      onChange={handleChange("panel1")}
    >
      <AccordionSummary
        expandIcon={
          open ? (
            <RemoveIcon sx={{ color: "white" }} />
          ) : (
            <AddIcon sx={{ color: "white" }} />
          )
        }
        aria-controls="panel1a-content" 
        id="panel1a-header"
      >
        <Stack>
          <Typography variant="h6" fontWeight={"bold"}>
            {job.title}
          </Typography>

          {/* <Typography variant="subtitle1">{job.summary.experience}</Typography>  */}
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <div className="sm:flex justify-between block">
            <div class="card w-full mt-4 shadow-xl bg-[#131331]">
              <div className="flex">
                <div class="card-body">
                  <h2 class="card-title">Job Summary</h2>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: { xs: "12px", md: "16px" },
                      letterSpacing: "1px",
                    }}
                  >
                    Job Time: {job.jobType?.replace("_", " ")}
                    <br />
                    Experience: {job.summary.experience} <br />
                    Qualification:
                    {job.summary.qualification}
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div class="card w-full shadow-xl mt-4  bg-[#131331]  ">
            <div className="flex">
              <div class="card-body">
                <ReactMarkdown rehypePlugins={rehypeRaw} className="markdown">
                  {job.description}
                </ReactMarkdown>
              </div>
            </div>
          </div>
          <div class="card-actions justify-center mt-4">
            <CareerPopup title={job.title} titleId={job.designationId} />
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default CareerCard;
