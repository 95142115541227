import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { useStyles } from '../../styles'

import shapesVector from "../../assets/img/products/shapesVector.png"
import Card from './Card'


import upgradeResto from "../../assets/img/services/vectors/upgradeResto.png"

import androidIOS from "../../assets/img/services/vectors/icons/androidIOS.png"
import foodMenu from "../../assets/img/services/vectors/icons/foodMenu.png"
import orderMgmt from "../../assets/img/services/vectors/icons/orderMgmt.png"
import ordering from "../../assets/img/services/vectors/icons/ordering.png"
import payment from "../../assets/img/services/vectors/icons/payment.png"
import realTime from "../../assets/img/services/vectors/icons/realTime.png"


function Details() {
    const classes = useStyles();

    const cardData = [
        {
            title: "Eye-Catching Menu Display",
            desc: "Showcase your culinary masterpieces with captivating images and detailed descriptions. Our menu display captivates customers and helps them make choices.",
            img: foodMenu,
        },
        {
            title: "Effortless Ordering",
            desc: "Our interface allows customers to browse your menu, customize orders, and place them directly through the app. This minimizes errors and boosts satisfaction.",
            img: ordering,
        },
        {
            title: "Real-Time Updates",
            desc: "Keep customers informed about orders with real-time updates. Provide information on order status, delivery times, and more. Enhance the dining experience",
            img: realTime,
        },
        {
            title: "Secure Payment Options",
            desc: "Prioritize transaction security with secure payment options. Customers can confidently complete transactions using trusted methods",
            img: payment,
        },
        {
            title: "Efficient Order Management",
            desc: "Streamline operations with our user-friendly dashboard. Handle orders, track deliveries, and monitor customer preferences efficiently",
            img: orderMgmt,
        },
        {
            title: "Android and iOS Compatibility",
            desc: "Reach a wider customer base with our solution, compatible with both Android and iOS platforms. Thrive in the digital restaurant industry",
            img: androidIOS,
        },
    ];

    return (
        <Stack sx={{
            backgroundImage: `url(${shapesVector}) !important`,
            backgroundSize: "cover",
        }}>
            {/* <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>


                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            Why Choose Stackroots HRMS
                        </Typography>

                        <Box display={{ xs: "block    ", md: "none" }} py={2} sx={{ margin: "0 auto !important" }} component={"img"} src={""} alt='vector' width={{ xs: "70%", sm: "50%", md: "40%" }} />

                        <Typography className={classes.mainContent}>
                            Managing human resources effectively requires a solution that is flexible, user-friendly, and future-ready. Here's why StackRoots HRMS is the perfect choice for your organization
                        </Typography>

                    </Stack>
                    <Box display={{ xs: "none", md: "block" }} component={"img"} src={""} alt='vector' width={{ xs: "70%", sm: "50%", md: "40%" }} />
                </Stack>
            </Stack>


            <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>

                    <Box component={"img"} sx={{
                        display: { xs: "none", md: "block" },
                    }} src={""} alt='vector' width={{ xs: "70%", sm: "50%", md: "35%" }} />

                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            Web-Based (Cloud) Solution
                        </Typography>
                        <Box component={"img"} sx={{
                            display: { xs: "block", md: "none" },
                            m: "0 auto !important",
                            py: 2
                        }} src={lmsVector2} width={{ xs: "70%", sm: "50%", md: "35%" }} />
                        <Typography className={classes.mainContent}>
                            Benefit from the agility and accessibility of our cloud-based HRMS. Access vital HR data securely from any location, at any time, using laptops, tablets, and smartphones. Say goodbye to on-premises constraints and embrace the freedom and efficiency of cloud technology.
                        </Typography>


                    </Stack>
                </Stack>
            </Stack>


            <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>



                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            Intuitive and Flexible Interface
                        </Typography>

                        <Box display={{ xs: "block    ", md: "none" }} py={2} sx={{ margin: "0 auto !important" }} component={"img"} src={""} alt='vector' width={{ xs: "70%", sm: "50%", md: "40%" }} />

                        <Typography className={classes.mainContent}>
                            Our user-friendly interface ensures a seamless experience for HR professionals and employees alike. With an intuitive layout and customizable dashboard, you can effortlessly navigate through different modules and personalize the system to match your specific requirements.
                        </Typography>

                    </Stack>
                    <Box display={{ xs: "none", md: "block" }} component={"img"} src={""} alt='vector' width={{ xs: "70%", sm: "50%", md: "40%" }} />
                </Stack>
            </Stack>


            <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>

                    <Box component={"img"} sx={{
                        display: { xs: "none", md: "block" },
                    }} src={""} alt='vector' width={{ xs: "70%", sm: "50%", md: "35%" }} />

                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            Enhanced Mobility
                        </Typography>
                        <Box component={"img"} sx={{
                            display: { xs: "block", md: "none" },
                            m: "0 auto !important",
                            py: 2
                        }} src={lmsVector2} width={{ xs: "70%", sm: "50%", md: "35%" }} />
                        <Typography className={classes.mainContent}>
                            Empower your workforce with the freedom of mobility. Our HRMS enables employees to access their personal HR-related information and perform transactions securely and directly, enhancing their engagement and productivity.
                        </Typography>


                    </Stack>
                </Stack>
            </Stack> */}

            {/* <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>



                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            Management Insights
                        </Typography>

                        <Box display={{ xs: "block    ", md: "none" }} py={2} sx={{ margin: "0 auto !important" }} component={"img"} src={""} alt='vector' width={{ xs: "70%", sm: "50%", md: "40%" }} />

                        <Typography className={classes.mainContent}>
                            Gain valuable insights into your HR processes through robust reporting capabilities. Generate reports and analyze various HR-related data to make informed decisions and drive continuous improvement in your organization's workforce management.
                        </Typography>

                    </Stack>
                    <Box display={{ xs: "none", md: "block" }} component={"img"} src={""} alt='vector' width={{ xs: "70%", sm: "50%", md: "40%" }} />
                </Stack>
            </Stack> */}


            <Stack alignItems={"center"} mb={5} mt={4}>
                <Typography Typography className={classes.mainHeader}>Features of Restaurant food delivery System</Typography>
                <Grid container sx={{ width: "85%", m: "0 auto", mt: { xs: "50px", md: "80px" }, }} align="center" justify="center" rowGap={8}>
                    {
                        cardData.map((card) => (

                            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                                <Card cardData={card} />
                            </Grid>
                        ))
                    }
                </Grid>
            </Stack>


            {/* <Stack alignItems={"center"} mb={8} mt={10}>
                <Typography Typography className={classes.mainHeader}> Key Benefits</Typography>
                <Grid container sx={{ width: "85%", m: "0 auto", marginTop: { xs: "50px", md: "80px" }, }} align="center" justify="center" alignItems={"center"} justifyContent={"center"} rowGap={8}>
                    {
                        benifits.map((benifit) => (
                            <Grid item xs={12} sm={6} md={6} xl={4}>
                                <Benifits benifit={benifit} />
                            </Grid>
                        ))
                    }
                </Grid>
            </Stack> */}

            <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>

                    <Box component={"img"} sx={{
                        display: { xs: "none", md: "block" },
                    }} src={upgradeResto} alt='vector' width={{ xs: "70%", sm: "50%", md: "35%" }} />

                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            Upgrade Your Restaurant Business with Our E-commerce App Solution
                        </Typography>
                        <Box component={"img"} sx={{
                            display: { xs: "block", md: "none" },
                            m: "0 auto !important",
                            py: 2
                        }} src={upgradeResto} width={{ xs: "70%", sm: "50%", md: "35%" }} />
                        <Typography className={classes.mainContent}>
                            Don't miss out on the opportunity to upgrade your restaurant business. Contact us today to learn more about how our E-commerce app solution can transform your operations and drive success in the online food delivery market.
                        </Typography>


                    </Stack>
                </Stack>
            </Stack>


        </Stack>
    )
}

export default Details