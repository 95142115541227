import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import "../../components/Erp/Banner3.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Container } from "@mui/system";
import humanresources from "../../assets/img/products/humanresources.png";
import accounting from "../../assets/img/products/accounting.png";
import management from "../../assets/img/products/management.png";
import asset from "../../assets/img/products/asset.png";
import warehouse from "../../assets/img/products/warehouse.png";
import planner from "../../assets/img/products/planner.png";
import FreeDemo from "../FreeDemo";
import PopUp from "../PopUp";
import { Fade } from "react-awesome-reveal";
import { useStyles } from "../../styles";

const Banner3 = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const classes = useStyles();
  return (
    // <Container maxWidth="xl">
    <>
      <Box
        className="banner1"
        sx={{
          width: "100%",
          paddingBottom: "180px",
          // height: "470px",
          // height: "100vh",
          display: "flex",
          flexWrap: "wrap",
          // position: "relative",
          // margin: { xs: "10px", md: "30px" },
          // pb={ 14}
        }}
      >
        <Box
          sx={{
            maxWidth: "800px",
            flex: 1,
            padding: { xs: "20px", md: "50px" },
            marginTop: { md: "110px", xs: "100px" },
            marginLeft: { xs: "0px", md: "70px" },
          }}
        >
          {" "}
          {/* <Fade triggerOnce="true" direction="up"> */}
          <Typography
            className={classes.mainHeader}
            sx={{
              marginTop: "50px",
            }}
          >
            Customized ERP Solution
          </Typography>
          {/* </Fade>{" "}
          <Fade triggerOnce="true" direction="up"> */}
          <Typography className={classes.boldMainContent}
            sx={{
              marginTop: "20px",
            }}
          >
            We specialize in delivering customized ERP & CRM solutions tailored to meet the unique needs of your organization. With our experienced team of experts, we are dedicated to implementing the best ERP and CRM systems that empower your business to thrive in today's competitive landscape.
          </Typography>
          {/* </Fade> */}
          {/* <Button
            variant="contained"
            sx={{
              color: "#4F46E5",
              background: "white",
              "&:hover": {
                background: "#e6f7ff",
              },
              marginTop: "50px",
              borderRadius: "10px",
              fontSize: { xs: "15px", md: "20px" },
              fontWeight: "bold",
            }}
            onClick={handleOpen}
          >
            BOOK FOR FREE DEMO <ArrowForwardIcon />
          </Button> */}
          <PopUp title="Erp" />
          {/* <Paper
            elevation={3}
            sx={{
              marginTop: "50px",
              position: "absolute",
              maxWidth: "lg",
              marginLeft: "auto",
              marginRight: "auto",
              left: "0",
              right: "0",
              p: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid item md={4} sm={6} xs={12}>
                <Fade triggerOnce="true" direction="up">
                  <div className="hrms">
                    <Card>
                      <CardContent
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <IconButton
                          size="large"
                          aria-label="show 4 new mails"
                          color="inherit"
                        >
                          <img
                            src={humanresources}
                            style={{ fontSize: "2.5rem", color: "#4F46E5" }}
                          />
                        </IconButton>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          textAlign="center"
                        >
                          HRMS
                        </Typography>

                        <info>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            textAlign=""
                            fontSize="16px"
                            sx={{ color: "white" }}
                          >
                            <ul style={{ listStyle: "inherit" }}>
                              <li> Candidate recruitment management</li>
                              <li> Employee management management</li>
                              <li> Timesheet upload management</li>
                              <li>
                                {" "}
                                Integration of biometric devices management
                              </li>
                              <li> Employee self service portal management</li>
                              <li>Payroll & WPS processing management</li>
                            </ul>
                          </Typography>
                        </info>
                      </CardContent>
                    </Card>
                  </div>
                </Fade>
              </Grid>

              <Grid item md={4} sm={6} xs={12}>
                <Fade triggerOnce="true" direction="up">
                  <div className="hrms">
                    <Card>
                      <CardContent
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <IconButton
                          size="large"
                          aria-label="show 4 new mails"
                          color="inherit"
                        >
                          <img
                            src={accounting}
                            style={{ fontSize: "2.5rem", color: "#4F46E5" }}
                          />
                        </IconButton>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          textAlign="center"
                        >
                          Accounting
                        </Typography>

                        <info>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            textAlign=""
                            fontSize="16px"
                            sx={{ color: "white" }}
                          >
                            <ul style={{ listStyle: "inherit" }}>
                              <li> Easy accounts automation</li>
                              <li>PDC Management</li>
                              <li> Reminders & timely notifications</li>
                              <li> Bank reconciliation</li>
                              <li>In-depth financial reports</li>
                            </ul>
                          </Typography>
                        </info>
                      </CardContent>
                    </Card>
                  </div>
                </Fade>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Fade triggerOnce="true" direction="up">
                  <div className="hrms">
                    <Card>
                      <CardContent
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <IconButton
                          size="large"
                          aria-label="show 4 new mails"
                          color="inherit"
                        >
                          <img
                            src={management}
                            style={{ fontSize: "2.5rem", color: "#4F46E5" }}
                          />
                        </IconButton>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          textAlign="center"
                        >
                          CRM
                        </Typography>

                        <info>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            textAlign=""
                            fontSize="16px"
                            sx={{ color: "white" }}
                          >
                            <ul style={{ listStyle: "inherit" }}>
                              <li> Sales automation</li>
                              <li>Lead & deal management</li>
                              <li>Task routing</li>
                              <li> Communication tracking</li>
                              <li>Insights & reports</li>
                            </ul>
                          </Typography>
                        </info>
                      </CardContent>
                    </Card>
                  </div>
                </Fade>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Fade triggerOnce="true" direction="up">
                  <div className="hrms">
                    <Card>
                      <CardContent
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <IconButton
                          size="large"
                          aria-label="show 4 new mails"
                          color="inherit"
                        >
                          <img
                            src={asset}
                            style={{ fontSize: "2.5rem", color: "#4F46E5" }}
                          />
                        </IconButton>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          textAlign="center"
                        >
                          Assets
                        </Typography>

                        <info>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            textAlign=""
                            fontSize="16px"
                            sx={{ color: "white" }}
                          >
                            <ul style={{ listStyle: "inherit" }}>
                              <li> Asset life cycle management</li>
                              <li> Asset purchase $ sales</li>
                              <li> Asset maintenance management</li>
                              <li>Asset allocation & switching</li>
                              <li>Asset depreciation</li>
                            </ul>
                          </Typography>
                        </info>
                      </CardContent>
                    </Card>
                  </div>
                </Fade>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Fade triggerOnce="true" direction="up">
                  <div className="hrms">
                    <Card>
                      <CardContent
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <IconButton
                          size="large"
                          aria-label="show 4 new mails"
                          color="inherit"
                        >
                          <img
                            src={warehouse}
                            style={{ fontSize: "2.5rem", color: "#4F46E5" }}
                          />
                        </IconButton>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          textAlign="center"
                        >
                          Inventory
                        </Typography>

                        <info>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            textAlign=""
                            fontSize="16px"
                            sx={{ color: "white" }}
                          >
                            <ul style={{ listStyle: "inherit" }}>
                              <li> Inventory & warehouse management,</li>
                              <li> supplier automation,</li>
                              <li> sales & purchase management,</li>
                              <li> barcode management.</li>
                            </ul>
                          </Typography>
                        </info>
                      </CardContent>
                    </Card>
                  </div>
                </Fade>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Fade triggerOnce="true" direction="up">
                  <div className="hrms">
                    <Card>
                      <CardContent
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <IconButton
                          size="large"
                          aria-label="show 4 new mails"
                          color="inherit"
                        >
                          <img
                            src={planner}
                            style={{ fontSize: "2.5rem", color: "#4F46E5" }}
                          />
                        </IconButton>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          textAlign="center"
                        >
                          Point of sales
                        </Typography>

                        <info>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            textAlign=""
                            fontSize="16px"
                            sx={{ color: "white" }}
                          >
                            <ul style={{ listStyle: "inherit" }}>
                              <li> Store &sales management</li>
                              <li>Easy billing checkout</li>
                              <li> Loyalty coupon management</li>
                              <li>Daily sales monitoring & reporting</li>
                            </ul>
                          </Typography>
                        </info>
                      </CardContent>
                    </Card>
                  </div>
                </Fade>
              </Grid>
            </Grid>
          </Paper> */}
        </Box>
      </Box>
    </>
  );
};

export default Banner3;
