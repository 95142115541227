import React from 'react'
import Vfx from '../../components/vfx/Vfx'
import ContactForm from '../../components/Contact/ContactForm'
import imgProgramer from "../../assets/img/services/programers.png";
import { Helmet } from 'react-helmet';
const VfxPage = () => {
  return (
    <>
      <Helmet>
        <title>Stackroots | Adaptive Web Design</title>
      </Helmet>
      <div>

        <Vfx />
        <ContactForm image={imgProgramer} />
      </div>
    </>

  )
}

export default VfxPage