import React from 'react'
import Banner from '../../components/restaurantFood/Banner'
import ContactForm from '../../components/Contact/ContactForm'
import Details from '../../components/restaurantFood/Details'
import { Helmet } from "react-helmet"

function RestaurantFood() {
    return (
        <>
            <Helmet>
                <title>Restaurant food delivery system</title>
                <meta
                    name="description"
                    content=""
                />
            </Helmet>

            <Banner />
            <Details />
            <ContactForm title="Get a quote" />
        </>
    )
}

export default RestaurantFood