import { Box, Grid, Stack, Typography, Container } from "@mui/material";
import React from "react";
import gymHero from "../../assets/img/gymhero.png";
import PersonIcon from "@mui/icons-material/Person";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ComputerIcon from "@mui/icons-material/Computer";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import HandshakeIcon from "@mui/icons-material/Handshake";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";

const GymHero = () => {
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(180deg, #131331 2.08%, rgba(16, 18, 57, 0.6) 54.69%, #131331 100%),url(${gymHero})`,
        backgroundSize: "cover",
        padding: "50px",
      }}
    >
      <Container maxWidth="xl">
        <Grid
          container
          padding="40px 0px 40px 0px"
          alignItems={"center"}
          spacing={4}
        >
          <Grid item xs={12} md={6} lg={4}>
            <Stack>
              <Stack spacing={2}>
                <PersonIcon
                  style={{
                    background:
                      "linear-gradient(180deg, #009ADE 0%, #0C4DA1 100%)",
                    fontSize: "50px",
                    color: "white",
                    padding: "5px",
                  }}
                />

                <Typography variant="h5" className="text-white">
                  GYM ANYTIME
                </Typography>
              </Stack>
              <Typography variant="body1" className="text-white">
                Perfect online GYM Partner application
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            {" "}
            <Stack>
              <Stack spacing={2}>
                <ArrowCircleRightIcon
                  style={{
                    background:
                      "linear-gradient(180deg, #009ADE 0%, #0C4DA1 100%)",
                    fontSize: "50px",
                    color: "white",
                    padding: "5px",
                  }}
                />

                <Typography variant="h5" className="text-white">
                  EASY SLOT BOOKING
                </Typography>
              </Stack>
              <Typography variant="body1" className="text-white">
                Let your clients book a time slot - with GYM ANYTIME
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            {" "}
            <Stack>
              <Stack spacing={2}>
                <AccessTimeFilledIcon
                  style={{
                    background:
                      "linear-gradient(180deg, #009ADE 0%, #0C4DA1 100%)",
                    fontSize: "50px",
                    color: "white",
                    padding: "5px",
                  }}
                />

                <Typography variant="h5" className="text-white">
                  TIME MANAGEMENT
                </Typography>
              </Stack>
              <Typography variant="body1" className="text-white">
                24*7 time management. Manage your time and reduce wastage
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Grid
          container
          padding="40px 0px 40px 0px"
          alignItems={"center"}
          spacing={4}
        >
          <Grid item xs={12} md={6} lg={4}>
            <Stack>
              <Stack spacing={2}>
                <ComputerIcon
                  style={{
                    background:
                      "linear-gradient(180deg, #009ADE 0%, #0C4DA1 100%)",
                    fontSize: "50px",
                    color: "white",
                    padding: "5px",
                  }}
                />

                <Typography variant="h5" className="text-white">
                  CUSTOM GYM WEBSITE
                </Typography>
              </Stack>
              <Typography variant="body1" className="text-white">
                We enable you to create your own custom website.
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            {" "}
            <Stack>
              <Stack spacing={2}>
                <SubscriptionsIcon
                  style={{
                    background:
                      "linear-gradient(180deg, #009ADE 0%, #0C4DA1 100%)",
                    fontSize: "50px",
                    color: "white",
                    padding: "5px",
                  }}
                />

                <Typography variant="h5" className="text-white">
                  SUBSCRIPTION ON
                </Typography>
              </Stack>
              <Typography variant="body1" className="text-white">
                Flexible plans are available. Choose a subscription plan
                suitable for your needs
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            {" "}
            <Stack>
              <Stack spacing={2}>
                <HandshakeIcon
                  style={{
                    background:
                      "linear-gradient(180deg, #009ADE 0%, #0C4DA1 100%)",
                    fontSize: "50px",
                    color: "white",
                    padding: "5px",
                  }}
                />

                <Typography variant="h5" className="text-white">
                  HOSTING FREE
                </Typography>
              </Stack>
              <Typography variant="body1" className="text-white">
                We take care of hosting your website and application for life
                long
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default GymHero;
