import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Formik, Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Container } from "@mui/system";
import easytouse from "../../assets/img/products/easytouse.png";
import card from "../../assets/img/products/card.png";
import collaboration from "../../assets/img/products/collaboration.png";
import { Fade } from "react-awesome-reveal";
import FreeDemo from "../FreeDemo";
import PopUp from "../PopUp";
import { useStyles } from "../../styles";
import atttendanceBanner from "../../assets/img/new/atttendanceBanner.png"

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    bgcolor: "background.paper",

    boxShadow: 24,
    p: 4,
};

const Banner = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const classes = useStyles();
    return (
        <>
            <Box
                sx={{
                    background: `url(${atttendanceBanner})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "100%",
                    paddingBottom: { xs: "150px", md: "50px" },
                    // height: "470px",
                    height: { xs: "auto", md: "100vh" },
                    display: "flex",
                    flexWrap: "wrap",
                    // margin: { xs: "10px", md: "30px" },
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        padding: { xs: "20px", md: "50px" },
                        marginTop: { xs: "100px", md: "80px" },
                        marginLeft: { xs: "0px", md: "70px" },
                        maxWidth: "900px"
                    }}
                >
                    <Typography
                        className={classes.mainHeader}
                        sx={{

                            marginTop: { md: "100px", xs: "0px" },
                        }}
                    >
                        Attendance Management
                    </Typography>

                    <Typography
                        className={classes.mainContent}
                        sx={{
                            marginTop: { md: "25px", xs: "10px" },
                        }}
                    >
                        Stackroots offers a powerful Attendance Management software designed to streamline employee attendance tracking and management.
                    </Typography>
                    <PopUp margin={"50px"} background={"#e6f7ff"} title={"  Attendance Management"} color={"#4F46E5"} buttonTitle={"Contact Us"} />
                </Box>
            </Box>

        </>
    );

};

export default Banner;
