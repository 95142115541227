import React from 'react'
import Banner from '../../components/Autostack/Banner'
import Details from '../../components/Autostack/Details'
import { Stack } from '@mui/material'
import ContactForm from '../../components/Contact/ContactForm'
import shapesVector from '../../assets/img/products/shapesVector.png'
import { Helmet } from 'react-helmet'
function AutoStack() {
    return (
        <>
            <Helmet>
                <title>Stackroots | AutoStack</title>
            </Helmet>
            <Banner />
            <Details />
            <ContactForm title="Get a quote" />
        </>
    )
}

export default AutoStack