import { Grid, Stack, Typography } from '@mui/material'
import React from 'react'

function QuestionDetails({ questionSummary }) {
    return (
        <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} gap={3}>

            <Grid container rowGap={0} columnSpacing={1} sx={{
                width: "250px",
                height: "110px",
            }}>
                <Grid item xs={6} >
                    <Typography sx={{ fontSize: "17px", fontWeight: "400" }}>Total Marks</Typography>
                </Grid>
                <Grid item xs={6} >
                    <Typography sx={{ fontSize: "17px", fontWeight: "500" }}>{questionSummary.totalMarks} marks</Typography>
                </Grid>

                <Grid item xs={6} >
                    <Typography sx={{ fontSize: "17px", fontWeight: "400" }}>Duration</Typography>
                </Grid>
                <Grid item xs={6} >
                    <Typography sx={{ fontSize: "17px", fontWeight: "500" }}>{questionSummary.duration} min </Typography>
                </Grid>
                <Grid item xs={6} >
                    <Typography sx={{ fontSize: "17px", fontWeight: "400" }}>Questions</Typography>
                </Grid>
                <Grid item xs={6} >
                    <Typography sx={{ fontSize: "17px", fontWeight: "500" }}>{questionSummary.totalQuestions} </Typography>
                </Grid>
            </Grid>
        </Stack>
    )
}

export default QuestionDetails