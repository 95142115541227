import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import Container from "./Container";
import captionLink from "../assets/img/captionlink.png";
import { Fade } from "react-awesome-reveal";
import { makeStyles } from "@mui/styles";
import { useStyles } from "../styles";
// import shock from "../assets/img/shock.png";



const HeroCaption = () => {
  const classes = useStyles();
  return (
    <Stack
      sx={{
        // backgroundImage: `url(${captionLink})`,
        // backgroundPosition: "50% 50%",
        // backgroundSize: "cover",
        backgroundRepeat: "no-repeat",

        backgroundImage: `linear-gradient(180deg, #151137 0%, #151137 96.87%),url(${captionLink})`,
        backgroundBlendMode: "screen",
        padding: { xs: "10px", md: "40px" }
      }}
    >
      <Container>
        <Grid
          container
          padding="40px 0px 40px 0px"
          alignItems={"center"}
          spacing={2}
        >
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box sx={{ flex: 1 }}>
              {" "}

              <Typography
                className={classes.mainHeader}
              // fontWeight={"bold"}
              // className="text-white"
              // fontSize={{ xs: "24px", md: "48px" }}
              >
                STACKROOTS
              </Typography>
              <Typography
                className={classes.mainHeader}
              // variant="h3"
              // fontWeight={"bold"}
              // className="text-white"
              // fontSize={{ xs: "24px", md: "48px" }}
              >
                Rooting Your Business With Us
              </Typography>{" "}
            </Box>
          </Grid>
          {/* <Divider
            orientation="vertical"
            flexItem
            sx={{
              borderLeft: "2px solid white",
              marginRight: "16px",
              display: { xs: "none", sm: "flex" },
            }}
          /> */}

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box sx={{ flex: 1 }}>

              <Typography
                // variant="body1"
                // className="text-white"
                className={classes.mainContent}
                marginLeft={{ xs: 0, sm: 4 }}
              >
                Stackroots is a dynamic and innovative technology solutions and software services company that delivers simple and prolific solutions to a plethora of problems and challenges faced by businesses. With a growing focus on AI, Metaverse, Blockchain, and IoT-based applications, we are constantly pushing the boundaries of technology to provide cutting-edge solutions to our clients. We are dedicated to creating customized and innovative products that help businesses thrive in the digital landscape. We will be your trusted partner in overcoming technological hurdles and achieving success.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Stack>
  );
};

export default HeroCaption;
