import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Formik, Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Container } from "@mui/system";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
// Import React FilePond
import { FilePond, File, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import axios from "axios";
import { Co2Sharp } from "@mui/icons-material";
import { CustomLoadingButton } from "../../utils/Button";
import { toast } from "react-toastify";
// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",

  boxShadow: 24,
  p: 3,
};

const SendResume = ({ handleClose, title, titleId, position }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [Message, setMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [fileError, setFileError] = useState(false);
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/category/designation/active`)
      .then((res) => setMenu(res.data.data.categories))
      .catch((err) => console.log(err));
  }, []);
  console.log("menu", menu, titleId);
  const LoginSchema = Yup.object().shape({
    fullname: Yup.string().required("name is required"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    category: Yup.string().required("Position is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      fullname: "",
      category: titleId,
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      console.log("file", files);
      if (!files.length) {
        setFileError(true);
      }
      const formData = new FormData();
      Object.entries(values).forEach(([key, value], index) => {
        formData.append(key, value);
      });

      files.forEach((item) => {
        formData.append("image", item.file);
      });
      const res = await axios
        .post(`${process.env.REACT_APP_API_URL}/resume`, formData)
        .then((res) => res)
        .catch((err) => toast.error(err.response.data.message));

      if (!res.data.isError) {
        setIsSuccess(true);
        // setInterval(() => {
        //   handleClose();
        //   window.location.reload();
        // }, 2000);
        console.log("Success", res);
      }
    },
  });

  const {
    errors,
    resetForm,
    touched,
    values,
    isSubmitting,
    setFieldTouched,
    isValid,
    isValidating,
    handleSubmit,
    getFieldProps,
  } = formik;

  return (
    <Box>
      {isSuccess ? (
        <Card sx={{ padding: "50px" }}>
          <Stack
            direction={"column"}
            spacing={1}
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <svg
              width="60"
              height="60"
              className="text-green-300"
              viewBox="0 0 100 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.6666 50L46.6666 66.6667L73.3333 33.3333M50 96.6667C43.8716 96.6667 37.8033 95.4596 32.1414 93.1144C26.4796 90.7692 21.3351 87.3317 17.0017 82.9983C12.6683 78.6649 9.23082 73.5204 6.8856 67.8586C4.54038 62.1967 3.33331 56.1283 3.33331 50C3.33331 43.8716 4.54038 37.8033 6.8856 32.1414C9.23082 26.4796 12.6683 21.3351 17.0017 17.0017C21.3351 12.6683 26.4796 9.23084 32.1414 6.88562C37.8033 4.5404 43.8716 3.33333 50 3.33333C62.3767 3.33333 74.2466 8.24998 82.9983 17.0017C91.75 25.7534 96.6666 37.6232 96.6666 50C96.6666 62.3768 91.75 74.2466 82.9983 82.9983C74.2466 91.75 62.3767 96.6667 50 96.6667Z"
                stroke="currentColor"
                strokeWidth="3"
              />
            </svg>
            <h3 className="py-5 text-xl text-green-500">
              Message sent successfully
            </h3>
            <p className="text-gray-700 md:px-3">{Message}</p>
            <Button
              variant="outlined"
              onClick={() => {
                resetForm();
                handleClose();
                setIsSuccess(false);
                setFiles([]);
              }}
            >
              Go back
            </Button>
          </Stack>
        </Card>
      ) : (
        <Box sx={{ flex: 1, marginTop: { xs: "0px" } }}>
          <Card sx={{ height: "100%", backgroundColor: "transparent" }}>
            <CardContent>
              {/* <Typography variant="h5" fontWeight={"bold"}>
                Start your free demo
              </Typography> */}
              <Typography variant="subtitle1">
                Please complete all fields
              </Typography>
              <FormikProvider value={formik}>
                <Form autoComplete="off">
                  <Stack spacing={3} sx={{ marginTop: "16px" }}>
                    <TextField
                      focused
                      fullWidth
                      autoComplete="username"
                      type="name"
                      label="Enter your name"
                      placeholder="Enter your name"
                      {...getFieldProps("fullname")}
                      error={Boolean(touched.fullname && errors.fullname)}
                      helperText={touched.fullname && errors.fullname}
                    //   sx={{background:'#F0F0F0'}}
                    />
                    <TextField
                      focused
                      fullWidth
                      autoComplete="username"
                      type="email"
                      label="Email address"
                      placeholder="Email address"
                      {...getFieldProps("email")}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    //   sx={{background:'#F0F0F0'}}
                    />

                    {/* <TextField
                      fullWidth
                      autoComplete="phone"
                      label="Phone"
                      {...getFieldProps("phone")}
                      error={Boolean(touched.phone && errors.phone)}
                      helperText={touched.phone && errors.phone}
                      //   sx={{background:'#F0F0F0'}}
                    /> */}

                    {position && <FormControl fullWidth focused>
                      <InputLabel id="demo-simple-select-label">
                        Position
                      </InputLabel>

                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Position"
                        placeholder="Position"
                        {...getFieldProps("category")}
                        error={Boolean(touched.category && errors.category)}
                        MenuProps={{
                          sx: {
                            "& .MuiPaper-root": {
                              backgroundColor: "whitesmoke",
                              color: "#131331",
                            },
                          },
                        }}
                      >
                        {menu?.map((menuItem) => (
                          <MenuItem
                            key={menuItem.name}
                            value={menuItem._id}
                            sx={{
                              "& .MuiPaper-root": {
                                backgroundColor: "lightblue",
                              },

                              // "& .MuiPaper-root .MuiMenu-paper .MuiPaper-root .MuiPopover-paper":
                              //   {
                              //     backgroundColor: "black",
                              //   },
                            }}
                          >
                            {menuItem.name}
                          </MenuItem>
                        ))}
                      </Select>

                      {Boolean(touched.category && errors.category) && (
                        <FormHelperText
                          sx={{
                            color: "#bf3333",
                            marginLeft: "16px !important",
                          }}
                        >
                          {touched.category && errors.category}
                        </FormHelperText>
                      )}
                    </FormControl>}

                    <FilePond
                      files={files}
                      onupdatefiles={setFiles}
                      allowMultiple={false}
                      maxFiles={1}
                      name="files"
                      labelIdle="Drop your Resume Here"
                      labelFileTypeNotAllowed="Only PDF files allowed"
                      credits={false}
                      allowFileTypeValidation={true}
                      acceptedFileTypes={["application/pdf"]}
                    />
                    {fileError && !files.length && (
                      <FormHelperText
                        sx={{
                          color: "#bf3333",
                          marginLeft: "10px !important",
                          marginTop: "-10px !important",
                          marginBottom: "10px !important",
                        }}
                      >
                        Please upload your resume
                      </FormHelperText>
                    )}
                  </Stack>

                  <CustomLoadingButton
                    loading={isSubmitting}
                    type="submit"
                    fullWidth
                  >
                    submit
                  </CustomLoadingButton>
                </Form>
              </FormikProvider>
            </CardContent>
          </Card>
        </Box>
      )}
    </Box>
  );
};

export default SendResume;
