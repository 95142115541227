export const setCandidateToken = (token) => {
    localStorage.setItem("candidateToken", token)
}
export const setCandidateName = (name) => {
    localStorage.setItem("candidateName", name)
}
export const setCandidateEmail = (email) => {
    localStorage.setItem("candidateEmail", email)
}

export const clearStorage = () => {
    localStorage.clear();
}