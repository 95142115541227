import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { useStyles } from '../../styles'

import shapesVector from "../../assets/img/products/shapesVector.png"
import Card from './Card'

import architech from "../../assets/img/products/certificationBg.jpg"

import certChoose from "../../assets/img/services/vectors/certChoose.png"
import instVerification from "../../assets/img/services/vectors/instVerification.png"

import audit from "../../assets/img/services/vectors/icons/audit.png"
import batchwise from "../../assets/img/services/vectors/icons/batchwise.png"
import connection from "../../assets/img/services/vectors/icons/connection.png"
import cross from "../../assets/img/services/vectors/icons/cross.png"
import instVerify from "../../assets/img/services/vectors/icons/instVerify.png"
import privacy from "../../assets/img/services/vectors/icons/privacy.png"
import records from "../../assets/img/services/vectors/icons/records.png"
import smart from "../../assets/img/services/vectors/icons/smart.png"
import timestamp from "../../assets/img/services/vectors/icons/timestamp.png"
import trans from "../../assets/img/services/vectors/icons/trans.png"
import trust from "../../assets/img/services/vectors/icons/trust.png"
import ui from "../../assets/img/services/vectors/icons/ui.png"





function Details() {
    const classes = useStyles();

    const cardData = [
        {
            title: "Instant Verification",
            desc: "Enables real-time certificate verification via blockchain technology, ensuring swift confirmation of authenticity.",
            img: instVerify,
        },
        {
            title: "Batchwise Issuance",
            desc: "Facilitates issuing batches of certificates with a single click, streamlining the process and saving time.",
            img: batchwise,
        },
        {
            title: "Immutable Records",
            desc: "Blockchain-stored certificates are immutable, preventing fraudulent attempts to create fake certificates.",
            img: records,
        },
        {
            title: "Decentralized Trust",
            desc: "Relies on a decentralized network of nodes for verification, enhancing trust and security.",
            img: trust,
        },
        {
            title: "Transparency",
            desc: "Ensures a transparent verification process visible to all parties, promoting trust and openness.",
            img: trans,
        },
        {
            title: "Data Privacy",
            desc: "Encrypts or hashes sensitive data while allowing verification, maintaining individual privacy.",
            img: privacy,
        },
        {
            title: "Interoperability",
            desc: "Supports multiple blockchain platforms, ensuring compatibility with different issuers and verifiers.",
            img: connection,
        },
        {
            title: "Smart Contracts",
            desc: "Automates issuance and verification using self-executing contracts with predefined rules.",
            img: smart,
        },
        {
            title: "User-Friendly Interface",
            desc: "Provides an intuitive interface for all parties, simplifying the process and encouraging adoption.",
            img: ui,
        },
        {
            title: "Timestamping",
            desc: "Utilizes the blockchain's timestamping capability to accurately record issuance time.",
            img: timestamp,
        },
        {
            title: "Audit Trail",
            desc: "Maintains a detailed audit trail of issuance and verification activities, enhancing accountability.",
            img: audit,
        },
        {
            title: "Cross-Platform Accessibility",
            desc: "Ensures accessibility across different devices and platforms, facilitating verification from anywhere.",
            img: cross,
        },
        // {
        //     title: "Scalability",
        //     desc: "Efficiently handles a large volume of verifications to meet various industry demands.",
        //     img: benifitIcon5,
        // },
    ];


    return (
        <Stack sx={{
            backgroundImage: `url(${shapesVector}) !important`,
            backgroundSize: "cover",
        }}>
            <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>



                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            Instant Verification, Hassle-Free Process
                        </Typography>

                        <Box display={{ xs: "block    ", md: "none" }} py={2} sx={{ margin: "0 auto !important" }} component={"img"} src={instVerification} alt='vector' width={{ xs: "70%", sm: "50%", md: "40%" }} />

                        <Typography className={classes.mainContent}>
                            Gone are the days of waiting for manual verifications and dealing with lengthy processes. CertVerify makes certificate verification instantaneous and hassle-free. With a simple click of a button, you can confirm the authenticity of any certificate, saving valuable time and resources. Whether you are an employer, an educational institution, or an individual seeking to validate credentials, our platform empowers you to make informed decisions swiftly and with ease.
                        </Typography>

                    </Stack>
                    <Box display={{ xs: "none", md: "block" }} component={"img"} src={instVerification} alt='vector' width={{ xs: "70%", sm: "50%", md: "40%" }} />
                </Stack>
            </Stack>


            <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>

                    <Box component={"img"} sx={{
                        display: { xs: "none", md: "block" },
                    }} src={certChoose} alt='vector' width={{ xs: "70%", sm: "50%", md: "48%" }} />

                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            Why Choose CertVerify?
                        </Typography>
                        <Box component={"img"} sx={{
                            display: { xs: "block", md: "none" },
                            m: "0 auto !important",
                            py: 2
                        }} src={certChoose} alt='vector' width={{ xs: "70%", sm: "50%", md: "35%" }} />
                        <Typography className={classes.mainContent}>
                            At CertVerify, we take pride in offering a fully customizable and secure certificate verification experience. Our software provides a user-friendly interface that allows you to access and verify certificates effortlessly, whether you are using a desktop computer or a mobile device on the go. Embracing the blockchain's decentralized and tamper-proof nature, we ensure that every certificate stored on our platform remains intact and protected against any unauthorized alterations. With CertVerify, you can have complete confidence in the integrity of your certificates and the trust they inspire.
                        </Typography>


                    </Stack>
                </Stack>
            </Stack>

            <Stack alignItems={"center"} mb={5} mt={4}>
                <Typography Typography className={classes.mainHeader}>Features of CertVerify</Typography>
                <Grid container sx={{ width: "85%", m: "0 auto", mt: { xs: "50px", md: "80px" }, }} align="center" justify="center" rowGap={8}>
                    {
                        cardData.map((card) => (
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                <Card cardData={card} />
                            </Grid>
                        ))
                    }
                </Grid>
            </Stack>


            {/* <Stack alignItems={"center"} mb={8} mt={10}>
                <Typography Typography className={classes.mainHeader}> CertVerify Working Process</Typography>
                <Grid container sx={{ width: "85%", m: "0 auto", marginTop: { xs: "50px", md: "80px" }, }} align="center" justify="center" alignItems={"center"} justifyContent={"center"} rowGap={8}>
                    {
                        steps.map((step) => (
                            <Grid item xs={12} sm={6} md={6} xl={4}>
                                <Benifits benifit={step} />
                            </Grid>
                        ))
                    }
                </Grid>
            </Stack> */}


            <div
                className="hero_about_bg"
                style={{
                    // backgroundImage: `url(${aboutLink})`,
                    background: `url(${architech})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundBlendMode: "hard-light",


                }}
            >
                <Container maxWidth="xl">
                    <Grid
                        container
                        padding="60px 2px 60px 20px"
                        alignItems={"center"}
                        spacing={2}
                    >
                        <Grid item xs={12} md={6}>
                            <Box sx={{ flex: 1 }}>
                                <Typography
                                    // variant="h4"
                                    // className="text-white"
                                    // fontWeight={{ xs: "600", md: "bold" }}
                                    // fontSize={{ xs: "28px", md: 32 }}
                                    // gutterBottom
                                    className={classes.mainHeader}
                                >
                                    Join CertVerify Today
                                </Typography>
                                <Typography mt={2} className={classes.mainContent}>
                                    Experience the power of blockchain technology for certificate verification and take your verification process to new heights with CertVerify. Join our growing community of organizations and individuals who trust CertVerify to verify credentials efficiently, securely, and with confidence. Simplify your certificate verification journey and start using CertVerify today!
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </Stack>
    )
}

export default Details