import React from "react";
import { useNavigate } from "react-router-dom";
import degree from "../assets/img/services/degree.png";
import boosting from "../assets/img/services/website boosting.png";
import ecommerce1 from "../assets/img/services/e-commerce (1).png";
import branding from "../assets/img/services/branding.png";
import socialmedia1 from "../assets/img/services/socialmedia1.png";
import hosting from "../assets/img/services/hosting.png";
import enterprise from "../assets/img/services/enterprise.png";
import adaptive from "../assets/img/services/adaptiveWD.png"
import uiDesign from "../assets/img/services/cloud based web app.png"
import uiux from "../assets/img/services/uidesign2.png"
import marketing360 from "../assets/img/services/degree.png"
import crm1 from "../assets/img/services/crm (1).png"
import seo from "../assets/img/services/seo degital marketing.png"
import appDevCard from "../assets/img/services/appdevCard.png"
import staffAugCard from "../assets/img/services/staffAugCard.png"
import vfxCard from "../assets/img/services/vfxCard.png"


const ServiceCard = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="container mx-auto px-4  sm:flex justify-between items-stretch ">

        <div className="py-10 px-2">
          <div
            className="max-w-sm rounded cursor-pointer bg-white overflow-hidden shadow-lg hover:scale-110 transition duration-500 min-h-full"
            onClick={() => navigate("/services/cloudbasedapplication")}
          >
            <img
              className="w-full"
              src={uiDesign}
              alt=""
            />
            <div className="px-6 py-4">
              <div className="font-bold text-xl text-black mb-2">
                Cloud Based Web Applications
              </div>
              <p className="text-black dark:text-white text-base">
                We are not only create website development, we create best cloud
                based web application as on your custom requirements.
              </p>
            </div>
          </div>
        </div>

        <div className="py-10 px-2">
          <div
            className="max-w-sm rounded cursor-pointer overflow-hidden bg-white shadow-lg hover:scale-110 transition duration-500 min-h-full"
            onClick={() => navigate("/services/applicationdevelopment")}
          >
            <img className="w-full" src={appDevCard} alt="" />
            <div className="px-6 py-4">
              <div className="font-bold text-xl text-black mb-2">
                Mobile Application Devolpment
              </div>
              <p className="text-black dark:text-gray-300 text-base">
                We offers end-to-end solutions for building custom mobile apps that meet the unique needs of businesses and organizations across various industries
              </p>
            </div>
          </div>
        </div>



        <div className="py-10 px-2">
          <div
            className="max-w-sm rounded cursor-pointer overflow-hidden bg-white  shadow-lg hover:scale-110 transition duration-500 min-h-full"
            onClick={() => navigate("/services/ecommerceapplication")}
          >
            <img className="w-full " src={ecommerce1} alt="" />
            <div className="px-6 py-4 ">
              <div className="font-bold text-xl text-black mb-2">
                E-Commerce Application
              </div>
              <p className="text-black dark:text-whitetext-base">
                Changes happening to the world sales activities. We place your
                business authentic from real world to digital place
              </p>
            </div>
          </div>
        </div>


      </div>



      <div className="container mx-auto px-4  sm:flex justify-between items-stretch ">

        <div className="py-10 px-2">
          <div
            className="max-w-sm rounded cursor-pointer bg-white overflow-hidden shadow-lg hover:scale-110 transition duration-500 min-h-full"
            onClick={() => navigate("/services/crm")}
          >
            <img className="w-full" src={crm1} alt="" />
            <div className="px-6 py-4">
              <div className="font-bold text-xl text-black mb-2">
                CRM Applications
              </div>
              <p className="text-black dark:text-white text-base">
                We create best CRM software ,efficient way you to handle your
                business enquires & customer data.
              </p>
            </div>
          </div>
        </div>

        <div className="py-10 px-2">
          <div
            className="max-w-sm rounded cursor-pointer  overflow-hidden shadow-lg hover:scale-110 transition duration-500 bg-white min-h-full "
            onClick={() => navigate("/services/360degreemarketing")}
          >
            <img className="w-full " src={marketing360} alt="" />
            <div className="px-6 py-4 ">
              <div className="font-bold text-xl text-black mb-2">
                360 Degree Marketing
              </div>
              <p className="text-black dark:text-white text-base">
                We do marketing in all around your needs. We belives in 360
                degree marketing.
              </p>
            </div>
          </div>
        </div>



        <div className="py-10 px-2">
          <div
            className="max-w-sm rounded cursor-pointer overflow-hidden bg-white shadow-lg hover:scale-110 transition duration-500 min-h-full"
            onClick={() => navigate("/services/seo")}
          >
            <img className="w-full" src={seo} alt="" />
            <div className="px-6 py-4">
              <div className="font-bold text-xl text-black mb-2">
                SEO & Digital Marketing
              </div>
              <p className="text-black dark:text-white text-base">
                Best digital marketing services for your business needs. We
                create digital marketing campaigns with best digital marketing
                experts.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-4  sm:flex justify-between items-stretch ">
        <div className="py-10 px-2">
          <div
            className="max-w-sm rounded cursor-pointer overflow-hidden bg-white shadow-lg hover:scale-110 transition duration-500 min-h-full"
            onClick={() => navigate("/services/websiteboosting")}
          >
            <img className="w-full" src={boosting} alt="" />
            <div className="px-6 py-4">
              <div className="font-bold text-xl text-black mb-2">
                Website Boosting
              </div>
              <p className="text-black dark:text-white text-base">
                Creating the website with high speed accesses in less internet
                connectivity areas.
              </p>
            </div>
          </div>
        </div>

        <div className="py-10 px-2">
          <div
            className="max-w-sm rounded cursor-pointer overflow-hidden bg-white shadow-lg hover:scale-110 transition duration-500 min-h-full"
            onClick={() => navigate("/services/uidesign")}
          >
            <img
              className="w-full"
              src={uiux}
              alt=""
            />
            <div className="px-6 py-4">
              <div className="font-bold text-xl text-black mb-2">
                UX/UI Design
              </div>
              <p className="text-black dark:text-white text-base">
                Stackroots involved the best UX/UI designer in the city. We are
                not just a creator of design a system, we are the best creator.
              </p>
            </div>
          </div>
        </div>



        <div className="py-10 px-2">
          <div
            className="max-w-sm rounded cursor-pointer overflow-hidden bg-white shadow-lg hover:scale-110 transition duration-500 min-h-full"
            onClick={() => navigate("/services/branding")}
          >
            <img className="w-full" src={branding} alt="" />
            <div className="px-6 py-4">
              <div className="font-bold text-xl text-black mb-2">Branding</div>
              <p className="text-black dark:text-gray-300 text-base">
                Every business need to be branded, until and unless not reach
                your customer. So we create all kind of branding for your
                business.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-4 sm:flex justify-between items-stretch ">
        <div className="py-10 px-2">
          <div
            className="max-w-sm rounded cursor-pointer overflow-hidden shadow-lg hover:scale-110 transition duration-500 min-h-full bg-white"
            onClick={() => navigate("/services/socialmediamarketing")}
          >
            <img className="w-full" src={socialmedia1} alt="" />
            <div className="px-6 py-4">
              <div className="font-bold text-xl text-black mb-2">
                Social Media Marketing
              </div>
              <p className="text-black dark:text-gray-300 text-base">
                Each days the number users will go through the social media ,
                that shows a larges data for their daily needs. Hence we create
                nest social media marketing to reach your business to a mass
                market.
              </p>
            </div>
          </div>
        </div>

        <div className="py-10 px-2">
          <div
            className="max-w-sm rounded cursor-pointer overflow-hidden bg-white shadow-lg hover:scale-110 transition duration-500 min-h-full"
            onClick={() => navigate("/services/hosting")}
          >
            <img className="w-full" src={hosting} alt="" />
            <div className="px-6 py-4">
              <div className="font-bold text-xl text-black mb-2">
                Web Hosting
              </div>
              <p className="text-black dark:text-gray-300 text-base">
                Our customer don't need to worry about the hosting and
                technicality of the system. We take care about those to a SaaS
                model. Best Hosting plans we give out to our customers.
              </p>
            </div>
          </div>
        </div>

        <div className="py-10 px-2">
          <div
            className="max-w-sm rounded cursor-pointer overflow-hidden bg-white shadow-lg hover:scale-110 transition duration-500 min-h-full"
            onClick={() => navigate("/services/enterprise")}
          >
            <img className="w-full" src={enterprise} alt="" />
            <div className="px-6 py-4">
              <div className="font-bold text-xl text-black mb-2">
                Enterprise Applications
              </div>
              <p className="text-black dark:text-gray-300 text-base">
                We provide best ERP application for your business needs. We
                develop best custom made ERP for production & manufacturing,
                Supply chain management, Human Resource management & Finance.
              </p>
            </div>
          </div>
        </div>

        {/* <div className="container mx-auto px-4 sm:flex justify-between items-stretch ">
          ter
        </div> */}

      </div>

      <div className="container mx-auto px-4 sm:flex justify-between items-stretch ">




        <div className="py-10 px-2">
          <div
            className="max-w-sm rounded cursor-pointer overflow-hidden shadow-lg hover:scale-110 transition duration-500 min-h-full border-none"
            onClick={() => navigate("/services/adaptivewebdesign")}
          >
            <img
              className="w-full"
              src={adaptive}
              alt=""
            />
            <div className="px-6 py-4 bg-white">
              <div className="font-bold text-black text-xl mb-2">
                Website Development Services
              </div>
              <p className=" dark:text-gray-300 text-black text-base">
                Each design what we create will suits all environment. Each
                client needs will be far different, so we are adaptive to design
                as your needs.
              </p>
            </div>
          </div>
        </div>

        <div className="py-10 px-2">
          <div
            className="max-w-sm rounded cursor-pointer overflow-hidden bg-white shadow-lg hover:scale-110 transition duration-500 min-h-full"
            onClick={() => navigate("/services/staffaugmentation")}
          >
            <img className="w-full" src={staffAugCard} alt="" />
            <div className="px-6 py-4">
              <div className="font-bold text-xl text-black mb-2">
                Staff augmentation
              </div>
              <p className="text-black dark:text-gray-300 text-base">
                Stackroots staff augmentation service is the ideal solution for businesses looking to scale up quickly and efficiently.


              </p>
            </div>
          </div>
        </div>
        <div className="py-10 px-2">
          <div
            className="max-w-sm rounded cursor-pointer overflow-hidden bg-white shadow-lg hover:scale-110 transition duration-500 min-h-full"
            onClick={() => navigate("/services/vfx")}
          >
            <img className="w-full" src={vfxCard} alt="" />
            <div className="px-6 py-4">
              <div className="font-bold text-xl text-black mb-2">
                Film VFX Services
              </div>
              <p className="text-black dark:text-gray-300 text-base">
                Stackroots is committed to staying at the forefront of technological advancements in the VFX industry.


              </p>
            </div>
          </div>
        </div>

        {/* <div className="py-10 px-2">
          <div
            className="max-w-sm rounded cursor-pointer overflow-hidden bg-white shadow-lg hover:scale-110 transition duration-500 min-h-full"
            onClick={() => navigate("/services/applicationdevelopment")}
          >
            <img className="w-full" src={appDevCard} alt="" />
            <div className="px-6 py-4">
              <div className="font-bold text-xl text-black mb-2">
                Mobile Application Devolpment
              </div>
              <p className="text-black dark:text-gray-300 text-base">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis elit sem ut vitae sed fringilla fringilla  Mattis elit sem ut vitae sed fringilla fringilla afgrfsvcx gnvc
              </p>
            </div>
          </div>
        </div> */}

      </div>
    </>
  );
};

export default ServiceCard;
