import { Box, Button, Stack, Typography } from '@mui/material'
import navigate from "../assets/img/navigate.png";
import { Link } from 'react-router-dom';

function ProductsDropDown() {
    return (
        <>
            <Stack >
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-start"} p={4}>
                    <div style={{ width: "40px", height: "auto", position: "absolute", top: "160px" }}>
                        <Typography style={{ "fontStyle": "normal", "fontWeight": "600", "fontSize": "25px", "lineHeight": "39px", "letterSpacing": "0.03em", "color": "#FFFFFF", paddingBottom: "8px", "transform": "rotate(-90deg) " }} variant='h5'>PRODUCTS</Typography>

                    </div>

                    <Stack ml={7} pl={2} sx={{ borderLeft: "1px solid #BDBDBD" }}>
                        <Stack>
                            <Link to={"products/stackarch"} style={{ cursor: "pointer" }}>
                                <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{
                                    width: "250px",
                                    height: "70px"
                                }}>
                                    <img width={"10%"} src={navigate} />
                                    <Typography
                                        sx={{
                                            // borderBottom: "1px solid #BDBDBD",
                                            padding: "10px",
                                            "fontStyle": "normal", "fontWeight": "500", "fontSize": "15px", "lineHeight": "24px", "letterSpacing": "0.03em", width: "250px",
                                            "&:hover": {
                                                marginBottom: "5px"
                                            }
                                        }}
                                    >
                                        StackArch
                                    </Typography>
                                </Stack>
                            </Link>

                            <Link to={"products/lms"} style={{ cursor: "pointer" }}>

                                <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{
                                    width: "250px",
                                    height: "70px"
                                }}>
                                    <img width={"10%"} src={navigate} />
                                    <Typography
                                        sx={{
                                            // borderBottom: "1px solid #BDBDBD",
                                            padding: "10px",
                                            "&:hover": {
                                                marginBottom: "5px"
                                            },
                                            "fontStyle": "normal", "fontWeight": "500", "fontSize": "15px", "lineHeight": "24px", "letterSpacing": "0.03em", width: "250px"
                                        }}
                                    >
                                        Stack-Ed.
                                    </Typography>
                                </Stack>
                            </Link>
                            <Link to={"products/certverify"} style={{ cursor: "pointer" }}>

                                <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{
                                    width: "250px",
                                    height: "70px"
                                }}>
                                    <img width={"10%"} src={navigate} />
                                    <Typography
                                        sx={{
                                            // borderBottom: "1px solid #BDBDBD",
                                            padding: "10px",
                                            "&:hover": {
                                                marginBottom: "5px"
                                            },
                                            "fontStyle": "normal", "fontWeight": "500", "fontSize": "15px", "lineHeight": "24px", "letterSpacing": "0.03em", width: "250px"
                                        }}
                                    >
                                        Cert-verify
                                    </Typography>
                                </Stack>
                            </Link>


                            {/* <Link to={"products/gymanytime"} style={{ cursor: "pointer" }}>

                                <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{
                                    width: "250px",
                                    height: "70px"
                                }}>
                                    <img width={"10%"} src={navigate} />
                                    <Typography
                                        sx={{
                                            // borderBottom: "1px solid #BDBDBD",
                                            padding: "10px",
                                            "&:hover": {
                                                marginBottom: "5px"
                                            },
                                            "fontStyle": "normal", "fontWeight": "500", "fontSize": "15px", "lineHeight": "24px", "letterSpacing": "0.03em", width: "250px"
                                        }}
                                    >
                                        Gym Anytime
                                    </Typography>
                                </Stack>
                            </Link> */}

                            {/* <Link to={"products/hospistack"} style={{ cursor: "pointer" }}>
                                <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{
                                    width: "250px",
                                    height: "70px"
                                }}>
                                    <img width={"10%"} src={navigate} />
                                    <Typography
                                        sx={{
                                            // borderBottom: "1px solid #BDBDBD",
                                            padding: "10px",
                                            "&:hover": {
                                                marginBottom: "5px"
                                            },
                                            "fontStyle": "normal", "fontWeight": "500", "fontSize": "15px", "lineHeight": "24px", "letterSpacing": "0.03em", width: "250px"
                                        }}
                                    >
                                        HospitStack
                                    </Typography>
                                </Stack>
                            </Link> */}

                        </Stack>
                    </Stack>
                    <Stack>
                        <Stack>

                            <Link to={"products/blockchain"} style={{ cursor: "pointer" }}>

                                <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{
                                    width: "250px",
                                    height: "70px"
                                }}>
                                    <img width={"10%"} src={navigate} />
                                    <Typography
                                        sx={{
                                            // borderBottom: "1px solid #BDBDBD",
                                            padding: "10px",
                                            "&:hover": {
                                                marginBottom: "5px"
                                            },
                                            "fontStyle": "normal", "fontWeight": "500", "fontSize": "15px", "lineHeight": "24px", "letterSpacing": "0.03em", width: "250px"
                                        }}
                                    >
                                        Blockchain-Based Decentralized Marketplace
                                    </Typography>
                                </Stack>
                            </Link>



                            {/* <Link to={"products/website"}  style={{ cursor: "pointer" }}>

                                <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{
                                    width: "250px",
                                    height: "70px"
                                }}>
                                    <img width={"10%"} src={navigate} />
                                    <Typography
                                        sx={{
                                            // borderBottom: "1px solid #BDBDBD",
                                            padding: "10px",
                                            "&:hover": {
                                                marginBottom: "5px"
                                            },
                                            "fontStyle": "normal", "fontWeight": "500", "fontSize": "15px", "lineHeight": "24px", "letterSpacing": "0.03em", width: "250px"
                                        }}
                                    >
                                        Website Development
                                    </Typography>
                                </Stack>
                            </Link> */}
                            <Link to={"products/socialmedia"} style={{ cursor: "pointer" }}>

                                <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{
                                    width: "250px",
                                    height: "70px"
                                }}>
                                    <img width={"10%"} src={navigate} />
                                    <Typography
                                        sx={{
                                            // borderBottom: "1px solid #BDBDBD",
                                            padding: "10px",
                                            "&:hover": {
                                                marginBottom: "5px"
                                            },
                                            "fontStyle": "normal", "fontWeight": "500", "fontSize": "15px", "lineHeight": "24px", "letterSpacing": "0.03em", width: "250px"
                                        }}
                                    >
                                        Social Media
                                    </Typography>
                                </Stack>
                            </Link>

                            <Link to={"products/autostack"} style={{ cursor: "pointer" }}>
                                <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{
                                    width: "250px",
                                    height: "70px"
                                }}>
                                    <img width={"10%"} src={navigate} />
                                    <Typography
                                        sx={{
                                            // borderBottom: "1px solid #BDBDBD",
                                            padding: "10px",
                                            "&:hover": {
                                                marginBottom: "5px"
                                            },
                                            "fontStyle": "normal", "fontWeight": "500", "fontSize": "15px", "lineHeight": "24px", "letterSpacing": "0.03em", width: "250px"
                                        }}
                                    >
                                        AutoStack
                                    </Typography>
                                </Stack>
                            </Link>

                            {/* <Link to={"products/flight"} style={{ cursor: "pointer" }}>

                                <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{
                                    width: "250px",
                                    height: "70px"
                                }}>
                                    <img width={"10%"} src={navigate} />
                                    <Typography
                                        sx={{
                                            // borderBottom: "1px solid #BDBDBD",
                                            padding: "10px",
                                            "&:hover": {
                                                marginBottom: "5px"
                                            },
                                            "fontStyle": "normal", "fontWeight": "500", "fontSize": "15px", "lineHeight": "24px", "letterSpacing": "0.03em", width: "250px"
                                        }}
                                    >
                                        Flight Booking
                                    </Typography>
                                </Stack>
                            </Link> */}


                        </Stack>
                    </Stack>
                    <Stack>
                        <Stack >

                            <Link to={"/products/attendance"} style={{ cursor: "pointer" }}  >
                                <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{
                                    width: "250px",
                                    height: "70px"
                                }}>
                                    <img width={"10%"} src={navigate} />
                                    <Typography
                                        sx={{
                                            // borderBottom: "1px solid #BDBDBD",
                                            padding: "10px",
                                            "&:hover": {
                                                marginBottom: "5px"
                                            },
                                            "fontStyle": "normal", "fontWeight": "500", "fontSize": "15px", "lineHeight": "24px", "letterSpacing": "0.03em", width: "250px"
                                        }}
                                    >
                                        Attendance Management
                                    </Typography>
                                </Stack>
                            </Link>



                            {/* <Link to={"products/digitalmarketing"}  style={{ cursor: "pointer" }}>

                                <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{
                                    width: "250px",
                                    height: "70px"
                                }}>
                                    <img width={"10%"} src={navigate} />
                                    <Typography
                                        sx={{
                                            // borderBottom: "1px solid #BDBDBD",
                                            padding: "10px",
                                            "&:hover": {
                                                marginBottom: "5px"
                                            },
                                            "fontStyle": "normal", "fontWeight": "500", "fontSize": "15px", "lineHeight": "24px", "letterSpacing": "0.03em", width: "250px"
                                        }}
                                    >
                                        Digital Marketing
                                    </Typography>
                                </Stack>
                            </Link> */}
                            <Link to={"products/ecommerce"} style={{ cursor: "pointer" }}>

                                <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{
                                    width: "250px",
                                    height: "70px"
                                }}>
                                    <img width={"10%"} src={navigate} />
                                    <Typography
                                        sx={{
                                            // borderBottom: "1px solid #BDBDBD",
                                            padding: "10px",
                                            "&:hover": {
                                                marginBottom: "5px"
                                            },
                                            "fontStyle": "normal", "fontWeight": "500", "fontSize": "15px", "lineHeight": "24px", "letterSpacing": "0.03em", width: "250px"
                                        }}
                                    >
                                        E-Commerce
                                    </Typography>
                                </Stack>
                            </Link>

                            {/* <Link to={"products/erp"} style={{ cursor: "pointer" }}>
                                <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{
                                    width: "250px",
                                    height: "70px"
                                }}>
                                    <img width={"10%"} src={navigate} />
                                    <Typography
                                        sx={{
                                            // borderBottom: "1px solid #BDBDBD",
                                            padding: "10px",
                                            "&:hover": {
                                                marginBottom: "5px"
                                            },
                                            "fontStyle": "normal", "fontWeight": "500", "fontSize": "15px", "lineHeight": "24px", "letterSpacing": "0.03em", width: "250px"
                                        }}
                                    >
                                        ERP & CRM Software
                                    </Typography>
                                </Stack>
                            </Link> */}

                            <Link to={"/products"} style={{ cursor: "pointer" }}>
                                <Stack direction={"row"} alignItems={"center"} spacing={2.2} sx={{
                                    width: "250px",
                                    height: "70px"
                                }}>
                                    <Box component={"img"} width={"10%"} src={navigate} sx={{ opacity: "0" }} />
                                    {/* <Typography
                                        sx={{
                                            // borderBottom: "1px solid #BDBDBD",
                                            padding: "10px",
                                            "&:hover": {
                                                marginBottom: "5px"
                                            },
                                            "fontStyle": "normal", "fontWeight": "500", "fontSize": "15px", "lineHeight": "24px", "letterSpacing": "0.03em", width: "250px"
                                        }}
                                    >
                                        View More
                                    </Typography> */}
                                    <Button onClick={() => navigate("/services")} sx={{
                                        background: "white !important",
                                        // color: ,
                                        // ":hover": {
                                        //     "color": "linear-gradient(180deg, #019ADE 0%, #0159AB 100%, rgba(255, 255, 255, 0) 100%)"
                                        // },
                                        "borderRadius": "5px",
                                        width: "100px",
                                        height: "40px",
                                    }}>
                                        <h1 style={{
                                            fontSize: "15px",
                                            background: "-webkit-linear-gradient(#019ADE, #0159AB)",
                                            "-webkit-background-clip": "text",
                                            "-webkit-text-fill-color": "transparent",
                                        }}>
                                            View More
                                        </h1>
                                    </Button>
                                </Stack>
                            </Link>

                        </Stack>

                    </Stack>
                </Stack>
            </Stack>
        </>
    )
}

export default ProductsDropDown