import {
  Box,
  Stack,
  Typography
} from "@mui/material";
import React from "react";
import degree1 from "../../assets/img/products/degree1.png";
import adaptivebg from "../../assets/img/services/adaptivebg.png";

import angular from "../../assets/img/services/logo/angularLogo.png";
import express from "../../assets/img/services/logo/expressLogo.png";
import mongo from "../../assets/img/services/logo/mongoLogo.png";
import mui from "../../assets/img/services/logo/muilogo.png";
import node from "../../assets/img/services/logo/nodelogo.png";
import react from "../../assets/img/services/logo/reactlogo.png";

import onestop from "../../assets/img/services/vectors/onestop.png";
import dataDriven from "../../assets/img/services/vectors/dataDriven.png";
import experiance from "../../assets/img/services/vectors/experiance.png";


import Marquee from "react-fast-marquee";
import { useStyles } from "../../styles";
import Techstack from "../Techstack";

const DegreeMarketing = () => {
  const classes = useStyles();

  const icons = [
    {
      img: react
    },
    {
      img: angular
    },
    {
      img: node
    },
    {
      img: mongo
    },
    {
      img: mui
    },
    {
      img: express
    },
  ]

  return (
    <div style={{ background: "#131331" }}>
      <div
        className="adaptivebg"
        style={{
          backgroundImage: `url(${adaptivebg})`,
        }}
      >
        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 2, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={12}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: { xs: "center", md: "flex-start" },
                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}

              >
                360 Degree Marketing
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "20px", md: "40px" },
                  flex: 1,
                  display: { md: "none", xs: "flex" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={degree1} alt="banner" />
              </Box>
              <Typography
                className={classes.mainContent}
                marginTop={{ xs: "30px", md: "10px" }}
              >
                Stackroots offers a comprehensive 360-degree marketing approach that helps businesses leverage marketing opportunities across various channels in real-time. With our data-driven approach and years of marketing experience, we deliver outstanding results for our clients. We combine advanced technology with smart talent to deliver high-quality digital solutions that work for our clients. Our team of experts creates suitable digital strategies to attract the target audience and increase conversions. We stay up-to-date with the latest marketing trends and tools to ensure that our clients stay ahead of the competition.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={degree1} alt="banner" />
            </Box>
          </Stack>
        </Box>
      </div>

      <Box
        sx={{
          width: "100%",
          // height: "100vh",
          display: "flex",
          flexWrap: "wrap",
          p: { xs: 3, md: 6 },
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row-reverse" }}
          sx={{
            alignItems: "center",
            justifyContent: "center",
          }}
          mt={12}
        >
          <Box
            sx={{
              display: "flex",

              flexDirection: "column",
              flex: 1,
            }}
          >
            <Typography
              className={classes.mainHeader}
              textAlign={{ xs: "center", md: "initial" }}
              mb={3}
            >
              One-Stop Digital Solutions Provider
            </Typography>
            <Box
              sx={{
                marginTop: { xs: "20px", md: "40px" },
                flex: 1,
                display: { xs: "flex", md: "none" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={onestop} />
            </Box>
            <Typography
              className={classes.mainContent}
              marginTop="20px"
            >
              We cater to businesses of all sizes and industries, providing them with a one-stop solution for all their digital marketing needs. Whether it's improving web presence or optimizing websites for better search results, we have the expertise to drive more sales through effective marketing campaigns. Our holistic approach ensures that all aspects of digital marketing, including SEO, social media marketing, content marketing, and paid advertising, are integrated seamlessly for maximum impact.
            </Typography>
          </Box>

          <Box
            sx={{
              marginTop: { xs: "0px", md: "40px" },
              flex: 1,
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img alt="vector" src={onestop} />
          </Box>
        </Stack>
      </Box>

      {/*  */}

      <Box
        sx={{
          width: "100%",
          // height: "100vh",
          display: "flex",
          flexWrap: "wrap",
          p: { xs: 3, md: 6 },
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          sx={{
            alignItems: "center",
            justifyContent: "center",
          }}
          mt={12}
        >
          <Box
            sx={{
              display: "flex",

              flexDirection: "column",
              flex: 1,
            }}
          >
            <Typography
              className={classes.mainHeader}
              textAlign={{ xs: "center", md: "initial" }}
              mb={3}
            >
              Data-Driven Approach
            </Typography>
            <Box
              sx={{
                marginTop: { xs: "20px", md: "40px" },
                flex: 1,
                display: { xs: "flex", md: "none" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={dataDriven} />
            </Box>
            <Typography
              className={classes.mainContent}
              marginTop="20px"
            >
              At Stackroots, we believe in the power of data. We analyze customer behavior, market trends, and industry insights to develop targeted marketing strategies that yield measurable results. Our team uses advanced analytics tools to track and measure the success of our campaigns, allowing us to make data-driven decisions and optimize marketing efforts for better ROI.
            </Typography>
          </Box>

          <Box
            sx={{
              marginTop: { xs: "0px", md: "40px" },
              flex: 1,
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img alt="vector" src={dataDriven} width={"70%"} />
          </Box>
        </Stack>
      </Box>

      {/*  */}

      <Box
        sx={{
          width: "100%",
          // height: "100vh",
          display: "flex",
          flexWrap: "wrap",
          p: { xs: 3, md: 6 },
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row-reverse" }}
          sx={{
            alignItems: "center",
            justifyContent: "center",
          }}
          mt={12}
        >
          <Box
            sx={{
              display: "flex",

              flexDirection: "column",
              flex: 1,
            }}
          >
            <Typography
              className={classes.mainHeader}
              textAlign={{ xs: "center", md: "initial" }}
              mb={3}
            >
              Personalized Customer Experience
            </Typography>
            <Box
              sx={{
                marginTop: { xs: "20px", md: "40px" },
                flex: 1,
                display: { xs: "flex", md: "none" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={experiance} />
            </Box>
            <Typography
              className={classes.mainContent}
              marginTop="20px"
            >
              We understand the importance of delivering a personalized customer experience. Our 360-degree marketing approach allows us to track customer interactions and preferences, enabling us to tailor marketing messages and offers to individual customers. By providing a personalized experience, we enhance customer satisfaction and loyalty, ultimately driving business growth. Overall, Stackroots is a trusted partner for businesses looking to enhance their marketing efforts through a comprehensive 360-degree approach. Contact us today to discuss how we can help your business succeed in reaching its marketing goals and driving growth.
            </Typography>
          </Box>

          <Box
            sx={{
              marginTop: { xs: "0px", md: "40px" },
              flex: 1,
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img alt="vector" src={experiance} width={"80%"} />
          </Box>
        </Stack>
      </Box>

      <Box sx={{ marginTop: "50px", display: { xs: "none", md: "block", sm: "block" } }}>
        <Techstack />
      </Box>

      <Box p={3} sx={{ marginTop: "50px", display: { xs: "block", md: "none", sm: "none" } }} >
        <Typography
          sx={
            { "fontStyle": "normal", "fontWeight": "600", "fontSize": "24px", "lineHeight": "29px", "color": "#FFFFFF", textAlign: "center" }
          }
        >
          Expertise And Technical Skills
        </Typography>
        <Stack>
          <Marquee gradient={false}>
            {icons.map((icon) => (
              <Box component={"img"}
                sx={{
                  mt: "25px",
                  width: "105px"
                }}
                src={icon.img}
                alt={"tech stack"}
              />
            ))
            }
          </Marquee>

          <Marquee gradient={false} direction={"right"}>
            {icons.map((icon) => (
              <Box component={"img"}
                sx={{
                  mt: "25px",
                  width: "105px"
                }}
                src={icon.img}
                alt={"tech stack"}
              />
            ))
            }
          </Marquee>
        </Stack>
      </Box>
    </div >
  );
};

export default DegreeMarketing;
