import {
    Box,
    Typography
} from "@mui/material";
import React from "react";
import { useStyles } from "../../styles";
import PopUp from "../PopUp";

import restBanner from "../../assets/img/services/vectors/restBanner.jpg"

const Banner = () => {
    const classes = useStyles();
    return (
        <>
            <Box
                sx={{
                    // TODO:IMG
                    background: `url(${restBanner})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "100%",
                    paddingBottom: { xs: "150px", md: "50px" },
                    // height: "470px",
                    height: { xs: "auto", md: "100vh" },
                    display: "flex",
                    flexWrap: "wrap",
                    // margin: { xs: "10px", md: "30px" },
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        padding: { xs: "20px", md: "50px" },
                        marginTop: { xs: "100px", md: "0px" },
                        marginLeft: { xs: "0px", md: "70px" },
                        maxWidth: "900px"
                    }}
                >
                    <Typography
                        className={classes.mainHeader}
                        sx={{

                            marginTop: { md: "100px", xs: "0px" },
                        }}
                    >
                        Restaurant food delivery system
                    </Typography>

                    <Typography
                        className={classes.mainContent}
                        sx={{
                            marginTop: { md: "25px", xs: "10px" },
                        }}
                    >
                        Introducing our revolutionary E-commerce app solution designed to take your restaurant business to new heights in the online food delivery market. With our platform, you can tap into the vast potential of online ordering and transform the way your restaurant operates.
                    </Typography>
                    <PopUp margin={"50px"} title={"Restaurant Food delivery system"} background={"#e6f7ff"} color={"#4F46E5"} />
                </Box>
            </Box>

        </>
    );

};

export default Banner;
