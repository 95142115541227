import React from 'react'
import ContactForm from '../../components/Contact/ContactForm'
import UiUxDesign from '../../components/UiDesign/UiUxDesign'
import officeDesk from "../../assets/img/services/officeDesk.png";
import { Helmet } from 'react-helmet';

const UiDesign = () => {
  return (
    <>
      <Helmet>
        <title>Stackroots | Ui Design</title>
      </Helmet>
      <div>
        <UiUxDesign />
        <ContactForm image={officeDesk} />
      </div>
    </>
  )
}

export default UiDesign