import React from 'react'
import Banner from '../../components/certverify/Banner'
import ContactForm from '../../components/Contact/ContactForm'
import Details from '../../components/certverify/Details'
import { Helmet } from "react-helmet"

function Certverify() {
    return (
        <>
            <Helmet>
                <title>Cert Verify</title>
                <meta
                    name="description"
                    content="Stack-Ed. LMS is the best LMS platform designed to revolutionize the way online courses are created, managed, and delivered to enhance learning experiences."
                />
            </Helmet>

            <Banner />
            <Details />
            <ContactForm title="Get a quote" />
        </>
    )
}

export default Certverify