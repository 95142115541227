import ThemeChanger from "./ThemeChanger";
import { Disclosure } from "@headlessui/react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/img/logo.png";
import huddle from "../assets/img/huddle.png";
import navigate from "../assets/img/navigate.png";

import { Stack } from "@mui/system";
import { Box, setRef, Typography } from "@mui/material";
import ProductsDropDown from "./ProductsDropDown";
import { useState } from "react";
import ServicesDropDown from "./ServicesDropDown";
import { ArrowDropDown } from "@mui/icons-material";
export default function Navbar() {

  const [productHover, setProductHover] = useState(false);
  const [serviceHover, setServiceHover] = useState(false);


  const navigation = ["Product", "Features", "Company", "Blog"];
  const location = useLocation();
  const absolute = [
    "/company",
    "/products",
    "/blogs",
    "/blogs/blogdetail",
    "/services",
    "/careers",
    "/contact",
  ];
  const check = absolute.includes(location.pathname);

  const checkPath = (path) => {
    return location.pathname.split("/")[1] === path;
  };

  return (
    <div className="w-full  top-0 z-50 fixed">
      <nav
        className={
          !check
            ? "container max-w-full  absolute flex flex-wrap items-center justify-between p-2 mx-auto lg:justify-between xl:px-10"
            : "container max-w-full  relative flex flex-wrap items-center justify-between p-2 mx-auto lg:justify-between xl:px-10"
        }
        style={{
          background: "linear-gradient(180deg, #151136 0%, #0A0C22 100%)",
          opacity: "0.9",
        }}
      >
        {/* Logo  */}
        <Disclosure>
          {({ open, close }) => (
            <>
              <div className="flex flex-wrap items-center justify-between w-full lg:w-auto">
                <Link to="/">
                  <a className="flex items-center space-x-2 text-2xl font-medium text-[#0057A9] dark:text-gray-100">
                    <span>
                      <img src={Logo} alt="N" className="h-[75px] w-[265px]" />
                    </span>
                    {/* <span></span> */}
                  </a>
                </Link>

                <Disclosure.Button
                  aria-label="Toggle Menu"
                  className="px-2 py-1 ml-auto  rounded-md lg:hidden hover:text-[#0057A9] focus:text-[#0057A9] focus:bg-white focus:outline-none dark:text-gray-300 dark:focus:bg-trueGray-700"
                >
                  <svg
                    className="w-6 h-6 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    {open && (
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                      />
                    )}
                    {!open && (
                      <path
                        fillRule="evenodd"
                        d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                      />
                    )}
                  </svg>
                </Disclosure.Button>

                <Disclosure.Panel style={{ width: "100%" }} className="text-gray-300 px-5 flex flex-col gap-1 overflow-y-auto max-h-screen ">
                  <>
                    {/* {navigation.map((item, index) => (
                      <Link key={index} to="/">
                <a className="w-full px-4 py-2 -ml-4  rounded-md dark:text-gray-300  text-white hover:text-[#0057A9] focus:text-[#0057A9] focus:bg-white dark:focus:bg-gray-800 focus:outline-none dark:focus:bg-trueGray-700">
                  {item}
                </a>
              </Link>
                    ))} */}
                    <Link
                      to="/"
                      className="w-full px-4 py-2 -ml-4  rounded-md dark:text-gray-300  text-white hover:text-[#0057A9] focus:text-[#0057A9] focus:bg-white dark:focus:bg-gray-800 focus:outline-none"
                      style={{
                        width: "100%",
                        background: checkPath("") ? "white" : "",
                        color: checkPath("") ? "#0057A9" : "",
                      }}
                      onClick={close}
                    >
                      Home
                    </Link>
                    <Link
                      to="/company"
                      className="w-full px-4 py-2 -ml-4  rounded-md dark:text-gray-300  text-white hover:text-[#0057A9] focus:text-[#0057A9] focus:bg-white dark:focus:bg-gray-800 focus:outline-none"
                      onClick={close}
                      style={{
                        background: checkPath("company") ? "white" : "",
                        color: checkPath("company") ? "#0057A9" : "",
                      }}
                    >
                      Company
                    </Link>

                    {/* fdsf */}
                    {/* <Disclosure
                      // to="/products"
                      className="w-full px-4 py-2 -ml-4  rounded-md dark:text-gray-300  text-white hover:text-[#0057A9] focus:text-[#0057A9] focus:bg-white dark:focus:bg-gray-800 focus:outline-none "
                      onClick={close}
                      style={{
                        background: checkPath("products") ? "white" : "",
                        color: checkPath("products") ? "#0057A9" : "",
                      }}
                    >
                      <Stack direction={"row"} justifyContent="space-between">
                        Products <ArrowDropDown />
                      </Stack>
                    </Disclosure> */}
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="w-full px-4 py-2 -ml-4 text-left  rounded-md dark:text-gray-300  text-white "
                            style={{
                              background: checkPath("products") ? "white" : "",
                              color: checkPath("products") ? "#0057A9" : "",
                            }}
                          >
                            <Stack direction={"row"} justifyContent="space-between" >
                              Products <ArrowDropDown />
                            </Stack>
                          </Disclosure.Button>
                          <Disclosure.Panel style={{ width: "100%" }} className="text-gray-300 px-5 flex flex-col gap-1">
                            <Link to={"products/stackarch"} onClick={close} className="w-500 px-4 py-2 -ml-4  rounded-md flex flex-row">StackArch</Link>
                            <Link to={"products/lms"} onClick={close} className="w-500 px-4 py-2 -ml-4  rounded-md">Stack Ed</Link>
                            <Link to={"products/certverify"} onClick={close} className="w-500 px-4 py-2 -ml-4  rounded-md">Cert Verify</Link>
                            <Link to={"products/ecommerce"} onClick={close} className="w-500 px-4 py-2 -ml-4  rounded-md">E-Commerce</Link>
                            <Link onClick={close} to={"/products"} className="w-500 px-4 py-2 -ml-4  rounded-md">View more <ArrowDropDown style={{ transform: "rotate(-90deg)" }} /></Link>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>

                    <Disclosure>
                      {({ open }) => (
                        <>

                          <Disclosure.Button className="w-full px-4 py-2 -ml-4 text-left  rounded-md dark:text-gray-300  text-white "
                            style={{
                              background: checkPath("services") ? "white" : "",
                              color: checkPath("services") ? "#0057A9" : "",
                            }}
                          >
                            <Stack direction={"row"} justifyContent="space-between" >
                              Services <ArrowDropDown />
                            </Stack>
                          </Disclosure.Button>
                          <Disclosure.Panel className="text-gray-300 px-5 flex flex-col gap-1">
                            <Link to={"services/adaptivewebdesign"} onClick={close} className="w-500 px-4 py-2 -ml-4  rounded-md flex flex-row">Website Development</Link>
                            <Link to={"services/cloudbasedapplication"} onClick={close} className="w-500 px-4 py-2 -ml-4  rounded-md">Cloud Based Web
                              Applications</Link>
                            <Link to={"services/uidesign"} onClick={close} className="w-500 px-4 py-2 -ml-4  rounded-md">UX/UI Design</Link>
                            <Link to={"services/360degreemarketing"} onClick={close} className="w-500 px-4 py-2 -ml-4  rounded-md">360 Degree Marketing</Link>

                            <Link to={"services/crm"} onClick={close} className="w-500 px-4 py-2 -ml-4  rounded-md">CRM Applications</Link>
                            <Link to={"services/seo"} onClick={close} className="w-500 px-4 py-2 -ml-4  rounded-md">SEO & Digital Marketing</Link>
                            <Link to={"services/ecommerceapplication"} onClick={close} className="w-500 px-4 py-2 -ml-4  rounded-md">Ecommerce Application</Link>
                            <Link to={"services/enterprise"} onClick={close} className="w-500 px-4 py-2 -ml-4  rounded-md">Enterprise Applications</Link>

                            <Link onClick={close} to={"/services"} className="w-500 px-4 py-2 -ml-4  rounded-md">View more <ArrowDropDown style={{ transform: "rotate(-90deg)" }} /> </Link>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                    {/* <Link
                      to="/services"
                      className="w-full px-4 py-2 -ml-4  rounded-md dark:text-gray-300  text-white hover:text-[#0057A9] focus:text-[#0057A9] focus:bg-white dark:focus:bg-gray-800 focus:outline-none"
                      onClick={close}
                      style={{
                        background: checkPath("services") ? "white" : "",
                        color: checkPath("services") ? "#0057A9" : "",
                      }}
                    >
                      <Stack direction={"row"} justifyContent="space-between">
                        Services <ArrowDropDown />
                      </Stack>
                    </Link> */}

                    <Link
                      to="/careers"
                      className="w-full px-4 py-2 -ml-4  rounded-md dark:text-gray-300  text-white hover:text-[#0057A9] focus:text-[#0057A9] focus:bg-white dark:focus:bg-gray-800 focus:outline-none"
                      onClick={close}
                      style={{
                        background: checkPath("careers") ? "white" : "",
                        color: checkPath("careers") ? "#0057A9" : "",
                      }}
                    >
                      Careers
                    </Link>
                    <Link
                      to="/blogs"
                      className="w-full px-4 py-2 -ml-4  rounded-md dark:text-gray-300  text-white hover:text-[#0057A9] focus:text-[#0057A9] focus:bg-white dark:focus:bg-gray-800 focus:outline-none"
                      onClick={close}
                      style={{
                        background: checkPath("blogs") ? "white" : "",
                        color: checkPath("blogs") ? "#0057A9" : "",
                      }}
                    >
                      Blogs
                    </Link>
                    <Link
                      to="/contact"
                      className="w-full px-4 py-2 -ml-4  rounded-md dark:text-gray-300  text-white hover:text-[#0057A9] focus:text-[#0057A9] focus:bg-white dark:focus:bg-gray-800 focus:outline-none"
                      onClick={close}
                      style={{
                        background: checkPath("contact") ? "white" : "",
                        color: checkPath("contact") ? "#0057A9" : "",
                      }}
                    >
                      Contact
                    </Link>
                    {/* Mode &nbsp;
                    <ThemeChanger /> */}
                  </>
                </Disclosure.Panel>
              </div>
            </>
          )
          }
        </Disclosure >

        {/* menu  */}
        <div className="hidden text-center lg:flex lg:items-center" >


          <ul className="items-center justify-end flex-1 gap-2 pt-6 list-none lg:pt-0 lg:flex">
            {/* {navigation.map((menu, index) => (
              <li className="mr-3 nav__item" key={index}>
                <Link to="/">
                  <a className="inline-block px-4 py-2 text-lg font-normal text-white no-underline rounded-md dark:text-gray-200 hover:text-[#0057A9] focus:text-[#0057A9] focus:bg-white focus:outline-none dark:focus:bg-gray-800">
                    {menu}
                  </a>
                </Link>
              </li>
            ))} */}

            {/* <Link
              to="/huddle"
              // className={`${"border-orange-500 hover:border-blue-500"} transition duration-300 ease-in-out border-2`}
              className="inline-block px-4 py-2 text-lg font-normal text-white no-underline rounded-md dark:text-gray-200 focus:outline-none dark:focus:bg-gray-800"
            >
              <img
                width={"110px"}
                src={huddle}
                className={
                  "hover:scale-110 scale-100 transition duration-300 ease-in-out transform"
                }
              />
            </Link> */}

            <Link
              to="/"
              className="inline-block px-4 py-2 text-lg font-normal text-white no-underline dark:text-gray-200 hover:text-[#009ADE] focus:text-[#009ADE]  focus:outline-none dark:focus:bg-gray-800"

            >
              <div style={{
                borderBottom: checkPath("") ? "3px solid #009ADE" : "",
                color: checkPath("") ? "#009ADE" : "",
                paddingBottom: "12px"
              }}>

                Home
              </div>
            </Link>
            <Link
              to="/company"
              className="inline-block px-4 py-2 text-lg font-normal text-white no-underline dark:text-gray-200 hover:text-[#009ADE] focus:text-[#009ADE]  focus:outline-none dark:focus:bg-gray-800"

            >
              <div style={{
                borderBottom: checkPath("company") ? "3px solid #009ADE" : "",
                color: checkPath("company") ? "#009ADE" : "",
                paddingBottom: "12px"
              }}>
                Company
              </div>
            </Link>
            <Link
              onClick={() => setProductHover(false)}
              onMouseEnter={() => setProductHover(true)}
              onMouseLeave={() => setProductHover(false)}
              to="/products"
              className="inline-block px-4 py-2 text-lg font-normal text-white no-underline dark:text-gray-200 hover:text-[#009ADE] focus:text-[#009ADE]  focus:outline-none dark:focus:bg-gray-800"
              style={{
                display: "flex"
              }}
            >
              <div style={{
                borderBottom: checkPath("products") ? "3px solid #009ADE" : "",
                color: checkPath("products") ? "#009ADE" : "",
                paddingBottom: "12px",
              }}>
                Products
              </div>
              <ArrowDropDown sx={{ color: checkPath("products") ? "#009ADE" : "", }} />

            </Link>

            <Link
              onClick={() => setServiceHover(false)}
              onMouseEnter={() => setServiceHover(true)}
              onMouseLeave={() => setServiceHover(false)}
              to="/services"
              className="inline-block px-4 py-2 text-lg font-normal text-white no-underline dark:text-gray-200 hover:text-[#009ADE] focus:text-[#009ADE]  focus:outline-none dark:focus:bg-gray-800"
              style={{
                display: "flex"
              }}
            >
              <div style={{
                borderBottom: checkPath("services") ? "3px solid #009ADE" : "",
                color: checkPath("services") ? "#009ADE" : "",
                paddingBottom: "12px"
              }}>
                Services
              </div>
              <ArrowDropDown sx={{ color: checkPath("services") ? "#009ADE" : "", }} />
            </Link>
            <Link
              to="/blogs"
              className="inline-block px-4 py-2 text-lg font-normal text-white no-underline dark:text-gray-200 hover:text-[#009ADE] focus:text-[#009ADE]  focus:outline-none dark:focus:bg-gray-800"
            >
              <div style={{
                borderBottom: checkPath("blogs") ? "3px solid #009ADE" : "",
                color: checkPath("blogs") ? "#009ADE" : "",
                paddingBottom: "12px"
              }}>
                Blogs
              </div>
            </Link>
            <Link
              to="/careers"
              className="inline-block px-4 py-2 text-lg font-normal text-white no-underline dark:text-gray-200 hover:text-[#009ADE] focus:text-[#009ADE]  focus:outline-none dark:focus:bg-gray-800"

            >
              <div style={{
                borderBottom: checkPath("careers") ? "3px solid #009ADE" : "",
                color: checkPath("careers") ? "#009ADE" : "",
                paddingBottom: "12px"
              }}>

                Careers
              </div>
            </Link>
            <Link
              to="/contact"
              className="inline-block px-4 py-2 text-lg font-normal text-white no-underline dark:text-gray-200 hover:text-[#009ADE] focus:text-[#009ADE]  focus:outline-none dark:focus:bg-gray-800"
            >
              <div style={{
                borderBottom: checkPath("contact") ? "3px solid #009ADE" : "",
                color: checkPath("contact") ? "#009ADE" : "",
                paddingBottom: "12px"
              }}>

                Contact
              </div>
            </Link>
          </ul>
        </div >

        <div className="hidden mr-3 space-x-4 lg:flex nav__item">
          {/* <Link to="/">
            <a className="px-6 py-2 text-white bg-indigo-600 rounded-md md:ml-5">
              Get Started
            </a>
          </Link> */}

          {/* <ThemeChanger /> */}
        </div>
      </nav >
      {
        productHover &&
        <div
          onClick={() => setProductHover(false)}
          onMouseEnter={() => setProductHover(true)}
          onMouseLeave={() => setProductHover(false)}
          style={{
            "background": "linear-gradient(180deg, #019ADE 0%, #0159AB 100%, rgba(255, 255, 255, 0) 100%)", "backdropFilter": "blur(7.5px)", "borderRadius": "10px",
            width: "890px",
            height: "270px",
            position: "absolute",
            top: "70px",
            left: "25%"

          }}>

          <ProductsDropDown />
        </div>
      }

      {
        serviceHover &&
        <div
          onClick={() => setServiceHover(false)}
          onMouseEnter={() => setServiceHover(true)}
          onMouseLeave={() => setServiceHover(false)}
          style={{
            "background": "linear-gradient(180deg, #019ADE 0%, #0159AB 100%, rgba(255, 255, 255, 0) 100%)", "backdropFilter": "blur(7.5px)", "borderRadius": "10px",
            width: "950px",
            height: "270px",
            position: "absolute",
            top: "70px",
            left: "25%",

          }}>
          <ServicesDropDown />
        </div>
      }
    </div >
  );
}
// df
