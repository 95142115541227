import React from "react";
import Banner9 from "../../components/DigitalMarketing/Banner9";
import Descriptions2 from "../../components/DigitalMarketing/Descriptions2";
import Detailed1 from "../../components/DigitalMarketing/Detailed1";
import ContactForm from "../../components/Contact/ContactForm";
import PopupWidget from "../../components/popupWidget";
import { Helmet } from "react-helmet";

const DigitalMarketing = () => {

  return (
    <>
      <Helmet>
        <title>Stackroots | Digital Marketing</title>
      </Helmet>
      <div>
        <Banner9 />
        <Descriptions2 />
        <Detailed1 />
        <ContactForm title="Get a quote" />
        {/* <PopupWidget />/ */}
      </div>
    </>
  );
};

export default DigitalMarketing;
