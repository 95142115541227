import React from "react";
import { Box, Button, Typography, Stack } from "@mui/material";
import { Container } from "@mui/system";
import { Fade } from "react-awesome-reveal";
import doctor1 from "../../assets/img/products/doctor1.png";

const Descriptions2 = () => {
  return (
    <Container maxWidth="xl">
      {/* <Fade triggerOnce="true" direction="up"> */}
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ flexDirection: "column", marginTop: "50px", flex: 3 }}>
              <Typography
                variant="h2"
                fontWeight="bold"
                textAlign="initial"
                lineHeight="60px"
                sx={{
                  fontSize: { xs: 32, md: 34 },
                  lineHeight: "48px",
                  color: "black",
                }}
              >
                Deliver every project on time and on budget with StackArch
                project management software built for architects, architectural
                firms, Construction firms, contractors etc.
              </Typography>

              <Typography
                variant="h4"
                fontSize="18px"
                fontWeight="medium"
                marginTop="10px"
                textAlign="initial"
                sx={{ lineHeight: "31px", color: "black" }}
              >
                Stackroots ERP is an easy-to-use powerful cloud-based ERP
                solution with multiple modes of operation Manage all aspects of
                your business, from finances to the workforce, while also
                staying on top of orders, managing inventory, and
                more.Stackroots ERP is more powerful, because it applies a
                single set of resource planning tools across the entire
                enterprise , provides real-time integration of sales, operating
                and financial data and connects resource planning approaches, to
                the extended supply chain of customers and suppliers. Stackroots
                ERP improves business performance
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },

                flex: 1,
              }}
            >
              <img src={doctor1} alt="banner" width="100%" />
            </Box>
          </Stack>
        </Box>
      {/* </Fade> */}
    </Container>
  );
};

export default Descriptions2;
