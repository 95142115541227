import React from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import navigate from "../assets/img/navigate.png";
import { Link } from 'react-router-dom';


function ServicesDropDown() {
    return (
        <>
            <Stack >
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} p={4}>
                    <div style={{ width: "40px", height: "auto", position: "absolute", top: "150px" }}>
                        <Typography style={{ "fontStyle": "normal", "fontWeight": "600", "fontSize": "25px", "lineHeight": "39px", "letterSpacing": "0.03em", "color": "#FFFFFF", paddingBottom: "8px", "transform": "rotate(-90deg) ", }} variant='h5'>SERVICES</Typography>

                    </div>

                    <Stack ml={7} pl={2} sx={{ borderLeft: "1px solid #BDBDBD" }}>
                        <Stack>
                            <Link to={"services/adaptivewebdesign"} style={{ cursor: "pointer" }}>
                                <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{
                                    width: "250px",
                                    height: "70px"
                                }}>
                                    <img width={"10%"} src={navigate} />
                                    <Typography
                                        sx={{
                                            // borderBottom: "1px solid #BDBDBD",
                                            padding: "10px",
                                            "&:hover": {
                                                marginBottom: "5px"
                                            },
                                            "fontStyle": "normal", "fontWeight": "500", "fontSize": "15px", "lineHeight": "24px", "letterSpacing": "0.03em", width: "250px"
                                        }}
                                    >
                                        Website Development Services
                                    </Typography>
                                </Stack>
                            </Link>

                            <Link to={"services/cloudbasedapplication"} style={{ cursor: "pointer" }}>
                                <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{
                                    width: "250px",
                                    height: "70px"
                                }}>
                                    <img width={"10%"} src={navigate} />
                                    <Typography
                                        sx={{
                                            // borderBottom: "1px solid #BDBDBD",
                                            padding: "10px",
                                            "&:hover": {
                                                marginBottom: "5px"
                                            },
                                            "fontStyle": "normal", "fontWeight": "500", "fontSize": "15px", "lineHeight": "24px", "letterSpacing": "0.03em", width: "250px"
                                        }}
                                    >
                                        Cloud Based Web
                                        Applications
                                    </Typography>
                                </Stack>
                            </Link>

                            <Link to={"services/uidesign"} style={{ cursor: "pointer" }}>

                                <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{
                                    width: "250px",
                                    height: "70px"
                                }}>
                                    <img width={"10%"} src={navigate} />
                                    <Typography
                                        sx={{
                                            // borderBottom: "1px solid #BDBDBD",
                                            padding: "10px",
                                            "&:hover": {
                                                marginBottom: "5px"
                                            },
                                            "fontStyle": "normal", "fontWeight": "500", "fontSize": "15px", "lineHeight": "24px", "letterSpacing": "0.03em", width: "250px"
                                        }}
                                    >
                                        UX/UI Design
                                    </Typography>
                                </Stack>
                            </Link>

                            {/* <Link to={"services/360degreemarketing"} style={{ cursor: "pointer" }}>
                                <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{
                                    width: "250px",
                                    height: "70px"
                                }}>
                                    <img width={"10%"} src={navigate} />
                                    <Typography
                                        sx={{
                                            // borderBottom: "1px solid #BDBDBD",
                                            padding: "10px",
                                            "&:hover": {
                                                marginBottom: "5px"
                                            },
                                            "fontStyle": "normal", "fontWeight": "500", "fontSize": "15px", "lineHeight": "24px", "letterSpacing": "0.03em", width: "250px"
                                        }}
                                    >
                                        360 Degree Marketing
                                    </Typography>
                                </Stack>
                            </Link> */}


                        </Stack>
                    </Stack>
                    <Stack>
                        <Stack>

                            <Link to={"services/crm"} style={{ cursor: "pointer" }}>
                                <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{
                                    width: "250px",
                                    height: "70px"
                                }}>
                                    <img width={"10%"} src={navigate} />
                                    <Typography
                                        sx={{
                                            // borderBottom: "1px solid #BDBDBD",
                                            padding: "10px",
                                            "&:hover": {
                                                marginBottom: "5px"
                                            },
                                            "fontStyle": "normal", "fontWeight": "500", "fontSize": "15px", "lineHeight": "24px", "letterSpacing": "0.03em", width: "250px"
                                        }}
                                    >
                                        CRM Applications
                                    </Typography>
                                </Stack>
                            </Link>



                            <Link to={"services/ecommerceapplication"} style={{ cursor: "pointer" }}>

                                <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{
                                    width: "250px",
                                    height: "70px"
                                }}>
                                    <img width={"10%"} src={navigate} />
                                    <Typography
                                        sx={{
                                            // borderBottom: "1px solid #BDBDBD",
                                            padding: "10px",
                                            "&:hover": {
                                                marginBottom: "5px"
                                            },
                                            "fontStyle": "normal", "fontWeight": "500", "fontSize": "15px", "lineHeight": "24px", "letterSpacing": "0.03em", width: "250px"
                                        }}
                                    >
                                        E-Commerce Application
                                    </Typography>
                                </Stack>
                            </Link>

                            <Link to={"services/seo"} style={{ cursor: "pointer" }}>

                                <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{
                                    width: "250px",
                                    height: "70px"
                                }}>
                                    <img width={"10%"} src={navigate} />
                                    <Typography
                                        sx={{
                                            // borderBottom: "1px solid #BDBDBD",
                                            padding: "10px",
                                            "&:hover": {
                                                marginBottom: "5px"
                                            },
                                            "fontStyle": "normal", "fontWeight": "500", "fontSize": "15px", "lineHeight": "24px", "letterSpacing": "0.03em", width: "250px"
                                        }}
                                    >
                                        SEO & Digital Marketing
                                    </Typography>
                                </Stack>
                            </Link>

                            {/* <Link to={"services/websiteboosting"} style={{ cursor: "pointer" }}>


                                <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{
                                    width: "250px",
                                    height: "70px"
                                }}>
                                    <img width={"10%"} src={navigate} />
                                    <Typography
                                        sx={{
                                            // borderBottom: "1px solid #BDBDBD",
                                            padding: "10px",
                                            "&:hover": {
                                                marginBottom: "5px"
                                            },
                                            "fontStyle": "normal", "fontWeight": "500", "fontSize": "15px", "lineHeight": "24px", "letterSpacing": "0.03em", width: "250px"
                                        }}
                                    >
                                        Website Boosting
                                    </Typography>
                                </Stack>
                            </Link> */}



                        </Stack>
                    </Stack>
                    <Stack>
                        <Stack>
                            <Link to={"services/branding"} style={{ cursor: "pointer" }}>

                                <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{
                                    width: "250px",
                                    height: "70px"
                                }}>
                                    <img width={"10%"} src={navigate} />
                                    <Typography
                                        sx={{
                                            // borderBottom: "1px solid #BDBDBD",
                                            padding: "10px",
                                            "&:hover": {
                                                marginBottom: "5px"
                                            },
                                            "fontStyle": "normal", "fontWeight": "500", "fontSize": "15px", "lineHeight": "24px", "letterSpacing": "0.03em", width: "250px"
                                        }}
                                    >
                                        Branding
                                    </Typography>
                                </Stack>
                            </Link>

                            <Link to={"services/socialmediamarketing"} style={{ cursor: "pointer" }}>

                                <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{
                                    width: "250px",
                                    height: "70px"
                                }}>
                                    <img width={"10%"} src={navigate} />
                                    <Typography
                                        sx={{
                                            // borderBottom: "1px solid #BDBDBD",
                                            padding: "10px",
                                            "&:hover": {
                                                marginBottom: "5px"
                                            },
                                            "fontStyle": "normal", "fontWeight": "500", "fontSize": "15px", "lineHeight": "24px", "letterSpacing": "0.03em", width: "250px"
                                        }}
                                    >
                                        Social Media Marketing
                                    </Typography>
                                </Stack>
                            </Link>

                            {/* <Link to={"services/applicationdevelopment"} style={{ cursor: "pointer" }}>
                                <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{
                                    width: "250px",
                                    height: "70px"
                                }}>
                                    <img width={"10%"} src={navigate} />
                                    <Typography
                                        sx={{
                                            // borderBottom: "1px solid #BDBDBD",
                                            padding: "10px",
                                            "&:hover": {
                                                marginBottom: "5px"
                                            },
                                            "fontStyle": "normal", "fontWeight": "500", "fontSize": "15px", "lineHeight": "24px", "letterSpacing": "0.03em", width: "250px"
                                        }}
                                    >
                                        Mobile App Development
                                    </Typography>
                                </Stack>
                            </Link> */}


                            <Link to={"/services"} style={{ cursor: "pointer" }}>
                                <Stack direction={"row"} alignItems={"center"} spacing={2.2} sx={{
                                    width: "250px",
                                    height: "70px"
                                }}>
                                    <Box component={"img"} width={"10%"} src={navigate} sx={{ opacity: "0" }} />
                                    {/* <Typography
                                        sx={{
                                            // borderBottom: "1px solid #BDBDBD",
                                            padding: "10px",
                                            "&:hover": {
                                                marginBottom: "5px"
                                            },
                                            "fontStyle": "normal", "fontWeight": "500", "fontSize": "15px", "lineHeight": "24px", "letterSpacing": "0.03em", width: "250px"
                                        }}
                                    >
                                        View More
                                    </Typography> */}
                                    <Button onClick={() => navigate("/services")} sx={{
                                        background: "white !important",
                                        // color: ,
                                        // ":hover": {
                                        //     "color": "linear-gradient(180deg, #019ADE 0%, #0159AB 100%, rgba(255, 255, 255, 0) 100%)"
                                        // },
                                        "borderRadius": "5px",
                                        width: "100px",
                                        height: "40px",
                                    }}>
                                        <h1 style={{
                                            fontSize: "15px",
                                            background: "-webkit-linear-gradient(#019ADE, #0159AB)",
                                            "-webkit-background-clip": "text",
                                            "-webkit-text-fill-color": "transparent",
                                        }}>
                                            View More
                                        </h1>
                                    </Button>
                                </Stack>
                            </Link>
                            {/* <Link to={"services/hosting"} style={{ cursor: "pointer" }}>
                                <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{
                                    width: "250px",
                                    height: "70px"
                                }}>
                                    <img width={"10%"} src={navigate} />
                                    <Typography
                                        sx={{
                                            // borderBottom: "1px solid #BDBDBD",
                                            padding: "10px",
                                            "&:hover": {
                                                marginBottom: "5px"
                                            },
                                            "fontStyle": "normal", "fontWeight": "500", "fontSize": "15px", "lineHeight": "24px", "letterSpacing": "0.03em", width: "250px"
                                        }}
                                    >
                                        Web Hosting
                                    </Typography>
                                </Stack>
                            </Link> */}
                            {/* <Link to={"services/enterprise"} style={{ cursor: "pointer" }}>
                                <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{
                                    width: "250px",
                                    height: "70px"
                                }}>
                                    <img width={"10%"} src={navigate} />
                                    <Typography
                                        sx={{
                                            // borderBottom: "1px solid #BDBDBD",
                                            padding: "10px",
                                            "&:hover": {
                                                marginBottom: "5px"
                                            },
                                            "fontStyle": "normal", "fontWeight": "500", "fontSize": "15px", "lineHeight": "24px", "letterSpacing": "0.03em", width: "250px"
                                        }}
                                    >
                                        Enterprise Applications
                                    </Typography>
                                </Stack>
                            </Link> */}
                        </Stack>
                    </Stack>
                </Stack>
            </Stack >

        </>
    )
}

export default ServicesDropDown