import { Stack, Typography } from "@mui/material";
import React from "react";
import Container from "./Container";
// import "./DropUs.css";
import hexagon from "../assets/img/hexagon.png";
import mobhexagon from "../assets/img/mobhexagon.png";
import SectionTitle from "./SectionTitle";
import { Fade } from "react-awesome-reveal";
import { useStyles } from "../styles";
const HeroHexagon = () => {
  const classes = useStyles();
  return (
    <div
      style={{
        background: "linear-gradient(180deg, #0B0D23 83.33%, #242242 100%)",
        paddingBottom: "80px",
      }}
    >
      <Typography py={6} textAlign={"center"} className={classes.mainHeader}>What We Have Here For You</Typography>

      <img src={hexagon} className="w-full lg:block hidden" />

      <img
        src={mobhexagon}
        className="w-full h-full object-contain lg:hidden"
      />

      {/* <Stack gap={2}>
        <Stack direction="row" gap={2} marginLeft="60px">
          <div class="hex"></div>
          <div class="hex"></div>
          <div class="hex"></div>
        </Stack>
        <Stack direction="row" gap={2}>
          <div class="hex"></div>
          <div class="hex"></div>
          <div class="hex"></div>
          <div class="hex"></div>
        </Stack>
      </Stack> */}
      {/* <ul className="honeycomb">
        <li className="honeycomb-cell">
          <img className="honeycomb-cell_img" src="img/work-1.jpg" />
          <div className="honeycomb-cell_title">
            360 Degree Marketing <br />
            We do marketing in all around your needs. We belives in 360 degree
            marketing.
          </div>
        </li>
        <li className="honeycomb-cell">
          <img className="honeycomb-cell_img" src="img/work-2.jpg" />
          <div className="honeycomb-cell_title">Graphic Design</div>
        </li>
        <li className="honeycomb-cell">
          <img className="honeycomb-cell_img" src="img/Work3.jpg" />
          <div className="honeycomb-cell_title">Illustration</div>
        </li>
        <li className="honeycomb-cell">
          <img className="honeycomb-cell_img" src="img/work4.jpg" />
          <div className="honeycomb-cell_title">Motion Graphics</div>
        </li>
        <li className="honeycomb-cell">
          <img className="honeycomb-cell_img" src="img/work5.jpg" />
          <div className="honeycomb-cell_title">
            3D<small>Animation</small>
          </div>
        </li>
        <li className="honeycomb-cell">
          <img className="honeycomb-cell_img" src="img/work06.jpg" />
          <div className="honeycomb-cell_title">
            Cinematic<small>4D</small>
          </div>
        </li>
        <li className="honeycomb-cell">
          <img className="honeycomb-cell_img" src="img/work07.jpg" />
          <div className="honeycomb-cell_title">Share Design</div>
        </li>
        <li className="honeycomb-cell honeycomb_Hidden"></li>
      </ul> */}
    </div>
  );
};

export default HeroHexagon;
