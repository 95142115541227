import {
    Box,
    Typography
} from "@mui/material";
import React from "react";
import { useStyles } from "../../styles";
import PopUp from "../PopUp";

import banner from "../../assets/img/new/pdcBanner.jpg"


const Banner = () => {

    const classes = useStyles();
    return (
        <>
            <Box
                sx={{
                    // TODO:IMG
                    background: `url(${banner})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "100%",
                    paddingBottom: { xs: "150px", md: "50px" },
                    // height: "470px",
                    height: { xs: "auto", md: "100vh" },
                    display: "flex",
                    flexWrap: "wrap",
                    // margin: { xs: "10px", md: "30px" },
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        padding: { xs: "20px", md: "50px" },
                        marginTop: { xs: "100px", md: "0px" },
                        marginLeft: { xs: "0px", md: "70px" },
                        maxWidth: "900px"
                    }}
                >
                    <Typography
                        className={classes.mainHeader}
                        sx={{

                            marginTop: { md: "100px", xs: "0px" },
                        }}
                    >
                        PDC Management System
                    </Typography>

                    <Typography
                        className={classes.mainContent}
                        sx={{
                            marginTop: { md: "25px", xs: "10px" },
                        }}
                    >
                        Welcome to Stackroots' PDC Management System, the ultimate solution for businesses looking to simplify and automate their Post Dated Cheque (PDC) management process. Our advanced software empowers businesses to efficiently handle PDCs, reducing manpower costs and ensuring hassle-free management. With Stackroots, you can say goodbye to manual processing and embrace automated check clearing solutions that provide safe and secure communication.
                    </Typography>
                    <PopUp margin={"50px"} title={"PDC Management System"} background={"#e6f7ff"} color={"#4F46E5"} />
                </Box>
            </Box>

        </>
    );

};

export default Banner;
