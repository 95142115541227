import { Stack, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import ContactForm from "../../components/Contact/ContactForm";
import GymBanner from "../../components/Gym/GymBanner";
import GymContact from "../../components/Gym/GymContact";
import GymHero from "../../components/Gym/GymHero";
import GymProducts from "../../components/Gym/GymProducts";
import SectionTitle from "../../components/SectionTitle";
import { useStyles } from "../../styles";

const Gym = () => {
  const classes = useStyles();
  return (
    <>
      <Helmet>
        <title>Stackroots | Gym</title>
      </Helmet>
      <div>
        <GymBanner />
        <GymProducts />
        <GymHero />
        <div
          style={{
            background: "linear-gradient(180deg, #151137 0%, #151137 96.87%)",
          }}
        >
          <Stack justifyContent="center" alignItems={"center"} p={{ xs: 2, md: 5 }}>
            <Stack maxWidth={"950px"}>
              <Typography textAlign={{ xs: "left", md: "center" }} className={classes.mainHeader}>Gym Anytime, the ultimate gym management solution</Typography>
              <Typography mt={2} className={classes.mainContent}> Gym Anytime addresses the unique challenges faced by gym owners and managers, offering a comprehensive gym booking app that simplifies client management and enhances customer satisfaction. By providing convenience, streamlined booking processes, efficient time and resource management, and effective communication channels, Gym Anytime revolutionizes the way gyms operate. With Gym Anytime, gym owners can focus on providing exceptional service, while clients enjoy the flexibility and convenience of scheduling their workouts at their convenience. Experience improved customer satisfaction levels and ratings with Stackroots Gym Anytime, the ultimate gym management solution.</Typography>
            </Stack>
          </Stack>
          {/* <SectionTitle
            title="A caring fitness community to help you stay on track."
            color="text-white"
          >
            We have served our clients well. So well that they have improved their
            average customer satisfaction levels and ratings
          </SectionTitle> */}
        </div>
        <GymContact />
      </div>
    </>

  );
};

export default Gym;
