import { Box, Stack, Typography } from '@mui/material'
import React from 'react'

function Benifits({ benifit }) {
    return (
        <Stack gap={2} width={{ xs: "350px", md: "360px" }} alignItems={"center"}>
            <Box width={"80px"} component={"img"} src={benifit.icon} />
            <Typography sx={{ "fontSize": "24px", "fontStyle": "normal", "fontWeight": "700", "lineHeight": "normal" }}>{benifit.title}</Typography>

            <Typography sx={{ "fontSize": "18px", "fontStyle": "normal", "fontWeight": "300", textAlign: "center", "lineHeight": "normal" }} >{benifit.desc}</Typography>
        </Stack>
    )
}

export default Benifits