import {
  Box,
  Stack,
  Typography
} from "@mui/material";
import React from "react";
import delivery from "../../assets/img/products/delivery.png";
import uidesign from "../../assets/img/products/uidesign.png";
import adaptivebg from "../../assets/img/services/adaptivebg.png";
import desingBanner from "../../assets/img/services/designBanner.png";
import designingbg from "../../assets/img/services/designingbg.png";
import flowchart from "../../assets/img/services/flowchart.png";
import flowPath from "../../assets/img/services/path.svg";

import ai from "../../assets/img/services/logo/ai.png";
import canava from "../../assets/img/services/logo/canava.png";
import diamond from "../../assets/img/services/logo/diamond.png";
import figma from "../../assets/img/services/logo/figma.png";
import ps from "../../assets/img/services/logo/ps.png";
import xd from "../../assets/img/services/logo/xd.png";

import Marquee from "react-fast-marquee";
import { useStyles } from "../../styles";
import Techstack from "../Techstack";


import bestInClass from "../../assets/img/services/vectors/bestInClass.png";
import design from "../../assets/img/services/vectors/design.png";

const UiUxDesign = () => {
  const classes = useStyles();
  const icons = [
    {
      img: figma
    },
    {
      img: xd
    },
    {
      img: diamond
    },
    {
      img: ai
    },
    {
      img: canava
    },
    {
      img: ps

    },
  ]
  return (
    <div style={{ background: "#131331" }}>
      <div
        className="adaptivebg"
      // style={{
      //   backgroundImage: `url(${adaptivebg})`,
      // }}
      >
        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 2, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={12}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: { xs: "center", md: "flex-start" },
                flexDirection: "column",
                gap: { xs: "25px", md: "0px" },
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                mb={3}
              >
                Stackroots Creating Intuitive and Evolved Designs
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "0px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={uidesign} alt="banner" />
              </Box>
              <Typography
                mt={2}
                className={classes.mainContent}
              >
                At Stackroots, we are the #1 UI/UX design company that brings ideas to life through scalable, sustainable, and futuristic design solutions. Our team of talented programmers and website design specialists can help you meet and even exceed industry standards with our UI/UX optimization services. We strive to make your website more integrated into your brand, easier to use, more likely to convert, and capable of creating positive impressions. We place a high value on the needs of our clients and ensure that their demands are met. Our UI/UX expert team at Stackroots is dedicated to creating highly intuitive and evolved designs for web and mobile applications. We work closely with you to understand your business goals and target audience, enabling us to create customized solutions that align with your brand identity.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={uidesign} alt="banner" />
            </Box>
          </Stack>
        </Box>

        {/*  */}

        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row-reverse" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={12}
          >
            <Box
              sx={{
                display: "flex",

                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}
              >
                Best-in-Class Digital Experiences
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "20px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img alt="vector" src={bestInClass} />
              </Box>
              <Typography
                className={classes.mainContent}
                marginTop="20px"
              >
                Our UI/UX designers at Stackroots are committed to delivering best-in-class digital experiences that help your business succeed in the digital world. We stay up-to-date with the latest design trends and technologies to ensure that your website stands out from the competition. By focusing on user-centered design principles, we create user interfaces and experiences that are optimized for usability and engagement. We understand that every business is unique, which is why we offer customized UI/UX design solutions. Whether you need a complete website redesign or improvements to specific elements of your user interface, we have the expertise to deliver results that drive business growth.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img width={"80%"} alt="vector" src={bestInClass} />
            </Box>
          </Stack>
        </Box>

      </div>

      <Box
        className="adaptivebg"
        sx={{
          backgroundImage: { xs: `url(${desingBanner})`, md: `url(${designingbg})` },
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          // height: "650px",
        }}
      >
        <Box sx={{ marginTop: { xs: "20px", md: "50px" }, p: { md: 6, sx: "0px" } }}>
          <Typography
            className={classes.mainHeader}
            textAlign="center"
            sx={{
              marginTop: { xs: "35px", md: 0, sm: 0 },
            }}
          >
            Designing Process
          </Typography>
          <Box
            sx={{
              marginTop: { xs: "20px", md: "40px" },
            }}
          >
            <Box component={"img"} src={flowchart} alt="banner" sx={{ display: { xs: "none", md: "block" }, margin: "0 auto", }} />
            <Box component={"img"} src={flowPath} alt="banner" sx={{ display: { xs: "block", md: "none" }, margin: "0 auto", }} />
          </Box>
        </Box>
      </Box>
      <Box
        className="adaptivebg"
        sx={{
          backgroundImage: { xs: "none", md: `url(${adaptivebg})` },
        }}
      >
        <Box
          sx={{
            width: "100%",
            // height: "100%",
            display: "flex",
            flexWrap: "wrap",
            mt: "50px",
            p: { xs: 2, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: { xs: "center", md: "flex-start" },
                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                mb={3}

              >
                Enhancing User Experience
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "0px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={delivery} alt="banner" />
              </Box>
              <Typography
                className={classes.mainContent}
                marginTop={{ xs: "40px", md: "10px" }}
              >
                Our UI/UX design services go beyond aesthetics. We prioritize creating a seamless user experience by designing intuitive navigation, clear information architecture, and engaging interactions. By optimizing the user journey and removing any friction points, we aim to increase user satisfaction and encourage repeat visits.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={delivery} alt="banner" />
            </Box>
          </Stack>
        </Box>


        {/*  */}

        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row-reverse" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={12}
          >
            <Box
              sx={{
                display: "flex",

                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}
              >
                Let STACKROOTS Create the Best UI/UX Design for Your Business
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "20px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img alt="vector" src={design} />
              </Box>
              <Typography
                className={classes.mainContent}
                marginTop="20px"
              >
                If you're looking to create a highly intuitive and evolved user interface and experience, contact Stackroots today. Our team of UI/UX design experts will work closely with you to understand your needs and develop a customized solution that aligns with your brand identity. Let us help you create best-in-class digital experiences that drive business success.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={design} />
            </Box>
          </Stack>
        </Box>

        <Box sx={{ marginTop: "50px", display: { xs: "none", md: "block" } }}>
          <Techstack />
        </Box>

        <Box p={3} sx={{ marginTop: "50px", display: { xs: "block", md: "none", } }} >
          <Typography
            sx={
              { "fontStyle": "normal", "fontWeight": "600", "fontSize": "24px", "lineHeight": "29px", "color": "#FFFFFF", textAlign: "center" }
            }
          >
            Expertise And Technical Skills
          </Typography>
          <Stack>
            <Marquee gradient={false}>
              {icons.map((icon) => (
                <Box component={"img"}
                  sx={{
                    mt: "25px",
                    width: "115px"
                  }}
                  src={icon.img}
                  alt={"tech stack"}
                />
              ))
              }
            </Marquee>

            <Marquee gradient={false} direction={"right"}>
              {icons.map((icon) => (
                <Box component={"img"}
                  sx={{
                    mt: "25px",
                    width: "115px"
                  }}
                  src={icon.img}
                  alt={"tech stack"}
                />
              ))
              }
            </Marquee>
          </Stack>
        </Box>
      </Box>
    </div>
  );
};

export default UiUxDesign;
