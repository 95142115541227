import React from "react";
import DropUs from "../../components/DropUs";
import PopupWidget from "../../components/popupWidget";
import Banner7 from "../../components/Socialmedia/Banner7";
import Description4 from "../../components/Socialmedia/Description4";
import Detail1 from "../../components/Socialmedia/Detail1";
import ContactForm from "../../components/Contact/ContactForm";
import { Helmet } from "react-helmet";

const SocialMedia = () => {
  return (
    <>
      <Helmet>
        <title>Stackroots | Social Media</title>
      </Helmet>
      <div>
        <Banner7 />
        <Description4 />
        {/* <Detail1 /> */}
        <ContactForm title="Get a quote" />
      </div>
    </>

  );
};

export default SocialMedia;
