import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import Container from "../Container";
import Pro1 from "../../assets/img/products/gym1.png";
import Pro2 from "../../assets/img/products/gym2.png";
import Pro3 from "../../assets/img/pro3.png";
import gym1hex from "../../assets/img/gym1hex.png";
import Pro4 from "../../assets/img/pro5.png";
import SectionTitle from "../SectionTitle";
import { useStyles } from "../../styles";

const GymProducts = () => {
  const classes = useStyles();
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(180deg, #151136 26.56%, #282559 39.58%, #161237 55.21%, #282457 70.83%, #0A0C22 95.83%, #212458 99.48%),url(${gym1hex})`,
        backgroundBlendMode: "hard-light",
        backgroundRepeat: "no-repeat",
        backgroundPositionY: "center",
        backgroundPositionX: "left",
      }}
    >
      <Container>
        <Grid container padding="40px 0px 40px 0px" alignItems={"center"}>
          <Grid item xs={12} md={6} lg={6}>
            <Box>
              <Typography
                className={classes.mainHeader}
                marginBottom={4}
              >
                Track your progress with Gymanytime.
              </Typography>
              <Typography className={classes.mainContent}
                sx={{
                  color: "white",
                }}
              >
                The need for Gym Anytime is evident in today's busy world, where individuals seek convenient and efficient ways to manage their fitness routines. With Gym Anytime, clients can effortlessly book their gym slots, effectively manage their time, and receive exceptional service from gym owners. This user-friendly app enhances customer satisfaction, improves ratings, and builds strong and long-lasting business relationships. Gym Anytime, developed by Stackroots Technology Solutions, is the perfect partner for individuals looking to prioritize their fitness and achieve their desired body in no time.
              </Typography>
              <ul class="list-disc text-white p-5">
                <li>   <Typography className={classes.mainContent}> 	Perfect online GYM Partner application </Typography></li>
                <li><Typography className={classes.mainContent}> 	Let your clients book a time slot with Gym Anytime </Typography></li>
                <li> <Typography className={classes.mainContent}> 	Efficient Time Management</Typography></li>
              </ul>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={Pro1} />
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          padding="40px 0px 40px 0px"
          alignItems={"center"}
          direction={{ xs: "column-reverse", sm: "row" }}
          spacing={2}
        >
          <Grid item xs={12} md={6} lg={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "inital",
              }}
            >
              <img src={Pro2} />
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Stack spacing={2}>
              <Typography
                className={classes.mainHeader}
              >
                Never miss your fitness routine.
              </Typography>
              <Typography className={classes.mainContent}
              >
                Gym Anytime is the perfect solution for busy professionals and individuals who have struggled to prioritize their fitness due to their hectic schedules. With result-backed fitness plans, efficient time management, comprehensive workout routines, personalized nutritional guidance, and progress tracking, Gym Anytime equips you with the tools and support you need to achieve your desired body in no time. Take control of your health and fitness with Gym Anytime, and let it be the catalyst for transforming your busy professional life into a balanced and healthy lifestyle.
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default GymProducts;
