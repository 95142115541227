import { Box, Typography, Stack } from "@mui/material";
import React from "react";
import CareerPopup from "./Carreer/CareerPopup";
import phone from "../assets/img/products/phone.png";
import { CustomButton } from "../utils/Button";
import { useStyles } from "../styles";

const Hero1 = ({ openings }) => {
  const classes = useStyles();
  return (

    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      <Box
        sx={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: { xs: "column", md: "row", sm: "row" },
        }}
      >
        <Box
          sx={{
            flexDirection: "column",
          }}
        >
          <Typography
            className={classes.mainHeader}
          >
            ENROUTE TO YOUR CAREER
          </Typography>
          <Typography
            mt={2}
            className={classes.boldMainContent}
          >
            Stackroots is a dynamic and innovative technology solutions &
            software services company, delivering simple and prolific solutions
            to a plethora of problems and challenges faced by businesses.
          </Typography>
          <Stack direction={{ xs: "column", md: "row" }} spacing={2} mt={4}>
            {openings &&
              <CareerPopup position={"true"} />
            }
            <CustomButton
              onClick={() => {
                window.location.href = "mailto:hr@stackroots.in";
              }}
            >
              SUBMIT YOUR ASSIGNMENTS
            </CustomButton>
          </Stack>
        </Box>

        <Box
          sx={{
            padding: "50px",
            marginTop: { xs: "0px", md: "40px" },
            display: { xs: "flex", sm: "flex", md: "flex" },
          }}
        >
          <img src="../frame.svg" alt="banner" width="1000px" />
        </Box>
      </Box>
    </Box>
  );
};

export default Hero1;
