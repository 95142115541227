import { Box, Card, Container, Divider, Stack, Typography, FormControl, RadioGroup, FormControlLabel, Radio, alpha, FormGroup, Checkbox, Button } from '@mui/material'
import React, { useImperativeHandle, useState } from 'react'
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        margin: theme.spacing(2, 0),
        color: 'black',
    },
    label: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(1),
    },
    option: {
        display: 'block',
        margin: theme.spacing(1, 2),
        borderRadius: '5px',
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing(0),
        '&:hover': {
            backgroundColor: theme.palette.grey[100],
        },
    },
    radio: {
        color: 'black',
        '&.Mui-checked': {
            color: 'black',
        },
    },
    labelSelected: {
        // fontWeight: 'bold',
    },
    checkbox: {
        color: 'black',
        '&.Mui-checked': {
            color: 'black',
        },
    },

}));

function QuestionPapper({ qustionPapper, values, handleChange }, ref) {
    const classes = useStyles();

    const handleFinishExam = async () => {
        try {
            const answers = Object.keys(values).map((questionId) => ({
                questionId: questionId,
                answers: values[questionId],
            }));
            await axios.post(`${process.env.REACT_APP_API_URL}/events/candidate/exam/finish`, {
                userResponse: answers,
            });
            toast.success("Exam submitted successfully");
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } catch (e) {
            console.log(e);
            toast.error(e.response.data.message);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
    };

    useImperativeHandle(ref, () => ({
        handleFinishExam
    }));

    return (
        <FormControl component="fieldset" className={classes.root} sx={{ display: "flex", gap: 3 }}>
            {
                qustionPapper?.sections?.map((section, sectionIndex) => (

                    <Card sx={{
                        color: "black", "backgroundColor": "#fafafa", boxShadow: "none", minHeight: "300px", width: "100%", borderRadius: "12px",
                        p: { xs: 2, md: 3 }
                    }}>
                        <Typography sx={{ fontSize: "22px", fontWeight: "500" }} textAlign={"center"} mb={3}>{section.title} Section</Typography>
                        <Stack gap={3}>
                            {
                                section?.questions?.map((q, questionIndex) => (
                                    <Card sx={{ color: "black", "backgroundColor": "rgba(96, 88, 158, 0.1)", boxShadow: "none", minHeight: "300px", width: "100%", borderRadius: "12px", p: 3 }} key={q.questionId._id}>
                                        <Stack direction={{ xs: "column", md: "row" }} alignItems={{ xs: "flex-start", md: "center" }} justifyContent={"space-between"} gap={{ xs: 2, md: 0 }}>
                                            <Typography width={{ xs: "100%", md: "90%" }} sx={{ fontSize: { xs: "17px", md: "18px" }, fontWeight: "500" }}>{(sectionIndex * section.questions.length) + questionIndex + 1}. {q.questionId.question}</Typography>
                                            <Stack gap={{ xs: 2, md: 0 }} width={{ xs: "auto", md: "100px" }} direction={{ xs: "row", md: "column" }}>
                                                <Typography sx={{ fontSize: "15px", fontWeight: "500" }}>mark : {q.mark}</Typography>
                                                <Typography sx={{ fontSize: "15px", fontWeight: "500" }}>negetive : {q.negativeMark}</Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack sx={{ borderRadius: "12px", backgroundColor: "white ", py: 2, mt: 2 }}>
                                            {q.questionId.optionType === "RADIO" && (
                                                <RadioGroup key={q.questionId._id} aria-label="capital" name={q.questionId._id}
                                                    value={values[q.questionId._id]?.length ? values[q.questionId._id][0] : ''}
                                                >
                                                    {q.questionId.options.map((option, index) => (
                                                        <FormControlLabel
                                                            key={option}
                                                            className={classes.option}
                                                            value={option}
                                                            control={<Radio onClick={(event) => handleChange(event, q.questionId._id)} color="primary" className={classes.radio} />}
                                                            label={option}
                                                            classes={{
                                                                label: values[q.questionId._id] === option ? classes.labelSelected : "",
                                                            }}
                                                        />
                                                    ))}
                                                </RadioGroup>
                                            )}
                                            {q.questionId.optionType === "CHECKBOX" && (
                                                <FormGroup>
                                                    {q.questionId.options.map((option, index) => (
                                                        <FormControlLabel
                                                            key={option}
                                                            className={classes.option}
                                                            value={option}
                                                            control={<Checkbox color="primary" className={classes.checkbox} />}
                                                            label={option}
                                                            checked={values[q.questionId._id] && values[q.questionId._id].includes(option)}
                                                            classes={{
                                                                label: values[q.questionId._id] && values[q.questionId._id].includes(option) ? classes.labelSelected : "",
                                                            }}
                                                            onChange={(event) => handleChange(event, q.questionId._id)}
                                                        />
                                                    ))}
                                                </FormGroup>
                                            )}
                                        </Stack>
                                    </Card>
                                ))
                            }
                        </Stack>
                    </Card>

                ))
            }
            <Stack width={"100%"} alignItems={{ xs: "center", md: "flex-end" }}>
                <Button onClick={handleFinishExam} type='submit' sx={{ m: "0 0 0 1px", width: "200px", background: "linear-gradient(180deg, #009ADE 0%, #0057A9 100%) !important", color: "white", }} variant='contained' > Save & Submit</Button>
            </Stack>
        </FormControl>
    )
}

export default QuestionPapper