import React from 'react'
import CloudBased from '../../components/CloudBased/CloudBased'
import ContactForm from '../../components/Contact/ContactForm'
import building from "../../assets/img/services/building.png";
import { Helmet } from 'react-helmet';
const CloudBasedApplications = () => {
  return (
    <>
      <Helmet>
        <title>Stackroots | Cloud Based Applications</title>
      </Helmet>
      <div>
        <CloudBased />
        <ContactForm image={building} />
      </div>
    </>
  )
}

export default CloudBasedApplications