import React from 'react'
import { Helmet } from 'react-helmet';
import ContactForm from "../../components/Contact/ContactForm";
import Banners1 from '../../components/Ecommerce/Banners1'
import Descriptions3 from '../../components/Ecommerce/Descriptions3'
import Detailed2 from '../../components/Ecommerce/Detailed2'
import PopupWidget from '../../components/popupWidget'

const Ecommerce = () => {
  return (
    <>
      <Helmet>
        <title>Stackroots | Ecommerce</title>
      </Helmet>
      <div>
        <Banners1 />
        <Descriptions3 />
        {/* <Detailed2 /> */}
        <ContactForm title="Get a quote" />
        {/* <PopupWidget /> */}
      </div>
    </>
  )
}

export default Ecommerce