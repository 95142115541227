import { Container, FormControl, Grid, InputLabel, MenuItem, Pagination, Select } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
// import Pagination from "./Pagination";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import FadeLoader from "react-spinners/FadeLoader";
import BlogTabs from "./BlogTabs";



const styles = {
  pagination: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: '3rem',
  },
  paginationButton: {
    width: '40px',
    height: '40px',
    fontFamily: 'inherit',
    fontWeight: 600,
    fontSize: '16px',
    margin: '0 10px',
    borderRadius: '6px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    color: 'white',
    border: '1px solid #1976d2',
  },
  paginationMob: {
    display: 'flex',
    // flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: '3rem',
  },
  paginationButtonMob: {
    width: '20px',
    height: '30px',
    fontFamily: 'inherit',
    fontWeight: 600,
    fontSize: '12px',
    margin: '0 5px',
    borderRadius: '6px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    color: 'white',
    border: '1px solid #1976d2',
  },
  activeButton: {
    fontWeight: 900,
    background: 'linear-gradient(180deg, #00BFFF 0%, #1E90FF 100%) !important',
    color: '#101010 !important',
  },
};

const BlogCategories = () => {
  const [value, setValue] = useState("all");
  const [categories, setCategories] = useState([]);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(6);
  const [active, setActive] = useState(categories[0]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(null);


  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/category/user`)
      .then((res) => {
        const categories = res.data.data.categories;
        categories.unshift({ _id: "all", name: "All" });
        setCategories(categories);
        if (categories.length) {
          setValue("all")
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (value === "all") {
      getBlogs()
    } else {
      getBlogs(value)
    }
  }, [currentPage]);

  const getBlogs = (id) => {
    setIsLoading(true)
    let url = `${process.env.REACT_APP_API_URL}/blog/user/all/category?size=${postsPerPage}&page=${currentPage - 1}`;
    if (id && id !== "all") {
      url += `&categoryId=${id}`;
    } else {
      url += ""
    }

    axios
      .get(url)
      .then((res) => {
        setBlogs(res.data.data.blogPosts);
        setTotalRecords(Math.ceil(res.data.data.maxRecords / postsPerPage))
        setIsLoading(false)
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false)
      });
  };


  const handleChange = (event, newValue) => {
    if (event.target.value === "all") {
      setValue("all")
      getBlogs();
      setCurrentPage(1);
    } else {
      setCurrentPage(1);
      setValue(event.target.value);
      getBlogs(event.target.value);
    }
    console.log(newValue);
  };


  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = blogs;
  return (
    <Container maxWidth="xl" sx={{ marginTop: "160px" }}>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "24px", maxWidth: "500px", margin: "0 auto" }}>
          <FormControl

            fullWidth focused>
            <InputLabel id="demo-simple-select-label">
              Select a Category
            </InputLabel>
            <Select
              onChange={(e, newValue) => handleChange(e, newValue.props.value)}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="select a category "
              value={value}
              MenuProps={{
                sx: {
                  "& .MuiPaper-root": {
                    backgroundColor: "whitesmoke",
                    color: "#131331",
                  },
                },
              }}
              sx={{
                ".MuiSvgIcon-root ": {
                  fill: "white !important",
                },

              }}
            >
              {categories?.map((cat, i) => (
                <MenuItem value={cat._id}>{cat.name}</MenuItem>
              ))}

            </Select>
          </FormControl>
        </Box>
        <Container>
          {
            isLoading ?
              <Stack alignItems={"center"} p={{ xs: 5, md: 10 }}>
                <FadeLoader color="#ddd" />
              </Stack>
              :
              <Grid container rowSpacing={2} columnSpacing={5}>
                {currentPosts?.map((blog, index) => (
                  <Grid item md={4} sm={6} xs={12} >
                    <BlogTabs data={blog} />
                  </Grid>
                ))}
              </Grid>
          }
          <Stack display={{ xs: "none", sm: "flex" }}>
            <div
              className="pagination"
              style={{ paddingBottom: '50px', marginTop: '3rem', ...styles.pagination }}
            >

              <Pagination
                siblingCount={1} boundaryCount={1}
                count={totalRecords}
                page={currentPage}
                onChange={(e, newVal) => setCurrentPage(newVal)}
                color="primary"
                sx={{
                  '& .MuiPaginationItem-root': {
                    ...styles.paginationButton,
                  },
                  '& .Mui-selected': {
                    ...styles.activeButton,
                  },
                }}
              />
            </div>
          </Stack>
          <div
            className="pagination"
            style={{ paddingBottom: '50px', marginTop: '3rem', ...styles.paginationMob }}
          >
            <Stack display={{ xs: "flex", sm: "none" }}>
              <Pagination
                siblingCount={0} boundaryCount={1}
                count={totalRecords}
                page={currentPage}
                onChange={(e, newVal) => setCurrentPage(newVal)}
                color="primary"
                sx={{
                  '& .MuiPaginationItem-root': {
                    ...styles.paginationButtonMob,
                  },
                  '& .Mui-selected': {
                    ...styles.activeButton,
                  },
                }}
              />
            </Stack>
          </div>
          {/* <Pagination
            totalPosts={totalRecords}
            postsPerPage={postsPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          /> */}
        </Container>
      </Box>

    </Container>
  );
};

export default BlogCategories;
