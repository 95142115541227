import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { useStyles } from '../../styles'

import timetable from "../../assets/img/new/timetable.png"
import calendarVector from "../../assets/img/new/calendarVector.png"
import radioBtn from "../../assets/img/new/radioBtn.png"
import shapesVector from "../../assets/img/products/shapesVector.png"
function Details() {
    const classes = useStyles();
    return (
        <Stack sx={{
            backgroundImage: `url(${shapesVector}) !important`,
            backgroundSize: "cover",
        }}>
            <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>



                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            Key Features of Stackroots Employee Attendance Management System
                        </Typography>
                        <Box sx={{ display: { xs: "block", md: "none", }, m: "0 auto !important", py: 2 }} component={"img"} src={timetable} width={{ xs: "70%", sm: "50%", md: "40%" }} />
                        <Typography className={classes.mainContent}>
                            Stackroots offers an advanced Attendance Management software designed to simplify and automate employee attendance tracking for businesses. With its comprehensive set of features, the Employee Attendance Management System streamlines the process of managing employee attendance, ensuring accuracy and efficiency.
                        </Typography>

                        <Stack spacing={1}>
                            <Stack direction={"row"} alignItems="center" spacing={2}>
                                <Box component={"img"} src={radioBtn} width={"24px"} />
                                <Typography className={classes.mainContent}>
                                    Employee Management
                                </Typography>
                            </Stack>
                            <Stack direction={"row"} alignItems="center" spacing={2}>
                                <Box component={"img"} src={radioBtn} width={"24px"} />
                                <Typography className={classes.mainContent}>
                                    Shift Management
                                </Typography>
                            </Stack>
                            <Stack direction={"row"} alignItems="center" spacing={2}>
                                <Box component={"img"} src={radioBtn} width={"24px"} />
                                <Typography className={classes.mainContent}>
                                    Work Schedule Management
                                </Typography>
                            </Stack>
                            <Stack direction={"row"} alignItems="center" spacing={2}>
                                <Box component={"img"} src={radioBtn} width={"24px"} />
                                <Typography className={classes.mainContent}>
                                    Leave Management
                                </Typography>
                            </Stack>
                            <Stack direction={"row"} alignItems="center" spacing={2}>
                                <Box component={"img"} src={radioBtn} width={"24px"} />
                                <Typography className={classes.mainContent}>
                                    Overtime and Deductions
                                </Typography>
                            </Stack>
                            <Stack direction={"row"} alignItems="center" spacing={2}>
                                <Box component={"img"} src={radioBtn} width={"24px"} />
                                <Typography className={classes.mainContent}>
                                    Holidays and Vacation
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Box display={{ xs: "none", md: "block" }} component={"img"} src={timetable} width={{ xs: "70%", sm: "50%", md: "40%" }} />
                </Stack>
            </Stack>


            <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>

                    <Box display={{ xs: "none", md: "block" }} component={"img"} src={calendarVector} width={{ xs: "70%", sm: "50%", md: "35%" }} />

                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            Enhance Efficiency with Stackroots Attendance Management Solution
                        </Typography>
                        <Box sx={{ display: { xs: "block", md: "none", }, m: "0 auto !important", py: 2 }} component={"img"} src={calendarVector} width={{ xs: "70%", sm: "50%", md: "40%" }} />
                        <Typography className={classes.mainContent}>
                            The Employee Attendance Management System is specifically designed to make the process of tracking employee attendance easier for businesses. It provides a centralized platform for businesses to manage their employee attendance records, which allows them to keep track of employee attendance in real-time. With this software, businesses can easily monitor employee attendance, identify areas of concern, and take corrective action to improve employee productivity. The Employee Attendance Management System is suitable for businesses of all sizes, from small startups to large corporations, and can be customized to meet the unique needs of each business.
                        </Typography>


                    </Stack>

                </Stack>
            </Stack>
        </Stack>
    )
}

export default Details