import React from "react";
import DropUs from "../../components/DropUs";
import Banner3 from "../../components/Erp/Banner3";
import Banners from "../../components/Erp/Banners";
import Cards1 from "../../components/Erp/Cards1";
import Description1 from "../../components/Erp/Description1";
import PopupWidget from "../../components/popupWidget";
import ContactForm from "../../components/Contact/ContactForm";
import ErpCards from "../../components/ErpCards";
import { Helmet } from "react-helmet";

const Erp = () => {
  return (
    <>
      <Helmet>
        <title>Stackroots | ERP</title>
      </Helmet>
      <div style={{ backgroundColor: "#131331" }}>
        <Banner3 />
        <ErpCards />
        <Banners />

        <Description1 />
        {/* <Cards1 /> */}
        <ContactForm title="Get a quote" />
        {/* <PopupWidget /> */}
      </div>
    </>
  );
};

export default Erp;
