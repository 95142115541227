import { Avatar, Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import testBg from "../../assets/img/testbg.png";

const Testimonials = ({ testimonial }) => {
  console.log(testimonial);
  return (
    <Container
      maxWidth="xl"
      sx={{
        backgroundRepeat: "no-repeat",

        backgroundImage: `url(${testBg}`,
        backgroundBlendMode: "hard-light",
      }}
    >
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        p={{ xs: 2, md: 10 }}
        spacing={4}
      >
        <Stack alignItems={"center"} spacing={2}>
          <Avatar
            alt="Remy Sharp"
            src={testimonial.image}
            sx={{ width: 112, height: 112 }}
          />
          <Box>
            <Typography variant="h6" color={"primary"} textAlign={"center"}>
              {testimonial?.title}
            </Typography>
          </Box>
        </Stack>
        <Box>
          <Typography variant="h6" color={"primary"} textAlign={"center"}>
            {testimonial?.description}
          </Typography>
        </Box>
      </Stack>
    </Container>
  );
};

export default Testimonials;
