import React from "react";
import { Box, Button, Typography, Stack, Grid, Card, CardContent, IconButton } from "@mui/material";
import { Container } from "@mui/system";
import degree1 from "../../assets/img/products/degree1.png";
import PersonIcon from "@mui/icons-material/Person";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ComputerIcon from "@mui/icons-material/Computer";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import HandshakeIcon from "@mui/icons-material/Handshake";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import adaptivebg from "../../assets/img/services/adaptivebg.png";
import webbg from "../../assets/img/services/webbg.png";

import easy from "../../assets/img/services/icons/easy.png";
import highPerform from "../../assets/img/services/icons/highPerform.png";
import scalablity from "../../assets/img/services/icons/scalablity.png";
import security from "../../assets/img/services/icons/security.png";
import techSupport from "../../assets/img/services/icons/techSupport.png";

import react from "../../assets/img/services/logo/reactlogo.png";
import angular from "../../assets/img/services/logo/angularLogo.png";
import node from "../../assets/img/services/logo/nodelogo.png";
import mongo from "../../assets/img/services/logo/mongoLogo.png";
import mui from "../../assets/img/services/logo/muilogo.png";
import express from "../../assets/img/services/logo/expressLogo.png";

import hosting from "../../assets/img/services/vectors/hosting.png";


import Techstack from "../Techstack";
import Marquee from "react-fast-marquee";
import { useStyles } from "../../styles";


const WebHosting1 = () => {
  const classes = useStyles();
  const icons = [
    {
      img: react
    },
    {
      img: angular
    },
    {
      img: node
    },
    {
      img: mongo
    },
    {
      img: mui
    },
    {
      img: express
    },
  ]
  return (
    <div style={{ background: "#131331" }}>
      <div
        className="adaptivebg"
        style={{
          backgroundImage: `url(${adaptivebg})`,
        }}
      >
        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={12}
          >
            <Box
              sx={{
                display: "flex",

                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={{ xs: 0, md: 3 }}
              >
                Web Hosting Services
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "0px", md: "40px" },
                  // flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={degree1} alt="banner" />
              </Box>
              <Typography mt={1} className={classes.mainContent}
              >
                Choosing the right web hosting provider is crucial for the success of your website and business. At Stackroots, we offer best-in-class web hosting services to ensure that your website is secure, reliable, and performs optimally. Our web hosting services provide a reliable and secure environment for your website. We use advanced technology and infrastructure to ensure that your website is always up and running, with minimal downtime. Our servers are equipped with robust security measures to protect your website from cyber threats and ensure the safety of your data.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={degree1} alt="banner" />
            </Box>
          </Stack>
        </Box>
      </div>
      <Box sx={{ marginTop: { xs: "10px", md: "60px" } }}>
        <Typography
          variant="h2"
          fontWeight={{ xs: "600", md: "bold" }}
          textAlign="center"
          lineHeight="60px"
          sx={{
            fontSize: { xs: "28px", md: 34 },
            lineHeight: "48px",
            color: "white",
            marginTop: { xs: "15px", md: 0, sm: 0 },
          }}
        >
          Features of Web Hosting
        </Typography>
        <Box component={"div"}
          sx={{
            backgroundImage: `linear-gradient(180deg, #131331 2.08%, rgba(16, 18, 57, 0.6) 54.69%, #131331 100%),url(${webbg})`,
            backgroundSize: "cover",
            padding: { xs: "10px", md: "50px" },
          }}
        >
          <Container maxWidth="xl">
            <Grid
              container
              padding="40px 0px 40px 0px"
              alignItems={"center"}
              spacing={4}
            >
              <Grid item xs={12} md={6} lg={4}>
                <Stack>
                  <Stack spacing={2} alignItems={{ xs: "center", md: "initial" }}>

                    {/* <Stack sx={{
                      borderRadius: "10px",
                      background:
                        "linear-gradient(180deg, #009ADE 0%, #0C4DA1 100%)",
                      fontSize: "10px",
                      color: "white",
                      padding: "10px",
                      width: "50px",
                      height: "50px"
                    }}>
                      <img src={techSupport}
                      />
                    </Stack> */}

                    <HandshakeIcon
                      style={{
                        borderRadius: "10px",
                        background:
                          "linear-gradient(180deg, #009ADE 0%, #0C4DA1 100%)",
                        fontSize: "50px",
                        color: "white",
                        padding: "5px",
                      }}
                    />

                    <Typography variant="h5" className="text-white">
                      FREE HOSTING
                    </Typography>
                  </Stack>
                  <Typography variant="body1" className="text-white" textAlign={{ xs: "center", md: "initial" }}>
                    We take care of hosting your website and application for life long.
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                {" "}
                <Stack>
                  <Stack spacing={2} alignItems={{ xs: "center", md: "initial" }}>
                    <Stack sx={{
                      borderRadius: "10px",
                      background:
                        "linear-gradient(180deg, #009ADE 0%, #0C4DA1 100%)",
                      fontSize: "10px",
                      color: "white",
                      padding: "10px",
                      width: "50px",
                      height: "50px"
                    }}>
                      <img src={highPerform}
                      />
                    </Stack>

                    <Typography textTransform={"uppercase"} variant="h5" className="text-white">
                      High performing
                    </Typography>
                  </Stack>
                  <Typography variant="body1" className="text-white" textAlign={{ xs: "center", md: "initial" }}>
                    We choose the best hosting based on quality to provide you with a stable and low-latency network!
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                {" "}
                <Stack>
                  <Stack spacing={2} alignItems={{ xs: "center", md: "initial" }}>
                    <Stack sx={{
                      borderRadius: "10px",
                      background:
                        "linear-gradient(180deg, #009ADE 0%, #0C4DA1 100%)",
                      fontSize: "10px",
                      color: "white",
                      padding: "10px",
                      width: "50px",
                      height: "50px"
                    }}>
                      <img src={easy}
                      />
                    </Stack>

                    <Typography textTransform={"uppercase"} variant="h5" className="text-white">
                      Easy Management
                    </Typography>
                  </Stack>
                  <Typography variant="body1" className="text-white" textAlign={{ xs: "center", md: "initial" }}>
                    We choose the managed dedicated server which offers you a greater level of support than you simply can't expect from typical hosting
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <Grid
              container
              padding="40px 0px 40px 0px"
              alignItems={"center"}
              spacing={4}
            >
              <Grid item xs={12} md={6} lg={4}>
                <Stack>
                  <Stack spacing={2} alignItems={{ xs: "center", md: "initial" }}>
                    <Stack sx={{
                      borderRadius: "10px",
                      background:
                        "linear-gradient(180deg, #009ADE 0%, #0C4DA1 100%)",
                      fontSize: "10px",
                      color: "white",
                      padding: "10px",
                      width: "50px",
                      height: "50px"
                    }}>
                      <img src={scalablity}
                      />
                    </Stack>

                    <Typography textTransform={"uppercase"} variant="h5" className="text-white">
                      Scalability
                    </Typography>
                  </Stack>
                  <Typography variant="body1" className="text-white" textAlign={{ xs: "center", md: "initial" }}>
                    As your website grows, you may need more resources than you currently have. We choose the best hosting plan that allows you to easily upgrade or downgrade your resources as needed.
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                {" "}
                <Stack>
                  <Stack spacing={2} alignItems={{ xs: "center", md: "initial" }}>
                    <Stack sx={{
                      borderRadius: "10px",
                      background:
                        "linear-gradient(180deg, #009ADE 0%, #0C4DA1 100%)",
                      fontSize: "10px",
                      color: "white",
                      padding: "10px",
                      width: "50px",
                      height: "50px"
                    }}>
                      <img src={security}
                      />
                    </Stack>

                    <Typography textTransform={"uppercase"} variant="h5" className="text-white">
                      Security
                    </Typography>
                  </Stack>
                  <Typography variant="body1" className="text-white" textAlign={{ xs: "center", md: "initial" }}>
                    We choose the hosting which can help to protect your website from malicious attacks and data loss.
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                {" "}
                <Stack>
                  <Stack spacing={2} alignItems={{ xs: "center", md: "initial" }}>
                    <Stack sx={{
                      borderRadius: "10px",
                      background:
                        "linear-gradient(180deg, #009ADE 0%, #0C4DA1 100%)",
                      fontSize: "10px",
                      color: "white",
                      padding: "10px",
                      width: "50px",
                      height: "50px"
                    }}>
                      <img src={techSupport}
                      />
                    </Stack>

                    <Typography textTransform={"uppercase"} variant="h5" className="text-white">
                      Technical Support
                    </Typography>
                  </Stack>
                  <Typography variant="body1" className="text-white" textAlign={{ xs: "center", md: "initial" }}>
                    We will help you resolve issues you encounter when launching and running your website.
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>



      {/*  */}

      <Box
        sx={{
          width: "100%",
          // height: "100vh",
          display: "flex",
          flexWrap: "wrap",
          p: { xs: 3, md: 6 },
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          sx={{
            alignItems: "center",
            justifyContent: "center",
          }}
          mt={12}
        >
          <Box
            sx={{
              display: "flex",

              flexDirection: "column",
              flex: 1,
            }}
          >
            <Typography
              className={classes.mainHeader}
              textAlign={{ xs: "center", md: "initial" }}
              mb={3}
            >
              Choose Stackroots for Reliable Web Hosting
            </Typography>
            <Box
              sx={{
                marginTop: { xs: "20px", md: "40px" },
                flex: 1,
                display: { xs: "flex", md: "none" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={hosting} />
            </Box>
            <Typography
              className={classes.mainContent}
              marginTop="20px"
            >
              If you're looking for reliable, secure, and high-performance web hosting services, look no further than Stackroots. Our team of experts will work closely with you to understand your hosting requirements and provide a customized solution that meets your needs. Let us take care of your website hosting so that you can focus on growing your business. Contact us today to get started.
            </Typography>
          </Box>

          <Box
            sx={{
              marginTop: { xs: "0px", md: "40px" },
              flex: 1,
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img alt="vector" src={hosting} />
          </Box>
        </Stack>
      </Box>

      <Box sx={{ marginTop: "50px", display: { xs: "none", md: "block", sm: "block" } }}>
        <Techstack />
      </Box>

      <Box p={3} sx={{ marginTop: "50px", display: { xs: "block", md: "none", sm: "none" } }} >
        <Typography
          sx={
            { "fontStyle": "normal", "fontWeight": "600", "fontSize": "24px", "lineHeight": "29px", "color": "#FFFFFF", textAlign: "center" }
          }
        >
          Expertise And Technical Skills
        </Typography>
        <Stack>
          <Marquee gradient={false}>
            {icons.map((icon) => (
              <Box component={"img"}
                sx={{
                  mt: "25px",
                  width: "105px"
                }}
                src={icon.img}
                alt={"tech stack"}
              />
            ))
            }
          </Marquee>

          <Marquee gradient={false} direction={"right"}>
            {icons.map((icon) => (
              <Box component={"img"}
                sx={{
                  mt: "25px",
                  width: "105px"
                }}
                src={icon.img}
                alt={"tech stack"}
              />
            ))
            }
          </Marquee>
        </Stack>
      </Box>
    </div>
  );
}

export default WebHosting1