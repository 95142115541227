import { Box, Card, Container, Divider, Stack, Typography, FormControl, RadioGroup, FormControlLabel, Radio, Button, alpha, Grid, IconButton, List, ListItemText, ListItemButton, SwipeableDrawer, ListItem, } from '@mui/material'
import React, { useEffect, useState, useRef, forwardRef } from 'react'
import { makeStyles } from '@mui/styles';
import QuestionPapper from '../components/Carreer/exam/QuestionPapper';
import axios from 'axios';
import FadeLoader from 'react-spinners/FadeLoader';
import moment from 'moment';
import QuestionDetails from '../components/Carreer/exam/QuestionDetails';
import { sentenceCase } from "sentence-case";
import profile from "../assets/img/profile.png"
import { AccessAlarm, Menu } from '@mui/icons-material/';
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'


function ExamPage() {
    const [questionSummary, setQuestionSummary] = useState({});
    const [startExamClicked, setStartExamClicked] = useState(false);
    const [questionPapper, setQuestionPapper] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const ForwardedQuestionPapperComponent = forwardRef(QuestionPapper);

    const [state, setState] = useState({
        left: false,
    });

    const questionRef = useRef();

    const [values, setValues] = useState({});
    const [remainingTime, setRemainingTime] = useState(null);

    const { width, height } = useWindowSize()

    useEffect(() => {
        getQuestionSummary();
    }, [])

    const getQuestionSummary = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/events/candidate/exam/questionPaperSummary`)
            setQuestionSummary(res.data.data)
        } catch (e) {
            console.log(e)
        }
    }

    const getQusetionPapper = async () => {
        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/events/candidate/exam/questionPaper`)
            let temp = {};
            res.data.data.sections.forEach((section) => {
                section.questions.forEach((question) => {
                    temp[question.questionId._id] = question.userAnswer;
                })
            })
            setTimeout(() => {
                setValues({ ...temp })
                setQuestionPapper(res.data.data)
                setIsLoading(false)
                setRemainingTimer(res.data.data.endTime);
            }, 1000);

        } catch (e) {
            console.log(e)
            setIsLoading(false)
        }
    }

    const setRemainingTimer = (endTime) => {
        const diff = moment(endTime).diff(moment());
        const rt = diff > 0 ? diff : 0;
        setRemainingTime(rt)
    }

    const handleDecreaseTime = () => {
        if (remainingTime > 0) {
            setRemainingTime((prevRemainingTime) => prevRemainingTime - 1000);
            if (remainingTime <= 1000) {
                callChiledFuction();
            }
        }
    }
    const callChiledFuction = () => {
        console.log("Timer reached zero, submitting question paper...");
        const question = questionRef.current;
        question.handleFinishExam();
    }

    useEffect(() => {
        const interval = setInterval(() =>
            handleDecreaseTime()
            , 1000);

        return () => clearInterval(interval)
    }, [remainingTime])

    const formatTimer = () => {
        const difference = moment.duration(remainingTime);
        return `${difference.hours().toString().padStart(2, "0")}:${difference.minutes().toString().padStart(2, "0")}:${difference.seconds().toString().padStart(2, "0")}`;
    }


    const handleStartExamButton = async () => {
        setStartExamClicked(true)
        getQusetionPapper();
    }

    function getRandomColor() {
        const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
        return randomColor;
    }

    const handleLogout = () => {
        localStorage.clear();
        window.location.href = "/careers"
    }

    const handleChange = async (event, questionId) => {
        const selectedOptions = values[questionId] || [];
        const optionValue = event.target.value;

        let updatedOptions;

        if (event.target.type === 'checkbox') {
            if (selectedOptions.includes(optionValue)) {
                updatedOptions = selectedOptions.filter((option) => option !== optionValue);
            } else {
                updatedOptions = [...selectedOptions, optionValue];
            }

            setValues((prevValues) => ({
                ...prevValues,
                [questionId]: updatedOptions,
            }));
        } else {
            updatedOptions = values[questionId][0] === optionValue ? [] : [optionValue];
            setValues((prevValues) => ({
                ...prevValues,
                [questionId]: updatedOptions,
            }));
        }


        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/events/candidate/exam/answer`, {
                questionId,
                answer: updatedOptions,
            });
        } catch (err) {
            console.error(err);
        }
    };
    console.log(values)
    // MOBILE DRAWER
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: "300px", }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
            color={"black"}
        >
            <Stack sx={{ background: "linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(5,35,68,1) 55%, rgba(9,9,121,1) 100%)", height: "100vh" }}>
                <Stack color={"white"} alignItems={"center"} justifyContent={"center"} mt={12} gap={1} >
                    <Box width={"80px"} component={"img"} borderRadius={"50%"} src={profile} />
                    <Typography sx={{ fontSize: "20px", fontWeight: "600", color: "white" }}>{sentenceCase(localStorage.getItem("candidateName"))}</Typography>
                    <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>{localStorage.getItem("candidateEmail")}</Typography>
                </Stack>
                <Stack mx={2} gap={1} pt={3}>
                    <Button onClick={handleLogout} variant='contained'
                        sx={{ color: "white", "height": "40px", background: "linear-gradient(180deg, #009ADE 0%, #0057A9 100%) !important" }}>
                        Logout
                    </Button>
                </Stack>
            </Stack>
        </Box>
    );

    return (
        <>
            {/* ========================= WEB VIEW ================================ */}
            {questionSummary?.isFinished === true &&
                <Confetti
                    width={width}
                    height={height}
                    recycle={false}
                    wind={0.01}
                    numberOfPieces={300}
                />
            }

            <Stack display={{ xs: "none", md: "flex" }} bgcolor={{ xs: "white", md: "#052344" }} color={"black "} py={2}>
                <Container maxWidth={{ xs: "xs", md: "xl" }}>

                    <Stack direction={"row"} >
                        <Stack display={{ xs: "none", md: "flex" }} flex={3} alignItems={"center"} gap={3}>
                            <Card sx={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", width: "250px", height: "240px", borderRadius: "12px" }}>
                                <Stack color={"black"} width={"100%"} height={"100%"}>
                                    <Stack color={"white"} alignItems={"center"} justifyContent={"center"} height={"150px"} sx={{
                                        background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(5,35,68,1) 35%, rgba(9,9,121,1) 100%)"
                                    }}>
                                        <Box width={"80px"} component={"img"} borderRadius={"50%"} src={profile} />
                                        <Typography sx={{ fontSize: "20px", fontWeight: "600", color: "white" }}>{sentenceCase(localStorage.getItem("candidateName"))}</Typography>
                                        <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>{localStorage.getItem("candidateEmail")}</Typography>
                                    </Stack>

                                    <Stack mx={2} gap={1} pt={3}>
                                        <Button onClick={handleLogout} variant='contained'
                                            sx={{ color: "white", "height": "40px", background: "linear-gradient(180deg, #009ADE 0%, #0057A9 100%) !important" }}>
                                            Logout
                                        </Button>
                                    </Stack>
                                </Stack>
                            </Card>

                            <Card sx={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", width: "250px", height: "180px", borderRadius: "12px" }}>
                                <Stack height={"100%"} width={"100%"} alignItems={"center"} justifyContent={"center"} gap={1}>
                                    <Typography sx={{ fontSize: "28px", fontWeight: "600", color: "black" }}>Timer</Typography>
                                    <Stack alignItems={"center"}>
                                        <Typography sx={{ color: "red", fontSize: "40px", fontWeight: "700" }}>{formatTimer()}</Typography>
                                        <Typography sx={{ color: "black", fontSize: "20px", fontWeight: "500" }}>Remaining</Typography>
                                    </Stack>
                                </Stack>
                            </Card>
                        </Stack>
                        <Divider orientation="vertical" variant="middle" flexItem sx={{ background: "gray", display: { xs: "none", md: "flex" } }} />
                        <Stack flex={10} px={{ xs: 0, md: 5 }}>
                            <Card sx={{ color: "black", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", width: "100%", borderRadius: "12px", height: '90vh', overflowY: 'scroll', '&::-webkit-scrollbar': { display: `${!isLoading && !startExamClicked ? "none" : "block"}`, "width": "6px", "overflow": "auto", }, }}>
                                {isLoading &&
                                    <Stack height={"100%"} width={"100%"} p={3} alignItems={"center"} justifyContent={"center"} >
                                        <FadeLoader color="#000000" />
                                    </Stack>
                                }
                                {!startExamClicked && <Stack display={{ xs: "none", md: "flex" }} m={2} alignItems={"center"}>
                                    <Typography sx={{ fontSize: { xs: "20px", md: "30px" }, fontWeight: "600" }}>Aptitude Assessment</Typography>
                                    <Typography sx={{ fontSize: { xs: "16px", md: "20px" }, fontWeight: "400" }}>Evaluating Key Cognitive Abilities for Professional Success</Typography>
                                </Stack>}
                                {
                                    !isLoading &&
                                    <Typography my={2.5} textAlign={"center"} sx={{ fontSize: { xs: "20px", md: "30px" }, fontWeight: 600 }}>{questionSummary?.title}</Typography>
                                }
                                {
                                    !isLoading && !startExamClicked &&
                                    <Stack height={"auto"} width={"100%"} p={3} gap={3}>
                                        {
                                            questionSummary?.isFinished === true &&
                                            <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} gap={3}>
                                                <Stack alignItems={"center"} justifyContent={"center"} gap={1} sx={{
                                                    width: "200px",
                                                    height: "140px",
                                                    borderRadius: "12px",
                                                    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0,0, 0.19)",
                                                }}>
                                                    <Typography sx={{ fontSize: "20px", fontWeight: "400" }}>Your Score</Typography>
                                                    <Typography sx={{ fontSize: "25px", fontWeight: "600" }}>{questionSummary.securedMarks}</Typography>
                                                </Stack>
                                            </Stack>
                                        }

                                        <Stack gap={3} direction={"row"} flexWrap={{ xs: "wrap", md: "nowrap" }} justifyContent={"center"}>

                                            <Grid container sx={{
                                                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                                                width: "300px",
                                                height: "140px",
                                                borderRadius: "12px",
                                                p: "10px",
                                                background: "linear-gradient(116.51deg, #0F0628 13.29%, #032170 96.8%)",
                                                color: "white",
                                            }}>
                                                <Grid item xs={6} >
                                                    <Stack gap={1}>
                                                        <Typography sx={{ fontSize: "16px", fontWeight: 450 }}>Total Questions</Typography>
                                                        <Typography sx={{ fontSize: "16px", fontWeight: 450 }}>Duration</Typography>
                                                        <Typography sx={{ fontSize: "16px", fontWeight: 450 }}>Status</Typography>
                                                        <Typography sx={{ fontSize: "16px", fontWeight: 450 }}>Total Marks</Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={6} >
                                                    <Stack gap={1}>
                                                        <Typography sx={{ fontSize: "16px", fontWeight: 450 }}>{questionSummary.totalQuestions}</Typography>
                                                        <Typography sx={{ fontSize: "16px", fontWeight: 450 }}>{questionSummary.duration} min</Typography>
                                                        <Typography sx={{ fontSize: "16px", fontWeight: 450 }}>{questionSummary.isFinished === true ? "Completed" : "Pending"}</Typography>
                                                        <Typography sx={{ fontSize: "16px", fontWeight: 450 }}>{questionSummary.totalMarks}</Typography>
                                                    </Stack>
                                                </Grid>
                                            </Grid>

                                            <Stack direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"center"} gap={3}>
                                                {
                                                    questionSummary?.questionSections?.map((section, index) => (
                                                        <Stack alignItems={"center"} justifyContent={"center"} gap={1} sx={{
                                                            width: { xs: "300px", md: "200px" },
                                                            height: { xs: "80px", md: "140px" },
                                                            borderRadius: "12px",
                                                            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0,0, 0.19)",
                                                            background: "linear-gradient(116.51deg, #0F0628 13.29%, #032170 96.8%)",
                                                            color: "white"
                                                        }}>
                                                            <Typography sx={{ fontSize: "20px", fontWeight: "500" }}>{section.title}</Typography>
                                                            <Typography sx={{ fontSize: "17px", fontWeight: "400" }}>Questions : {section.questions}</Typography>
                                                        </Stack>
                                                    ))
                                                }
                                            </Stack>
                                        </Stack>


                                        {questionSummary?.isFinished === false && <Button onClick={handleStartExamButton} variant='contained' sx={{
                                            background: "green !important",
                                            color: "white",
                                            width: "200px",
                                            m: "0 auto",
                                            color: "white", "height": "40px",
                                            background: "linear-gradient(180deg, #009ADE 0%, #0057A9 100%) !important"
                                        }}>Start Test</Button>}

                                    </Stack>
                                }
                                {!isLoading && startExamClicked &&

                                    <Stack px={3} mb={"40px !important"} >
                                        <QuestionDetails questionSummary={questionSummary} />
                                        <ForwardedQuestionPapperComponent
                                            ref={questionRef}
                                            qustionPapper={questionPapper}
                                            bgcolor={alpha(getRandomColor(), 0.1)}
                                            values={values}
                                            handleChange={handleChange}
                                        />
                                    </Stack>
                                }
                            </Card>
                        </Stack>
                    </Stack>
                </Container>
            </Stack>

            {/* =========================== MOBILE VIEW =========================== */}

            <Stack color={"black"}
                display={{ xs: "block", md: "none" }}
                sx={{ background: "white", minHeight: "100vh" }}>

                <Stack alignItems={"center"} direction={"row"} px={3} justifyContent={"space-between"} sx={{
                    background: "#052344",
                    height: "84px",
                    width: "100%",
                    position: "fixed",
                    zIndex: 100,
                    top: 0,

                }}>
                    <IconButton onClick={toggleDrawer("left", true)}>
                        <Menu fontSize='large' sx={{ color: "white" }} />
                    </IconButton>

                    <SwipeableDrawer
                        anchor={"left"}
                        open={state["left"]}
                        onClose={toggleDrawer("left", false)}
                        onOpen={toggleDrawer("left", true)}
                    >
                        {list("left")}
                    </SwipeableDrawer>

                    <Stack gap={1} direction={"row"} alignItems={"center"}>
                        <AccessAlarm fontSize='large' sx={{ color: 'white' }} />
                        <Typography sx={{ color: "red", fontSize: "30px", fontWeight: "600" }}>{formatTimer()}</Typography>
                        <Typography sx={{ color: "white", fontSize: "18px", fontWeight: "400" }}>Remaining</Typography>
                    </Stack>
                </Stack>

                {isLoading &&
                    <Stack height={"80vh"} width={"100%"} p={3} alignItems={"center"} justifyContent={"center"} >
                        <FadeLoader color="#000000" />
                    </Stack>
                }
                {!isLoading && <Typography my={2.5} textAlign={"center"} sx={{ fontSize: { xs: "20px", md: "30px" }, fontWeight: 600 }}>{questionSummary?.title}</Typography>}
                {
                    !isLoading && !startExamClicked &&
                    <Stack mt={12} height={"auto"} width={"100%"} p={3} gap={3}>
                        <Stack gap={3} direction={"row"} flexWrap={{ xs: "wrap", md: "nowrap" }} justifyContent={"center"}>
                            {
                                questionSummary?.isFinished === true &&
                                <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} gap={3}>
                                    <Stack alignItems={"center"} justifyContent={"center"} gap={1} sx={{
                                        width: "200px",
                                        height: "120px",
                                        borderRadius: "12px",
                                        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0,0, 0.19)",
                                    }}>
                                        <Typography sx={{ fontSize: "20px", fontWeight: "400" }}>Your Score</Typography>
                                        <Typography sx={{ fontSize: "25px", fontWeight: "600" }}>{questionSummary.securedMarks}</Typography>
                                    </Stack>
                                </Stack>
                            }

                            <Grid container sx={{
                                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                                width: "300px",
                                height: "140px",
                                borderRadius: "12px",
                                p: "10px",
                                background: "linear-gradient(116.51deg, #0F0628 13.29%, #032170 96.8%)",
                                color: "white",
                            }}>
                                <Grid item xs={6} >
                                    <Stack gap={1}>
                                        <Typography sx={{ fontSize: "16px", fontWeight: 450 }}>Total Questions</Typography>
                                        <Typography sx={{ fontSize: "16px", fontWeight: 450 }}>Duration</Typography>
                                        <Typography sx={{ fontSize: "16px", fontWeight: 450 }}>Status</Typography>
                                        <Typography sx={{ fontSize: "16px", fontWeight: 450 }}>Total Marks</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={6} >
                                    <Stack gap={1}>
                                        <Typography sx={{ fontSize: "16px", fontWeight: 450 }}>{questionSummary.totalQuestions}</Typography>
                                        <Typography sx={{ fontSize: "16px", fontWeight: 450 }}>{questionSummary.duration} min</Typography>
                                        <Typography sx={{ fontSize: "16px", fontWeight: 450 }}>{questionSummary.isFinished === true ? "Completed" : "Pending"}</Typography>
                                        <Typography sx={{ fontSize: "16px", fontWeight: 450 }}>{questionSummary.totalMarks}</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>

                            <Stack direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"center"} gap={3}>
                                {
                                    questionSummary?.questionSections?.map((section, index) => (
                                        <Stack alignItems={"center"} justifyContent={"center"} gap={1} sx={{
                                            width: { xs: "300px", md: "200px" },
                                            height: { xs: "80px", md: "140px" },
                                            borderRadius: "12px",
                                            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0,0, 0.19)",
                                            background: "linear-gradient(116.51deg, #0F0628 13.29%, #032170 96.8%)",
                                            color: "white"
                                        }}>
                                            <Typography sx={{ fontSize: "20px", fontWeight: "500" }}>{section.title}</Typography>
                                            <Typography sx={{ fontSize: "17px", fontWeight: "400" }}>Questions : {section.questions}</Typography>
                                        </Stack>
                                    ))
                                }
                            </Stack>
                        </Stack>

                        {questionSummary?.isFinished === false && <Button onClick={handleStartExamButton} variant='contained' sx={{
                            background: "green !important",
                            color: "white",
                            width: "200px",
                            m: "0 auto",
                            color: "white", "height": "40px",
                            background: "linear-gradient(180deg, #009ADE 0%, #0057A9 100%) !important"
                        }}>Start Test</Button>}

                    </Stack>
                }
                {!isLoading && startExamClicked &&
                    <Stack mt={8} px={{ xs: 1, md: 3 }} mb={"40px !important"} >
                        <QuestionDetails questionSummary={questionSummary} />
                        <ForwardedQuestionPapperComponent
                            ref={questionRef}
                            qustionPapper={questionPapper}
                            bgcolor={alpha(getRandomColor(), 0.1)}
                            values={values}
                            handleChange={handleChange}
                        />
                    </Stack>

                }
            </Stack>
        </>
    )
}

export default ExamPage