import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import theme from "./utils/theme";
import ReactGA from "react-ga4"
import axios from "axios";
import { clearStorage } from "./storeService";
// import { ThemeProvider } from "next-themes";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



// axios intercepters
axios.interceptors.request.use((request) => {
  const token = localStorage.getItem("candidateToken");
  request.headers.Authorization = `Bearer ${token}`;
  return request;
}, (error) => {
  console.log(error)
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      console.log(error.response)
      clearStorage();
      window.location = '/careers';
    }
    return Promise.reject(error);
  }
);
// axios intercepters ends

ReactGA.initialize(`${process.env.REACT_APP_MEASUREMENT_ID}`)

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <React.StrictMode>
          <ToastContainer />
          <App />
        </React.StrictMode>
      </BrowserRouter>
    </ThemeProvider>
  </StyledEngineProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
