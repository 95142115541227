import React from 'react'
import Banner from '../../components/pdc/Banner'
import ContactForm from '../../components/Contact/ContactForm'
import Details from '../../components/pdc/Details'
import { Helmet } from "react-helmet"

function PDC() {
    return (
        <>
            <Helmet>
                <title>PDC Management System </title>
                <meta
                    name="description"
                    content=""
                />
            </Helmet>

            <Banner />
            <Details />
            <ContactForm title="Get a quote" />
        </>
    )
}

export default PDC