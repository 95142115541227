import { Box, Button, Card, CardContent, Modal, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { useStyles } from '../styles';

import schedule from "../assets/img/schedule.png"
import { CustomLoadingButton } from '../utils/Button';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import axios from 'axios';

import { setCandidateToken, setCandidateName, setCandidateEmail } from "../storeService"
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify"



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#131331',
    // border: '0px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: "9px"
};

const validationSchema = Yup.object().shape({
    fullname: Yup.string().required('Full name is required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    phone: Yup.string().required('Phone number is required'),
})

function CareerEvents({ startDate, endDate, title, description, jobExpoId }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();

    const loginCandidate = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/events/candidate/login`,
                { email: values.email, phoneNumber: values.phone, fullname: values.fullname, jobExpoId: jobExpoId })
            setCandidateToken(res.data.data.candidateToken);
            setCandidateName(res.data.data.fullname)
            setCandidateEmail(res.data.data.email)
            navigate("/careers/test")
            toast.success("Login Successful")
        } catch (e) {
            console.log(e)
            toast.error("Login Failed")
        }
    }

    const initialValues = {
        fullname: "",
        email: "",
        phone: "",
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: () => {
            loginCandidate();
        }
    })
    const { getFieldProps, values, errors, touched } = formik

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <Stack alignItems={"center"} mb={5} gap={3}>

            <Stack width={{ xs: "380px", sm: "600px", md: "1112px" }} direction={"row"}>
                <Stack gap={1} alignItems={"center"} justifyContent={"center"} width={"191px"} sx={{
                    "width": "191px", "height": "175px",
                    "background": "linear-gradient(132.58deg, #004898 8.06%, rgba(7, 47, 91, 0) 105.2%)", borderRadius: "12px 0 0 12px"
                }}>
                    <Box component={"img"} src={schedule} width={"60px"} />
                    <Typography className={classes.boldMainContent}>{moment(startDate).format("LL").split(",")[0]} - <Box component={"br"} display={{ xs: "flex", md: "none" }} />{moment(endDate).format("LL").split(",")[0]} </Typography>
                </Stack>
                <Stack direction={{ xs: "column", md: "row" }} p={{ md: 5, xs: 2 }} alignItems={"center"} justifyContent={{ xs: "space-around", md: "space-between" }} sx={{ "width": "100%", "height": "175px", "background": "#021224", borderRadius: "0 12px 12px 0px" }}>
                    <Stack gap={1}>
                        <Typography className={classes.subMainHeader}>{title}</Typography>
                        <Typography className={classes.boldMainContent}>{description}</Typography>
                    </Stack>
                    <Button onClick={handleOpen} sx={{ "width": "110px", "height": "38px", "background": "#070769 !important", "borderRadius": "6px" }}>
                        Take Test
                    </Button>
                </Stack>
            </Stack>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Card sx={{ height: "100%", background: "#131331" }}>
                        <CardContent>
                            <Typography variant="h5" fontWeight={"bold"}>
                                Login to take the test
                            </Typography>

                            <FormikProvider value={formik}>
                                <Form autoComplete="off">
                                    <Stack spacing={3} sx={{ marginTop: "16px" }}>
                                        <TextField
                                            focused
                                            fullWidth
                                            label="Enter your name"
                                            {...getFieldProps("fullname")}
                                            error={touched.fullname && Boolean(errors.fullname)}
                                            helperText={touched.fullname && errors.fullname}
                                        />
                                        <TextField
                                            focused
                                            fullWidth
                                            label="Email address"
                                            {...getFieldProps("email")}
                                            error={touched.email && Boolean(errors.email)}
                                            helperText={touched.email && errors.email}
                                        />

                                        <TextField
                                            focused
                                            fullWidth
                                            autoComplete="phone"
                                            label="Phone"
                                            {...getFieldProps("phone")}
                                            error={touched.phone && Boolean(errors.phone)}
                                            helperText={touched.phone && errors.phone}
                                        />
                                    </Stack>

                                    <CustomLoadingButton
                                        type="submit"
                                        fullWidth
                                        sx={{ marginTop: "20px", padding: "10px 20px" }}
                                    >
                                        login
                                    </CustomLoadingButton>
                                </Form>
                            </FormikProvider>
                        </CardContent>
                    </Card>
                </Box>
            </Modal>
        </Stack>
    )
}

export default CareerEvents