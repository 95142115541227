import React, { useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { Navbar, Footer, PopupWidget } from "./components/_index";
import Careers from "./pages/Careers";
import Company from "./pages/Company";
import Home from "./pages/Home";
import Products from "./pages/Product";
import Stackarch from "./pages/Products/Stackarch";
import Services from "./pages/Services";
import FlightBooking from "./pages/Products/FlightBooking";
import Hospistack from "./pages/Products/Hospistack";
import Erp from "./pages/Products/Erp";
import VehicleManagement from "./pages/Products/VehicleManagement";
import ScrollToTop from "./components/ScrollToTop";
import SocialMedia from "./pages/Products/SocialMedia";
import DigitalMarketing from "./pages/Products/DigitalMarketing";
import WebsiteDevelopment from "./pages/Products/WebsiteDevelopment";
import Ecommerce from "./pages/Products/Ecommerce";
import BlogDetail from "./components/Blogs/BlogDetail";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import AdaptiveWebDesign from "./pages/Services/AdaptiveWebDesign";
import UiDesign from "./pages/Services/UiDesign";
import CloudBasedApplications from "./pages/Services/CloudBasedApplications";
import Marketing from "./pages/Services/Marketing";
import Crm from "./pages/Services/Crm";
import Seo from "./pages/Services/Seo";
import WebsiteBoosting from "./pages/Services/WebsiteBoosting";
import Branding from "./pages/Services/Branding";
import SocialMedia1 from "./pages/Services/SocialMedia1";
import WebHosting from "./pages/Services/WebHosting";
import Enterprise from "./pages/Services/Enterprise";
import Ecommerce1 from "./pages/Services/Ecommerce1";
// import ReactGA from "react-ga";
import Huddle from "./pages/Huddle";
import Gym from "./pages/Products/Gym";
import Blockchain from "./pages/Products/Blockchain";

import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";
import stackLogo from "./assets/img/brands/stacklogo.png"
import CallUs from "./components/CallUs";
import AppDev from "./pages/Services/AppDev";
import StaffAugmentation from "./pages/Services/StaffAugmentation";
import ExamPage from "./pages/ExamPage";
import AutoStack from "./pages/Products/AutoStack";
import Attendance from "./pages/Products/Attendance";
import LMS from "./pages/Products/LMS";
import Certverify from "./pages/Products/Certverify";
import HRM from "./pages/Products/HRM";
import RestaurantFood from "./pages/Products/RestaurantFood";
import PDC from "./pages/Products/PDC";
import MobileVehicle from "./pages/Products/MobileVehicle";
import VfxPage from "./pages/Services/VfxPage";

const MemoizedExamPage = React.memo(ExamPage);

function App() {
  const location = useLocation();
  const isCareerTestRoute = location.pathname === "/careers/test";
  const candidateLoggedIn = localStorage.getItem("candidateToken");
  return (
    <>
      {
        !isCareerTestRoute &&
        <Navbar />
      }
      <ScrollToTop >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/company" element={<Company />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/stackarch" element={<Stackarch />} />
          <Route path="/services" element={<Services />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/huddle" element={<Huddle />} />
          <Route path="/products/flight" element={<FlightBooking />} />
          <Route path="/products/hospistack" element={<Hospistack />} />
          <Route path="/products/autostack" element={<AutoStack />} />
          <Route path="/products/gymanytime" element={<Gym />} />
          <Route path="/products/erp" element={<Erp />} />
          <Route path="/products/blockchain" element={<Blockchain />} />
          <Route path="/products/attendance" element={<Attendance />} />
          <Route path={`/careers/test`} element={
            candidateLoggedIn ? <MemoizedExamPage /> :
              <Navigate to="/careers" state={{ from: location }} />
          } />
          <Route
            path="/products/vehiclemanagement"
            element={<VehicleManagement />}
          />
          <Route path="/products/socialmedia" element={<SocialMedia />} />
          <Route
            path="/products/digitalmarketing"
            element={<DigitalMarketing />}
          />
          <Route
            path="/products/lms"
            element={<LMS />}
          />
          <Route
            path="/products/hrm"
            element={<HRM
            />}
          />
          <Route
            path="/products/restaurant"
            element={<RestaurantFood />}
          />
          <Route
            path="/products/pdc"
            element={<PDC />}
          />
          <Route
            path="/products/mobile-vehicle"
            element={<MobileVehicle />}
          />
          <Route
            path="/products/certverify"
            element={<Certverify />}
          />
          <Route path="/products/website" element={<WebsiteDevelopment />} />
          <Route path="/products/ecommerce" element={<Ecommerce />} />
          <Route path="/blogs" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blogs/blogdetail" element={<BlogDetail />} />
          <Route
            path="/services/adaptivewebdesign"
            element={<AdaptiveWebDesign />}
          />
          <Route
            path="/services/vfx"
            element={<VfxPage />}
          />
          <Route
            path="/services/cloudbasedapplication"
            element={<CloudBasedApplications />}
          />
          <Route path="/services/uidesign" element={<UiDesign />} />
          <Route path="/services/360degreemarketing" element={<Marketing />} />
          <Route path="/services/crm" element={<Crm />} />
          <Route path="/services/seo" element={<Seo />} />
          <Route
            path="/services/websiteboosting"
            element={<WebsiteBoosting />}
          />
          <Route path="/services/branding" element={<Branding />} />
          <Route
            path="/services/socialmediamarketing"
            element={<SocialMedia1 />}
          />
          <Route path="/services/hosting" element={<WebHosting />} />
          <Route path="/services/enterprise" element={<Enterprise />} />
          <Route
            path="/services/ecommerceapplication"
            element={<Ecommerce1 />}
          />
          <Route
            path="/services/applicationdevelopment"
            element={<AppDev />}
          />
          <Route
            path="/services/staffaugmentation"
            element={<StaffAugmentation />}
          />
        </Routes>
      </ScrollToTop>
      {!isCareerTestRoute && <Footer />}

      {
        !isCareerTestRoute &&
        <>
          <WhatsAppWidget
            phoneNo="919072863636"
            position="right"
            // widgetWidth="300px" 
            widgetWidthMobile="260px"
            // autoOpen={true}
            // autoOpenTimer={1000}
            messageBox={true}
            // messageBoxTxt="Hi Team, is there any related service available ?"
            iconSize="56"
            iconColor="white"
            iconBgColor="rgb(79, 206, 93)"
            headerIcon={stackLogo}
            // headerIconColor="pink"
            // headerTxtColor="black"
            // headerBgColor="tomato"
            headerTitle="Stackroots"
            headerCaption="Online"
            // bodyBgColor="#bbb"
            chatPersonName="Support"
            chatMessage={<>Hi there 👋 <br /> How we can help you?</>}
            // footerBgColor="#999"
            placeholder="Type a message.."
            btnBgColor="#075E54"
          />
          <CallUs />
          <PopupWidget />
        </>
      }
    </>
  );
}

export default App;
