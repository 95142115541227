import React from 'react'
import ContactForm from '../../components/Contact/ContactForm'
import EcommerceApp from '../../components/EcommerceApplication/EcommerceApp'
import crmForm from "../../assets/img/services/crmForm.png"
import { Helmet } from 'react-helmet'
const Ecommerce1 = () => {
  return (
    <>
      <Helmet>
        <title>Stackroots | Ecommerce App</title>
      </Helmet>
      <div>
        <EcommerceApp />
        <ContactForm title="Get a quote" image={crmForm} />
      </div>
    </>
  )
}

export default Ecommerce1