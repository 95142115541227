import React from "react";
import {
  Container,
  Partners,
  SectionTitle,
  PopupWidget,
  TeamSection,
} from "../components/_index";
import vme from "../assets/img/brands/vme.png";
import hiresgulf from "../assets/img/brands/hiregulf.png";
import prakriti from "../assets/img/brands/prakriti.png";
import ed from "../assets/img/brands/edgroup.png";
import malabar from "../assets/img/brands/malabar.png";
import rill from "../assets/img/brands/rill.png";
import fruvego from "../assets/img/brands/fruvego.png";
import arvind from "../assets/img/brands/arvind.png";
import capricorn from "../assets/img/brands/capricorn.png";
import freshcat from "../assets/img/brands/fresh.png";
import lambda from "../assets/img/brands/lambda.png";
import gymAnytime from "../assets/img/brands/gymAnytime.png";
import metropot from "../assets/img/brands/metropot.jpg";
import oriental from "../assets/img/brands/oriental.png";
import fabriclean from "../assets/img/brands/fabriclean.jpeg";
import maqlink from "../assets/img/brands/maqlink.png";
import { CustomButton } from "../utils/Button";
import { Navigate, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useStyles } from "../styles";
import { Stack, Typography, Box, Grid, Button } from "@mui/material";
import aboutusVector from "../assets/img/new/aboutusVector.png"
import pcBoost from "../assets/img/new/pcBoostVector.png"
import PopUp from "../components/PopUp";
import ourVision from "../assets/img/new/ourVision.png"
import icon1 from "../assets/img/new/icon1.png"
import icon2 from "../assets/img/new/icon2.png"
import icon3 from "../assets/img/new/icon3.png"
import icon4 from "../assets/img/new/icon4.png"
import blogVector from "../assets/img/new/blogVector.png"
import shapesVector from "../assets/img//products/shapesVector.png"
import ContactForm from "../components/Contact/ContactForm";
import GetAQuotePopUp from "../components/GetAQuotePopUp";

const Company = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const customers = [
    {
      logo: malabar,
    },
    {
      logo: "https://stackroots.co.in/wp-content/uploads/2022/02/hdfc-life-300x300.jpg",
    },
    // {
    //   logo: vme,
    // },
    {
      logo: prakriti,
    },
    {
      logo: ed,
    },
    {
      logo: maqlink,
    },
    {
      logo: hiresgulf,
    },
    {
      logo: rill,
    },
    {
      logo: lambda,
    },
    {
      logo: capricorn,
    },
    {
      logo: "https://stackroots.co.in/wp-content/uploads/2022/02/Darry-Soaps-Logo.jpg",
    },
    {
      logo: fabriclean,
    },
    {
      logo: oriental
    },

    {
      logo: "https://stackroots.co.in/wp-content/uploads/2021/06/Works_portfolio_Ocean.jpg",
    },
    {
      logo: "https://stackroots.co.in/wp-content/uploads/2022/02/FL_RAHIMAN_LOGO-2-1536x866.jpg",
    },
    {
      logo: "https://stackroots.co.in/wp-content/uploads/2021/06/Good-Family-Logo-01-1024x1024.png",
    },
    {
      logo: gymAnytime,
    },

    {
      logo: "https://stackroots.co.in/wp-content/uploads/2022/02/cocoshell-logo.png",
    },

    {
      logo: metropot,
    },
    {
      logo: "https://stackroots.co.in/wp-content/uploads/2022/02/Kerala-Taste-Logo_Final-01-1536x978.png",
    },

    {
      logo: fruvego,
    },
    {
      logo: arvind,
    },

    {
      logo: freshcat,
    },

  ];

  const listCustomers = (item, index) => {
    return (
      // <div className="" key={index}>
      //   <img
      //   alt=""
      //   src={item.logo}
      //   className="flex w-full"/>
      // </div>
      <div className="max-w-sm  max-h-full rounded flex items-center justify-center overflow-hidden hover:scale-110 transition duration-500 bg-white h-48 " >
        <img
          className={
            index === 8 ? "object-contain w-36" : "object-contain w-52"
          }
          src={item.logo}
          alt=""
        />
      </div>
    );
  };

  const services = [
    {
      icon: icon1,
      title: "Expertise and experience",
    },
    {
      icon: icon2,
      title: "Innovative solutions",
    },
    {
      icon: icon3,
      title: "Customer-focused approach",
    },
    {
      icon: icon4,
      title: "Results-oriented approach",
    },
  ]
  return (
    <Stack sx={{
      background: { xs: "none", md: `url(${shapesVector})` },
      backgroundSize: "contain",
    }}>
      <Helmet>
        <title>Stackroots | Company</title>
      </Helmet>
      <Container>

        <Stack mt={{ xs: 0, md: 3 }} direction={"row"} >
          <Box
            sx={{
              width: "100%",
              // height: "100vh",
              display: "flex",
              flexWrap: "wrap",
              p: { xs: 3, md: 6 },
            }}
          >
            <Stack
              direction={{ xs: "column", md: "row" }}
              sx={{
                alignItems: "center",
                justifyContent: "center",
              }}
              mt={{ xs: 0, md: 0 }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <Typography
                  className={classes.mainHeader}
                  textAlign={{ xs: "center", md: "initial" }}
                  mb={3}
                >
                  About Us
                </Typography>
                <Box
                  sx={{
                    marginTop: { xs: "0px", md: "40px" },
                    flex: 1,
                    display: { xs: "flex", md: "none" },
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box component="img" width={{ sm: "70%" }} src={aboutusVector} alt="banner" />
                </Box>
                <Typography
                  marginTop="10px"
                  className={classes.mainContent}
                >
                  Stackroots offers an impressive array of innovative solutions that are tailored to meet the unique needs of businesses across various industries. Our products are designed using cutting-edge technology and can be customized to help you achieve your business objectives. At Stackroots, we take pride in providing our clients with the most reliable, efficient, and cost-effective solutions to help them stay ahead of the competition.
                </Typography>
                <CustomButton style={{ marginTop: "20px", width: "250px", height: "45px" }} onClick={() => navigate('/contact')}>Contact Us</CustomButton>
              </Box>

              <Box
                sx={{
                  marginTop: { xs: "0px", md: "0px" },
                  flex: 1,
                  display: { xs: "none", md: "flex" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={aboutusVector} width="80%" alt="banner" />
              </Box>
            </Stack>
          </Box>
        </Stack>


        <Grid container align="center" justify="center" rowGap={8} mt={5}>
          <Grid item xs={12} md={6} >
            <Box component={"img"} src={pcBoost} width={"560px"} />
          </Grid>
          <Grid item xs={12} md={6} p={{ xs: 0, md: 3 }}  >
            <Stack spacing={6} height={"100%"} alignItems={"center"} justifyContent={"center"}>
              <Stack alignItems={"center"} justifyContent={"center"}>
                <Typography className={classes.subMainHeader}>Our Mission</Typography>
                <Typography className={classes.mainContent} textAlign={"left"} mt={2}>
                  Our mission is to deliver top-notch solutions and quality services that match client expectations to excel in the competitive global marketplace. We always strive to improve the quality of our products and services through technological innovations, new ideas and agile practices. We listen, research, plan and develop solutions together with our clients and help reach their set goals in time, through out great team, by utilizing the full potential and expertise of each members.
                </Typography>
              </Stack>
              <Stack alignItems={"center"} justifyContent={"center"}>
                <Typography className={classes.subMainHeader}>Our Vision</Typography>
                <Typography className={classes.mainContent} textAlign={"left"} mt={2}>
                  To bring excellent user experience through our products and create value and growth through our technology solutions and services to businesses, we cater.
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <Stack alignItems={"center"} mt={10}>
          <Typography className={classes.mainHeader}> Why Choose Us</Typography>
          <Grid container gap={3} mt={4} align="center">
            {
              services.map((item, index) => (

                <Grid m={"0 auto"} item xs={12} md={5.5} lg={2.5} sx={{
                  minWidth: "280px",
                  height: "240px",
                  background: "#1A163E",
                  borderRadius: "12px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                  p: 2
                }}>
                  <Box component={"img"} src={item.icon} width={"80px"} />
                  <Typography className={classes.subMainHeader} textAlign={"center"}>{item.title}</Typography>
                </Grid>
              ))
            }
          </Grid>
        </Stack>

        <Stack direction={{ xs: "column", md: "row" }} my={{ xs: 5, md: 10 }} sx={{ "minHeight": "375px", "background": "linear-gradient(94.18deg, #1A163E 1.36%, #0A0066 61.09%, #0B0449 80.69%)", "borderRadius": "12px" }}
          alignItems={"center"}
          justifyContent={"space-around"}
          gap={2}
          p={2}
        >
          <Box component={"img"} src={blogVector} width={"393px"} />
          <Stack width={{ xs: "100%", md: "50%" }} p={2} gap={2}>
            <Typography className={classes.mainHeader} color={"#fff"}>Our Blog</Typography>
            <Typography className={classes.mainContent} color={"#fff"}>Stay updated with the latest industry news, discover new tools and techniques, and gain valuable insights to enhance your knowledge and skills. We strive to provide valuable and engaging content that educates, inspires, and keeps you informed about the latest advancements in the tech industry.</Typography>
            <Button onClick={() => navigate("/blogs")} sx={{ "width": "130px", "height": "36px", "fontWeight": "500", "lineHeight": "36px", "color": "#000000", background: "white !important" }}>View Blogs</Button>
          </Stack>
        </Stack>

        <Stack>
          <Stack alignItems={"center"} width={{ xs: "100%", md: "80%" }} m="0 auto" gap={1} mt={10}>
            <Typography className={classes.mainHeader}>People Who Love Our Projects</Typography>
            <Typography className={classes.mainContent} >At Stackroots, success is not just a goal; it's a standard we consistently strive to achieve. We take immense pride in delivering successful projects that exceed our clients' expectations and drive tangible results.</Typography>
          </Stack>

          <Grid mt={3} container columnGap={4} rowGap={5} alignItems="flex-start" justifyContent="center">
            {customers.map((customer, index) => (
              <Grid item xs={12} md={2.5} sm={5.5}>
                <Stack sx={{
                  width: "100%",
                  height: "230px",
                  background: "white"
                }} alignItems={"center"} justifyContent={'center'}>
                  <img
                    className={
                      index === 8 ? "object-contain w-36" : "object-contain w-52"
                    }
                    src={customer.logo}
                    alt=""
                  />
                </Stack>
              </Grid>
            ))}
          </Grid>

          {/* <div className="mt-8 grid gap-10 mb-16 w-auto content-center sm:grid-cols-2 center lg:grid-cols-4 center">
            {customers.map(listCustomers)}
          </div> */}
        </Stack>


      </Container>
      <GetAQuotePopUp button={false} />
      {/* <ContactForm title={"Get A Quote"} /> */}
    </Stack>
  );
};

export default Company;
