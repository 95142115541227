import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import Container from "../Container";
import banner from "../../assets/img/block2.png";
import { useStyles } from "../../styles";

const Blockabout = () => {
  const classes = useStyles();
  return (
    <div
      className="hero_about_bg md:pt-36 md:pb-36 sm:pt-14 sm:pb-24"
      style={{
        // backgroundImage: `url(${aboutLink})`,
        backgroundPositionX: "center",
        backgroundPositionY: "20%",

        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",

        backgroundImage: `url(${banner})`,
        backgroundColor: "#131331",
        // backgroundBlendMode: "overlay",
      }}
    >
      <Container>
        <Grid container padding="40px 0px 40px 0px" alignItems={"center"}>
          <Grid item xs={12} sm={6}>
            <Box sx={{ flex: 1, }}>
              <Typography
                className={classes.mainHeader}
              >
                About Blockchain
              </Typography>
              <Typography mt={2} className={classes.mainContent}
              >
                Blockchain is a shared, immutable ledger that records
                transactions and tracks assets in a business network. It helps
                to eliminate middlemen between transactions. Blockchain
                transactions are secure & transparent and it eliminates "single
                point of failure".
              </Typography>
            </Box>
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={ProImage} />
            </Box>
          </Grid> */}
        </Grid>
      </Container>
    </div>
  );
};

export default Blockabout;
