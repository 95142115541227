import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { useStyles } from '../../styles'

import lmsVector2 from "../../assets/img/new/lmsVector2.png"
import shapesVector from "../../assets/img/products/shapesVector.png"



import benifitIcon5 from "../../assets/img/new/benifitIcon5.png"

import hrmsChoose from "../../assets/img/services/vectors/hrmsChoose.png"
import webBased from "../../assets/img/services/vectors/webBased.png"
import flexibleInterface from "../../assets/img/services/vectors/flexibleInterface.png"
import mobility from "../../assets/img/services/vectors/mobility.png"
import Insights from "../../assets/img/services/vectors/Insights.png"
import elevateHr from "../../assets/img/services/vectors/elevateHr.png"

import easy from "../../assets/img/services/vectors/icons/easy.png"
import efficiency from "../../assets/img/services/vectors/icons/efficiency.png"
import process from "../../assets/img/services/vectors/icons/process.png"
import collab from "../../assets/img/services/vectors/icons/collab.png"
import manage from "../../assets/img/services/vectors/icons/manage.png"
import cost from "../../assets/img/services/vectors/icons/cost.png"
import growth from "../../assets/img/services/vectors/icons/growth.png"
import complex from "../../assets/img/services/vectors/icons/complex.png"
import planning from "../../assets/img/services/vectors/icons/planning.png"
import reporting from "../../assets/img/services/vectors/icons/reporting.png"



import Benifits from './Benifits'


function Details() {
    const classes = useStyles();

    const benifits = [
        {
            title: "Ease of Use",
            desc: "Simplify HR tasks with our user-friendly HRMS, designed for efficiency and ease of use.",
            icon: easy,
        },
        {
            title: "Improved Efficiency",
            desc: "Boost productivity and efficiency with our cutting-edge HRMS solution.",
            icon: efficiency,
        },
        {
            title: "Standardized Processes",
            desc: "Streamline and standardize HR processes for consistency and precision.",
            icon: process,
        },
        {
            title: "Collaboration with Bionics ERP",
            desc: "Seamlessly integrate with Bionics ERP for enhanced collaboration and data management.",
            icon: collab,
        },
        {
            title: "Management Insights",
            desc: "Gain valuable insights for effective HR management and decision-making.",
            icon: manage,
        },
        {
            title: "Cost Efficiency",
            desc: "Reduce HR-related costs and optimize your financial resources.",
            icon: cost,
        },
        {
            title: "Scalability for Growth",
            desc: "Easily scale your HR operations to accommodate business growth and expansion.",
            icon: growth,
        },
        {
            title: "Reduced Complexity",
            desc: "Simplify complex HR tasks and processes with our intuitive HRMS.",
            icon: complex,
        },
        {
            title: "Effective Planning and Scheduling",
            desc: "Plan and schedule your workforce efficiently with our advanced HRMS tools.",
            icon: planning,
        },
        {
            title: "Enhanced Reporting",
            desc: "Generate comprehensive reports and insights for informed decision-making.",
            icon: reporting,
        },
    ];


    return (
        <Stack sx={{
            backgroundImage: `url(${shapesVector}) !important`,
            backgroundSize: "cover",
        }}>
            <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>



                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            Why Choose Stackroots HRMS
                        </Typography>

                        <Box display={{ xs: "block    ", md: "none" }} py={2} sx={{ margin: "0 auto !important" }} component={"img"} src={hrmsChoose} alt='vector' width={{ xs: "70%", sm: "50%", md: "40%" }} />

                        <Typography className={classes.mainContent}>
                            Managing human resources effectively requires a solution that is flexible, user-friendly, and future-ready. Here's why StackRoots HRMS is the perfect choice for your organization
                        </Typography>

                    </Stack>
                    <Box display={{ xs: "none", md: "block" }} component={"img"} src={hrmsChoose} alt='vector' width={{ xs: "70%", sm: "50%", md: "40%" }} />
                </Stack>
            </Stack>


            <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>

                    <Box component={"img"} sx={{
                        display: { xs: "none", md: "block" },
                    }} src={webBased} alt='vector' width={{ xs: "70%", sm: "50%", md: "35%" }} />

                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            Web-Based (Cloud) Solution
                        </Typography>
                        <Box component={"img"} sx={{
                            display: { xs: "block", md: "none" },
                            m: "0 auto !important",
                            py: 2
                        }} src={webBased} width={{ xs: "70%", sm: "50%", md: "40%" }} />
                        <Typography className={classes.mainContent}>
                            Benefit from the agility and accessibility of our cloud-based HRMS. Access vital HR data securely from any location, at any time, using laptops, tablets, and smartphones. Say goodbye to on-premises constraints and embrace the freedom and efficiency of cloud technology.
                        </Typography>


                    </Stack>
                </Stack>
            </Stack>


            <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>



                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            Intuitive and Flexible Interface
                        </Typography>

                        <Box display={{ xs: "block    ", md: "none" }} py={2} sx={{ margin: "0 auto !important" }} component={"img"} src={flexibleInterface} alt='vector' width={{ xs: "70%", sm: "50%", md: "40%" }} />

                        <Typography className={classes.mainContent}>
                            Our user-friendly interface ensures a seamless experience for HR professionals and employees alike. With an intuitive layout and customizable dashboard, you can effortlessly navigate through different modules and personalize the system to match your specific requirements.
                        </Typography>

                    </Stack>
                    <Box display={{ xs: "none", md: "block" }} component={"img"} src={flexibleInterface} alt='vector' width={{ xs: "70%", sm: "50%", md: "40%" }} />
                </Stack>
            </Stack>


            <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>

                    <Box component={"img"} sx={{
                        display: { xs: "none", md: "block" },
                    }} src={mobility} alt='vector' width={{ xs: "70%", sm: "50%", md: "35%" }} />

                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            Enhanced Mobility
                        </Typography>
                        <Box component={"img"} sx={{
                            display: { xs: "block", md: "none" },
                            m: "0 auto !important",
                            py: 2
                        }} src={mobility} width={{ xs: "70%", sm: "50%", md: "35%" }} />
                        <Typography className={classes.mainContent}>
                            Empower your workforce with the freedom of mobility. Our HRMS enables employees to access their personal HR-related information and perform transactions securely and directly, enhancing their engagement and productivity.
                        </Typography>


                    </Stack>
                </Stack>
            </Stack>

            <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>



                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            Management Insights
                        </Typography>

                        <Box display={{ xs: "block    ", md: "none" }} py={2} sx={{ margin: "0 auto !important" }} component={"img"} src={Insights} alt='vector' width={{ xs: "70%", sm: "50%", md: "40%" }} />

                        <Typography className={classes.mainContent}>
                            Gain valuable insights into your HR processes through robust reporting capabilities. Generate reports and analyze various HR-related data to make informed decisions and drive continuous improvement in your organization's workforce management.
                        </Typography>

                    </Stack>
                    <Box display={{ xs: "none", md: "block" }} component={"img"} src={Insights} alt='vector' width={{ xs: "70%", sm: "50%", md: "30%" }} />
                </Stack>
            </Stack>


            {/* <Stack alignItems={"center"} mb={5} mt={4}>
                <Typography Typography className={classes.mainHeader}>Features of Stack-Ed LMS</Typography>
                <Grid container sx={{ width: "85%", m: "0 auto", mt: { xs: "50px", md: "80px" }, }} align="center" justify="center" rowGap={8}>
                    {
                        cardData.map((card) => (

                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                <Card cardData={card} />
                            </Grid>
                        ))
                    }
                </Grid>
            </Stack> */}


            <Stack alignItems={"center"} mb={8} mt={10}>
                <Typography Typography className={classes.mainHeader}> Key Benefits</Typography>
                <Grid container sx={{ width: "85%", m: "0 auto", marginTop: { xs: "50px", md: "80px" }, }} align="center" justify="center" alignItems={"center"} justifyContent={"center"} rowGap={8}>
                    {
                        benifits.map((benifit) => (
                            <Grid item xs={12} sm={6} md={6} xl={4}>
                                <Benifits benifit={benifit} />
                            </Grid>
                        ))
                    }
                </Grid>
            </Stack>

            <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>

                    <Box component={"img"} sx={{
                        display: { xs: "none", md: "block" },
                    }} src={elevateHr} alt='vector' width={{ xs: "70%", sm: "50%", md: "45%" }} />

                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            Elevate Your HR Processes with StackRoots' HRMS solution
                        </Typography>
                        <Box component={"img"} sx={{
                            display: { xs: "block", md: "none" },
                            m: "0 auto !important",
                            py: 2
                        }} src={elevateHr} width={{ xs: "70%", sm: "50%", md: "35%" }} />
                        <Typography className={classes.mainContent}>
                            By choosing Stackroots HRMS, you can transform your human resource management processes, enhance team efficiency, and unlock the full potential of your workforce. Our commitment to providing exceptional service and cutting-edge technology ensures that you can streamline your HR operations and elevate your organizational efficiency.
                        </Typography>


                    </Stack>
                </Stack>
            </Stack>


        </Stack>
    )
}

export default Details