import React from 'react'
import ContactForm from '../../components/Contact/ContactForm'
import Crm1 from '../../components/Crm/Crm1'
import crmForm from "../../assets/img/services/crmForm.png"
import { Helmet } from 'react-helmet'
const Crm = () => {
  return (
    <>
      <Helmet>
        <title>Stackroots | CRM</title>
      </Helmet>
      <div>
        <Crm1 />
        <ContactForm title="Get a quote" image={crmForm} />
      </div>
    </>
  )
}

export default Crm