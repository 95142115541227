import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { useStyles } from '../../styles'

import shapesVector from "../../assets/img/products/shapesVector.png"


import compReports from "../../assets/img/services/vectors/compReports.png"
import pdcEasy from "../../assets/img/services/vectors/pdcEasy.png"
import pdchoose from "../../assets/img/services/vectors/pdchoose.png"
import restriction from "../../assets/img/services/vectors/restriction.png"
import unlockPotential from "../../assets/img/services/vectors/unlockPotential.png"
import userMagt from "../../assets/img/services/vectors/userMagt.png"

import access from "../../assets/img/services/vectors/icons/access.png"
import contracts from "../../assets/img/services/vectors/icons/contracts.png"
import create from "../../assets/img/services/vectors/icons/create.png"
import management from "../../assets/img/services/vectors/icons/management.png"
import print from "../../assets/img/services/vectors/icons/print.png"
import reports from "../../assets/img/services/vectors/icons/reports.png"

import Benifits from './Benifits'


function Details() {
    const classes = useStyles();


    // TODO:IMG

    // TODO:CONTENT

    const benefits = [
        {
            title: "Create Contracts",
            desc: "Effortlessly generate contracts with all the necessary PDC details, streamlining your business agreements and enhancing your efficiency.",
            icon: contracts,
        },
        {
            title: "Create and Manage PDCs",
            desc: "Simplify the process of creating and managing Post Dated Cheques, reducing errors, saving time, and ensuring precision in your financial transactions.",
            icon: create,
        },
        {
            title: "Print PDC",
            desc: "Generate professional-quality Post Dated Cheques with just a few clicks, ensuring accuracy and a polished appearance for your financial documents.",
            icon: print,
        },
        {
            title: "Comprehensive Reports",
            desc: "Access detailed reports to gain valuable insights into your PDC transactions, empowering you to make well-informed financial decisions for your business.",
            icon: reports,
        },
        {
            title: "User Management",
            desc: "Efficiently manage user access and permissions, enhancing security and control over your PDC management system, ensuring data integrity and confidentiality.",
            icon: management,
        },
        {
            title: "Access Restriction",
            desc: "Customize access levels to protect sensitive PDC information, ensuring only authorized personnel can access and modify data within your system.",
            icon: access,
        },
    ];


    return (
        <Stack sx={{
            backgroundImage: `url(${shapesVector}) !important`,
            backgroundSize: "cover",
        }}>
            <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>


                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            Why Choose Stackroots' PDC Management System?
                        </Typography>

                        <Box display={{ xs: "block    ", md: "none" }} py={2} sx={{ margin: "0 auto !important" }} component={"img"} src={pdchoose} alt='vector' width={{ xs: "70%", sm: "50%", md: "40%" }} />

                        <Typography className={classes.mainContent}>
                            Managing PDCs can be a time-consuming, error-prone task. By choosing Stackroots' PDC Management System, you can revolutionize your operations and enjoy a range of benefits. Here's why our solution stands out
                        </Typography>

                    </Stack>
                    <Box display={{ xs: "none", md: "block" }} component={"img"} src={pdchoose} alt='vector' width={{ xs: "70%", sm: "50%", md: "40%" }} />
                </Stack>
            </Stack>


            <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>

                    <Box component={"img"} sx={{
                        display: { xs: "none", md: "block" },
                    }} src={pdcEasy} alt='vector' width={{ xs: "70%", sm: "50%", md: "48%" }} />

                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            PDC Management Made Easy
                        </Typography>
                        <Box component={"img"} sx={{
                            display: { xs: "block", md: "none" },
                            m: "0 auto !important",
                            py: 2
                        }} src={pdcEasy} width={{ xs: "100%", sm: "50%", md: "35%" }} />
                        <Typography className={classes.mainContent}>
                            With our solution, handling PDCs becomes a breeze. From creating and managing PDCs to printing them with precision, our system ensures efficient and accurate tracking of your transactions
                        </Typography>


                    </Stack>
                </Stack>
            </Stack>


            <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>



                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            Comprehensive Reports
                        </Typography>

                        <Box display={{ xs: "block    ", md: "none" }} py={2} sx={{ margin: "0 auto !important" }} component={"img"} src={compReports} alt='vector' width={{ xs: "70%", sm: "50%", md: "40%" }} />

                        <Typography className={classes.mainContent}>
                            Gain valuable insights into your PDCs with our comprehensive reporting functionality. Generate detailed reports on due dates, status, and more, enabling you to make informed decisions and plan ahead effectively
                        </Typography>

                    </Stack>
                    <Box display={{ xs: "none", md: "block" }} component={"img"} src={compReports} alt='vector' width={{ xs: "70%", sm: "50%", md: "40%" }} />
                </Stack>
            </Stack>


            <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>

                    <Box component={"img"} sx={{
                        display: { xs: "none", md: "block" },
                    }} src={userMagt} alt='vector' width={{ xs: "70%", sm: "50%", md: "35%" }} />

                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            User Management
                        </Typography>
                        <Box component={"img"} sx={{
                            display: { xs: "block", md: "none" },
                            m: "0 auto !important",
                            py: 2
                        }} src={userMagt} width={{ xs: "70%", sm: "50%", md: "35%" }} />
                        <Typography className={classes.mainContent}>
                            Our system provides robust user management capabilities, allowing you to control access and permissions based on individual roles. Ensure data integrity and security with ease
                        </Typography>


                    </Stack>
                </Stack>
            </Stack>

            <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>



                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            Access Restriction
                        </Typography>

                        <Box display={{ xs: "block    ", md: "none" }} py={2} sx={{ margin: "0 auto !important" }} component={"img"} src={restriction} alt='vector' width={{ xs: "70%", sm: "50%", md: "40%" }} />

                        <Typography className={classes.mainContent}>
                            Safeguard your sensitive data by implementing access restrictions. Our solution ensures that only authorized personnel can access and manage PDC-related information, providing an additional layer of security
                        </Typography>

                    </Stack>
                    <Box display={{ xs: "none", md: "block" }} component={"img"} src={restriction} alt='vector' width={{ xs: "70%", sm: "50%", md: "40%" }} />
                </Stack>
            </Stack>



            <Stack alignItems={"center"} mb={8} mt={10}>
                <Typography Typography className={classes.mainHeader}> Key Features of  Stackroots PDC Management System </Typography>
                <Grid container sx={{ width: "85%", m: "0 auto", marginTop: { xs: "50px", md: "80px" }, }} align="center" justify="center" alignItems={"center"} justifyContent={"center"} rowGap={8}>
                    {
                        benefits.map((benefit) => (
                            <Grid item xs={12} sm={6} md={6} xl={4}>
                                <Benifits benifit={benefit} />
                            </Grid>
                        ))
                    }
                </Grid>
            </Stack>

            <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>

                    <Box component={"img"} sx={{
                        display: { xs: "none", md: "block" },
                    }} src={unlockPotential} alt='vector' width={{ xs: "70%", sm: "50%", md: "35%" }} />

                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            Unlock the potential of automated PDC management with Stackroots
                        </Typography>
                        <Box component={"img"} sx={{
                            display: { xs: "block", md: "none" },
                            m: "0 auto !important",
                            py: 2
                        }} src={unlockPotential} width={{ xs: "70%", sm: "50%", md: "35%" }} />
                        <Typography className={classes.mainContent}>
                            Experience the efficiency and reliability of StackRoots' PDC Management System as it transforms your PDC management process. With our commitment to exceptional service and cutting-edge technology, we provide an intuitive and secure platform to streamline your operations and reduce manual effort. Say goodbye to manual processes, reduce costs, and enhance operational efficiency. Get in touch with us today to explore how Stackroots' PDC Management System can benefit your business.
                        </Typography>


                    </Stack>
                </Stack>
            </Stack>


        </Stack>
    )
}

export default Details