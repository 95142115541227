import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Formik, Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Container } from "@mui/system";
import easytouse from "../../assets/img/products/easytouse.png";
import card from "../../assets/img/products/card.png";
import collaboration from "../../assets/img/products/collaboration.png";
import { Fade } from "react-awesome-reveal";
import FreeDemo from "../FreeDemo";
import PopUp from "../PopUp";
import { useStyles } from "../../styles";
import ecomBanner from "../../assets/img/new/ecomBanner.png"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
};

const Banner = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const classes = useStyles();
  return (
    <>
      <Box
        sx={{
          background: `url(${ecomBanner})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          width: "100%",
          paddingBottom: { xs: "150px", md: "50px" },
          // height: "470px",
          height: { xs: "auto", md: "100vh" },
          display: "flex",
          flexWrap: "wrap",
          // margin: { xs: "10px", md: "30px" },
        }}
      >
        <Box
          sx={{
            flex: 1,
            padding: { xs: "20px", md: "50px" },
            marginTop: { xs: "100px", md: "80px" },
            marginLeft: { xs: "0px", md: "70px" },
            maxWidth: "900px"
          }}
        >
          <Typography
            className={classes.mainHeader}
            sx={{

              marginTop: { md: "100px", xs: "0px" },
            }}
          >
            E-Commerce Websites & Applications
          </Typography>

          <Typography
            className={classes.mainContent}
            sx={{
              marginTop: { md: "25px", xs: "10px" },
            }}
          >
            At StackRoots, we pride ourselves on delivering exceptional e-commerce websites and applications that are tailored to meet the unique needs of your business. With our expertise and passion for cutting-edge technology, we create the best-in-class solutions that empower you to succeed in the competitive online marketplace.
          </Typography>
          <PopUp margin={"50px"} background={"#e6f7ff"} title={"E-Commerce Websites & Applications"} color={"#4F46E5"} buttonTitle={"Contact Us"} />
        </Box>
      </Box>

    </>
  );

};

export default Banner;
