// import { Box, Card, Grid, Paper, Typography, IconButton, Stack } from "@mui/material";
// import { Container } from "@mui/system";
import React from "react";
// import phone from "../../assets/img/products/phone.png";
// import CardContent from "@mui/material/CardContent";
// import "animate.css";
// import monitor from '../assets/img/products/monitor.png'
// import monitor from "../../assets/img/products/monitor.png";
// import humanresources from "../../assets/img/products/humanresources.png";
import "../../components/Stackarch/Banner.css";

// import { Fade } from "react-awesome-reveal";
// import Hero from "../Hero";
import StackarchCards from "../StackarchCards";
import StackarchFeatures from "../StackarchFeatures";

const Banner2 = () => {
  return (
    // <Box sx={{ marginTop: "50px" }}>

    //     <Typography
    //       textAlign="center"
    //       variant="h6"
    //       fontWeight="bold"
    //       sx={{ fontSize: { xs: 22, md: 32, sm: 32 }, color: "#1F1F1F" }}
    //     >
    //       Deliver every project on time and on budget with StackArch project
    //       management
    //     </Typography>

    //     <Typography
    //       textAlign="center"
    //       variant="body1"
    //       fontWeight="bold"
    //       sx={{ fontSize: { xs: 22, md: 32, sm: 32 }, color: "#000000" }}
    //     >
    //       ERP software built for architects, architectural firms, Construction
    //       firms, contractors etc.
    //     </Typography>

    //     <Box
    //       sx={{
    //         display: "flex",
    //         justifyContent: "center",
    //         height: { xs: "200px", md: "600px" },
    //       }}
    //     >
    //       <img src={monitor} alt="/" />
    //     </Box>
    //     <Container maxWidth="xl">
    //       <Typography
    //         variant="body2"
    //         textAlign="justify"
    //         fontSize="18px"
    //         sx={{ color: "#1F1F1F" }}
    //       >
    //         StackArch is a complete project management solution for
    //         architectural firms. StachArch project management includes{" "}
    //         <span style={{ fontWeight: "bolder" }}>
    //           project creation, Project management
    //         </span>{" "}
    //         after creation, execution of various project stages,{" "}
    //         <span style={{ fontWeight: "bolder" }}>project timelines,</span> and{" "}
    //         <span style={{ fontWeight: "bolder" }}>project cost</span>{" "}
    //         calculation.
    //       </Typography>

    //       <Typography
    //         variant="body2"
    //         textAlign="justify"
    //         fontSize="18px"
    //         marginTop="20px"
    //         sx={{ color: "#1F1F1F" }}
    //       >
    //         StackArch has an easily
    //         <span style={{ fontWeight: "bolder" }}>controllable dashboard</span>
    //         to handle the project management
    //         <span style={{ fontWeight: "bolder" }}></span> very accurately. From
    //         the dashboard itself admin can manage the team
    //         <span style={{ fontWeight: "bolder" }}>
    //           {" "}
    //           productivity analysis, analytical project management, forecasting
    //           the project timeline
    //         </span>{" "}
    //         and<span style={{ fontWeight: "bolder" }}> work schedules.</span>
    //       </Typography>
    //       <Typography
    //         variant="body2"
    //         textAlign="justify"
    //         fontSize="18px"
    //         marginTop="20px"
    //         sx={{ color: "#1F1F1F" }}
    //       >
    //         StackArch analytical; reporting gives a clarity in project detailing
    //         and stages are completed.
    //         <span style={{ fontWeight: "bolder" }}>
    //           {" "}
    //           StackArch Architectural ERP integrates{" "}
    //         </span>
    //         all architectural based functions into a single integrated software
    //         program that runs on a single database so that various departments
    //         and areas can easily share information and communicate with each
    //         other. The integrated approach of StackArch Architectural ERP has
    //         tremendous power and potential in improving the efficiency,
    //         productivity, and competitiveness of the organisation.
    //       </Typography>
    //     </Container>
    //     <Box sx={{ marginTop: "50px" }}>
    //       <Typography
    //         textAlign="center"
    //         variant="h6"
    //         fontWeight="bold"
    //         fontSize="32px"
    //         sx={{ paddingTop: "30px", color: "#1F1F1F" }}
    //       >
    //         Features of Architectural ERP
    //       </Typography>
    //       <Container maxWidth="xl" sx={{ marginTop: { xs: 1, md: 5 } }}>
    //         <Grid container spacing={3}>
    //           <Grid item md={4} sm={6} xs={12}>
    //             <div className="one">
    //               <Card>
    //                 <CardContent
    //                   sx={{
    //                     display: "flex",
    //                     justifyContent: "center",
    //                     flexDirection: "column",
    //                   }}
    //                 >
    //                   <IconButton
    //                     size="large"
    //                     aria-label="show 4 new mails"
    //                     color="inherit"
    //                   >
    //                     <img
    //                       src={humanresources}
    //                       style={{ fontSize: "2.5rem", color: "#4F46E5" }}
    //                     />
    //                   </IconButton>

    //                   <Typography
    //                     gutterBottom
    //                     variant="h5"
    //                     component="div"
    //                     fontSize="22px"
    //                     textAlign="center"
    //                     sx={{ color: " #4F46E5" }}
    //                   >
    //                     Easy to use
    //                   </Typography>

    //                   <inform>
    //                     <Typography
    //                       variant="body2"
    //                       color="text.secondary"
    //                       sx={{ color: "white", padding: "10px" }}
    //                     >
    //                       Easy To Set Up, Easy To Customise And Easy To Scale.
    //                       Help your project management teams can assist more
    //                       projects faster with AI based forecasting & project
    //                       management tools
    //                     </Typography>
    //                   </inform>
    //                 </CardContent>
    //               </Card>
    //             </div>
    //           </Grid>
    //           <Grid item md={4} sm={6} xs={12}>
    //             <div className="two">
    //               <Card>
    //                 <CardContent
    //                   sx={{
    //                     display: "flex",
    //                     justifyContent: "center",
    //                     flexDirection: "column",
    //                   }}
    //                 >
    //                   <IconButton
    //                     size="large"
    //                     aria-label="show 4 new mails"
    //                     color="inherit"
    //                   >
    //                     <img
    //                       src={humanresources}
    //                       style={{ fontSize: "2.5rem", color: "#4F46E5" }}
    //                     />
    //                   </IconButton>

    //                   <Typography
    //                     gutterBottom
    //                     variant="h5"
    //                     component="div"
    //                     fontSize="22px"
    //                     textAlign="center"
    //                     sx={{ color: " #4F46E5" }}
    //                   >
    //                     Team efficiency
    //                   </Typography>
    //                   <inform>
    //                     <Typography
    //                       variant="body2"
    //                       color="text.secondary"
    //                       sx={{ color: "white", padding: "10px" }}
    //                     >
    //                       An integrated StackArck ERP system automates project
    //                       creation by automatically capturing data from the
    //                       admin & user. This provides audits trails for better
    //                       visibility and also helps increase efficiency and
    //                       accuracy.
    //                     </Typography>
    //                   </inform>
    //                 </CardContent>
    //               </Card>
    //             </div>
    //           </Grid>
    //           <Grid item md={4} sm={6} xs={12}>
    //             <div className="three">
    //               <Card>
    //                 <CardContent
    //                   sx={{
    //                     display: "flex",
    //                     justifyContent: "center",
    //                     flexDirection: "column",
    //                   }}
    //                 >
    //                   <IconButton
    //                     size="large"
    //                     aria-label="show 4 new mails"
    //                     color="inherit"
    //                   >
    //                     <img
    //                       src={humanresources}
    //                       style={{
    //                         fontSize: "2.5rem",
    //                         color: "#4F46E5",
    //                       }}
    //                     />
    //                   </IconButton>

    //                   <Typography
    //                     gutterBottom
    //                     variant="h5"
    //                     component="div"
    //                     fontSize="22px"
    //                     textAlign={"center"}
    //                     sx={{ color: " #4F46E5" }}
    //                   >
    //                     Chat’s
    //                   </Typography>
    //                   <inform>
    //                     <Typography
    //                       variant="body2"
    //                       color="text.secondary"
    //                       sx={{ color: "white", padding: "10px" }}
    //                     >
    //                       StackArch ERP, each project can collectively ensure
    //                       communication between each member in the same
    //                       projectsClients have the privileges to take meetings
    //                       with each project team.
    //                     </Typography>
    //                   </inform>
    //                 </CardContent>
    //               </Card>
    //             </div>
    //           </Grid>
    //           <Grid item md={4} sm={6} xs={12}>
    //             <div className="four">
    //               <Card>
    //                 <CardContent
    //                   sx={{
    //                     display: "flex",
    //                     justifyContent: "center",
    //                     flexDirection: "column",
    //                   }}
    //                 >
    //                   <IconButton
    //                     size="large"
    //                     aria-label="show 4 new mails"
    //                     color="inherit"
    //                   >
    //                     <img
    //                       src={humanresources}
    //                       style={{ fontSize: "2.5rem", color: "#4F46E5" }}
    //                     />
    //                   </IconButton>
    //                   <Typography
    //                     gutterBottom
    //                     variant="h5"
    //                     component="div"
    //                     fontSize="22px"
    //                     textAlign="center"
    //                     sx={{ color: " #4F46E5" }}
    //                   >
    //                     Standardisation
    //                   </Typography>
    //                   <inform>
    //                     <Typography
    //                       variant="body2"
    //                       color="text.secondary"
    //                       sx={{ color: "white", padding: "10px" }}
    //                     >
    //                       StackArch ERP, keep improvement of project management,
    //                       operational performance, cost reduction through
    //                       decreased process errors, facilitation of
    //                       communication, profiting from expert knowledge form
    //                       the Industry standards
    //                     </Typography>
    //                   </inform>
    //                 </CardContent>
    //               </Card>
    //             </div>
    //           </Grid>
    //           <Grid item md={4} sm={6} xs={12}>
    //             <div className="five">
    //               <Card>
    //                 <CardContent
    //                   sx={{
    //                     display: "flex",
    //                     justifyContent: "center",
    //                     flexDirection: "column",
    //                   }}
    //                 >
    //                   <IconButton
    //                     size="large"
    //                     aria-label="show 4 new mails"
    //                     color="inherit"
    //                   >
    //                     <img
    //                       src={humanresources}
    //                       style={{ fontSize: "2.5rem", color: "#4F46E5" }}
    //                     />
    //                   </IconButton>

    //                   <Typography
    //                     gutterBottom
    //                     variant="h5"
    //                     component="div"
    //                     fontSize="22px"
    //                     textAlign={"center"}
    //                     sx={{ color: " #4F46E5" }}
    //                   >
    //                     Collaborate
    //                   </Typography>
    //                   <inform>
    //                     <Typography
    //                       variant="body2"
    //                       color="text.secondary"
    //                       sx={{ color: "white", padding: "10px" }}
    //                     >
    //                       StackArch ERP easily integrates with the third party
    //                       ERP which is related to the field of financial, CRM,
    //                       Human capital, Inventory modules. Each stages of
    //                       development our team create easy integration modules
    //                       for existing ERP for architecture institutions
    //                     </Typography>
    //                   </inform>
    //                 </CardContent>
    //               </Card>
    //             </div>
    //           </Grid>
    //           <Grid item md={4} sm={6} xs={12}>
    //             <div className="six">
    //               <Card>
    //                 <CardContent
    //                   sx={{
    //                     display: "flex",
    //                     justifyContent: "center",
    //                     flexDirection: "column",
    //                   }}
    //                 >
    //                   <IconButton
    //                     size="large"
    //                     aria-label="show 4 new mails"
    //                     color="inherit"
    //                   >
    //                     <img
    //                       src={humanresources}
    //                       style={{ fontSize: "2.5rem", color: "#4F46E5" }}
    //                     />
    //                   </IconButton>

    //                   <Typography
    //                     gutterBottom
    //                     variant="h5"
    //                     component="div"
    //                     fontSize="22px"
    //                     textAlign="center"
    //                     sx={{ color: " #4F46E5" }}
    //                   >
    //                     Management
    //                   </Typography>
    //                   <inform>
    //                     <Typography
    //                       variant="body2"
    //                       color="text.secondary"
    //                       sx={{ color: "white", padding: "10px" }}
    //                     >
    //                       Managing each project with less effort and providing
    //                       better managerial decision making in the terms right
    //                       time. Each managerial insight can keep in particular
    //                       problem statements, to recover the stages of the
    //                       project management system with timely delivery.
    //                     </Typography>
    //                   </inform>
    //                 </CardContent>
    //               </Card>
    //             </div>
    //           </Grid>
    //           <Grid item md={4} sm={6} xs={12}>
    //             <div className="seven">
    //               <Card>
    //                 <CardContent
    //                   sx={{
    //                     display: "flex",
    //                     justifyContent: "center",
    //                     flexDirection: "column",
    //                   }}
    //                 >
    //                   <IconButton
    //                     size="large"
    //                     aria-label="show 4 new mails"
    //                     color="inherit"
    //                   >
    //                     <img
    //                       src={humanresources}
    //                       style={{ fontSize: "2.5rem", color: "#4F46E5" }}
    //                     />
    //                   </IconButton>

    //                   <Typography
    //                     gutterBottom
    //                     variant="h5"
    //                     component="div"
    //                     fontSize="22px"
    //                     textAlign="center"
    //                     sx={{ color: " #4F46E5" }}
    //                   >
    //                     On-cloud use
    //                   </Typography>
    //                   <inform>
    //                     <Typography
    //                       variant="body2"
    //                       color="text.secondary"
    //                       sx={{ color: "white", padding: "10px" }}
    //                     >
    //                       Now part of the technology shifting , we create the
    //                       StackArch ERP with an intelligent cloud based
    //                       solution, for easy use. Moving to cloud based
    //                       solutions can keep your productivity increased with
    //                       your team as well
    //                     </Typography>
    //                   </inform>
    //                 </CardContent>
    //               </Card>
    //             </div>
    //           </Grid>
    //           <Grid item md={4} sm={6} xs={12}>
    //             <div className="eight">
    //               <Card>
    //                 <CardContent
    //                   sx={{
    //                     display: "flex",
    //                     justifyContent: "center",
    //                     flexDirection: "column",
    //                   }}
    //                 >
    //                   <IconButton
    //                     size="large"
    //                     aria-label="show 4 new mails"
    //                     color="inherit"
    //                   >
    //                     <img
    //                       src={humanresources}
    //                       style={{ fontSize: "2.5rem", color: "#4F46E5" }}
    //                     />
    //                   </IconButton>

    //                   <Typography
    //                     gutterBottom
    //                     variant="h5"
    //                     component="div"
    //                     fontSize="22px"
    //                     textAlign="center"
    //                     sx={{ color: " #4F46E5" }}
    //                   >
    //                     Planning
    //                   </Typography>
    //                   <inform>
    //                     <Typography
    //                       variant="body2"
    //                       color="text.secondary"
    //                       sx={{ color: "white", padding: "10px" }}
    //                     >
    //                       We create each project management with proper
    //                       planning, with the team scheduling, and managerial
    //                       control over all that. In planning phase, plan is made
    //                       and strategies are set, taking into consideration the
    //                       company policies, procedures and rules Planning
    //                       provides direction.
    //                     </Typography>
    //                   </inform>
    //                 </CardContent>
    //               </Card>
    //             </div>
    //           </Grid>
    //           <Grid item md={4} sm={6} xs={12}>
    //             <div className="nine">
    //               <Card>
    //                 <CardContent
    //                   sx={{
    //                     display: "flex",
    //                     justifyContent: "center",
    //                     flexDirection: "column",
    //                   }}
    //                 >
    //                   <IconButton
    //                     size="large"
    //                     aria-label="show 4 new mails"
    //                     color="inherit"
    //                   >
    //                     <img
    //                       src={humanresources}
    //                       style={{ fontSize: "2.5rem", color: "#4F46E5" }}
    //                     />
    //                   </IconButton>

    //                   <Typography
    //                     gutterBottom
    //                     variant="h5"
    //                     component="div"
    //                     fontSize="22px"
    //                     textAlign={"center"}
    //                     sx={{ color: " #4F46E5" }}
    //                   >
    //                     Lower cost
    //                   </Typography>
    //                   <inform>
    //                     <Typography
    //                       variant="body2"
    //                       color="text.secondary"
    //                       sx={{ color: "white", padding: "10px" }}
    //                     >
    //                       StackArch ERP can reduce the additional cost that
    //                       acquired on a particular project during the life cycle
    //                       of the project delivery. We concentrate on each
    //                       project stage while consuming the least cost to timely
    //                       delivery.
    //                     </Typography>
    //                   </inform>
    //                 </CardContent>
    //               </Card>
    //             </div>
    //           </Grid>
    //         </Grid>
    //       </Container>
    //     </Box>

    // </Box>
    <>
      {/* <Container maxWidth='xl'> */}
      <StackarchCards />
      {/* </Container> */}
      <StackarchFeatures />
    </>
  );
};

export default Banner2;
