import React from 'react'
import Banner from '../../components/Attendance/Banner'
import Details from '../../components/Attendance/Details'
import { Helmet } from 'react-helmet'
import ContactForm from '../../components/Contact/ContactForm'

function Attendance() {
    return (
        <>
            <Helmet>
                <title>Stackroots | Attendance</title>
            </Helmet>
            <div>
                <Banner />
                <Details />
                {/* <Detailed2 /> */}
                <ContactForm title="Get a quote" />
                {/* <PopupWidget /> */}
            </div>
        </>
    )
}

export default Attendance