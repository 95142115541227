import {
    Box,
    Typography
} from "@mui/material";
import React from "react";
import { useStyles } from "../../styles";
import PopUp from "../PopUp";

import mobileBanner from "../../assets/img/services/vectors/mobileBanner.jpg"

const Banner = () => {
    const classes = useStyles();
    return (
        <>
            <Box
                sx={{
                    // TODO:IMG
                    background: `url(${mobileBanner})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "100%",
                    paddingBottom: { xs: "150px", md: "50px" },
                    // height: "470px",
                    height: { xs: "auto", md: "100vh" },
                    display: "flex",
                    flexWrap: "wrap",
                    // margin: { xs: "10px", md: "30px" },
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        padding: { xs: "20px", md: "50px" },
                        marginTop: { xs: "100px", md: "0px" },
                        marginLeft: { xs: "0px", md: "70px" },
                        maxWidth: "900px"
                    }}
                >
                    <Typography
                        className={classes.mainHeader}
                        sx={{

                            marginTop: { md: "100px", xs: "0px" },
                        }}
                    >
                        Mobile Vehicle Service Solution
                    </Typography>

                    <Typography
                        className={classes.mainContent}
                        sx={{
                            marginTop: { md: "25px", xs: "10px" },
                        }}
                    >
                        Welcome to Stackroots' Mobile Vehicle Service Solution, the ultimate cloud-based solution for motor vehicle service centers. In today's competitive business landscape, minimizing operational costs and maximizing efficiency is crucial for success. With our innovative solution, you can take control of your fleet management, mobile service bookings, service vehicle tracking, and even set up a mini ecommerce system for spare part sales. Say goodbye to manual processes and embrace the power of Stackroots Mobile Vehicle Service Solution to streamline your operations and boost your bottom line.
                    </Typography>
                    <PopUp margin={"50px"} title={"Mobile Vehicle Service Solution"} background={"#e6f7ff"} color={"#4F46E5"} />
                </Box>
            </Box>

        </>
    );

};

export default Banner;
