import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import React from "react";
import Marquee from "react-fast-marquee";
import delivery from "../../assets/img/products/delivery.png";
import design from "../../assets/img/services/vfx.png";
import adaptivebg from "../../assets/img/services/adaptivebg.png";
import htmlbanner from "../../assets/img/services/htmlBanner.png";
import shapes from "../../assets/img/services/shapes2.png";
import webmethodbanner from "../../assets/img/services/webmethodbanner.png";
import webmethodology from "../../assets/img/services/webmethodology.png";

import angular from "../../assets/img/services/logo/angularLogo.png";
import express from "../../assets/img/services/logo/expressLogo.png";
import mongo from "../../assets/img/services/logo/mongoLogo.png";
import mui from "../../assets/img/services/logo/muilogo.png";
import node from "../../assets/img/services/logo/nodelogo.png";
import react from "../../assets/img/services/logo/reactlogo.png";

import custom from "../../assets/img/services/vectors/vfx1.png";
import shop from "../../assets/img/services/vectors/vfx2.png";
import webDesign from "../../assets/img/services/vectors/vfx3.png";
import siteSupport from "../../assets/img/services/vectors/vfx4.png";
import vfx5 from "../../assets/img/services/vectors/vfx5.png";
import vfx6 from "../../assets/img/services/vectors/vfx6.png";

import crmbg from "../../assets/img/services/crmbg.png";

import deal from "../../assets/img/new/icons/deal.png"
import creativity from "../../assets/img/new/icons/creativity.png"
import fastDelivery from "../../assets/img/new/icons/fast-delivery.png"
import visualEffect from "../../assets/img/new/icons/visual-effect.png"


import { useStyles } from "../../styles";
import Techstack from "../Techstack";

const AdaptiveDesign = () => {
  const classes = useStyles();
  const icons = [
    {
      img: react
    },
    {
      img: angular
    },
    {
      img: node
    },
    {
      img: mongo
    },
    {
      img: mui
    },
    {
      img: express
    },
  ]

  const vfxServices = [
    {
      img: creativity,
      title: "Creative Expertise",
      content: "Our team of VFX artists brings a wealth of creative talent and technical skill to every project, ensuring that your film's visual effects are nothing short of extraordinary."
    },
    {
      img: visualEffect,
      title: "State-of-the-Art Technology",
      content: "We leverage the latest VFX software and hardware to deliver cutting-edge visual effects that meet the highest industry standards."
    },
    {
      img: deal,
      title: "Collaborative Approach",
      content: "We prioritize open communication and collaboration, partnering closely with filmmakers to realize their vision through impressive VFX."
    },
    {
      img: fastDelivery,
      title: "Timely Delivery",
      content: "We understand the importance of meeting deadlines in the film industry, and we are dedicated to delivering exceptional results within agreed-upon timelines."
    },
  ]



  return (
    <Stack sx={{ background: "#131331" }}>
      <div
        className="adaptivebg"
        style={{
          background: `url(${adaptivebg})`,
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat"
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: { xs: "inherit", md: "100vh" },
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography mb={3} className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
              >
                Film VFX Services
              </Typography>

              <Box
                sx={{
                  display: { xs: "flex", md: "none" },
                  marginTop: { xs: "0px", md: "0px" },
                  flex: 1,
                  // display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                }}
              >
                <img src={design} alt="banner" />
              </Box>

              <Typography
                mt={2} className={classes.mainContent}
              >
                At Stackroots, we are committed to staying at the forefront of technological advancements in the VFX industry. We leverage state-of-the-art software and hardware to deliver visually stunning effects that meet the highest industry standards. Our dedication to excellence and attention to detail ensure that every visual element seamlessly integrates with live-action footage, enhancing the overall storytelling experience
              </Typography>
            </Box>

            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img style={{ width: "600px" }} src={design} alt="banner" />
            </Box>
          </Stack>
        </Box>

        {/*  */}

        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >

          <Stack
            direction={{ xs: "column", md: "row-reverse" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={2}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}
              >
                Visual Effects for Films
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "0px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img alt="vector" src={custom} />
              </Box>
              <Typography
                marginTop="10px"
                className={classes.mainContent}
              >
                We specialize in creating stunning visual effects for feature films, short films, documentaries, and other cinematic projects. Whether it's adding breathtaking CGI elements, enhancing practical effects, or creating realistic environments, we have the expertise to elevate your film to the next level.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={custom} width={"80%"} />
            </Box>
          </Stack>
        </Box>

        {/*  */}

        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={2}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}
              >
                CGI and Animation
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "0px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img alt="vector" src={shop} />
              </Box>
              <Typography
                marginTop="10px"
                className={classes.mainContent}
              >
                Our talented team excels in crafting lifelike computer-generated imagery (CGI) and captivating animation that seamlessly integrates with live-action footage. From character animation to dynamic simulations, we bring imagination to reality through the power of CGI.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={shop} width={"80%"} />
            </Box>
          </Stack>
        </Box>

        {/*  */}

        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row-reverse" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={2}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}
              >
                Matte Painting and Environment Design
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "0px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img alt="vector" src={webDesign} />
              </Box>
              <Typography
                marginTop="10px"
                className={classes.mainContent}
              >
                Transform your film's settings and landscapes with our exceptional matte painting and environment design services. We can create awe-inspiring backdrops, futuristic cityscapes, historical settings, and more, tailored to suit your creative vision.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={webDesign} width={"70%"} />
            </Box>
          </Stack>
        </Box>

        {/*  */}

        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={2}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}
              >
                Compositing and Post-Production
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "0px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img alt="vector" src={siteSupport} />
              </Box>
              <Typography
                marginTop="10px"
                className={classes.mainContent}
              >
                With our expertise in compositing and post-production, we ensure that all visual elements blend seamlessly to achieve a cohesive and polished look. Our meticulous attention to detail guarantees a flawless integration of VFX into your film, enhancing its overall impact.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={siteSupport} width={"80%"} />
            </Box>
          </Stack>
        </Box>
        {/*  */}

        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row-reverse" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={2}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}
              >
                3D Modeling and Texturing
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "0px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img alt="vector" src={vfx5} />
              </Box>
              <Typography
                marginTop="10px"
                className={classes.mainContent}
              >
                With our expertise in compositing and post-production, we ensure that all visual elements blend seamlessly to achieve a cohesive and polished look. Our meticulous attention to detail guarantees a flawless integration of VFX into your film, enhancing its overall impact.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={vfx5} width={"80%"} />
            </Box>
          </Stack>
        </Box>
        {/*  */}

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={2}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}
              >
                Special Effects
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "0px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img alt="vector" src={vfx6} />
              </Box>
              <Typography
                marginTop="10px"
                className={classes.mainContent}
              >
                From explosive action sequences to subtle atmospheric effects, we specialize in creating a wide range of special effects that captivate audiences and enhance storytelling. Our team is adept at producing practical and digital effects that bring excitement and realism to your film.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={vfx6} width={"90%"} />
            </Box>
          </Stack>
        </Box>
      </div>
      {/* <Stack
        className="webmethodbg"
        sx={{
          background: { xs: `url(${htmlbanner})`, md: `url(${webmethodbanner})` },
          backgroundSize: { xs: "cover", md: "none" }
        }}
      >
        <Box sx={{ marginTop: { xs: "20px", md: "60px" }, p: { xs: 0, md: 6 }, }}>
          <Typography
            className={classes.mainHeader}
            textAlign="center"
            sx={{
              marginBottom: "50px",
              marginTop: { xs: "45px", md: 0, },

            }}
          >
            Our Approach
          </Typography>

          <Typography
            marginTop="10px"
            className={classes.mainContent}
          >
            <Typography
              marginTop="10px"
              className={classes.mainContent}
            >
              We understand the importance of storytelling and visual impact in the world of filmmaking. Our team of VFX artists, designers, and technologists collaborate closely with you to understand your vision and bring it to reality. With an unwavering commitment to quality, innovation, and creative excellence, we ensure that our VFX services seamlessly integrate within your film's storyline, enhancing its emotional and visual impact.
            </Typography>
          </Typography>
        </Box>
      </Stack> */}

      <Stack>
        <Stack p={{ xs: 2, md: 6 }} mt={{ xs: 2, sm: 2 }} alignItems="center" justifyContent="center" spacing={{ xs: 1, md: 3 }}>
          <Typography className={classes.mainHeader}>Industries We Serve</Typography>
          <Grid container align="center" justify="center" rowSpacing={10}>
            {vfxServices?.map((item) => (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Stack sx={{
                  width: "280px",
                  height: "280px"
                }} alignItems="flex-start" spacing={2}>
                  <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} borderRadius={"50%"} bgcolor={"white"} width={"80px"} height={"80px"}>
                    <Box component="img" src={item.img} width={"40px"} />
                  </Stack>

                  <Typography className={classes.subMainHeader} textAlign={"left"}> {item.title}</Typography>
                  <Typography textAlign={"left"} className={classes.mainCotent}>
                    {item.content}
                  </Typography>

                </Stack>
              </Grid>
            ))
            }
          </Grid>
        </Stack>
      </Stack>


      <div
        className="adaptivebg"
        style={{
          backgroundImage: `url(${crmbg})`,
        }}
      >
        <Box sx={{ marginTop: "50px", p: { xs: 3, md: 12 } }}>
          <Typography
            className={classes.mainHeader}
            mb={2}
          >
            Let's Transform Your Vision
          </Typography>
          <Typography className={classes.mainContent}
            sx={{
              color: "white",
              textAlign: { md: "initial", sm: "initial", xs: "initial" },
            }}
          >
            Partner with Stackroots Film VFX Service to elevate your film with captivating visual effects that leave a lasting impression on audiences. Let us turn your creative vision into a cinematic masterpiece through the magic of VFX.
          </Typography>
        </Box>
      </div>


      <div
        className="adaptivebg"
        style={{
          backgroundImage: `url(${adaptivebg})`,
        }}
      >

        <Box sx={{ marginTop: "50px", display: { xs: "none", md: "block", sm: "block" } }}>
          <Techstack />
        </Box>

        <Box sx={{ marginTop: "50px", p: 3, mb: "60px", display: { xs: "block", md: "none", sm: "none" } }} >
          <Typography
            sx={
              { "fontStyle": "normal", "fontWeight": "600", "fontSize": "24px", "lineHeight": "29px", "color": "#FFFFFF", textAlign: "center" }
            }
          >
            Expertise And Technical Skills
          </Typography>

          <Stack>
            <Marquee gradient={false}>
              {icons.map((icon) => (
                <Box component={"img"}
                  sx={{
                    mt: "25px",
                    width: "105px"
                  }}
                  src={icon.img}
                  alt={"tech stack"}
                />
              ))
              }
            </Marquee>

            <Marquee gradient={false} direction={"right"}>
              {icons.map((icon) => (
                <Box component={"img"}
                  sx={{
                    mt: "25px",
                    width: "105px"
                  }}
                  src={icon.img}
                  alt={"tech stack"}
                />
              ))
              }
            </Marquee>
          </Stack>

        </Box>
      </div>
    </Stack>
  );
};

export default AdaptiveDesign;
