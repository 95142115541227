import React from "react";
import SectionTitle from "./SectionTitle";
import Blog1 from "../assets/img/blog1.png";
import Blog2 from "../assets/img/blog2.png";
import Blog3 from "../assets/img/blog3.png";
import degree from "../assets/img/services/degree.png";
import crm from "../assets/img/services/crm (1).png";
// import uiDesign from "../assets/img/services/uidesign2.png";
import seo from "../assets/img/services/seo degital marketing.png";
import boosting from "../assets/img/services/boosting.png";
import ecommerce1 from "../assets/img/services/e-commerce (1).png";
import enterprise from "../assets/img/services/enterprise.png";


import branding from "../assets/img/services/branding.png";
import socialmedia1 from "../assets/img/services/socialmedia1.png";
import hosting from "../assets/img/services/hosting.png";
import uiDesign from "../assets/img/services/cloud based web app.png"
import shock from "../assets/img/shock.png";
import { Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../utils/Button";
import adaptive from "../assets/img/services/adaptiveWD.png"
import { Fade } from "react-awesome-reveal";
import { useStyles } from "../styles";
import uiux from "../assets/img/services/uidesign2.png"


const HomeService = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <div
      style={{
        // backgroundImage: `url(${aboutLink})`,
        backgroundPosition: "cover",

        backgroundRepeat: "no-repeat",

        backgroundImage:
          "linear-gradient(rgb(35, 33, 65) 1.21%, rgb(11, 13, 35) 18.75%, rgb(11, 13, 35) 88.19%, rgba(11, 13, 35, 0.72) 100%, rgb(11 13 35) 99.48%)",
      }}
    >

      <Stack justifyContent={"center"} alignItems="center" p={"20px"}>
        <Stack maxWidth={"950px"}>
          <Typography className={classes.mainHeader} textAlign={"center"}> Our Services</Typography>
          <Typography mt={2} className={classes.mainContent}>  We understand that every client is unique, which is why we take the time to understand their needs and tailor our services & products accordingly. We create the best software applications and related products for our clients. We listen, research, and plan together with our clients to reach our goals, whether it be developing, software or a product. Explore our range of services & products and discover how we can help your business grow and succeed.
          </Typography>
        </Stack>
      </Stack>
      {/* <SectionTitle title="Our Services" color="text-white">
          You can use this space to highlight your first benefit or a feature of
          your product. It can also contain an image or Illustration like in the
          example along with some bullet points.
        </SectionTitle> */}

      <div className="flex-col">
        <div className="container mx-auto px-10 m-5 gap-5 mb-0 sm:flex justify-between items-stretch ">




          {/*  */}
          <div className="py-10 px-2">
            <div
              className="max-w-sm rounded cursor-pointer overflow-hidden shadow-lg hover:scale-110 transition duration-500 min-h-full border-none"
              onClick={() => navigate("/services/adaptivewebdesign")}
            >
              <img
                className="w-full"
                src={adaptive}
                alt=""
              />
              <div className="px-6 py-4 bg-white">
                <div className="font-bold text-black text-xl mb-2">
                  Website Development Services
                </div>
                <p className=" dark:text-gray-300 text-black text-base">
                  Each design what we create will suits all environment. Each
                  client needs will be far different, so we are adaptive to design
                  as your needs.
                </p>
              </div>
            </div>
          </div>

          <div className="py-10">
            <div
              className="max-w-sm rounded cursor-pointer  bg-white overflow-hidden shadow-lg hover:scale-110 transition duration-500 min-h-full max-h-full"
              onClick={() => navigate("/services/crm")}
              style={{ height: "410px" }}
            >
              <img className="w-full" src={crm} alt="" />
              <div className="px-6 py-7  bg-white" >
                <div className="font-bold text-xl text-black mb-2">
                  CRM Applications
                </div>
                <p className="text-black dark:text-white text-base">
                  We create best CRM software ,efficient way you to handle
                  your business enquires & customer data.
                </p>
              </div>
            </div>
          </div>

          {/*  */}
          <div className="py-10 px-2">
            <div
              className="max-w-sm rounded cursor-pointer bg-white overflow-hidden shadow-lg hover:scale-110 transition duration-500 min-h-full"
              onClick={() => navigate("/services/cloudbasedapplication")}
            >
              <img
                className="w-full"
                src={uiDesign}
                alt=""
              />
              <div className="px-6 py-4">
                <div className="font-bold text-xl text-black mb-2">
                  Cloud Based Web Applications
                </div>
                <p className="text-black dark:text-white text-base">
                  We are not only create website development, we create best cloud
                  based web application as on your custom requirements.
                </p>
              </div>
            </div>
          </div>


        </div>


        <div className="container mx-auto px-10 m-5 gap-5 mb-0 sm:flex justify-between items-stretch ">


          <div className="py-10 px-2">
            <div
              className="max-w-sm rounded cursor-pointer overflow-hidden bg-white  shadow-lg hover:scale-110 transition duration-500 min-h-full"
              onClick={() => navigate("/services/ecommerceapplication")}
            >
              <img className="w-full " src={ecommerce1} alt="" />
              <div className="px-6 py-4 ">
                <div className="font-bold text-xl text-black mb-2">
                  E-Commerce Application
                </div>
                <p className="text-black dark:text-whitetext-base">
                  Changes happening to the world sales activities. We place your
                  business authentic from real world to digital place
                </p>
              </div>
            </div>
          </div>


          {/*  */}
          <div className="py-10 px-2">
            <div
              className="max-w-sm rounded cursor-pointer overflow-hidden bg-white shadow-lg hover:scale-110 transition duration-500 min-h-full"
              onClick={() => navigate("/services/uidesign")}
            >
              <img
                className="w-full"
                src={uiux}
                alt=""
              />
              <div className="px-6 py-4">
                <div className="font-bold text-xl text-black mb-2">
                  UX/UI Design
                </div>
                <p className="text-black dark:text-white text-base">
                  Stackroots involved the best UX/UI designer in the city. We are
                  not just a creator of design a system, we are the best creator.
                </p>
              </div>
            </div>
          </div>


          <div className="py-10 px-2">
            <div
              className="max-w-sm rounded cursor-pointer overflow-hidden bg-white shadow-lg hover:scale-110 transition duration-500 min-h-full"
              onClick={() => navigate("/services/enterprise")}
            >
              <img className="w-full" src={enterprise} alt="" />
              <div className="px-6 py-4">
                <div className="font-bold text-xl text-black mb-2">
                  Enterprise Applications
                </div>
                <p className="text-black dark:text-gray-300 text-base">
                  We provide best ERP application for your business needs. We
                  develop best custom made ERP for production & manufacturing,
                  Supply chain management, Human Resource management & Finance.
                </p>
              </div>
            </div>
          </div>



        </div>

        <div className="flex items-center justify-center">
          <CustomButton
            onClick={() => navigate("/services")}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",

              marginBottom: "20px",
            }}
          >
            Read More
          </CustomButton>
        </div>

      </div>
    </div>
  );
};

export default HomeService;
