import React from "react";
import ServicesDescription from "../components/ServicesDescription";
import adaptivebg from "../assets/img/services/adaptivebg.png";
import { Stack, Typography } from "@mui/material"
import {
  ServiceCard,
  ServiceCard2,
  SectionTitle,
  PopupWidget,
} from "../components/_index";
import { Helmet } from "react-helmet";
import { useStyles } from "../styles";
import GetAQuotePopUp from "../components/GetAQuotePopUp";

const Services = () => {
  const classes = useStyles();
  return (
    <>
      <Helmet>
        <title>Stackroots | Services</title>
      </Helmet>
      <div style={{ background: "#131331" }}>
        <Stack
          className="adaptivebg"
          sx={{
            backgroundImage: `url(${adaptivebg})`,
            backgroundPosition: "top",
            backgroundSize: { xs: "250%", md: "cover" },
            backgroundRepeat: { xs: "no-repeat", md: "repeat" }
          }}
        >
          {/* <Head>
          <title>Stackroots - Best website company</title>
          <meta name="description" content="Stackroots - Best website company" />
          <link rel="icon" href="/img/Icon.png" />
        </Head> */}
          <ServicesDescription />

          <Typography className={classes.mainHeader} textAlign={"center"}> Our Services</Typography>

          <ServiceCard />
          <GetAQuotePopUp button={false} />

          {/* <SectionTitle title="What We Have Here For You">
          We listen, research and plan together with our clients to reach our
          goals, whether it be developing a website, a software or a product.
        </SectionTitle>
        <ServiceCard2 /> */}
        </Stack>
      </div>
    </>
  );
};

export default Services;
