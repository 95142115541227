import { Box, Card, Grid, Paper, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { Fade } from "react-awesome-reveal";
import coding from "../../assets/img/products/coding.png";

const Detail8 = () => {
  return (
    <Container maxWidth="xl" sx={{ marginTop: "50px" }}>
      {/* <Fade triggerOnce="true" direction="up"> */}
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              display: { xs: "none", sm: "flex", md: "flex" },
            }}
          >
            <img src={coding} alt="banner" />

            <Box
              sx={{
                flex: 1,
                padding: "50px",
                marginTop: { xs: "0px", md: "40px" },
              }}
            >
              <Box sx={{ flexDirection: "column" }}>
                <Typography
                  variant="h2"
                  fontWeight="bold"
                  textAlign="initial"
                  lineHeight="60px"
                  sx={{ fontSize: { xs: 32, md: 34 }, marginTop: "-89px" }}
                >
                  Lorem ipsum dolor sit, consectetur <br />
                  adipiscing ut aliquam,
                </Typography>

                <Typography
                  variant="h4"
                  fontSize="20px"
                  fontWeight="medium"
                  marginTop="10px"
                  textAlign="justify"
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Sagittis id mi leo libero et diam nibh at amet. Quis urna,
                  consectetur et cras. Nisi velit dolor congue fringilla quam.
                  Nisl tristique tellus massa vivamus purus suspendisse. Vel,
                  faucibus augue lectus aliquam mattis eros,
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      {/* </Fade> */}
    </Container>
  );
};

export default Detail8;
