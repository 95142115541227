import React from 'react'
import { Helmet } from 'react-helmet'
import ContactForm from '../components/Contact/ContactForm'
import ContactUs from '../components/Contact/ContactUs'
import GetAQuotePopUp from '../components/GetAQuotePopUp'


const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Stackroots | Contact Us</title>
      </Helmet>
      <div>
        <ContactUs />
        {/* <ContactForm /> */}
        <GetAQuotePopUp button={false} />

      </div>
    </>
  )
}

export default Contact