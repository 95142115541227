import { Box, Typography } from "@mui/material";
import React from "react";
import gymBan from "../../assets/img/products/gymban.png";
import { useStyles } from "../../styles";
import Container from "../Container";
import PopUp from "../PopUp";
const GymBanner = () => {
  const classes = useStyles();
  return (
    <div
      style={{
        backgroundImage: `url(${gymBan})`,
        // minHeight: "100vh",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50% 50%",
        backgroundSize: "cover",
        paddingBottom: "100px",
      }}
    >
      <Container>
        <Box
          sx={{
            maxWidth: "800px",
            flex: 1,
            padding: { xs: "20px", md: "50px 50px" },
            marginTop: { xs: "100px", md: "0px" },
            // marginLeft: { xs: "0px", md: "70px" },
          }}

        >
          <Typography
            className={classes.mainHeader}
            sx={{

              marginTop: { md: "100px", xs: "0px" },
            }}
          >
            Manage Your Clients <br /> Anywhere, Anytime
          </Typography>
          {/* <Typography variant="h3"
          fontSize="64px"
          fontWeight="bold"
          color="white"
          textAlign="initial"
          lineHeight="60px"
          sx={{ fontSize: { xs: 42, md: 64, sm: 32, color:'white' } }}>
          Construction Management
          </Typography> */}

          <Typography
            className={classes.mainContent}
            sx={{
              marginTop: { md: "25px", xs: "10px" },
            }}
          >
            Stackroots Gym Anytime offers a comprehensive solution for gym owners and managers to streamline their operations and provide exceptional service to their clients.
          </Typography>
          {/* <Typography variant='body1' sx={{color:'white', fontSize:'24px'}}>
      
          Construction ERP software that covers Construction
          
          </Typography>
          <Typography variant='body1' sx={{color:'white', fontSize:'24px'}}>
          and Project Execution, Purchase & Procurement.
          </Typography> */}
          {/* <Button
            variant="contained"
            sx={{
              color: "#4F46E5",
              background: "white",
              "&:hover": {
                background: "#e6f7ff",
              },
              marginTop: "50px",
              borderRadius: "10px",
              fontSize: { xs: "15px", md: "20px" },
              fontWeight: "bold",
            }}
            onClick={handleOpen}
          >
            BOOK FOR FREE DEMO <ArrowForwardIcon />
          </Button>

          <FreeDemo open={open} title="stacharch" handleClose={handleClose} /> */}

          <PopUp title="Gym Anytime" />
        </Box>
      </Container>
    </div>
  );
};

export default GymBanner;
