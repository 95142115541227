import React from "react";
import { Box, Button, Typography, Stack, Grid, Card, CardContent, IconButton } from "@mui/material";
import { Container } from "@mui/system";
import design from "../../assets/img/products/design.png";
import optimization from "../../assets/img/services/optimization.png";
import adaptivebg from "../../assets/img/services/adaptivebg.png";
import cloudbg from "../../assets/img/services/cloudbg.png";
import stackImg from "../../assets/img/services/stackimg.png";
import featureCloud from "../../assets/img/services/featureCloud.png";
import cloudfeatures from "../../assets/img/services/cloudfeatures.png";
import cloudGroup from "../../assets/img/services/group.png";
import fastUx from "../../assets/img/services/vectors/fastux.png";
import dev from "../../assets/img/services/vectors/dev.png";

import react from "../../assets/img/services/logo/reactlogo.png";
import angular from "../../assets/img/services/logo/angularLogo.png";
import node from "../../assets/img/services/logo/nodelogo.png";
import mongo from "../../assets/img/services/logo/mongoLogo.png";
import mui from "../../assets/img/services/logo/muilogo.png";
import express from "../../assets/img/services/logo/expressLogo.png";

import Techstack from "../Techstack";
import Marquee from "react-fast-marquee";
import { useStyles } from "../../styles";

const CloudBased = () => {
  const classes = useStyles();

  const icons = [
    {
      img: react
    },
    {
      img: angular
    },
    {
      img: node
    },
    {
      img: mongo
    },
    {
      img: mui
    },
    {
      img: express
    },
  ]

  return (
    <div style={{ background: "#131331" }}>
      <div
        className="adaptivebg"
        style={{
          backgroundImage: `url(${adaptivebg})`,
        }}
      >
        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 2, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={12}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: { xs: "center", md: "flex-start" },
                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}

              >
                Cloud Based Web Applications
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "0px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={design} alt="banner" />
              </Box>
              <Typography
                className={classes.mainContent}
                marginTop={{
                  xs: "30px", md: "10px"
                }}
              >
                STACKROOTS creates best cloud based web applications as on our
                clients custom requirements. Cloud based web application
                development is the process of creating and maintaining
                applications that are hosted on a remote server, or cloud.
                cloud-based applications can be accessed from anywhere in the
                world, and they can be scaled up or down to meet changing needs.
                cloud-based development is often more cost-effective than
                traditional on-premises development, as it eliminates the need
                for expensive hardware and software licenses. Because of this,
                cloud-based web application development is increasingly popular
                among organizations of all sizes.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={design} alt="banner" />
            </Box>
          </Stack>
        </Box>
      </div>

      <Box
        className="adaptivebg"
        sx={{
          background: { xs: `url(${featureCloud})}`, md: `url(${cloudbg})}`, },
        }}
      >
        <Box sx={{ marginTop: "60px", p: { xs: 3, md: 6 } }}>
          <Typography
            className={classes.mainHeader}
            textAlign="center"
            sx={{

              marginBottom: "50px",
              marginTop: { xs: "35px", md: 0, sm: 0 },
            }}
          >
            Features Of Cloud Based Web Applications
          </Typography>
          <Box component={"img"} src={cloudGroup} alt="banner" sx={{ margin: "0 auto", display: { xs: "block", md: "none" } }} />
          <Box component={"img"} src={cloudfeatures} alt="banner" sx={{ margin: "0 auto", display: { xs: "none", md: "block" } }} />
        </Box>
      </Box>

      <div
        className="adaptivebg"
        style={{
          backgroundImage: `url(${adaptivebg})`,
        }}
      >

        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexWrap: "wrap",
            p: { md: 6, xs: 3 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",

                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                textAlign={{ md: "initial", xs: "center" }}
                mb={3}
                className={classes.mainHeader}
              >
                Benefits of Cloud-Based Web Applications
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "0px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={optimization} alt="banner" />
              </Box>
              <Typography
                className={classes.mainContent}
                fontSize={{ xs: "16px", md: "18px" }}
                marginTop={{ xs: "30px", md: "10px" }}
              >
                One of the major advantages of cloud-based development is its cost-effectiveness. By eliminating the need for expensive hardware and software licenses, organizations of all sizes can save significantly on development costs. Additionally, cloud-based web applications offer the flexibility to easily adapt to changing business needs, making them an increasingly popular choice.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={optimization} alt="vector" />
            </Box>
          </Stack>
        </Box>

        {/*  */}

        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexWrap: "wrap",
            p: { md: 6, xs: 3 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row-reverse" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",

                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                textAlign={{ md: "initial", xs: "center" }}
                mb={3}
                className={classes.mainHeader}
              >
                Incredibly Fast User Experiences
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "0px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img alt="vector" src={fastUx} />
              </Box>
              <Typography
                className={classes.mainContent}
                fontSize={{ xs: "16px", md: "18px" }}
                marginTop={{ xs: "30px", md: "10px" }}
              >
                At Stackroots, we prioritize creating cloud-based web applications that provide incredibly fast user experiences. Our highly qualified team dedicates all necessary resources to meet your quality requirements. By focusing on usability, we ensure that your web application runs smoothly, resulting in more satisfied customers and increased sales. Our highly creative designers are skilled at creating user-friendly web designs that allow visitors to swiftly and easily access the information they need. A well-designed interface enhances user experience, increasing engagement and driving conversions. With Stackroots, you can expect a visually appealing and intuitive design that aligns with your brand identity.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={fastUx} />
            </Box>
          </Stack>
        </Box>


        {/*  */}

        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexWrap: "wrap",
            p: { md: 6, xs: 3 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",

                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                textAlign={{ md: "initial", xs: "center" }}
                mb={3}
                className={classes.mainHeader}
              >
                Customized Development Process
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "0px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img alt="vector" src={dev} />
              </Box>
              <Typography
                className={classes.mainContent}
                fontSize={{ xs: "16px", md: "18px" }}
                marginTop={{ xs: "30px", md: "10px" }}
              >
                We understand that every business has unique requirements. That's why we offer customized development processes to ensure that your cloud-based web application perfectly aligns with your specific needs. Our team works closely with you to understand your goals and objectives, providing tailored solutions that deliver exceptional results. When it comes to cloud-based web applications, Stackroots is your trusted partner. With our expertise in cloud-based development, we deliver cost-effective solutions that offer fast user experiences and user-friendly designs. Contact us today to discuss how we can customize a web application that meets your business needs and drives success.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={dev} />
            </Box>
          </Stack>
        </Box>

        {/*  */}


      </div>



      <Box sx={{ marginTop: "50px", display: { xs: "none", md: "block", sm: "block" } }}>
        <Techstack />
      </Box>

      <Box p={3} sx={{ marginTop: "50px", display: { xs: "block", md: "none", sm: "none" } }} >
        <Typography
          sx={
            { "fontStyle": "normal", "fontWeight": "600", "fontSize": "24px", "lineHeight": "29px", "color": "#FFFFFF", textAlign: "center" }
          }
        >
          Expertise And Technical Skills
        </Typography>
        <Stack>
          <Marquee gradient={false}>
            {icons.map((icon) => (
              <Box component={"img"}
                sx={{
                  mt: "25px",
                  width: "105px"
                }}
                src={icon.img}
                alt={"tech stack"}
              />
            ))
            }
          </Marquee>

          <Marquee gradient={false} direction={"right"}>
            {icons.map((icon) => (
              <Box component={"img"}
                sx={{
                  mt: "25px",
                  width: "105px"
                }}
                src={icon.img}
                alt={"tech stack"}
              />
            ))
            }
          </Marquee>
        </Stack>
      </Box>
    </div>
  );
}

export default CloudBased