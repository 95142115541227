import React from 'react'
import DegreeMarketing from '../../components/360DegreeMarketing/DegreeMarketing'
import ContactForm from '../../components/Contact/ContactForm'
import desk from "../../assets/img/services/desk.png";
import { Helmet } from 'react-helmet';
const Marketing = () => {
  return (
    <>
      <Helmet>
        <title>Stackroots | Marketing</title>
      </Helmet>
      <div>
        <DegreeMarketing />
        <ContactForm title="Get a quote" image={desk} />
      </div>
    </>
  )
}

export default Marketing