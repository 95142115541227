import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import blockban from "../../assets/img/blockban.png";
import { useStyles } from "../../styles";
import Container from "../Container";
import PopUp from "../PopUp";
const Blockban = () => {
  const classes = useStyles();
  return (
    <Stack
      sx={{
        backgroundImage: `url(${blockban})`,
        // minHeight: "100vh",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50% 50%",
        backgroundSize: "cover",

      }}
      pb={15}
    >
      <Container>
        <Box
          sx={{
            maxWidth: "800px",
            flex: 1,
            padding: { xs: "20px", md: "50px" },
            marginTop: "110px",
            // marginLeft: { xs: "0px", md: "70px" },
          }}
        >
          <Typography
            className={classes.mainHeader}
            sx={{
              marginTop: { md: "10px", xs: "0px" },
            }}
          >
            Blockchain-Based Decentralized Marketplace
          </Typography>

          <Typography
            className={classes.boldMainContent}
            sx={{
              marginTop: { md: "25px", xs: "10px" },
              // display: { xs: "none", md: "block" },
            }}
          >
            A decentralized digital marketplace backed by blockchain technology
            provides a secure, robust & transparent environment that connects
            Gym centers, Users, Vendors, and an E-Commerce application. This
            eco-system uses GYM coins as its fuel.
          </Typography>
          {/* <Typography variant='body1' sx={{color:'white', fontSize:'24px'}}>
      
          Construction ERP software that covers Construction
          
          </Typography>
          <Typography variant='body1' sx={{color:'white', fontSize:'24px'}}>
          and Project Execution, Purchase & Procurement.
          </Typography> */}
          {/* <Button
            variant="contained"
            sx={{
              color: "#4F46E5",
              background: "white",
              "&:hover": {
                background: "#e6f7ff",
              },
              marginTop: "50px",
              borderRadius: "10px",
              fontSize: { xs: "15px", md: "20px" },
              fontWeight: "bold",
            }}
            onClick={handleOpen}
          >
            BOOK FOR FREE DEMO <ArrowForwardIcon />
          </Button>

          <FreeDemo open={open} title="stacharch" handleClose={handleClose} /> */}

          <PopUp title="Blockchain" />
        </Box>
      </Container>
    </Stack>
  );
};

export default Blockban;
