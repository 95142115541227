import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Formik, Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Container } from "@mui/system";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import monitor from "../../src/assets/img/products/monitor.png";
import axios from "axios";
import { CustomLoadingButton } from "../utils/Button";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",

  boxShadow: 24,
  p: 3,
};

const FreeDemo = ({ handleClose, title }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [Message, setMessage] = useState("");
  const LoginSchema = Yup.object().shape({
    fullname: Yup.string().required("name is required"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    phone: Yup.string()
      .required("Phone is required")
      .min(10, "Enter correct phone number"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      fullname: "",
      phone: "",
      projectName: `${title}`,
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      // const formData = new FormData();
      // formData.append("name", values.name);
      // formData.append("email", values.email);
      // formData.append("phone", values.phone);
      // formData.append("subject", values.subject);
      // formData.append("from_name", values.from_name);
      // formData.append("access_key", "8a1084ea-48aa-44ff-bd06-88b7906943a4");
      // const object = Object.fromEntries(formData);
      // const json = JSON.stringify(object);

      const res = await axios
        .post(`${process.env.REACT_APP_API_URL}/mail/demo`, values)
        .then((res) => res)
        .catch((err) => console.log(err));

      if (!res.data.isError) {
        setIsSuccess(true);

        console.log("Success", res);
      }
    },
  });

  const {
    errors,
    resetForm,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
  } = formik;

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexWrap: "wrap",
        padding: { xs: "0", md: "20px" },
        gap: "20px",
      }}
    >
      <Box
        sx={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          display: { xs: "none", sm: "block", md: "block" },
        }}
      >
        <Box sx={{ flexDirection: "column" }}>
          <Typography
            id="modal-modal-title"
            variant="h4"
            sx={{ marginTop: "50px" }}
          >
            Start Your Free Demo
          </Typography>
          {/* <Typography id="modal-modal-description" fontSize="20px">
            A Complete ERP Software for Construction Management
          </Typography> */}
          <Typography
            variant="h4"
            fontSize="20px"
            fontWeight="medium"
            marginTop="10px"
            textAlign="initial"
          >
            <ul
              style={{
                fontSize: "16px",
                listStyle: "inherit",
                marginTop: "24px",
                padding: "10px",
              }}
            >
              <li>No hidden charges or No credit card</li>
              <li style={{ marginTop: "20px" }}>
                {" "}
                You can change plans or cancel your account at any time
              </li>
              <li style={{ marginTop: "20px" }}> Simple and easy to use</li>
              <li style={{ marginTop: "20px" }}>
                {" "}
                Advanced Analyitical Data and Reporting
              </li>
            </ul>
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              height: { xs: "200px", md: "260px" },
            }}
          >
            <img src={monitor} alt="/" />
          </Box>
        </Box>
      </Box>
      {isSuccess ? (
        <Card sx={{ padding: "50px" }}>
          <div className="flex flex-col items-center justify-center h-full text-center text-white rounded-md mt-4">
            <svg
              width="60"
              height="60"
              className="text-green-300"
              viewBox="0 0 100 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.6666 50L46.6666 66.6667L73.3333 33.3333M50 96.6667C43.8716 96.6667 37.8033 95.4596 32.1414 93.1144C26.4796 90.7692 21.3351 87.3317 17.0017 82.9983C12.6683 78.6649 9.23082 73.5204 6.8856 67.8586C4.54038 62.1967 3.33331 56.1283 3.33331 50C3.33331 43.8716 4.54038 37.8033 6.8856 32.1414C9.23082 26.4796 12.6683 21.3351 17.0017 17.0017C21.3351 12.6683 26.4796 9.23084 32.1414 6.88562C37.8033 4.5404 43.8716 3.33333 50 3.33333C62.3767 3.33333 74.2466 8.24998 82.9983 17.0017C91.75 25.7534 96.6666 37.6232 96.6666 50C96.6666 62.3768 91.75 74.2466 82.9983 82.9983C74.2466 91.75 62.3767 96.6667 50 96.6667Z"
                stroke="currentColor"
                strokeWidth="3"
              />
            </svg>
            <h3 className="py-5 text-xl text-green-500">
              Message sent successfully
            </h3>
            <p className="text-gray-700 md:px-3">{Message}</p>
            <Button
              variant="outlined"
              onClick={() => {
                resetForm();
                handleClose();
              }}
            >
              Go back
            </Button>
          </div>
        </Card>
      ) : (
        <Box sx={{ flex: 1, marginTop: { xs: "0px" } }}>
          <Card sx={{ height: "100%", background: "#0B0D23" }}>
            <CardContent>
              <Typography variant="h5" fontWeight={"bold"}>
                Start your free demo
              </Typography>
              <Typography variant="subtitle1">
                Please complete all fields
              </Typography>
              <FormikProvider value={formik}>
                <Form autoComplete="off">
                  <Stack spacing={3} sx={{ marginTop: "16px" }}>
                    <TextField
                      focused
                      fullWidth
                      autoComplete="username"
                      type="name"
                      label="Enter your name"
                      {...getFieldProps("fullname")}
                      error={Boolean(touched.fullname && errors.fullname)}
                      helperText={touched.fullname && errors.fullname}
                    //   sx={{background:'#F0F0F0'}}
                    />
                    <TextField
                      focused
                      fullWidth
                      autoComplete="username"
                      type="email"
                      label="Email address"
                      {...getFieldProps("email")}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    //   sx={{background:'#F0F0F0'}}
                    />

                    <TextField
                      focused
                      fullWidth
                      autoComplete="phone"
                      label="Phone"
                      {...getFieldProps("phone")}
                      error={Boolean(touched.phone && errors.phone)}
                      helperText={touched.phone && errors.phone}
                    //   sx={{background:'#F0F0F0'}}
                    />
                  </Stack>
                  {/* <TextField
                          fullWidth
                          autoComplete="username"
                          type="name"
                          label="Enter your message"
                          {...getFieldProps("message")}
                          error={Boolean(touched.message && errors.message)}
                          helperText={touched.message && errors.message}
                          //   sx={{background:'#F0F0F0'}}
                        /> */}

                  <CustomLoadingButton
                    loading={isSubmitting}
                    type="submit"
                    fullWidth
                    sx={{ marginTop: "50px", padding: "10px 20px" }}
                  >
                    submit
                  </CustomLoadingButton>
                </Form>
              </FormikProvider>
            </CardContent>
          </Card>
        </Box>
      )}
    </Box>
  );
};

export default FreeDemo;
