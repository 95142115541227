import {
  Box,
  Card,
  Grid,
  Paper,
  Typography,
  IconButton,
  Container,
} from "@mui/material";

import React from "react";
import CardContent from "@mui/material/CardContent";
import socialnetwork from "../../assets/img/products/socialnetwork.png";
import { Fade } from "react-awesome-reveal";
import monitor2 from "../../assets/img/products/monitor2.png";
import customize from "../../assets/img/products/customize.png";
import cloudcomputing from "../../assets/img/products/cloudcomputing.png";
import mobility from "../../assets/img/products/mobility.png";
import scalable2 from "../../assets/img/products/scalable2.png";
import erpban from "../../assets/img/products/erpbg.png";
import ErpCards from "../ErpCards";
import { useStyles } from "../../styles";

const Banners = () => {
  const classes = useStyles();
  return (
    <div
      className="cont"
      style={{
        backgroundImage: `url(${erpban})`,
      }}
    >
      <Container
        maxWidth="xl"
      // sx={{ marginTop: '50px'}}
      >
        <Box sx={{ marginTop: "50px" }}>
          {/* <Fade triggerOnce="true" direction="up"> */}
          <Typography
            className={classes.mainHeader}
            sx={{
              paddingTop: "30px",
            }}
            textAlign={"center  "}
          >
            FEATURES OF ENTERPRISE RESOURCE PLANNING
          </Typography>
          {/* </Fade> */}
        </Box>
        <Grid
          container
          spacing={3}
          sx={{ marginTop: "50px", paddingBottom: "50px" }}
        >
          <Grid item md={4} sm={6} xs={6}>
            {/* <Fade triggerOnce="true" direction="up"> */}
            {/* <div className="hrms"> */}
            <div
              sx={{
                boxShadow: "0px 0px 0px 1px",
                backgroundColor: "transparent",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  minHeight: "180px",
                }}
              >
                <IconButton
                  size="large"
                  aria-label="show 4 new mails"
                  color="inherit"
                >
                  <img
                    src={socialnetwork}
                    style={{
                      width: "70px",
                      filter: "brightness(0) invert(1)",
                    }}
                  />
                </IconButton>

                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  textAlign="center"
                  sx={{
                    color: "#56C5FF",
                    fontSize: { xs: 14, md: 22, sm: 22 },
                  }}
                >
                  Web Based (Cloud) Solution
                </Typography>

                {/* <info>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ color: "white" }}
                    >
                      Easy To Set Up, Easy To Customise And Easy To Scale. Help
                      your project management teams can assist more projects
                      faster with AI based forecasting & project management
                      tools
                    </Typography>
                  </info> */}
              </CardContent>
            </div>
            {/* </div> */}
            {/* </Fade> */}
          </Grid>
          <Grid item md={4} sm={6} xs={6}>
            {/* <Fade triggerOnce="true" direction="up"> */}
            {/* <div className="hrms"> */}
            <div
              sx={{
                boxShadow: "0px 0px 0px 1px",
                backgroundColor: "transparent",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  minHeight: "180px",
                }}
              >
                <IconButton
                  size="large"
                  aria-label="show 4 new mails"
                  color="inherit"
                >
                  <img
                    src={mobility}
                    style={{
                      width: "70px",
                      filter: "brightness(0) invert(1)",
                    }}
                  />
                </IconButton>

                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  textAlign="center"
                  sx={{
                    color: "#56C5FF",
                    fontSize: { xs: 14, md: 22, sm: 22 },
                  }}
                >
                  Freedom of Mobility
                </Typography>
                {/* <info>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ color: "white" }}
                    >
                      An integrated Stackroots ERP system automates project
                      creation by automatically capturing data from the admin &
                      user. This provides audits trails for better visibility
                      and also helps increase efficiency and accuracy.
                    </Typography>
                  </info> */}
              </CardContent>
            </div>
            {/* </div> */}
            {/* </Fade> */}
          </Grid>
          <Grid item md={4} sm={6} xs={6}>
            {/* <Fade triggerOnce="true" direction="up"> */}
            {/* <div className="hrms"> */}
            <div
              sx={{
                boxShadow: "0px 0px 0px 1px",
                backgroundColor: "transparent",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  minHeight: "180px",
                }}
              >
                <IconButton
                  size="large"
                  aria-label="show 4 new mails"
                  color="inherit"
                >
                  <img
                    src={monitor2}
                    style={{
                      width: "70px",
                      filter: "brightness(0) invert(1)",
                    }}
                  />
                </IconButton>

                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  textAlign="center"
                  sx={{
                    color: " #56C5FF",
                    fontSize: { xs: 14, md: 22, sm: 22 },
                  }}
                >
                  Customizable Dashboard
                </Typography>
                {/* <info>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ color: "white" }}
                    >
                      Stackroots ERP, each project can collectively ensure
                      communication between each member in the same
                      projectsClients have the privileges to take meetings with
                      each project team.
                    </Typography>
                  </info> */}
              </CardContent>
            </div>
            {/* </div> */}
            {/* </Fade> */}
          </Grid>
          <Grid item md={4} sm={6} xs={6}>
            {/* <Fade triggerOnce="true" direction="up"> */}
            {/* <div className="hrms"> */}
            <div
              sx={{
                boxShadow: "0px 0px 0px 1px",
                backgroundColor: "transparent",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  minHeight: "180px",
                }}
              >
                <IconButton
                  size="large"
                  aria-label="show 4 new mails"
                  color="inherit"
                >
                  <img
                    src={customize}
                    style={{
                      width: "70px",
                      filter: "brightness(0) invert(1)",
                    }}
                  />
                </IconButton>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  textAlign="center"
                  sx={{
                    color: " #56C5FF",
                    fontSize: { xs: 14, md: 22, sm: 22 },
                  }}
                >
                  Fully Costumizable
                </Typography>
                {/* <info>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ color: "white" }}
                    >
                      Stackroots ERP, keep improvement of project management,
                      operational performance, cost reduction through decreased
                      process errors, facilitation of communication, profiting
                      from expert knowledge form the Industry standards
                    </Typography>
                  </info> */}
              </CardContent>
            </div>
            {/* </div> */}
            {/* </Fade> */}
          </Grid>
          <Grid item md={4} sm={6} xs={6}>
            {/* <Fade triggerOnce="true" direction="up"> */}
            {/* <div className="hrms"> */}
            <div
              sx={{
                boxShadow: "0px 0px 0px 1px",
                backgroundColor: "transparent",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  minHeight: "180px",
                }}
              >
                <IconButton
                  size="large"
                  aria-label="show 4 new mails"
                  color="inherit"
                >
                  <img
                    src={scalable2}
                    style={{
                      width: "70px",
                      filter: "brightness(0) invert(1)",
                    }}
                  />
                </IconButton>

                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  textAlign={"center"}
                  sx={{
                    color: " #56C5FF",
                    fontSize: { xs: 14, md: 22, sm: 22 },
                  }}
                >
                  Self Scalable
                </Typography>
                {/* <info>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ color: "white" }}
                    >
                      Stackroots ERP easily integrates with the third party ERP
                      which is related to the field of financial, CRM, Human
                      capital, Inventory modules. Each stages of development our
                      team create easy integration modules for existing ERP for
                      architecture institutions
                    </Typography>
                  </info> */}
              </CardContent>
            </div>
            {/* </div> */}
            {/* </Fade> */}
          </Grid>
          <Grid item md={4} sm={6} xs={6}>
            {/* <Fade triggerOnce="true" direction="up"> */}
            {/* <div className="hrms"> */}
            <div
              sx={{
                boxShadow: "0px 0px 0px 1px",
                backgroundColor: "transparent",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  minHeight: "180px",
                }}
              >
                <IconButton
                  size="large"
                  aria-label="show 4 new mails"
                  color="inherit"
                >
                  <img
                    src={cloudcomputing}
                    style={{
                      width: "70px",
                      filter: "brightness(0) invert(1)",
                    }}
                  />
                </IconButton>

                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  textAlign="center"
                  sx={{
                    color: " #56C5FF",
                    fontSize: { xs: 14, md: 22, sm: 22 },
                  }}
                >
                  On-Cloud Deployment
                </Typography>
                {/* <info>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ color: "white" }}
                    >
                      Managing each project with less effort and providing
                      better managerial decision making in the terms right time.
                      Each managerial insight can keep in particular problem
                      statements, to recover the stages of the project
                      management system with timely delivery.
                    </Typography>
                  </info> */}
              </CardContent>
            </div>
            {/* </div> */}
            {/* </Fade> */}
          </Grid>
        </Grid>
      </Container>{" "}
    </div>
  );
};

export default Banners;
