import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import Container from "./Container";
import Pro1 from "../assets/img/pro1.png";
import Pro2 from "../assets/img/pro2.png";
import Pro3 from "../assets/img/pro3.png";
import Pro4 from "../assets/img/pro5.png";
import SectionTitle from "./SectionTitle";
import { useNavigate } from "react-router-dom";
import bgLayer from "../../src/assets/img/asifali.png";
import { Fade } from "react-awesome-reveal";
import { useStyles } from "../styles"

const HeroProducts = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(180deg, #151136 26.56%, #282559 39.58%, #161237 55.21%, #282457 70.83%, #0A0C22 95.83%, #212458 99.48%),url(${bgLayer}`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundBlendMode: "hard-light",
      }}
    >

      <Stack justifyContent="center" alignItems={"center"} p={"20px"}>
        <Stack maxWidth={"950px"} py={5}>
          <Typography className={classes.mainHeader}>Elevating Your Business with Customized Solutions</Typography>
          <Typography mt={2} className={classes.mainContent}> At StacKRoots, we understand that every company is unique and deserves the best solutions and products. That's why we offer customized blockchain-based web applications, mobile applications, ERP & CRM products, Metaverse environments, and full-stack software products. Our team is dedicated to reflecting your personal brand while scaling up and promoting an exceptional user experience with the new era of marketing. With our expertise and commitment to excellence, we will elevate your business to new heights.</Typography>
        </Stack>
      </Stack>

      <Container>
        <Grid
          container
          padding="0px 0px 40px 0px"
          alignItems={"center"}
          spacing={6}
        >
          <Grid item xs={12} md={6} lg={6}>

            <Box>
              <Typography
                // variant="h4"
                // fontWeight={"bold"}
                // className="text-white"
                className={classes.subMainHeader}
                gutterBottom
              >
                StackArch
              </Typography>
              <Typography className={classes.mainContent}>
                StackArch is a fast and visual all-in-one project management ERP software designed specifically for architectural Firms. Whether you're managing a small-scale or a large architectural firm, it provides a comprehensive solution to streamline and optimize your project management processes. With its user-friendly web and mobile platforms, StackArch offers the flexibility to access and manage your projects from anywhere, at any time. By implementing StackArch, you can take your architectural firm to the next level.
              </Typography>
              <Button
                variant="outlined"
                sx={{ color: "white", marginTop: "10px" }}
                onClick={() => navigate("/products/stackarch")}
              >
                Read More
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={Pro1} />
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          padding="40px 0px 40px 0px"
          alignItems={"center"}
          direction={{ xs: "column-reverse", sm: "column-reverse", md: "row" }}
          spacing={5}
        >
          <Grid item xs={12} md={6} lg={6}>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "inital",
              }}
            >
              <img src={Pro2} />
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>

            <Box>
              <Typography
                className={classes.subMainHeader}
              // variant="h4"
              // fontWeight={"bold"}
              // className="text-white"
              // gutterBottom
              >
                CertVerify - Your Trusted Certificate Verification Solution
              </Typography>
              <Typography mt={2} className={classes.mainContent}>
                We are the leading platform for seamless and reliable certificate verification. With our state-of-the-art blockchain-powered technology, we bring you an innovative solution to verify the authenticity of digital certificates with just one click. Say goodbye to cumbersome and time-consuming manual verification processes - with CertVerify, you can instantly validate the genuineness of any certificate issued through our system.

              </Typography>
              <Button
                variant="outlined"
                sx={{ color: "white", marginTop: "10px" }}
                onClick={() => navigate("/products/certverify")}
              >
                Read More
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          padding="40px 0px 40px 0px"
          alignItems={"center"}
          spacing={5}
        >
          <Grid item xs={12} md={6} lg={6}>

            <Box>
              <Typography
                // variant="h4"
                // fontWeight={"bold"}
                // className="text-white"
                // gutterBottom
                className={classes.subMainHeader}
              >
                Stack-Ed. The Ultimate Solution for Learning Management System


              </Typography>
              <Typography mt={2} className={classes.mainContent}>
                Stack-Ed LMS provides a set of features that allow you to track learner progress and analyze performance data. With our intuitive analytics tools, you can gain valuable insights into how your learners are engaging with the course material.
              </Typography>
              <Button
                variant="outlined"
                sx={{ color: "white", marginTop: "10px" }}
                onClick={() => navigate("/products/lms")}
              >
                Read More
              </Button>
            </Box>

          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={Pro3} />
            </Box>

          </Grid>
        </Grid>

        <Grid
          container
          padding="40px 0px 40px 0px"
          alignItems={"center"}
          direction={{ xs: "column-reverse", sm: "column-reverse", md: "row" }}
          spacing={5}
        >
          <Grid item xs={12} md={6} lg={6}>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "inital",
              }}
            >
              <img src={Pro4} />
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Box>
              <Typography
                className={classes.subMainHeader}
              >
                Blockchain-Based Decentralized Marketplace
              </Typography>
              <Typography mt={2} className={classes.mainContent}>
                Blockchain-Based Decentralized Marketplace, where innovation meets trust and transparency. Our marketplace harnesses the power of blockchain technology to facilitate seamless crypto payments and intermediary-free exchanges of goods. With the ability for buyers to view the origins and history of goods stored on the blockchain, trust between sellers and buyers is enhanced, making transactions more transparent and secure.
              </Typography>
              <Button
                variant="outlined"
                sx={{ color: "white", marginTop: "10px" }}
                onClick={() => navigate("/products/blockchain")}
              >
                Read More
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default HeroProducts;
