import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import Marquee from "react-fast-marquee";
import adaptivebg from "../../assets/img/services/adaptivebg.png";
import branding1 from "../../assets/img/services/branding1.png";
import flowchart1 from "../../assets/img/services/brandingFlow.png";
import designingbg1 from "../../assets/img/services/designBanner.png";
import designingbg from "../../assets/img/services/designingbg.png";
import flowchart from "../../assets/img/services/flowchart.png";
import Techstack from "../Techstack";

import angular from "../../assets/img/services/logo/angularLogo.png";
import express from "../../assets/img/services/logo/expressLogo.png";
import mongo from "../../assets/img/services/logo/mongoLogo.png";
import mui from "../../assets/img/services/logo/muilogo.png";
import node from "../../assets/img/services/logo/nodelogo.png";
import react from "../../assets/img/services/logo/reactlogo.png";
import { useStyles } from "../../styles";

import logoDesign from "../../assets/img/services/vectors/logoDesign.png";
import webDesign from "../../assets/img/services/vectors/webDesign.png";
import identity from "../../assets/img/services/vectors/identity.png";
import boostBrand from "../../assets/img/services/vectors/boostBrand.png";

const Branding1 = () => {
  const classes = useStyles();
  const icons = [
    {
      img: react
    },
    {
      img: angular
    },
    {
      img: node
    },
    {
      img: mongo
    },
    {
      img: mui
    },
    {
      img: express
    },
  ]

  return (
    <div style={{ background: "#131331" }}>
      <div
        className="adaptivebg"
        style={{
          backgroundImage: `url(${adaptivebg})`,
        }}
      >
        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={12}
          >
            <Box
              sx={{
                display: "flex",

                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}

              >
                Branding Services
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "0px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={branding1} alt="banner" />
              </Box>
              <Typography mt={2} className={classes.mainContent}
              >
                At Stackroots, we understand the importance of branding in creating a lasting impression on your target consumers. Our branding services are designed to give your organization, company, product, or service a unique personality that resonates with your audience. From logo design to in-office/store branding, we offer a comprehensive range of services to help you build and shape your brand.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={branding1} alt="banner" />
            </Box>
          </Stack>
        </Box>

        <Box component={"div"}
          className="adaptivebg"
          sx={{
            backgroundImage: { xs: `url(${designingbg1})`, md: `url(${designingbg})` },
            backgroundSize: { xs: "cover", }
            // height: "650px",
          }}
        >
          <Box sx={{ marginTop: { xs: "0px", md: "60px" }, p: { xs: 3, md: 6 } }}>
            <Typography className={classes.mainHeader}
              textAlign="center"

            >
              Branding Process
            </Typography>
            <Box
              sx={{
                marginTop: { xs: "50px", md: "40px" },
              }}
            >
              <Box component="img" src={flowchart} alt="banner" sx={{ margin: "0 auto", display: { xs: "none", md: "block", } }} />
              <Box component="img" src={flowchart1} alt="banner" sx={{ margin: "0 auto", display: { xs: "block", md: "none", width: "50%" } }} />
            </Box>
          </Box>
        </Box>




        {/*  */}

        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row-reverse" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={12}
          >
            <Box
              sx={{
                display: "flex",

                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}
              >
                Logo Design
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "20px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img alt="vector" src={logoDesign} />
              </Box>
              <Typography
                className={classes.mainContent}
                marginTop="20px"
              >
                A well-designed logo is the cornerstone of your brand identity. Our team of talented designers at Stackroots can create a visually appealing and meaningful logo that represents your brand values and communicates your message effectively. We take into consideration your target audience, industry trends, and your brand's unique characteristics to design a logo that stands out from the competition.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={logoDesign} width={"80%"} />
            </Box>
          </Stack>
        </Box>


        {/*  */}

        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={12}
          >
            <Box
              sx={{
                display: "flex",

                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}
              >
                Company Website Design
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "20px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img alt="vector" src={webDesign} />
              </Box>
              <Typography
                className={classes.mainContent}
                marginTop="20px"
              >
                Your website is often the first point of contact for potential customers. Our expert designers at Stackroots can create a visually stunning and user-friendly website that reflects your brand identity and engages your visitors. We focus on creating a seamless user experience, intuitive navigation, and compelling visuals to ensure that your website leaves a positive impression on your audience.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={webDesign} />
            </Box>
          </Stack>
        </Box>


        {/*  */}

        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row-reverse" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={12}
          >
            <Box
              sx={{
                display: "flex",

                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}
              >
                Building a Strong and Memorable Brand Identity
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "20px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img alt="vector" src={identity} />
              </Box>
              <Typography
                className={classes.mainContent}
                marginTop="20px"
              >
                Compelling and persuasive content is essential for conveying your brand message effectively. Our team of skilled writers at Stackroots can create engaging and informative content that captures the essence of your brand and resonates with your target audience. From website copy to blog posts and social media content, we can help you communicate your brand story in a way that connects with your customers. Corporate Stationery Design Consistency is key when it comes to branding. Our designers can create cohesive corporate stationery designs that align with your brand identity. From business cards to letterheads and envelopes, we ensure that every piece of stationery reflects your brand values and creates a professional and memorable impression.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={identity} width={"90%"} />
            </Box>
          </Stack>
        </Box>


        {/*  */}

        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={12}
          >
            <Box
              sx={{
                display: "flex",

                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}
              >
                Boost Your Brand's Online Presence with Us
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "20px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img alt="vector" src={boostBrand} />
              </Box>
              <Typography
                className={classes.mainContent}
                marginTop="20px"
              >
                If you're looking to build a strong online presence for your brand and boost conversions and revenue, contact Stackroots today. Our branding experts will work closely with you to understand your brand values and goals and develop a customized strategy that sets you apart from the competition. Let us help you create a memorable and impactful brand identity that resonates with your target audience.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={boostBrand} width={"80%"} />
            </Box>
          </Stack>
        </Box>

      </div>

      <Box sx={{ marginTop: "50px", display: { xs: "none", md: "block", sm: "block" } }}>
        <Techstack />
      </Box>

      <Box sx={{ marginTop: "50px", p: 3, mb: { xs: "0px", md: "60px" }, display: { xs: "block", md: "none", sm: "none" } }} >
        <Typography
          sx={
            { "fontStyle": "normal", "fontWeight": "600", "fontSize": "24px", "lineHeight": "29px", "color": "#FFFFFF", textAlign: "center" }
          }
        >
          Expertise And Technical Skills
        </Typography>

        <Stack>
          <Marquee gradient={false}>
            {icons.map((icon) => (
              <Box component={"img"}
                sx={{
                  mt: "25px",
                  width: "105px"
                }}
                src={icon.img}
                alt={"tech stack"}
              />
            ))
            }
          </Marquee>

          <Marquee gradient={false} direction={"right"}>
            {icons.map((icon) => (
              <Box component={"img"}
                sx={{
                  mt: "25px",
                  width: "105px"
                }}
                src={icon.img}
                alt={"tech stack"}
              />
            ))
            }
          </Marquee>
        </Stack>
      </Box>

    </div>
  );
}

export default Branding1