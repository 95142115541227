import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    outer: {
        width: '100px',
        height: '100px',
        margin: '5%',
        border: '4px solid white',
        borderRadius: "50%"
    },
    inner: {
        width: '80px',
        height: '80px',
        margin: 'auto',
        marginTop: '6%',
        textAlign: 'center',
        background: 'white',
        padding: '5px',
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    title: {
        color: 'white',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: 'normal',
    },
    description: {
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: '300',
        textAlign: 'center',
        lineHeight: 'normal',
    },
}));

function Benifits({ benifit }) {
    const classes = useStyles();

    return (
        <Stack gap={2} width={{ xs: '350px', md: '360px' }} alignItems={'center'}>
            <Box className={classes.outer}>
                <Box className={classes.inner}>
                    <Box component={"img"} src={benifit?.icon} width={"60%"} />
                </Box>
            </Box>
            <Typography className={classes.title}>{benifit.title}</Typography>
            <Typography className={classes.description}>{benifit.desc}</Typography>
        </Stack>
    );
}

export default Benifits;
