import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import Container from "./Container";
import ProImage from "../assets/img/about.png";
import aboutLink from "../assets/img/aboutlink.png";
import shock from "../assets/img/shock.png";
import { Fade } from "react-awesome-reveal";
import { useStyles } from "../styles";

const HeroAbout = () => {
  const classes = useStyles();
  return (
    <div
      className="hero_about_bg"
      style={{
        // backgroundImage: `url(${aboutLink})`,
        backgroundPosition: "cover",

        backgroundRepeat: "no-repeat",

        backgroundImage: `linear-gradient(180deg, rgba(33, 36, 88, 0.990908) 0%, #0B0D23 8.33%, rgba(17, 16, 47, 0.94) 48.12%, rgba(21, 17, 54, 0.97) 52.43%, rgba(11, 13, 35, 0.99) 99.48%),url(${shock})`,
        backgroundBlendMode: "color-dodge",
        backgroundSize: "contain",
      }}
    >
      <Container>
        <Grid
          container
          padding="40px 0px 40px 0px"
          alignItems={"center"}
          spacing={2}
        >
          <Grid item xs={12} sm={6}>

            <Box sx={{ flex: 1 }}>
              <Typography
                // variant="h4"
                // className="text-white"
                // fontWeight={"bold"}
                // gutterBottom
                className={classes.mainHeader}
              >
                Team STACKROOTS
              </Typography>
              <Typography mt={2} className={classes.mainContent}>
                We are highly skilled and experienced professionals dedicated to providing top-notch Blockchain & web development services. With a strong background in web development and a passion for creating innovative solutions, our team is committed to delivering exceptional results for our clients. We pride ourselves on our attention to detail, creativity, and ability to understand and meet our client’s unique business needs. With our expertise and commitment to excellence, we strive to exceed expectations and help businesses succeed in the competitive world.
              </Typography>
            </Box>

          </Grid>

          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={ProImage} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default HeroAbout;
