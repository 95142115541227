import React from 'react'
import { Helmet } from 'react-helmet'
import ContactForm from '../../components/Contact/ContactForm'
import SocialMediaMarketing from '../../components/SocialMediaMarketing/SocialMediaMarketing'
import socialForm from "../../assets/img/services/socialForm.png";

const SocialMedia1 = () => {
  return (
    <>
      <Helmet>
        <title>Stackroots | Social Media</title>
      </Helmet>
      <div>
        <SocialMediaMarketing />
        <ContactForm title="Get a quote" image={socialForm} />
      </div>
    </>
  )
}

export default SocialMedia1