import { Box, Stack, Typography } from '@mui/material'
import React from 'react'

function Card({ cardData }) {
    return (
        <Stack sx={{
            width: "300px",
            height: "285px",
            background: "#FFF",
            borderRadius: "22px",
            boxShadow: "0px 1px 7px 18px rgba(1, 2, 178, 0.25)",
            p: 3

        }} alignItems={"center"} justifyContent={"center"} gap={2}>
            <Box component={"img"} src={cardData.img} width={"75px"} />
            <Typography sx={{ "color": "#009ADE", "fontSize": "17px", "fontStyle": "normal", "fontWeight": "700", "lineHeight": "normal" }}>{cardData.title}</Typography>
            <Typography sx={{ "fontSize": "15px", "fontStyle": "normal", "fontWeight": "400", color: "black", "lineHeight": "22.5px" }}>{cardData.desc}</Typography>
        </Stack>
    )
}

export default Card