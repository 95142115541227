import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { ErrorMessage, Field, Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { CustomLoadingButton } from "../utils/Button";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",

  boxShadow: 24,
  p: 3,
};

const QuoteForm = ({ handleClose, title }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [Message, setMessage] = useState("");

  const [categories, setCategories] = useState();
  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/category/contactus/user`)
      .then((res) => {
        setCategories(res.data.data.categories);
      })
      .catch((err) => console.log(err));
  };

  const LoginSchema = Yup.object().shape({
    fullname: Yup.string().required("name is required"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    phone: Yup.string()
      .required("Phone is required")
      .min(10, "Enter correct phone number"),
    location: Yup.string().required("Location is required"),
    category: Yup.string().required("Category for is required"),
    budget: Yup.string().required("Budget for is required"),
    description: Yup.string().required("Description is required")
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      fullname: "",
      phone: "",
      location: "",
      category: "",
      budget: "",
      description: ""
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/mail/contact`, values);

        if (!response.data.isError) {
          setIsSuccess(true);
          setMessage(response.data.message);
          resetForm();
        } else {
          setIsSuccess(false);
          setMessage(response.data.message);
        }
      } catch (error) {
        setIsSuccess(false);
        setMessage("Client Error. Please check the console.log for more info");
        console.log(error);
      }

    },
  });

  const {
    errors,
    resetForm,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
  } = formik;

  return (
    <Box
      sx={{
        width: { xs: "100%", sm: "700px" },
        height: "100%",
        display: "flex",
        flexWrap: "wrap",
        gap: "20px",
      }}
    >
      {isSuccess ? (
        <Card sx={{ padding: "50px" }}>
          <div className="flex flex-col items-center justify-center h-full text-center text-white rounded-md mt-4">
            <svg
              width="60"
              height="60"
              className="text-green-300"
              viewBox="0 0 100 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.6666 50L46.6666 66.6667L73.3333 33.3333M50 96.6667C43.8716 96.6667 37.8033 95.4596 32.1414 93.1144C26.4796 90.7692 21.3351 87.3317 17.0017 82.9983C12.6683 78.6649 9.23082 73.5204 6.8856 67.8586C4.54038 62.1967 3.33331 56.1283 3.33331 50C3.33331 43.8716 4.54038 37.8033 6.8856 32.1414C9.23082 26.4796 12.6683 21.3351 17.0017 17.0017C21.3351 12.6683 26.4796 9.23084 32.1414 6.88562C37.8033 4.5404 43.8716 3.33333 50 3.33333C62.3767 3.33333 74.2466 8.24998 82.9983 17.0017C91.75 25.7534 96.6666 37.6232 96.6666 50C96.6666 62.3768 91.75 74.2466 82.9983 82.9983C74.2466 91.75 62.3767 96.6667 50 96.6667Z"
                stroke="currentColor"
                strokeWidth="3"
              />
            </svg>
            <h3 className="py-5 text-xl text-green-500">
              Message sent successfully
            </h3>
            <p className="text-gray-700 md:px-3">{Message}</p>
            <Button
              variant="outlined"
              onClick={() => {
                resetForm();
                handleClose();
              }}
            >
              Go back
            </Button>
          </div>
        </Card>
      ) : (
        <Box sx={{ flex: 1, marginTop: { xs: "0px" } }}>
          <Card sx={{ height: "100%", background: "#0B0D23" }}>
            <CardContent>
              <Typography variant="h5" fontWeight={"bold"}>
                Drop us a line
              </Typography>
              <Typography variant="subtitle1">
                Post your requirements here and we will contact you Shortly

              </Typography>
              <FormikProvider value={formik}>
                <Form autoComplete="off">
                  <Stack spacing={3} sx={{ marginTop: "16px" }}>
                    <Grid container rowGap={3} columnSpacing={2} >
                      <Grid item xs={12} sm={6}>
                        <TextField
                          focused
                          fullWidth
                          autoComplete="username"
                          type="name"
                          label="Enter your name"
                          {...getFieldProps("fullname")}
                          error={Boolean(touched.fullname && errors.fullname)}
                          helperText={touched.fullname && errors.fullname}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          focused
                          fullWidth
                          autoComplete="username"
                          type="email"
                          label="Email address"
                          {...getFieldProps("email")}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                        //   sx={{background:'#F0F0F0'}}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>

                        <TextField
                          focused
                          fullWidth
                          autoComplete="phone"
                          label="Phone"
                          {...getFieldProps("phone")}
                          error={Boolean(touched.phone && errors.phone)}
                          helperText={touched.phone && errors.phone}
                        //   sx={{background:'#F0F0F0'}}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          focused
                          fullWidth
                          autoComplete="location"
                          label="Location"
                          {...getFieldProps("location")}
                          error={Boolean(touched.location && errors.location)}
                          helperText={touched.location && errors.location}
                        //   sx={{background:'#F0F0F0'}}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth focused error={Boolean(touched.category && errors.category)}>
                          <InputLabel id="demo-simple-select-label">Looking For</InputLabel>
                          <Field
                            as={Select}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Looking For"
                            name="category"
                            error={Boolean(touched.category && errors.category)}

                            helperText={<ErrorMessage name="category" />}
                            MenuProps={{
                              sx: {
                                "& .MuiPaper-root": {
                                  backgroundColor: "whitesmoke",
                                  color: "#131331",
                                },
                              },
                            }}
                            sx={{
                              ".MuiSvgIcon-root ": {
                                fill: "white !important",
                              },
                            }}
                          >
                            {categories?.map((cat, i) => (
                              <MenuItem value={cat._id}>{cat.name}</MenuItem>
                            ))}
                          </Field>
                        </FormControl>

                        <ErrorMessage
                          name="category"
                          component="div"
                          style={{ fontSize: "0.75rem", color: "#d32f2f", marginTop: "3px", marginLeft: "14px" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth focused error={Boolean(touched.budget && errors.budget)}>
                          <InputLabel id="demo-simple-select-label">Budget</InputLabel>
                          <Field
                            as={Select}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Budget"
                            name="budget"
                            error={Boolean(touched.budget && errors.budget)}
                            helperText={<ErrorMessage name="budget" />}
                            MenuProps={{
                              sx: {
                                "& .MuiPaper-root": {
                                  backgroundColor: "whitesmoke",
                                  color: "#131331",
                                },
                              },
                            }}
                            sx={{
                              ".MuiSvgIcon-root ": {
                                fill: "white !important",
                              },
                            }}
                          >
                            <MenuItem value={"Less than $750"}>Less than $750</MenuItem>
                            <MenuItem value={"$750-$1000"}>$750-$1000</MenuItem>
                            <MenuItem value={"$1000-$2500"}>$1000-$2500</MenuItem>
                            <MenuItem value={"$2500-$5000"}>$2500-$5000</MenuItem>
                            <MenuItem value={"$5000+"}>$5000+</MenuItem>
                          </Field>
                        </FormControl>

                        <ErrorMessage
                          name="budget"
                          component="div"
                          style={{ fontSize: "0.75rem", color: "#d32f2f", marginTop: "3px", marginLeft: "14px" }}
                        />
                      </Grid>
                      <Grid item xs={12} >
                        <TextField
                          fullWidth
                          minRows={2}
                          type="name"
                          label="Describe your project"
                          {...getFieldProps("description")}
                          error={Boolean(touched.description && errors.description)}
                          helperText={touched.description && errors.description}
                          onContextMenu={(e) => e.preventDefault()}
                          onPaste={(e) => e.preventDefault()}
                          focused
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                  {/* <TextField
                          fullWidth
                          autoComplete="username"
                          type="name"
                          label="Enter your message"
                          {...getFieldProps("message")}
                          error={Boolean(touched.message && errors.message)}
                          helperText={touched.message && errors.message}
                          //   sx={{background:'#F0F0F0'}}
                        /> */}

                  <CustomLoadingButton
                    loading={isSubmitting}
                    type="submit"
                    fullWidth
                    sx={{ marginTop: "50px", padding: "10px 20px" }}
                  >
                    submit
                  </CustomLoadingButton>
                </Form>
              </FormikProvider>
            </CardContent>
          </Card>
        </Box>
      )}
    </Box>
  );
};

export default QuoteForm;
