import { useEffect } from "react";
import { Helmet } from "react-helmet";
import HeroAbout from "../components/HeroAbout";
import HeroCaption from "../components/HeroCaption";
import HeroHexagon from "../components/HeroHexagon";
import HeroProducts from "../components/HeroProducts";
import HomeService from "../components/HomeService";
import TestimonialSlider from "../components/Testimonials/TestimonialSlider";
import {
  Hero,
  Techstack,
} from "../components/_index";

import GetAQuotePopUp from "../components/GetAQuotePopUp";
import ContactForm from "../components/Contact/ContactForm";


export default function Home() {
  useEffect(() => {
    const onPageLoad = () => {
      // setOpen(true);
      localStorage.setItem("ad", true);
    };

    // Check if the page has already loaded
    const adCache = localStorage.getItem("ad");
    if (!adCache) {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Stackroots | Best Web Application Development Company</title>
      </Helmet>
      {/* <Head>
        <title>Stackroots - Best website company</title>
        <meta name="description" content="Stackroots - Best website company" />
        <link rel="icon" href="/img/Icon.png" />
      </Head> */}
      <Hero />
      <Techstack />
      <HeroCaption />
      <HeroProducts />
      <HeroAbout />
      <HeroHexagon />
      <HomeService />
      <TestimonialSlider />
      <GetAQuotePopUp button={false} />
      {/* <ContactForm title={"Get A Quote"} /> */}
      {/* <Benefits data={benefitOne} />
      <Benefits imgPos="right" data={benefitTwo} /> */}
      {/* <Techstack /> */}
      {/* <SectionTitle title="What We Have Here For You">
        We listen, research and plan together with our clients to reach our
        goals, whether it be developing a website, a software or a product.
      </SectionTitle>
      <ServiceCard2 /> */}
      {/* <HuddlePopup /> */}
      {/* <FifaCupWidget /> */}
      {/* <FifaPopup open={open} setOpen={setOpen} /> */}
      {/* <CallUs /> */}
    </>
  );
}
