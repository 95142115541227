import React from "react";
import { Helmet } from "react-helmet";
import ContactForm from "../../components/Contact/ContactForm";
import PopupWidget from "../../components/popupWidget";
import Banner5 from "../../components/VehicleManagement/Banner5";
import Description3 from "../../components/VehicleManagement/Description3";
import Details from "../../components/VehicleManagement/Details";

const VehicleManagement = () => {
  return (
    <>
      <Helmet>
        <title>Stackroots | Vehicle Management</title>
      </Helmet>
      <div>
        <Banner5 />
        <Description3 />
        <Details />
        <ContactForm title="Get a quote" />
        {/* <PopupWidget /> */}

      </div>
    </>

  );
};

export default VehicleManagement;
