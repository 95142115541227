import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Typography, useMediaQuery } from "@mui/material";
import Testimonials from "./Testimonials";
import captionLink from "../../assets/img/captionlink.png";
import testBg from "../../assets/img/testbg.png";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import axios from "axios";

export default function TestimonialSlider({ latestDeals }) {
  const [testimonial, setTestimonial] = useState();
  const SampleNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="control-btn" onClick={onClick}>
        <button className="next" style={{ right: "5px" }}>
          <ChevronRightIcon
            sx={{ fontSize: { xs: 25, md: 50 }, color: "white" }}
          />
        </button>
      </div>
    );
  };
  const SamplePrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="control-btn" onClick={onClick}>
        <button className="prev" style={{ left: "5px" }}>
          <ChevronLeftIcon
            sx={{ fontSize: { xs: 25, md: 50 }, color: "white" }}
          />
        </button>
      </div>
    );
  };
  //   const matches = useMediaQuery("(max-width:750px)");
  const settings = {
    dots: false,
    infinite: true,
    centerMode: true,
    centerPadding: "10px",
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    // variableWidth: matches,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1324,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
        },
      },
    ],
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/testimonial/public/all`)
      .then((res) => {
        setTestimonial(res.data.data.testimonials);
        console.log(res.data.data.testimonials);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    !!testimonial?.length && (
      <Box
        sx={{
          padding: { xs: "10px", md: "50px" },
          // backgroundImage: `url(${captionLink})`,
          // backgroundPosition: "50% 50%",
          // backgroundSize: "cover",
          backgroundRepeat: "repeat",

          backgroundImage: `linear-gradient(180deg, #151137 0%, #151137 96.87%),url(${captionLink})`,
          backgroundBlendMode: "hard-light",
        }}
      >
        <Typography variant="h4" className="text-white font-bold text-center">
          Reviews From Best Clients
        </Typography>
        <Slider {...settings} adaptiveHeight={true} className="Latest_slick">
          {/* {latestDeals?.map((product, index) => {
          return <Testimonials product={product} />;
        })} */}
          {testimonial?.map((item, index) => (
            <Testimonials key={index} testimonial={item} />
          ))}
        </Slider>
      </Box>
    )
  );
}
