import { Box, Stack } from '@mui/material'
import React from 'react'
import callUsIcon from "../assets/img/services/icons/call-phone-outline.gif"
import styles from "./CallUs.module.css"
function CallUs() {
    return (
        <>
            <Stack component={"a"} href='tel:9072863636' sx={{ display: { xs: "none", md: "block" } }}>

                {/* <div className='fixed z-40 flex items-center justify-center transition duration-300 bg-[#1270c8] rounded-full shadow-lg left-3 bottom-6  w-40 h-10 focus:outline-none hover:bg-[#0057A9] focus:bg-[#0057A9] ease cursor-pointer'>
                    <Box component={"a"} href='tel:9072863636' display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent="center" gap={"10px"}>
                        <img width={"20%"} src={callUsIcon} />
                        <span>9072863636</span>
                    </Box>
                </div> */}

                <div className={styles.searchBox}>
                    <a href='tel:9072863636' className={styles.searchBtn}>
                        <img width={"70%"} src={callUsIcon} />
                    </a>
                    <input className={styles.searchText} value={"+91 9072863636"} placeholder="Search Anything" />
                </div>
            </Stack>

            <Stack sx={{ display: { xs: "block", md: "none" } }}>
                <div className='fixed z-40 flex items-center justify-center transition duration-300 bg-[#1270c8] rounded-full shadow-lg left-3 bottom-3  w-14 h-14 focus:outline-none hover:bg-[#0057A9] focus:bg-[#0057A9] ease cursor-pointer'>
                    <Box component={"a"} href='tel:9072863636' display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent="center" gap={"10px"}>
                        <img width={"50%"} src={callUsIcon} />
                    </Box>
                </div>
            </Stack>
        </>
    )
}

export default CallUs