import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import coding from "../../assets/img/products/coding.png";
import { Fade } from "react-awesome-reveal";

const Detailed1 = () => {
  return (
    <div>
      <div style={{ background: "#F9F9F9" }}>
        {/* <Fade triggerOnce="true" direction="up"> */}
          <Typography
            textAlign="center"
            variant="h6"
            fontWeight="bold"
            fontSize="32px"
            sx={{ marginTop: "50px", paddingTop: "50px" }}
          >
            Features of Enterprise Resource Planning
          </Typography>
          <Container maxWidth="xl" sx={{ marginTop: { xs: 1, md: 2 } }}>
            <Grid container spacing={3}>
              <Grid item md={6} sm={6}>
                <Card sx={{ boxShadow: "none", background: "#F9F9F9" }}>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      fontSize="24px"
                    >
                      Employee Management
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ color: "#000000" }}
                    >
                      Lorem ipsum dolor sit amet,consecteturadiiscing elit.Lorem
                      ipsum dolor sit amet, consectetur adipiscing elit.
                      Sagittis id mi leo libero et diam nibh at amet. Quis urna,
                      consectetur et cras.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={6} sm={6}>
                <Card sx={{ boxShadow: "none", background: "#F9F9F9" }}>
                  <CardContent>
                    {/* <img src="right-arrow 1.png" alt='/' /> */}
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      fontSize="24px"
                    >
                      Leave Management
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ color: "#000000" }}
                    >
                      Lorem ipsum dolor sit amet,consecteturadiiscing elit.Lorem
                      ipsum dolor sit amet, consectetur adipiscing elit.
                      Sagittis id mi leo libero et diam nibh at amet. Quis urna,
                      consectetur et cras.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={6} sm={6}>
                <Card sx={{ boxShadow: "none", background: "#F9F9F9" }}>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      fontSize="24px"
                    >
                      Shift Management
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ color: "#000000" }}
                    >
                      Lorem ipsum dolor sit amet,consecteturadiiscing elit.Lorem
                      ipsum dolor sit amet, consectetur adipiscing elit.
                      Sagittis id mi leo libero et diam nibh at amet. Quis urna,
                      consectetur et cras.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={6} sm={6}>
                <Card sx={{ boxShadow: "none", background: "#F9F9F9" }}>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      fontSize="24px"
                    >
                      Overtime & Deductions
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ color: "#000000" }}
                    >
                      Lorem ipsum dolor sit amet,consecteturadiiscing elit.Lorem
                      ipsum dolor sit amet, consectetur adipiscing elit.
                      Sagittis id mi leo libero et diam nibh at amet. Quis urna,
                      consectetur et cras.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={6} sm={6}>
                <Card sx={{ boxShadow: "none", background: "#F9F9F9" }}>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      fontSize="24px"
                    >
                      Work Schedule Management
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ color: "#000000" }}
                    >
                      Lorem ipsum dolor sit amet,consecteturadiiscing elit.Lorem
                      ipsum dolor sit amet, consectetur adipiscing elit.
                      Sagittis id mi leo libero et diam nibh at amet. Quis urna,
                      consectetur et cras.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={6} sm={6}>
                <Card sx={{ boxShadow: "none", background: "#F9F9F9" }}>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      fontSize="24px"
                    >
                      Vacations and Holidays
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ color: "#000000" }}
                    >
                      Lorem ipsum dolor sit amet,consecteturadiiscing elit.Lorem
                      ipsum dolor sit amet, consectetur adipiscing elit.
                      Sagittis id mi leo libero et diam nibh at amet. Quis urna,
                      consectetur et cras.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        {/* </Fade> */}
      </div>
      <Container maxWidth="xl" sx={{ marginTop: "50px" }}>
        {/* <Fade triggerOnce="true" direction="up"> */}
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                display: { xs: "none", sm: "flex", md: "flex" },
              }}
            >
              <img src={coding} alt="banner" />

              <Box
                sx={{
                  flex: 1,
                  padding: "50px",
                  marginTop: { xs: "0px", md: "40px" },
                }}
              >
                <Box sx={{ flexDirection: "column" }}>
                  <Typography
                    variant="h2"
                    fontWeight="bold"
                    textAlign="initial"
                    lineHeight="60px"
                    sx={{ fontSize: { xs: 32, md: 34 }, marginTop: "-89px" }}
                  >
                    Lorem ipsum dolor sit, consectetur <br />
                    adipiscing ut aliquam,
                  </Typography>

                  <Typography
                    variant="h4"
                    fontSize="20px"
                    fontWeight="medium"
                    marginTop="10px"
                    textAlign="justify"
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Sagittis id mi leo libero et diam nibh at amet. Quis urna,
                    consectetur et cras. Nisi velit dolor congue fringilla quam.
                    Nisl tristique tellus massa vivamus purus suspendisse. Vel,
                    faucibus augue lectus aliquam mattis eros,
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        {/* </Fade> */}
      </Container>
    </div>
  );
};

export default Detailed1;
