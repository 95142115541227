import React from "react";
import ContactForm from "../Contact/ContactForm";
import indoor from "../../assets/img/indoor.png";
const GymContact = () => {
  return (
    <div
    // style={{
    //   background: `url(${indoor})`,
    //   backgroundBlendMode: "overlay",
    // }}
    >
      <ContactForm title="Get a quote" />
    </div>
  );
};

export default GymContact;
