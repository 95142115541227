import React from "react";
import { Box, Button, Typography, Stack } from "@mui/material";
import { Container } from "@mui/system";
import services1 from "../assets/img/products/services1.png";
import { useStyles } from "../styles";

const ServicesDescription = () => {
  const classes = useStyles();
  return (
    <>
      <div className="container mx-auto px-4 xl:px-10 sm:flex justify-between items-stretch">
        <div className="py-10">
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Stack
              direction={{ xs: "column", md: "row" }}
              sx={{
                flex: 1,

                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Stack sx={{ flexDirection: "column", flex: 2 }} spacing={{ xs: 4, md: 2 }}>
                <Typography
                  className={classes.mainHeader}
                  sx={{
                    textAlign: { xs: "center", md: "initial" },
                  }}
                >
                  What We Have Here For You
                </Typography>
                <Box
                  sx={{
                    display: { xs: "block", md: "none" },
                    flex: 1,
                  }}
                >
                  <img src={services1} alt="banner" width="100%" />
                </Box>
                <Typography
                  className={classes.boldMainContent}
                >
                  We believed in creating best website for our client’s , to
                  ensure that , their needs full filled with our design and
                  development. We create the best website and related services
                  for our client’s. We listen, research and plan together with
                  our clients to reach our goals, whether it be developing a
                  website, a software or a product.
                </Typography>
              </Stack>

              <Box
                sx={{
                  display: { xs: "none", md: "block" },
                  marginTop: { xs: "0px", md: "40px" },
                  flex: 1,
                }}
              >
                <img src={services1} alt="banner" width="100%" />
              </Box>
            </Stack>
          </Box>
        </div>
      </div>
    </>
  );
};

export default ServicesDescription;
