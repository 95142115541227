import Container from "./Container";
// import imageHero from "../assets/img/imageHero.png";
// import phone from "../assets/img/products/phone.png";
import time from "../assets/img/products/time.png";
import Frame from "../assets/img/products/Frame.png";
import {
    Box,
    Card,
    Grid,
    Paper,
    Typography,
    IconButton,
    Stack,
} from "@mui/material";
import shapesVector from "../assets/img/products/framestack.png";
import { useStyles } from "../styles";

export default function StackarchCards() {
    const classes = useStyles();
    return (
        <div style={{ backgroundImage: `url(${shapesVector})` }}>
            <Container maxWidth="xl" >
                <Stack spacing={8} py={5}>

                    <Stack
                        sx={{
                            flexDirection: { xs: "column", md: "row" },
                            justifyContent: "space-between",
                        }}
                    >
                        <div className="flex flex-1 items-center w-full lg:w-1/2">
                            <div className="max-w-2xl mb-8">
                                <Typography
                                    className={classes.mainHeader}
                                    sx={{
                                    }}
                                >
                                    The Ultimate Project Management ERP Software for Architects, architectural firms & Construction firms.
                                </Typography>

                                <Typography
                                    className={classes.mainContent}
                                    sx={{
                                        marginTop: "20px",
                                    }}
                                >
                                    StackArch provides architectural firms with a comprehensive project management solution. It provides a robust set of features. Architects can easily create projects, define project timelines, and allocate resources effectively. The software enables seamless collaboration among team members and facilitates efficient project execution, ensuring that deadlines are met and milestones are achieved.
                                </Typography>
                                <Typography
                                    className={classes.mainContent}
                                    marginTop="20px"
                                >
                                    StackArch has an easily controllable dashboard to handle the project management very accurately. From the dashboard itself admin can manage the team productivity analysis, analytical project management and forecasting the project timeline.
                                </Typography>
                            </div>
                        </div>
                        <div className="flex flex-1 items-center justify-center w-full lg:w-1/2 mb-5">
                            <div className="">
                                <img
                                    src={Frame}
                                    width="616"
                                    height="617"
                                    alt="Hero Illustration"
                                    layout="intrinsic"
                                    loading="eager"
                                    placeholder="blur"
                                />
                            </div>
                        </div>
                    </Stack>
                    <Stack
                        sx={{
                            flexDirection: { xs: "column-reverse", md: "row" },
                            justifyContent: "space-between",
                        }}
                    >
                        <div className="flex flex-1 items-center justify-center w-full lg:w-1/2">
                            <div className="">
                                <img
                                    src={time}
                                    width="616"
                                    height="617"
                                    alt="Hero Illustration"
                                    layout="intrinsic"
                                    loading="eager"
                                    placeholder="blur"
                                />
                            </div>
                        </div>
                        <div className="flex flex-1 items-center w-full lg:w-1/2">
                            <div className="max-w-2xl mb-8">
                                <Typography className={classes.mainHeader}
                                >
                                    Enhance Your Project Management with StackArch
                                </Typography>

                                <Typography
                                    className={classes.mainContent}
                                    sx={{
                                        marginTop: "20px",
                                    }}
                                >
                                    StackArch analytical reporting gives a clarity in project detailing and stages are completed. StackArch Architectural ERP integrates all architectural based functions into a single integrated software program that runs on a single database so that various departments and areas can easily share information and communicate with each other. The integrated approach of StackArch Architectural ERP has tremendous power and potential in improving the efficiency, productivity, and competitiveness of the organisation.
                                </Typography>
                            </div>
                        </div>
                    </Stack>
                </Stack>
            </Container>
        </div>
    )
}
