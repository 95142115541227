import React from "react";

export default function Container(props) {
  return (
    <div
      className={`container p-4 mx-auto xl:px-12 ${props.className ? props.className : ""
        }`}
    >
      {props.children}
    </div>
  );
}
