import React from 'react'
import { useStyles } from '../../styles';
import { Box, Button, Typography, Stack, Grid, Card, CardContent, IconButton } from "@mui/material";
import { Container } from "@mui/system";
import entrprise12 from "../../assets/img/services/entrprise12.png";
import Techstack from "../Techstack";
import enterprisebg from "../../assets/img/services/enterprisebg.png";
import adaptivebg from "../../assets/img/services/adaptivebg.png";


import react from "../../assets/img/services/logo/reactlogo.png";
import angular from "../../assets/img/services/logo/angularLogo.png";
import node from "../../assets/img/services/logo/nodelogo.png";
import mongo from "../../assets/img/services/logo/mongoLogo.png";
import mui from "../../assets/img/services/logo/muilogo.png";
import express from "../../assets/img/services/logo/expressLogo.png";
import staffAugBanner from "../../assets/img/services/staffAugBanner.png";
import planning from "../../assets/img/services/planning.png";
import staffQuality from "../../assets/img/services/staffQuality.png";
import staffConsulting from "../../assets/img/services/staffConsulting.png";

import radioButton from "../../assets/img/services/radioButton.png";

import fintech from "../../assets/img/services/fintech.png";
import ecom from "../../assets/img/services/ecom.png";
import education from "../../assets/img/services/education.png";
import health from "../../assets/img/services/health.png";

import multimedia from "../../assets/img/services/multimedia.png";

import apple from "../../assets/img/services/apple.png";
import android from "../../assets/img/services/android.png";
import cross from "../../assets/img/services/cross.png";
import watch from "../../assets/img/services/watch.png";


import mean from "../../assets/img/services/mean.png";  // mean stack
import mern from "../../assets/img/services/mern.png";  // mern stack
import blockchain from "../../assets/img/services/blockchain.png";  // blockChain
import dotnet from "../../assets/img/services/dotnet.png";  // dotnet
import php from "../../assets/img/services/php.png";  // php
import meta from "../../assets/img/services/meta.png";  // meta
import devops from "../../assets/img/services/devops.png";  // devops
import uiuxDev from "../../assets/img/services/uiuxDev.png";  // uiuxDev
import appDeveloper from "../../assets/img/services/appdeveloper.png";  // appDeveloper


import Marquee from "react-fast-marquee";

function Augmentation() {
    const classes = useStyles();
    const icons = [
        {
            img: react
        },
        {
            img: angular
        },
        {
            img: node
        },
        {
            img: mongo
        },
        {
            img: mui
        },
        {
            img: express
        },
    ]
    const industries = [
        {
            img: fintech,
            title: "Fintech",
            content: "We customize Mobile apps for managing finances, investments, and stock trading"
        },
        {
            img: ecom,
            title: "E-commerce",
            content: "Ecommerce Mobile apps makes your online shopping, payment processing, and order tracking easier."
        },
        {
            img: education,
            title: "Education",
            content: "Develop mobile apps that offer e-learning solutions, online courses, and other educational materials."
        },
        {
            img: health,
            title: "Health",
            content: "Develop mobile apps that track physical activity, monitor nutrition, and provide personalized workout plans."
        },
        {
            img: fintech,
            title: "Food & Beverage",
            content: "Mobile apps for ordering food, making reservations, and leaving reviews"
        },
        {
            img: ecom,
            title: "Travel",
            content: "We Create mobile apps that provide users with information about hotels, flights, car rentals, and other travel-related services."
        },
        {
            img: education,
            title: "Real Estate",
            content: "Create mobile apps that enable users to search for properties, schedule visits, and make offers.  "
        },
        {
            img: health,
            title: "Social Networking",
            content: "Create mobile apps that enable users to connect and interact with each other through social media platforms. "
        },


    ]
    const platforms = [
        {
            img: apple,
            title: "Ios",
            content: "Create mobile apps for devices with the iOS operating system: iPhones, iPads, Apple TVs, and App Clips."
        },
        {
            img: android,
            title: "Android",
            content: "Create mobile apps for devices with the iOS operating system: iPhones, iPads, Apple TVs, and App Clips."
        },
        {
            img: cross,
            title: "Cross Platform",
            content: "Create mobile apps for devices with the iOS operating system: iPhones, iPads, Apple TVs, and App Clips."
        },
        {
            img: watch,
            title: "Wearable Devices",
            content: "Create mobile apps for devices with the iOS operating system: iPhones, iPads, Apple TVs, and App Clips."
        },

    ];

    const staffs = [
        {
            img: mean,
            title: "MEAN STACK DEVELOPER ",
            content: "We are an experienced team of MEAN stack developers, who have excellent problem-solving skills, attention to detail, and the ability to work collaboratively in a team environment. We are familiar with the latest web development trends and best practices.",
        },
        {
            img: mern,
            title: "MERN STACK DEVELOPER",
            content: "Stackroots provides staff augmentation services to businesses looking to hire MERN stack developers. Our MERN stack developers have expertise in developing full-stack web applications that are highly scalable, secure, and performant.",
        },
        {
            img: blockchain,
            title: "BLOCK CHAIN DEVELOPER",
            content: "Stackroots provides businesses with top-quality blockchain developers who can help build decentralized applications that leverage the benefits of blockchain technology, such as security, transparency, and immutability.",
        },
        {
            img: dotnet,
            title: ".NET DEVELOPER",
            content: "We are experienced team of . NET Developers  who can help build software applications that meet clients unique requirements and deliver exceptional user experiences. Our developers are experts in their field and bring a wealth of technical expertise and experience to every project they work on.",
        },
        {
            img: php,
            title: "PHP LARAVEL DEVELOPER",
            content: " At Stackroots, our PHP Laravel developers have expertise in developing web applications using the Laravel framework. They possess strong knowledge in PHP, the programming language used to write applications on the Laravel platform.",
        },
        {
            img: appDeveloper,
            title: "MOBILE APP DEVELOPER",
            content: "Stackroots provides staff augmentation services to businesses looking to hire mobile app developers. Our mobile app developers have expertise in developing mobile applications using various mobile app development technologies.",
        },
        {
            img: uiuxDev,
            title: "UI/UX DEVELOPER",
            content: "We are an experienced team of UI/UX developers. Our developers have expertise in designing and developing user interfaces and experiences that are visually appealing, user-friendly, and intuitive. They possess strong knowledge in design principles, user behavior, web and mobile development technologies.",
        },
        {
            img: meta,
            title: "METAVERSE DEVELOPER ",
            content: "Stackrooots provides satffaugmentation services to businesses looking to hire a metaverse developers who can help build immersive virtual experiences for their customers. They stay up-to-date with the latest trends and developments in the metaverse space.",
        },
        {
            img: devops,
            title: "DEVOPS ENGINEER",
            content: "We are an experienced team of Devops engineers, who have excellent problem-solving skills, attention to detail, and the ability to work collaboratively in a team environment. We are familiar with the latest web development trends and best practices.",
        },

    ]

    return (
        <div style={{ background: "#131331" }}>
            <div
                className="adaptivebg"
                style={{
                    backgroundImage: `url(${adaptivebg})`,
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        // height: "100vh",
                        display: "flex",
                        flexWrap: "wrap",
                        p: { xs: 3, md: 6 },
                    }}
                >
                    <Stack
                        direction={{ xs: "column", md: "row" }}
                        sx={{
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                        mt={{ xs: 5, md: 0 }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                flex: 1,
                            }}
                        >
                            <Typography
                                className={classes.mainHeader}
                                textAlign={{ xs: "center", md: "initial" }}
                                mb={3}
                            >
                                Staff augmentation
                            </Typography>
                            <Box
                                sx={{
                                    marginTop: { xs: "0px", md: "40px" },
                                    flex: 1,
                                    display: { xs: "flex", md: "none" },
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Box component="img" width={{ sm: "70%" }} src={staffAugBanner} alt="banner" />
                            </Box>
                            <Typography
                                marginTop="10px"
                                className={classes.mainContent}
                            >
                                Staff augmentation is a popular solution for businesses looking to scale their workforce quickly and efficiently. Stackroots, provides top-notch staff augmentation services to help businesses augment their teams with experienced professionals who can seamlessly integrate into their existing workflows and deliver high-quality results, ensuring projects are completed on time and and within budget.
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                marginTop: { xs: "0px", md: "0px" },
                                flex: 1,
                                display: { xs: "none", md: "flex" },
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <img src={staffAugBanner} width="70%" alt="banner" />
                        </Box>
                    </Stack>
                </Box>

                <Stack
                    className="adaptivebg"
                    sx={{
                        backgroundImage: `url(${planning})`,
                        backgroundSize: { xs: "cover", md: "contain" }
                    }}
                >
                    <Stack sx={{ marginTop: { xs: 0, md: "60px" }, p: { xs: 2, md: 6 } }} alignItems="center" justifyContent="center">
                        <Typography
                            textAlign={"center"}
                            maxWidth={"1000px"}
                            className={classes.mainHeader}
                            mb={4}
                            sx={{
                                marginTop: { xs: "20px", md: 0, sm: 0 },
                            }}
                        >
                            On-demand & Quality-Driven Tech Team
                        </Typography>
                        <Grid container rowGap={5} align={"center"} mt={2} columnSpacing={4} px={{ xs: 0, sm: 3 }}>
                            {
                                staffs.map((item, index) => (
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={4} >
                                        <Stack sx={{ "width": { xs: "auto", lg: "400px" }, "height": "100%", "background": "#FFFFFF", "borderRadius": "12px" }} spacing={1} p={2} alignItems={"center"}>
                                            <Box component={"img"} src={item.img} height={"80px"} />
                                            <Typography sx={{ "fontStyle": "normal", "fontWeight": "700", "fontSize": "20px", "lineHeight": "35px", "textAlign": "center", "textTransform": "uppercase", "color": "#000000" }} textAlign={"center"} mt={2}>Hire a {item.title}</Typography>
                                            <Typography textAlign={"left"} color={"#000"}>{item.content}</Typography>
                                        </Stack>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Stack>
                </Stack>

                <Stack mt={{ xs: 3, sm: 5 }}>
                    <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column-reverse", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>

                        <Stack maxWidth={"485px"} spacing={3} alignItems={{ xs: "center", md: "flex-start" }}>
                            <Typography className={classes.mainHeader}>
                                Leverage Stackroots staff augmentation services

                            </Typography>
                            <Box component={"img"} src={staffConsulting} sx={{ m: "0 auto" }} width={{ xs: "70%", sm: "50%", md: "40%" }} display={{ xs: "flex", md: "none" }} />
                            <Typography className={classes.mainContent}>
                                One of the key advantages of working with Stackroots is our flexible staffing model. We understand that every business has unique needs and requirements, which is why we offer a range of engagement options to meet your specific needs. Whether you need to augment your staff for a short-term project or for a longer-term engagement, we can provide the right professionals to help you achieve your goals.
                            </Typography>
                        </Stack>
                        <Box component={"img"} src={staffConsulting} width={{ xs: "70%", sm: "50%", md: "40%" }} display={{ xs: "none", md: "flex" }} />


                    </Stack>
                </Stack>
            </div>


            <Box sx={{ marginTop: "50px", display: { xs: "none", md: "block", sm: "block" } }}>
                <Techstack />
            </Box>

            <Box p={3} sx={{ marginTop: "50px", display: { xs: "block", md: "none", sm: "none" } }} >
                <Typography
                    sx={
                        { "fontStyle": "normal", "fontWeight": "600", "fontSize": "24px", "lineHeight": "29px", "color": "#FFFFFF", textAlign: "center" }
                    }
                >
                    Expertise And Technical Skills
                </Typography>
                <Stack>
                    <Marquee gradient={false}>
                        {icons.map((icon) => (
                            <Box component={"img"}
                                sx={{
                                    mt: "25px",
                                    width: "105px"
                                }}
                                src={icon.img}
                                alt={"tech stack"}
                            />
                        ))
                        }
                    </Marquee>

                    <Marquee gradient={false} direction={"right"}>
                        {icons.map((icon) => (
                            <Box component={"img"}
                                sx={{
                                    mt: "25px",
                                    width: "105px"
                                }}
                                src={icon.img}
                                alt={"tech stack"}
                            />
                        ))
                        }
                    </Marquee>
                </Stack>
            </Box>
        </div>
    )
}

export default Augmentation