import React from 'react'
import Branding1 from '../../components/Branding/Branding1'
import ContactForm from '../../components/Contact/ContactForm'
import crmForm from "../../assets/img/services/crmForm.png"
import { Helmet } from 'react-helmet'

const Branding = () => {
  return (
    <>
      <Helmet>
        <title>Stackroots | Branding</title>
      </Helmet>
      <div>
        <Branding1 />
        <ContactForm title="Get a quote" image={crmForm} />
      </div>
    </>
  )
}

export default Branding