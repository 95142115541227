import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(theme => ({
    mainHeader: {
        [theme.breakpoints.up("xs")]: {
            color: "white",
            fontSize: 28,
            fontWeight: "bold",
            lineHeight: "38px"
        },
        [theme.breakpoints.up("sm")]: {
            color: "white",
            fontSize: 33,
            fontWeight: "bold",
            lineHeight: "38px"
        },
        [theme.breakpoints.up("md")]: {
            color: "white",
            fontSize: 38,
            fontWeight: "bold",
            lineHeight: "48px"
        },
        // "@media (min-width: 1280px)": {  
        //   marginTop: theme.spacing(5),
        //   backgroundColor: "red"
        // }
    },
    subMainHeader: {
        [theme.breakpoints.up("xs")]: {

            fontSize: 25,
            fontWeight: "600",
            lineHeight: "32px"
        },
        [theme.breakpoints.up("sm")]: {

            fontSize: 30,
            fontWeight: "700",
            lineHeight: "35px"
        },
        [theme.breakpoints.up("md")]: {

            fontSize: 30,
            fontWeight: "700",
            lineHeight: "35px"
        },
    },
    mainContent: {
        [theme.breakpoints.up("xs")]: {
            color: "white",
            fontSize: 17,
            fontWeight: "300",
            lineHeight: "29px"
        },
        [theme.breakpoints.up("sm")]: {
            color: "white",
            fontSize: 18,
            fontWeight: "300",
            lineHeight: "29px"
        },
        [theme.breakpoints.up("md")]: {
            color: "white",
            fontSize: 18,
            fontWeight: "300",
            lineHeight: "30px"
        },
    },
    boldMainContent: {
        [theme.breakpoints.up("xs")]: {
            color: "white",
            fontSize: 17,
            fontWeight: "300",
            lineHeight: "29px"
        },
        [theme.breakpoints.up("sm")]: {
            color: "white",
            fontSize: 18,
            fontWeight: "300",
            lineHeight: "29px"
        },
        [theme.breakpoints.up("md")]: {
            color: "white",
            fontSize: 18,
            fontWeight: "300",
            lineHeight: "30px"
        },
    },


}));