import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Typography,
  TextField,
} from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "../../components/DropUs.css";
import axios from "axios";
import imgBan from "../../assets/img/services/adaptivecontactbg.png";
import { Fade } from "react-awesome-reveal";

import { LoadingButton } from "@mui/lab";
import { CustomLoadingButton } from "../../utils/Button";

const ContactForm = ({ title, image }) => {
  const [category, setCategory] = useState();
  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/category/contactus/user`)
      .then((res) => {
        setCategory(res.data.data.categories);
      })
      .catch((err) => console.log(err));
  };
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitSuccessful, isSubmitting },
  } = useForm({
    mode: "onTouched",
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [Message, setMessage] = useState("");

  const userName = useWatch({ control, name: "name", defaultValue: "Someone" });

  const onSubmit = async (data, e) => {
    console.log(data)
    await axios
      .post(`${process.env.REACT_APP_API_URL}/mail/contact`, data)
      .then(async (response) => {
        if (!response.data.isError) {
          setIsSuccess(true);
          setMessage(response.data.message);
          // e.target.reset();
          // reset();
        } else {
          setIsSuccess(false);
          setMessage(response.data.message);
        }
      })
      .catch((error) => {
        setIsSuccess(false);
        setMessage("Client Error. Please check the console.log for more info");
        console.log(error);
      });
  };

  const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: "#ffff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#ffff",
      borderWidth: "0.5px",
      borderRadius: "5px",
      boxShadow: "0px 0px 10.798px 6.64491px rgba(0, 0, 0, 0.35)",
    },
    "& .MuiInputBase-input": {
      color: "#ffff",
      borderWidth: "0.5px",
      borderRadius: "5px",
      boxShadow: "0px 0px 10.798px 6.64491px rgba(0, 0, 0, 0.35)",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ffff",
        borderWidth: "0.5px",
        borderRadius: "5px",
        boxShadow: "0px 0px 10.798px 6.64491px rgba(0, 0, 0, 0.35)",
      },
      "&:hover fieldset": {
        borderColor: "#ffff",
        borderWidth: "0.5px",
        borderRadius: "5px",
        boxShadow: "0px 0px 10.798px 6.64491px rgba(0, 0, 0, 0.35)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#ffff",
        borderWidth: "0.5px",
        borderRadius: "5px",
        boxShadow: "0px 0px 10.798px 6.64491px rgba(0, 0, 0, 0.35)",
      },
    },
  });

  const CssSelect = styled(Select)({
    "#demo-simple-select": {
      border: "1px solid white",
      color: "white",
    },
    ".MuiFormLabel-root .MuiInputLabel-root .Mui-focused": {
      color: "#ffff",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "& .MuiInputBase-root .MuiOutlinedInput-root .MuiSelect-root": {
      border: "1px solid white",
    },
    ".MuiSvgIcon-root": {
      color: "#fff",
    },
    "&:before": {
      borderBottom: `1px solid light}`,
    },
    "&:hover": {
      ":before": {
        borderBottom: `1px solid dark}`,
      },
    },
    "& .MuiMenuItem-root": {
      backgroundColor: "dark.primary",
    },
    "& .MuiMenu-paper": {
      backgroundColor: "dark.primary",
    },
  });

  return (
    <Stack
      // className="drop"
      sx={{
        pt: "50px",
        background: {
          xs: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url(${image ? image : imgBan})`,
          md: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${imgBan})`
        },
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: { xs: "cover", md: "cover" },
      }}
    >
      <Container maxWidth="xl">
        <Box sx={{ padding: { xs: 2, md: 5 } }}>
          <Fade triggerOnce="true" direction="up">
            <Typography variant="h4" textAlign="center"
              sx={
                {
                  "fontFamily": "'Sansation', sans-serif",
                  "fontStyle": "normal", "fontWeight": "700", "fontSize": "34px", "lineHeight": "123.91%", "textAlign": "center", "letterSpacing": "0.1em"
                }
              }>
              {title ? title : "Drop us a line"}
            </Typography>
            <Typography
              variant="h6"
              textAlign="center"
              fontSize={{ xs: "16px", md: "20px" }}
              sx={{
                fontWeight: { xs: 400, md: 500 },
                mt: "10px"
              }} >
              Tell us a bit more about your project
            </Typography>
            <Typography
              variant="h6"
              textAlign="center"
              fontSize={{ xs: "16px", md: "20px" }}
              sx={{
                fontWeight: { xs: 400, md: 500 }
              }}
            >
              Post your requirements here and we will contact you Shortly
            </Typography>{" "}
          </Fade>
          {!isSubmitSuccessful && (
            <Box sx={{ marginTop: "50px" }}>
              <form onSubmit={handleSubmit(onSubmit)} >
                <Grid container spacing={4}>
                  <Grid item md={6} sm={6} xs={12}>
                    <Fade triggerOnce="true" direction="up">
                      <CssTextField
                        focused
                        placeholder="Enter your fullname"
                        label="Fullname"
                        fullWidth
                        {...register("fullname", {
                          required: "Full name is required",
                          maxLength: 80,
                        })}
                      />
                      {errors.fullname && (
                        <div className="mt-1 text-sm text-red-400 invalid-feedback">
                          {errors.fullname.message}
                        </div>
                      )}
                    </Fade>
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <Fade triggerOnce="true" direction="up">
                      <CssTextField
                        focused
                        label="Email"
                        placeholder="Enter your email"
                        fullWidth
                        {...register("email", {
                          required: "Enter your email",
                          pattern: {
                            value: /^\S+@\S+$/i,
                            message: "Please enter a valid email",
                          },
                        })}
                      />
                      {errors.email && (
                        <div className="mt-1 text-sm text-red-400 invalid-feedback">
                          {errors.email.message}
                        </div>
                      )}
                    </Fade>
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <Fade triggerOnce="true" direction="up">
                      <CssTextField
                        focused
                        label="Phone"
                        placeholder="Enter phone number"
                        fullWidth
                        {...register("phone", {
                          required: "Enter your number",
                          pattern: {
                            message: "Please enter a valid number",
                          },
                        })}
                      />
                      {errors.phone && (
                        <div className="mt-1 text-sm text-red-400 invalid-feedback">
                          {errors.phone.message}
                        </div>
                      )}
                    </Fade>
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <Fade triggerOnce="true" direction="up">
                      <CssTextField
                        focused
                        label="Location"
                        placeholder="Enter Location"
                        fullWidth
                        {...register("location", {
                          required: "Location is required",
                          maxLength: 80,
                        })}
                      />
                      {errors.location && (
                        <div className="mt-1 text-sm text-red-400 invalid-feedback">
                          {errors.location.message}
                        </div>
                      )}
                    </Fade>
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <Fade triggerOnce="true" direction="up">
                      <FormControl fullWidth focused>
                        <InputLabel id="demo-simple-select-label">
                          Looking for
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Looking for"
                          {...register("category", {
                            required: "Selection is required",
                          })}
                          MenuProps={{
                            sx: {
                              "& .MuiPaper-root": {
                                backgroundColor: "whitesmoke",
                                color: "#131331",
                              },
                            },
                          }}
                          sx={{
                            ".MuiSvgIcon-root ": {
                              fill: "white !important",
                            },
                          }}
                        >
                          {category?.map((cat, i) => (
                            <MenuItem value={cat._id}>{cat.name}</MenuItem>
                          ))}

                        </Select>
                      </FormControl>
                      {errors.category && (
                        <div className="mt-1 text-sm text-red-400 invalid-feedback">
                          {errors.category.message}
                        </div>
                      )}
                    </Fade>
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <Fade triggerOnce="true" direction="up">
                      <FormControl fullWidth focused>
                        <InputLabel id="demo-simple-select-label">
                          Budget
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Budget"
                          {...register("budget", {
                            required: "Budget is required",
                          })}
                          MenuProps={{
                            sx: {
                              "& .MuiPaper-root": {
                                backgroundColor: "whitesmoke",
                                color: "#131331",
                              },
                            },
                          }}
                          sx={{
                            ".MuiSvgIcon-root ": {
                              fill: "white !important",
                            },
                          }}
                        >
                          <MenuItem value={"Less than $750"}>
                            Less than $750
                          </MenuItem>
                          <MenuItem value={"$750-$1000"}>$750-$1000</MenuItem>
                          <MenuItem value={"$1000-$2500"}>$1000-$2500</MenuItem>
                          <MenuItem value={"$2500-$5000"}>$2500-$5000</MenuItem>
                          <MenuItem value={"$5000+"}>$5000+</MenuItem>
                        </Select>
                      </FormControl>
                      {errors.budget && (
                        <div className="mt-1 text-sm text-red-400 invalid-feedback">
                          {errors.budget.message}
                        </div>
                      )}
                    </Fade>
                  </Grid>

                  {/* </RadioGroup> */}
                  <Grid item md={12} sm={12} xs={12}>
                    <Fade triggerOnce="true" direction="up">
                      <CssTextField
                        onContextMenu={"return false"}
                        onPaste={(e) => e.preventDefault()}
                        focused
                        label="Describe your project"
                        placeholder="Describe "
                        fullWidth
                        multiline
                        minRows={2}
                        {...register("description", {
                          required: "Description is required",
                          maxLength: 500,
                        })}
                      />
                      {errors.description && (
                        <div className="mt-1 text-sm text-red-400 invalid-feedback">
                          {errors.description.message}
                        </div>
                      )}
                    </Fade>
                  </Grid>
                </Grid>
                <Fade triggerOnce="true" direction="up">
                  <Box sx={{ marginTop: "50px" }}>
                    <CustomLoadingButton
                      loading={isSubmitting}
                      type="submit"
                      sx={{
                        display: "flex",
                        margin: "0 auto",
                        padding: "10px 20px",
                      }}
                    >
                      Submit
                    </CustomLoadingButton>
                  </Box>
                </Fade>
              </form>
            </Box>
          )}
          {isSubmitSuccessful && isSuccess && (
            <>
              {" "}
              <Fade triggerOnce="true" direction="up">
                <div className="flex flex-col items-center justify-center h-full text-center text-white rounded-md mt-4">
                  <svg
                    width="60"
                    height="60"
                    className="text-green-300"
                    viewBox="0 0 100 100"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26.6666 50L46.6666 66.6667L73.3333 33.3333M50 96.6667C43.8716 96.6667 37.8033 95.4596 32.1414 93.1144C26.4796 90.7692 21.3351 87.3317 17.0017 82.9983C12.6683 78.6649 9.23082 73.5204 6.8856 67.8586C4.54038 62.1967 3.33331 56.1283 3.33331 50C3.33331 43.8716 4.54038 37.8033 6.8856 32.1414C9.23082 26.4796 12.6683 21.3351 17.0017 17.0017C21.3351 12.6683 26.4796 9.23084 32.1414 6.88562C37.8033 4.5404 43.8716 3.33333 50 3.33333C62.3767 3.33333 74.2466 8.24998 82.9983 17.0017C91.75 25.7534 96.6666 37.6232 96.6666 50C96.6666 62.3768 91.75 74.2466 82.9983 82.9983C74.2466 91.75 62.3767 96.6667 50 96.6667Z"
                      stroke="currentColor"
                      strokeWidth="3"
                    />
                  </svg>
                  <h3 className="py-5 text-xl text-green-500">
                    Message sent successfully
                  </h3>
                  <p className="text-gray-700 md:px-3">{Message}</p>
                  <button
                    className="mt-6 text-indigo-600 focus:outline-none"
                    onClick={() => reset()}
                  >
                    Go back
                  </button>
                </div>
              </Fade>
            </>
          )}
        </Box>
      </Container>
    </Stack>
  );
};

export default ContactForm;
