import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { useStyles } from '../../styles'

import flightVector from "../../assets/img/new/flightVector.png"
import flightBookingVector from "../../assets/img/new/flightBookingVector.png"
import radioBtn from "../../assets/img/new/radioBtn.png"
import shapesVector from "../../assets/img/products/shapesVector.png"
function Details() {
  const classes = useStyles();
  return (
    <Stack sx={{
      backgroundImage: `url(${shapesVector}) !important`,
      backgroundSize: "cover",
    }}>
      <Stack mt={{ xs: 3, sm: 5 }}>
        <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>



          <Stack maxWidth={"585px"} spacing={3}>
            <Typography className={classes.mainHeader}>
              Key Features of Flight booking management system
            </Typography>
            <Box sx={{ display: { xs: "block", md: "none", }, m: "0 auto !important", py: 2 }} component={"img"} src={flightVector} width={{ xs: "70%", sm: "50%", md: "40%" }} />
            <Typography className={classes.mainContent}>
              Stackroots  flight booking management system is designed to simplify and streamline the process of booking flights. Our software solution is specifically designed to enhance operational efficiency and improve customer satisfaction. With a wide range of comprehensive features, our flight booking management system provides
            </Typography>

            <Stack spacing={1}>
              <Stack direction={"row"} alignItems="center" spacing={2}>
                <Box component={"img"} src={radioBtn} width={"24px"} />
                <Typography className={classes.mainContent}>
                  Simplifies flight bookings
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems="center" spacing={2}>
                <Box component={"img"} src={radioBtn} width={"24px"} />
                <Typography className={classes.mainContent}>
                  •User-friendly interface
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems="center" spacing={2}>
                <Box component={"img"} src={radioBtn} width={"24px"} />
                <Typography className={classes.mainContent}>
                  Boosts customer satisfaction
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems="center" spacing={2}>
                <Box component={"img"} src={radioBtn} width={"24px"} />
                <Typography className={classes.mainContent}>
                  Real-time availability
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems="center" spacing={2}>
                <Box component={"img"} src={radioBtn} width={"24px"} />
                <Typography className={classes.mainContent}>
                  Secure payments
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Box sx={{ display: { xs: "none", md: "block" } }} component={"img"} src={flightVector} width={{ xs: "70%", sm: "50%", md: "40%" }} />
        </Stack>
      </Stack>


      <Stack mt={{ xs: 3, sm: 5 }}>
        <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>

          <Box display={{ xs: "none", md: "block" }} component={"img"} src={flightBookingVector} width={{ xs: "70%", sm: "50%", md: "35%" }} />

          <Stack maxWidth={"585px"} spacing={3}>
            <Typography className={classes.mainHeader}>
              Why choose Flight booking management system by Stackroots ?
            </Typography>
            <Box sx={{ display: { xs: "block", md: "none" }, m: "0 auto !important", py: 2 }} component={"img"} src={flightBookingVector} width={{ xs: "70%", sm: "50%", md: "40%" }} />
            <Typography className={classes.mainContent}>
              The flight booking management system by Stackroots provides a user-friendly interface that enables customers to effortlessly search for, compare, and book flights according to their preferences. From selecting travel dates and destinations to choosing different class options, the system caters to diverse customer needs. By integrating with multiple airlines and global distribution systems, the system ensures real-time availability and accurate pricing information, empowering users to make informed decisions. In addition to facilitating seamless flight bookings, the system also incorporates secure payment gateways, ensuring that customers can make online payments securely and conveniently. This feature adds an extra layer of reliability and trust to the booking process, enhancing the overall customer experience.
            </Typography>


          </Stack>

        </Stack>
      </Stack>
    </Stack>
  )
}

export default Details