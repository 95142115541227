import React from "react";
import { Helmet } from "react-helmet";
import ContactForm from "../../components/Contact/ContactForm";
import Banner6 from "../../components/FlightBooking/Banner6";
import Booking from "../../components/FlightBooking/Booking";
import Detailed from "../../components/FlightBooking/Detailed.jsx";
import PopupWidget from "../../components/popupWidget";

const FlightBooking = () => {
  return (
    <>
      <Helmet>
        <title>Stackroots | Flight Booking</title>
      </Helmet>
      <div>
        <Banner6 />
        <Detailed />
        {/* <Booking /> */}
        <ContactForm title="Get a quote" />
        {/* <PopupWidget /> */}
      </div>
    </>

  );
};

export default FlightBooking;
