import {
    Box,
    Typography
} from "@mui/material";
import React from "react";
import { useStyles } from "../../styles";
import PopUp from "../PopUp";

import hrmsBanner from "../../assets/img/services/vectors/hrmsBanner.jpg"

const Banner = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const classes = useStyles();
    return (
        <>
            <Box
                sx={{
                    // TODO:IMG
                    background: `url(${hrmsBanner})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "100%",
                    paddingBottom: { xs: "150px", md: "50px" },
                    // height: "470px",
                    height: { xs: "auto", md: "100vh" },
                    display: "flex",
                    flexWrap: "wrap",
                    // margin: { xs: "10px", md: "30px" },
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        padding: { xs: "20px", md: "50px" },
                        marginTop: { xs: "100px", md: "0px" },
                        marginLeft: { xs: "0px", md: "70px" },
                        maxWidth: "900px"
                    }}
                >
                    <Typography
                        className={classes.mainHeader}
                        sx={{

                            marginTop: { md: "100px", xs: "0px" },
                        }}
                    >
                        HRMS Solution- Streamline Your Human Resource Management with Stackroots HRMS
                    </Typography>

                    <Typography
                        className={classes.mainContent}
                        sx={{
                            marginTop: { md: "25px", xs: "10px" },
                        }}
                    >
                        Welcome to Stackroots' HRMS Application, the ultimate solution for businesses seeking to optimize their human resource management processes. In today's fast-paced business landscape, effectively managing your workforce is essential, and that's where Stackroots' HRMS stands out. Our comprehensive and cutting-edge Human Resource Management System is designed to streamline and elevate HR processes, aligning with the demands of the digital era. By embracing Stackroots HRMS, you can bid farewell to manual, time-consuming tasks and experience a new level of precision and efficiency in managing your workforce.
                    </Typography>
                    <PopUp margin={"50px"} title={"HRMS Solution"} background={"#e6f7ff"} color={"#4F46E5"} />
                </Box>
            </Box>

        </>
    );

};

export default Banner;
