import React, { useState, useEffect, useRef } from "react";

import { Disclosure, Transition } from "@headlessui/react";

import { Avatar, TextField, Stack, Box, Badge } from "@mui/material"
import moment from "moment"
import { useNavigate } from "react-router-dom";
import { ExpandMore, Cached } from "@mui/icons-material";

import chatIcon from "../assets/img/chatIcon.png"
import sendBtn from "../assets/img/sendBtn.png"
import chatBot from "../assets/img/chatBot.png"
import { styled } from '@mui/material/styles';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));


export default function PopupWidget() {
  const navigate = useNavigate();
  const [chats, setChats] = useState([]);
  const messagesEndRef = useRef(null);


  const queries = [
    {
      id: 1,
      message: "Welcome to Stackroots Technology Solutions. I am StackBot, How can I help you?",
      chips: [
        {
          name: "Products",
          path: "",
          targetId: 3
        },
        {
          name: "Services",
          path: "",
          targetId: 2
        },
        {
          name: "Contact Us",
          path: "/contact"
        }
      ]
    },
    {
      id: 2,
      message: "Which service you are interested in?",
      chips: [
        {
          name: "Website Development",
          path: "/services/adaptivewebdesign",
        },
        {
          name: "Cloud Based Web App",
          path: "/services/cloudbasedapplication",
        },
        {
          name: "UX/UI",
          path: "/services/uidesign",
        },
        {
          name: "360° Marketing",
          path: "/services/360degreemarketing",
        },
        {
          name: "CRM",
          path: "/services/crm",
        },
        {
          name: "E-Commerce",
          path: "/services/ecommerceapplication",
        },
        {
          name: "SEO",
          path: "/services/seo",
        },
        {
          name: "Boosting",
          path: "/services/websiteboosting",
        },
        {
          name: "Branding",
          path: "/services/branding",
        },
        {
          name: "Social Media Marketing",
          path: "/services/socialmediamarketing",
        },
        {
          name: "Mobile App Development",
          path: "/services/applicationdevelopment",
        },
        {
          name: "Staff augmentation",
          path: "/services/staffaugmentation",
        },
        {
          name: "VFX",
          path: "/services/vfx"
        },
      ]
    },
    {
      id: 3,
      message: "Which product you are interested in?",
      chips: [
        {
          name: "StackArch",
          path: "/products/stackarch",
        },
        {
          name: "CertVerify ",
          path: "/products/certverify",
        },
        {
          name: "Stack-Ed ",
          path: "/products/lms",
        },
        {
          name: "Gym Anytime",
          path: "/products/gymanytime",
        },
        {
          name: "Blockchain",
          path: "/products/blockchain",
        },
        {
          name: "AutoStack",
          path: "/products/autostack",
        },
        {
          name: "Flight Booking",
          path: "/products/flight",
        },
        {
          name: "Attendance Management",
          path: "/products/attendance",
        },
        {
          name: "E-Commerce",
          path: "/products/ecommerce",
        },

      ]
    },


  ];


  const appendBotMessage = (id = 1,) => {
    let query = queries.filter((q) => q.id == id)[0];
    setChats(prevChats => [...prevChats, {
      user: 0,
      date: Date.now(),
      message: query.message,
      chips: query.chips
    }]
    );
  }

  const appendUserMessage = (message = "") => {
    const newUserMessage = {
      user: 1,
      date: Date.now(),
      message: message,
    };
    setChats(prevChats => [...prevChats, newUserMessage]);
  };
  const handleSelect = (item) => {
    if (item.path !== "") {
      document.getElementById("chatclose")?.click();
      navigate(item.path);
    }
    else {
      appendUserMessage(item.name);
      appendBotMessage(item.targetId);
    }
  }

  useEffect(() => {
    appendBotMessage(1)
    return () => {
      setChats([])
    }
  }, [])

  useEffect(() => {
    setTimeout(() => messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' }), 100);
  }, [chats]);


  const handleReset = () => {
    setChats([]);
    appendBotMessage(1);
  }



  return (
    <div>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button id="chatclose" onClick={() => setTimeout(() => messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' }), 100)}
              className="fixed z-40 flex items-center justify-center transition duration-300 bg-[#1270c8] rounded-full shadow-lg right-4 bottom-20 w-14 h-14 focus:outline-none hover:bg-[#0057A9] focus:bg-[#0057A9] ease"
              style={{
                background:
                  "linear-gradient(180deg, #009ADE 0%, #0057A9 100%) !important",
              }}
            >
              <Transition
                show={!open}
                enter="transition duration-200 transform ease"
                enterFrom="opacity-0 -rotate-45 scale-75"
                leave="transition duration-100 transform ease"
                leaveTo="opacity-0 -rotate-45"
                className="absolute w-6 h-6 text-white"
              >
                <img src={chatIcon} width={"50px"} />

              </Transition>

              <Transition
                show={open}
                enter="transition duration-200 transform ease"
                enterFrom="opacity-0 rotate-45 scale-75"
                leave="transition duration-100 transform ease"
                leaveTo="opacity-0 rotate-45"
                className="absolute w-6 h-6 text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>{" "}
              </Transition>
            </Disclosure.Button>

            <Transition
              className="fixed  z-50 bottom-[80px] top-auto right-0 left-0 sm:top-auto sm:right-2 sm:left-auto mr-2   ml-2"
              enter="transition duration-200 transform ease"
              enterFrom="opacity-0 translate-y-5"
              leave="transition duration-200 transform ease"
              leaveTo="opacity-0 translate-y-5"
            >

              <Disclosure.Panel >
                <Stack sx={{
                  "maxWidth": "400px", "height": { xs: "400px", md: "400px", lg: "490px" }, "background": "#fffffc", "borderRadius": "12px", border: "1px solid black",
                }}>
                  <Stack sx={{ "maxWidth": "100%", "height": "50px !important", "background": "linear-gradient(90.45deg, #012674 4.96%, #3474FC 94.81%)", "borderRadius": "12px 12px 0px 0px" }} direction={"row"} alignItems="center" px={2} justifyContent={"space-between"}>

                    <Stack direction={"row"} alignItems="center" gap={1}>
                      <Box component="img" src={chatIcon} width={"20px"} />
                      <p style={{ "fontStyle": "normal", "fontWeight": "600", "fontSize": "12px", "lineHeight": "15px", "color": "#FFFFFF" }}>Chat with us</p>
                    </Stack>
                    <Stack direction="row" alignItems={"center"} gap={2}>

                      <Disclosure.Button >
                        <ExpandMore sx={{ color: "white" }} fontSize="medium" fontWeight={600} />
                      </Disclosure.Button>
                    </Stack>
                  </Stack>

                  <Stack p={2} sx={{
                    height: "400px",
                    overflowY: "scroll",
                    "&::-webkit-scrollbar": {
                      display: "none"
                    },
                  }}>
                    {
                      chats.map((item, index) => {
                        return (
                          item.user === 0 ? (
                            <>
                              <Stack direction={"row"} width={"100%"} alignItems="center" gap={1} mt={3}>
                                <StyledBadge
                                  overlap="circular"
                                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                  variant="dot" >
                                  <Avatar alt="Remy Sharp" src={chatBot} />
                                </StyledBadge>
                                <Stack width={"100%"} gap={1}>
                                  <Stack direction={"row"} justifyContent={"space-between"} alignItems="center">
                                    <p style={{ "fontStyle": "normal", "fontWeight": "500", "fontSize": "14px", "lineHeight": "20px", color: "black" }}>
                                      StackBot
                                    </p>
                                    <span style={{ "fontStyle": "normal", "fontWeight": "400", "fontSize": "12px", "lineHeight": "18px", color: "black" }}>
                                      {moment(item.date).calendar()}
                                    </span>
                                  </Stack>
                                  <Stack sx={{ "display": "flex", "flexDirection": "row", "alignItems": "center", "padding": "10px 14px", "gap": "8px", "width": "auto", "height": "auto", "background": "#F2F4F7", "boxShadow": "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)", "borderRadius": "0px 8px 8px 8px" }}>
                                    <p style={{ "fontWeight": "400", "fontSize": "16px", "lineHeight": "24px", "color": "#101828" }}>
                                      {item.message}
                                    </p>
                                  </Stack>
                                </Stack>
                              </Stack>

                              <Stack sx={{ ml: 6, mt: 2 }} alignItems="flex-end" justifyContent={"flex-end"} flexWrap={"wrap"} direction="row" gap={1}>
                                {item.chips.map((chip) => (
                                  <Stack onClick={() => handleSelect(chip)} component={"button"} sx={{ cursor: "pointer", "padding": "8px 12px", "gap": "8px", "width": "auto", "height": "40px", "left": "230px", "top": "174px", "border": "1px solid #2B66E3", "filter": "drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.1)) drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06))", "borderRadius": "8px 8px 0px 8px", color: "black" }}>
                                    {chip.name}
                                  </Stack>
                                ))
                                }
                              </Stack>
                            </>
                          ) : (

                            <Stack direction={"row-reverse"} width={"100%"} alignItems="center" gap={1} mt={3}>
                              <Avatar />
                              <Stack width={"auto"} gap={1}>
                                <Stack direction={"row"} justifyContent={"space-between"} alignItems="center">
                                  <p style={{ "fontStyle": "normal", "fontWeight": "500", "fontSize": "14px", "lineHeight": "20px" }}>
                                  </p>
                                  <span style={{ "fontStyle": "normal", "fontWeight": "400", "fontSize": "12px", "lineHeight": "18px" }}>
                                    {moment(item.date).calendar()}
                                  </span>
                                </Stack>
                                <Stack sx={{ "display": "flex", "flexDirection": "row", "alignItems": "center", "padding": "10px 14px", "gap": "8px", "width": "100%", "height": "auto", "background": "#F2F4F7", "boxShadow": "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)", "borderRadius": "8px 0px 8px 8px" }}>
                                  <p style={{ "fontWeight": "400", "fontSize": "16px", "lineHeight": "24px", "color": "#101828" }}>
                                    {item.message}
                                  </p>
                                  <div style={{ height: "1px" }} ref={messagesEndRef} />
                                </Stack>
                              </Stack>
                            </Stack>
                          )
                        )
                      })
                    }

                  </Stack>

                </Stack>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  );
}
