import Container from "./Container";
import easy from "../assets/img/products/easetouse.png"
import team from "../assets/img/products/team.png"
import chat from "../assets/img/products/chat1.png"
import cloud from "../assets/img/products/cloudUse.png"
import plan from "../assets/img/products/planning.png"
import manage from "../assets/img/products/management.png"
import standard from "../assets/img/products/std.png"
import low from "../assets/img/products/costlower.png"
import collab from "../assets/img/products/collabrate.png"
import homeShapes from "../assets/img/products/homeShapes.png"

import {
    Box,
    Card,
    Grid,
    Paper,
    Typography,
    IconButton,
    Stack,
} from "@mui/material";
import { useStyles } from "../styles";

export default function StackarchFeatures() {
    const classes = useStyles();
    return (
        <Stack my={5} sx={{
            background: `url(${homeShapes})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
        }}>
            <Typography
                textAlign={"center"}
                className={classes.mainHeader}
                sx={{
                    paddingTop: "30px",
                    pb: "20px"
                }}
            >
                Features of Enterprise Resource Planning
            </Typography>
            <Container maxWidth="xl" >
                <Stack spacing={{ md: "100px", xs: "50px" }} mt={2}>
                    <Stack
                        sx={{
                            flexDirection: { xs: "column-reverse", md: "row" },
                            justifyContent: "space-between",
                        }}
                    >
                        <div className="flex flex-1 items-center w-full lg:w-1/2 ">
                            <div className="max-w-2xl mb-8">
                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    mt={4}
                                    sx={{
                                        fontSize: { xs: 20, md: 32, sm: 32 },
                                        color: "#009ADF",
                                        textAlign: { md: "initial", sm: "initial", xs: "center" },
                                    }}
                                >
                                    Easy to use
                                </Typography>

                                <Typography
                                    className={classes.mainContent}
                                    sx={{
                                        marginTop: "20px",
                                        // textAlign: { md: "initial", sm: "initial", xs: "left" },
                                    }}
                                >
                                    Easy To Set Up, Easy To Customise And Easy To Scale. Help your project management teams can assist more projects faster with AI based forecasting & project management tools
                                </Typography>
                            </div>
                        </div>
                        <div className="flex flex-1 items-center justify-center w-full lg:w-1/2">
                            <div className="">
                                <img
                                    src={easy}
                                    width="250"
                                    height="50"
                                    alt="Hero Illustration"
                                    layout="intrinsic"
                                    loading="eager"
                                    placeholder="blur"
                                />
                            </div>
                        </div>
                    </Stack>
                    <Stack
                        sx={{
                            flexDirection: { xs: "column", md: "row" },
                            justifyContent: "space-between",
                        }}
                    >
                        <div className="flex items-center justify-center w-full lg:w-1/2 mb-5">
                            <div className="">
                                <img
                                    src={team}
                                    width="350"
                                    height="100"
                                    alt="Hero Illustration"
                                    layout="intrinsic"
                                    loading="eager"
                                    placeholder="blur"
                                />
                            </div>
                        </div>
                        <div className="flex items-center w-full lg:w-1/2 mt-5">
                            <div className="max-w-2xl mb-8">
                                <Typography
                                    variant="h6"
                                    // textAlign="end"
                                    fontWeight="bold"
                                    sx={{
                                        fontSize: { xs: 20, md: 32, sm: 32 },
                                        color: "#009ADF",
                                        textAlign: { md: "left", sm: "end", xs: "center" },
                                    }}
                                >
                                    Team Efficiency
                                </Typography>

                                <Typography
                                    variant="body2"
                                    // textAlign="end"
                                    // fontSize="18px"
                                    className={classes.mainContent}
                                    sx={{
                                        color: "#ffff",
                                        marginTop: "20px",
                                        textAlign: { md: "left", sm: "end", xs: "left" },
                                    }}
                                >
                                    An integrated StackArck ERP system automates project creation by automatically capturing data from the admin & user. This provides audits trails for better visibility and also helps increase efficiency and accuracy.


                                </Typography>
                            </div>
                        </div>
                    </Stack>
                    <Stack
                        sx={{
                            flexDirection: { xs: "column-reverse", md: "row" },
                            justifyContent: "space-between",
                        }}
                    >
                        <div className="flex items-center w-full lg:w-1/2">
                            <div className="max-w-2xl mb-8">
                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    mt={4}
                                    sx={{
                                        fontSize: { xs: 22, md: 32, sm: 32 },
                                        color: "#009ADF",
                                        textAlign: { md: "initial", sm: "initial", xs: "center" },
                                    }}
                                >
                                    Live Chat’s
                                </Typography>

                                <Typography
                                    className={classes.mainContent}
                                    variant="body2"
                                    // fontSize
                                    sx={{
                                        marginTop: "20px",
                                        textAlign: { md: "initial", sm: "initial", xs: "left" },
                                    }}
                                >
                                    StackArch ERP, each project can collectively ensure communication between each member in the same projects, clients have the privileges to take meetings with the project team.
                                </Typography>
                            </div>
                        </div>
                        <div className="flex items-center justify-center w-full lg:w-1/2">
                            <div className="">
                                <img
                                    src={chat}
                                    width="250"
                                    height="50"
                                    alt="Hero Illustration"
                                    layout="intrinsic"
                                    loading="eager"
                                    placeholder="blur"
                                />
                            </div>
                        </div>
                    </Stack>
                    <Stack
                        sx={{
                            flexDirection: { xs: "column", md: "row" },
                            justifyContent: "space-between",
                        }}
                    >
                        <div className="flex items-center justify-center w-full lg:w-1/2 mb-5">
                            <div className="">
                                <img
                                    src={standard}
                                    width="300"
                                    height="50"
                                    alt="Hero Illustration"
                                    layout="intrinsic"
                                    loading="eager"
                                    placeholder="blur"
                                />
                            </div>
                        </div>
                        <div className="flex items-center w-full lg:w-1/2">
                            <div className="max-w-2xl mb-8">
                                <Typography
                                    variant="h6"
                                    // textAlign="end"
                                    fontWeight="bold"
                                    sx={{
                                        fontSize: { xs: 22, md: 32, sm: 32 },
                                        color: "#009ADF",
                                        textAlign: { md: "left", sm: "end", xs: "center" },
                                    }}
                                >
                                    Standardisation
                                </Typography>

                                <Typography
                                    variant="body2"
                                    // textAlign="end"
                                    // fontSize="18px"
                                    className={classes.mainContent}
                                    sx={{
                                        color: "#ffff",
                                        marginTop: "20px",
                                        textAlign: { md: "left", sm: "end", xs: "left" },
                                    }}
                                >
                                    StackArch ERP, keep improvement of project management, operational performance, cost reduction through decreased process errors, facilitation of communication, profiting from expert knowledge form the Industry standards.


                                </Typography>
                            </div>
                        </div>
                    </Stack>
                    <Stack
                        sx={{
                            flexDirection: { xs: "column-reverse", md: "row" },
                            justifyContent: "space-between",
                        }}
                    >
                        <div className="flex items-center w-full lg:w-1/2">
                            <div className="max-w-2xl mb-8">
                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    mt={4}
                                    sx={{
                                        fontSize: { xs: 22, md: 32, sm: 32 },
                                        color: "#009ADF",
                                        textAlign: { md: "initial", sm: "initial", xs: "center" },
                                    }}
                                >
                                    Collaborate
                                </Typography>

                                <Typography
                                    className={classes.mainContent}
                                    variant="body2"
                                    // fontSize
                                    sx={{
                                        marginTop: "20px",
                                        textAlign: { md: "initial", sm: "initial", xs: "left" },
                                    }}
                                >
                                    StackArch ERP easily integrates with the third party ERP which is related to the field of financial, CRM, Human capital, Inventory modules. Each stages of development our team create easy integration modules for existing ERP for architecture institutions.
                                </Typography>
                            </div>
                        </div>
                        <div className="flex items-center justify-center w-full lg:w-1/2">
                            <div className="">
                                <img
                                    src={collab}
                                    width="300"
                                    height="100"
                                    alt="Hero Illustration"
                                    layout="intrinsic"
                                    loading="eager"
                                    placeholder="blur"
                                />
                            </div>
                        </div>
                    </Stack>
                    <Stack
                        sx={{
                            flexDirection: { xs: "column", md: "row" },
                            justifyContent: "space-between",
                        }}
                    >
                        <div className="flex items-center justify-center w-full lg:w-1/2 mb-5">
                            <div className="">
                                <img
                                    src={manage}
                                    width="300"
                                    height="100"
                                    alt="Hero Illustration"
                                    layout="intrinsic"
                                    loading="eager"
                                    placeholder="blur"
                                />
                            </div>
                        </div>
                        <div className="flex items-center w-full lg:w-1/2 mt-5">
                            <div className="max-w-2xl mb-8">
                                <Typography
                                    variant="h6"
                                    // textAlign="end"
                                    fontWeight="bold"
                                    sx={{
                                        fontSize: { xs: 22, md: 32, sm: 32 },
                                        color: "#009ADF",
                                        textAlign: { md: "start", sm: "end", xs: "center" },
                                    }}
                                >
                                    Management
                                </Typography>

                                <Typography
                                    variant="body2"
                                    // textAlign="end"
                                    // fontSize="18px"
                                    className={classes.mainContent}
                                    sx={{
                                        color: "#ffff",
                                        marginTop: "20px",
                                        textAlign: { md: "start", sm: "end", xs: "left" },
                                    }}
                                >
                                    Managing each project with less effort and providing better managerial decision making in the terms right time. Each managerial insight can keep in particular problem statements, to recover the stages of the project management system with timely delivery.
                                </Typography>
                            </div>
                        </div>
                    </Stack>
                    <Stack
                        sx={{
                            flexDirection: { xs: "column-reverse", md: "row" },
                            justifyContent: "space-between",
                        }}
                    >
                        <div className="flex items-center w-full lg:w-1/2">
                            <div className="max-w-2xl mb-8">
                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    mt={4}
                                    sx={{
                                        fontSize: { xs: 22, md: 32, sm: 32 },
                                        color: "#009ADF",
                                        textAlign: { md: "initial", sm: "initial", xs: "center" },
                                    }}
                                >
                                    On-cloud use
                                </Typography>

                                <Typography
                                    className={classes.mainContent}
                                    variant="body2"
                                    // fontSize
                                    sx={{
                                        marginTop: "20px",
                                        textAlign: { md: "initial", sm: "initial", xs: "left" },
                                    }}
                                >
                                    Now part of the technology shifting , we create the StackArch ERP with an intelligent cloud based solution, for easy use. Moving to cloud based solutions can keep your productivity increased with your team as well.
                                </Typography>
                            </div>
                        </div>
                        <div className="flex items-center justify-center w-full lg:w-1/2">
                            <div className="">
                                <img
                                    src={cloud}
                                    width="300"
                                    height="100"
                                    alt="Hero Illustration"
                                    layout="intrinsic"
                                    loading="eager"
                                    placeholder="blur"
                                />
                            </div>
                        </div>
                    </Stack>
                    <Stack
                        sx={{
                            flexDirection: { xs: "column", md: "row" },
                            justifyContent: "space-between",
                        }}
                    >
                        <div className="flex items-center justify-center w-full lg:w-1/2 mb-5">
                            <div className="">
                                <img
                                    src={plan}
                                    width="300"
                                    height="100"
                                    alt="Hero Illustration"
                                    layout="intrinsic"
                                    loading="eager"
                                    placeholder="blur"
                                />
                            </div>
                        </div>
                        <div className="flex items-center w-full lg:w-1/2 mt-5">
                            <div className="max-w-2xl mb-8">
                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    // mt={4}
                                    // textAlign="end"
                                    sx={{
                                        fontSize: { xs: 22, md: 32, sm: 32 },
                                        color: "#009ADF",
                                        textAlign: { md: "left", sm: "end", xs: "center" },
                                    }}
                                >
                                    Planning
                                </Typography>

                                <Typography
                                    variant="body2"
                                    // textAlign="end"
                                    // fontSize="18px"
                                    className={classes.mainContent}
                                    sx={{
                                        color: "#ffff",
                                        marginTop: "20px",
                                        textAlign: { md: "left", sm: "end", xs: "left" },
                                    }}
                                >
                                    Now part of the technology shifting , we create the StackArch ERP with an intelligent cloud based solution, for easy use. Moving to cloud based solutions can keep your productivity increased with your team as well.
                                </Typography>
                            </div>
                        </div>
                    </Stack>
                    <Stack
                        sx={{
                            flexDirection: { xs: "column-reverse", md: "row" },
                            justifyContent: "space-between",
                        }}
                    >
                        <div className="flex items-center w-full lg:w-1/2">
                            <div className="max-w-2xl mb-8">
                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    mt={4}
                                    sx={{
                                        fontSize: { xs: 22, md: 32, sm: 32 },
                                        color: "#009ADF",
                                        textAlign: { md: "initial", sm: "initial", xs: "center" },
                                    }}
                                >
                                    Lower cost
                                </Typography>

                                <Typography
                                    className={classes.mainContent}
                                    variant="body2"
                                    // fontSize
                                    sx={{
                                        marginTop: "20px",
                                        textAlign: { md: "initial", sm: "initial", xs: "left" },
                                    }}
                                >
                                    StackArch ERP can reduce the additional cost that acquired on a particular project during the life cycle of the project delivery. We concentrate on each project stage while consuming the least cost to timely delivery.
                                </Typography>
                            </div>
                        </div>
                        <div className="flex items-center justify-center w-full lg:w-1/2">
                            <div className="">
                                <img
                                    src={low}
                                    width="250"
                                    height="100"
                                    alt="Hero Illustration"
                                    layout="intrinsic"
                                    loading="eager"
                                    placeholder="blur"
                                />
                            </div>
                        </div>
                    </Stack>
                </Stack>
            </Container>
        </Stack>
    )
    // return (
    //   <>
    //     <Container maxWidth="xl">
    //       <Typography textAlign={{ md: "center" }}
    //         className={classes.mainHeader}
    //         // textAlign="center"
    //         // variant="h6"
    //         // fontWeight="bold"
    //         // sx={{
    //         //   paddingTop: "30px",
    //         //   color: "#ffff",
    //         //   fontSize: { xs: 20, md: 32, sm: 32 },
    //         // }}
    //         pt={5}
    //       >
    //         Features of Enterprise Resource Planning
    //       </Typography>
    //       <Stack spacing={{ md: "100px", xs: "50px" }} mt={6} pb={7}>
    //         <Stack gap={2}
    //           sx={{
    //             flexDirection: { xs: "column", md: "row" },
    //             justifyContent: "space-between",
    //           }}
    //         >
    //           <div className="flex flex-1 items-center w-full lg:w-1/2 ">
    //             <div className="max-w-2xl mb-8">
    //               <Typography
    //                 variant="h6"
    //                 fontWeight="bold"
    //                 sx={{
    //                   fontSize: { xs: 20, md: 32, sm: 32 },
    //                   color: "#009ADF",
    //                   textAlign: { md: "initial", sm: "initial", xs: "initial" },
    //                 }}
    //               >
    //                 Easy to use
    //               </Typography>

    //               <Typography
    //                 variant="body2"
    //                 mt={2}
    //                 className={classes.mainContent}
    //               >
    //                 Easy To Set Up, Easy To Customise And Easy To Scale. Help your
    //                 project management teams can assist more projects faster with AI
    //                 based forecasting & project management tools
    //               </Typography>
    //             </div>
    //           </div>
    //           <div className="flex flex-1 items-center justify-center w-full lg:w-1/2">
    //             <div className="" >
    //               <img
    //                 src={easy}
    //                 width="300"
    //                 height="50"
    //                 alt="Hero Illustration"
    //               // layout="intrinsic"
    //               // // loading="eager"
    //               // placeholder="blur"
    //               />
    //             </div>
    //           </div>
    //         </Stack>
    //         <Stack
    //           sx={{
    //             flexDirection: { xs: "column-reverse", md: "row" },
    //             justifyContent: "space-between",
    //           }}
    //         >
    //           <div className="flex items-center justify-center w-full lg:w-1/2 mb-5">
    //             <div className="">
    //               <img
    //                 src={team}
    //                 width="400"
    //                 height="100"
    //                 alt="Hero Illustration"
    //               // layout="intrinsic"
    //               // // loading="eager"
    //               // placeholder="blur"
    //               />
    //             </div>
    //           </div>
    //           <div className="flex items-center w-full lg:w-1/2 mt-5">
    //             <div className="max-w-2xl mb-8">
    //               <Typography
    //                 variant="h6"
    //                 // textAlign="end"
    //                 fontWeight="bold"
    //                 sx={{
    //                   fontSize: { xs: 20, md: 32, sm: 32 },
    //                   color: "#009ADF",
    //                   textAlign: { md: "end", sm: "end", xs: "left" },
    //                 }}
    //               >
    //                 Team Efficiency
    //               </Typography>

    //               <Typography
    //                 variant="body2"
    //                 // textAlign="end"
    //                 // fontSize="18px"
    //                 sx={{
    //                   color: "#ffff",
    //                   marginTop: "20px",
    //                   textAlign: { md: "end", sm: "end", xs: "left" },
    //                 }}
    //                 className={classes.mainContent}
    //               >
    //                 An integrated StackArck ERP system automates project creation by
    //                 automatically capturing data from the admin & user. This
    //                 provides audits trails for better visibility and also helps
    //                 increase efficiency and accuracy.
    //               </Typography>
    //             </div>
    //           </div>
    //         </Stack>
    //         <Stack
    //           sx={{
    //             flexDirection: { xs: "column", md: "row" },
    //             justifyContent: "space-between",
    //           }}
    //         >
    //           <div className="flex items-center w-full lg:w-1/2">
    //             <div className="max-w-2xl mb-8">
    //               <Typography
    //                 variant="h6"
    //                 fontWeight="bold"
    //                 sx={{
    //                   fontSize: { xs: 22, md: 32, sm: 32 },
    //                   color: "#009ADF",
    //                   textAlign: { md: "initial", sm: "initial", xs: "initial" },
    //                 }}
    //               >
    //                 Chat’s
    //               </Typography>

    //               <Typography
    //                 variant="body2"
    //                 // fontSize="18px"
    //                 mt={2}
    //                 className={classes.mainContent}
    //               >
    //                 StackArch ERP, each project can collectively ensure
    //                 communication between each member in the same projectsClients
    //                 have the privileges to take meetings with each project team.
    //               </Typography>
    //             </div>
    //           </div>
    //           <div className="flex items-center justify-center w-full lg:w-1/2">
    //             <div className="">
    //               <img
    //                 src={chat}
    //                 width="300"
    //                 height="50"
    //                 alt="Hero Illustration"
    //               // layout="intrinsic"
    //               // // loading="eager"
    //               // placeholder="blur"
    //               />
    //             </div>
    //           </div>
    //         </Stack>
    //         <Stack
    //           sx={{
    //             flexDirection: { xs: "column-reverse", md: "row" },
    //             justifyContent: "space-between",
    //           }}
    //         >
    //           <div className="flex items-center justify-center w-full lg:w-1/2 mb-5">
    //             <div className="">
    //               <img
    //                 src={standard}
    //                 width="300"
    //                 height="50"
    //                 alt="Hero Illustration"
    //               // layout="intrinsic"
    //               // // loading="eager"
    //               // placeholder="blur"
    //               />
    //             </div>
    //           </div>
    //           <div className="flex items-center w-full lg:w-1/2">
    //             <div className="max-w-2xl mb-8">
    //               <Typography
    //                 variant="h6"
    //                 // textAlign="end"
    //                 fontWeight="bold"
    //                 sx={{
    //                   fontSize: { xs: 22, md: 32, sm: 32 },
    //                   color: "#009ADF",
    //                   textAlign: { md: "end", sm: "end", xs: "left" },
    //                 }}
    //               >
    //                 Standardisation
    //               </Typography>

    //               <Typography
    //                 variant="body2"
    //                 // textAlign="end"
    //                 // fontSize="18px"
    //                 sx={{
    //                   color: "#ffff",
    //                   marginTop: "20px",
    //                   textAlign: { md: "end", sm: "end", xs: "left" },
    //                 }}
    //                 className={classes.mainContent}
    //               >
    //                 StackArch ERP, keep improvement of project management,
    //                 operational performance, cost reduction through decreased
    //                 process errors, facilitation of communication, profiting from
    //                 expert knowledge form the Industry standards
    //               </Typography>
    //             </div>
    //           </div>
    //         </Stack>
    //         <Stack gap={2}
    //           sx={{
    //             flexDirection: { xs: "column", md: "row" },
    //             justifyContent: "space-between",
    //           }}
    //         >
    //           <div className="flex items-center w-full lg:w-1/2">
    //             <div className="max-w-2xl mb-8">
    //               <Typography
    //                 variant="h6"
    //                 fontWeight="bold"
    //                 sx={{
    //                   fontSize: { xs: 22, md: 32, sm: 32 },
    //                   color: "#009ADF",
    //                   textAlign: { md: "initial", sm: "initial", xs: "initial" },
    //                 }}
    //               >
    //                 Collaborate
    //               </Typography>

    //               <Typography
    //                 variant="body2"
    //                 // fontSize="18px"
    //                 mt={2}
    //                 className={classes.mainContent}
    //               >
    //                 StackArch ERP easily integrates with the third party ERP which
    //                 is related to the field of financial, CRM, Human capital,
    //                 Inventory modules. Each stages of development our team create
    //                 easy integration modules for existing ERP for architecture
    //                 institutions
    //               </Typography>
    //             </div>
    //           </div>
    //           <div className="flex items-center justify-center w-full lg:w-1/2">
    //             <div className="">
    //               <img
    //                 src={collab}
    //                 width="350"
    //                 // height="100"
    //                 alt="Hero Illustration"
    //               // layout="intrinsic"
    //               // // loading="eager"
    //               // placeholder="blur"
    //               />
    //             </div>
    //           </div>
    //         </Stack>
    //         <Stack
    //           sx={{
    //             flexDirection: { xs: "column-reverse", md: "row" },
    //             justifyContent: "space-between",
    //           }}
    //         >
    //           <div className="flex items-center justify-center w-full lg:w-1/2 mb-5">
    //             <div className="">
    //               <img
    //                 src={manage}
    //                 width="350"
    //                 height="100"
    //                 alt="Hero Illustration"
    //               // layout="intrinsic"
    //               // // loading="eager"
    //               // placeholder="blur"
    //               />
    //             </div>
    //           </div>
    //           <div className="flex items-center w-full lg:w-1/2 mt-5">
    //             <div className="max-w-2xl mb-8">
    //               <Typography
    //                 variant="h6"
    //                 // textAlign="end"
    //                 fontWeight="bold"
    //                 sx={{
    //                   fontSize: { xs: 22, md: 32, sm: 32 },
    //                   color: "#009ADF",
    //                   textAlign: { md: "end", sm: "end", xs: "left" },
    //                 }}
    //               >
    //                 Management
    //               </Typography>

    //               <Typography
    //                 variant="body2"
    //                 // textAlign="end"
    //                 // fontSize="18px"
    //                 sx={{
    //                   color: "#ffff",
    //                   marginTop: "20px",
    //                   textAlign: { md: "end", sm: "end", xs: "left" },
    //                 }}
    //                 className={classes.mainContent}
    //               >
    //                 Managing each project with less effort and providing better
    //                 managerial decision making in the terms right time. Each
    //                 managerial insight can keep in particular problem statements, to
    //                 recover the stages of the project management system with timely
    //                 delivery.
    //               </Typography>
    //             </div>
    //           </div>
    //         </Stack>
    //         <Stack gap={2}
    //           sx={{
    //             flexDirection: { xs: "column", md: "row" },
    //             justifyContent: "space-between",
    //           }}
    //         >
    //           <div className="flex items-center w-full lg:w-1/2">
    //             <div className="max-w-2xl mb-8">
    //               <Typography
    //                 variant="h6"
    //                 fontWeight="bold"
    //                 sx={{
    //                   fontSize: { xs: 22, md: 32, sm: 32 },
    //                   color: "#009ADF",
    //                   textAlign: { md: "initial", sm: "initial", xs: "initial" },
    //                 }}
    //               >
    //                 On-cloud use
    //               </Typography>

    //               <Typography
    //                 variant="body2"
    //                 // fontSize="18px"
    //                 mt={2}
    //                 className={classes.mainContent}
    //               >
    //                 Now part of the technology shifting , we create the StackArch
    //                 ERP with an intelligent cloud based solution, for easy use.
    //                 Moving to cloud based solutions can keep your productivity
    //                 increased with your team as well
    //               </Typography>
    //             </div>
    //           </div>
    //           <div className="flex items-center justify-center w-full lg:w-1/2">
    //             <div className="">
    //               <img
    //                 src={cloud}
    //                 width="350"
    //                 height="100"
    //                 alt="Hero Illustration"
    //               // layout="intrinsic"
    //               // // loading="eager"
    //               // placeholder="blur"
    //               />
    //             </div>
    //           </div>
    //         </Stack>
    //         <Stack
    //           sx={{
    //             flexDirection: { xs: "column-reverse", md: "row" },
    //             justifyContent: "space-between",
    //           }}
    //         >
    //           <div className="flex items-center justify-center w-full lg:w-1/2 mb-5">
    //             <div className="">
    //               <img
    //                 src={plan}
    //                 width="350"
    //                 height="100"
    //                 alt="Hero Illustration"
    //               // layout="intrinsic"
    //               // // loading="eager"
    //               // placeholder="blur"
    //               />
    //             </div>
    //           </div>
    //           <div className="flex items-center w-full lg:w-1/2 mt-5">
    //             <div className="max-w-2xl mb-8">
    //               <Typography
    //                 variant="h6"
    //                 fontWeight="bold"
    //                 // textAlign="end"
    //                 sx={{
    //                   fontSize: { xs: 22, md: 32, sm: 32 },
    //                   color: "#009ADF",
    //                   textAlign: { md: "end", sm: "end", xs: "left" },
    //                 }}
    //               >
    //                 Planning
    //               </Typography>

    //               <Typography
    //                 variant="body2"
    //                 // textAlign="end"
    //                 // fontSize="18px"
    //                 sx={{
    //                   color: "#ffff",
    //                   marginTop: "20px",
    //                   textAlign: { md: "end", sm: "end", xs: "left" },
    //                 }}
    //                 className={classes.mainContent}
    //               >
    //                 We create each project management with proper planning, with the
    //                 team scheduling, and managerial control over all that. In
    //                 planning phase, plan is made and strategies are set, taking into
    //                 consideration the company policies, procedures and rules
    //                 Planning provides direction.
    //               </Typography>
    //             </div>
    //           </div>
    //         </Stack>
    //         <Stack
    //           sx={{
    //             flexDirection: { xs: "column", md: "row" },
    //             justifyContent: "space-between",
    //           }}
    //         >
    //           <div className="flex items-center w-full lg:w-1/2">
    //             <div className="max-w-2xl mb-8">
    //               <Typography
    //                 variant="h6"
    //                 fontWeight="bold"
    //                 sx={{
    //                   fontSize: { xs: 22, md: 32, sm: 32 },
    //                   color: "#009ADF",
    //                   textAlign: { md: "initial", sm: "initial", xs: "initial" },
    //                 }}
    //               >
    //                 Lower cost
    //               </Typography>

    //               <Typography
    //                 variant="body2"
    //                 // fontSize="18px"
    //                 mt={2}
    //                 className={classes.mainContent}
    //               >
    //                 StackArch ERP can reduce the additional cost that acquired on a
    //                 particular project during the life cycle of the project
    //                 delivery. We concentrate on each project stage while consuming
    //                 the least cost to timely delivery.
    //               </Typography>
    //             </div>
    //           </div>
    //           <div className="flex items-center justify-center w-full lg:w-1/2">
    //             <div className="">
    //               <img
    //                 src={low}
    //                 width="350"
    //                 height="100"
    //                 alt="Hero Illustration"
    //               // layout="intrinsic"
    //               // // loading="eager"
    //               // placeholder="blur"
    //               />
    //             </div>
    //           </div>
    //         </Stack>
    //       </Stack>
    //     </Container>
    //   </>
    // );
}