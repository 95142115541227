import React from 'react'
import AdaptiveDesign from '../../components/AdaptiveWebDesign/AdaptiveDesign'
import ContactForm from '../../components/Contact/ContactForm'
import imgProgramer from "../../assets/img/services/programers.png";
import { Helmet } from 'react-helmet';
const AdaptiveWebDesign = () => {
  return (
    <>
      <Helmet>
        <title>Stackroots | Adaptive Web Design</title>
      </Helmet>
      <div>

        <AdaptiveDesign />
        <ContactForm image={imgProgramer} />
      </div>
    </>

  )
}

export default AdaptiveWebDesign