import React from "react";
import { Box, Button, Typography, Stack } from "@mui/material";
import { Container } from "@mui/system";
import { Fade } from "react-awesome-reveal";
import erp1 from "../../assets/img/products/erpsec.png";
import ErpFeatures from "../ErpFeatures";
import { useStyles } from "../../styles";

const Description1 = () => {
  const classes = useStyles();
  return (
    <>
      <Container maxWidth="xl">
        <Box
          py={1}
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Stack p={2}
            direction={{ xs: "column", md: "row" }}
            spacing={2}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ flexDirection: "column", flex: 3 }}>
              {/* <Fade triggerOnce="true" direction="up"> */}
              <Typography
                className={classes.subMainHeader}
                textAlign={{ xs: "leeft", md: "initial" }}

                sx={{
                  marginTop: { xs: "50px", md: 0 },
                }}
              >
                Stackroots ERP improves business performance
              </Typography>
              {/* </Fade>{" "}
            <Fade triggerOnce="true" direction="up"> */}
              <Typography
                mt={2}
                className={classes.mainContent}
                sx={{
                }}
              >
                Stackroots ERP is an easy-to-use powerful cloud-based ERP
                solution with multiple modes of operation Manage all aspects of
                your business, from finances to the workforce, while also
                staying on top of orders, managing inventory, and more.
              </Typography>
              <Typography
                marginTop="10px"
                className={classes.mainContent}
              >
                Stackroots ERP is more powerful, because it applies a single set
                of resource planning tools across the entire enterprise ,
                provides real-time integration of sales, operating and financial
                data and connects resource planning approaches, to the extended
                supply chain of customers and suppliers. Stackroots ERP improves
                business performance.
              </Typography>
              {/* </Fade> */}
            </Box>
            {/* <Fade triggerOnce="true" direction="up"> */}
            <Box
              sx={{
                marginTop: { xs: "0px", md: "70px" },

                flex: 2,
              }}
            >
              <img src={erp1} alt="banner" width="100%" />
            </Box>
            {/* </Fade> */}
          </Stack>
        </Box>
      </Container>
      <ErpFeatures />
    </>
  );
};

export default Description1;
