import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { useStyles } from '../../styles'

import carDetails from "../../assets/img/new/carDetails.png"
import carInspection from "../../assets/img/new/carInspection.png"
import radioBtn from "../../assets/img/new/radioBtn.png"
import shapesVector from "../../assets/img/products/shapesVector.png"
function Details() {
    const classes = useStyles();
    return (
        <Stack sx={{
            backgroundImage: `url(${shapesVector}) !important`,
            backgroundSize: "cover",
        }}>
            <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>



                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            The Ultimate Online Service Management Solution for Automobile Service Stations
                        </Typography>

                        <Box display={{ xs: "block    ", md: "none" }} py={2} sx={{ margin: "0 auto !important" }} component={"img"} src={carDetails} width={{ xs: "70%", sm: "50%", md: "40%" }} />

                        <Typography className={classes.mainContent}>
                            AutoStack, developed by StackRoots, is a game-changer for automobile service stations. With its online service management application and 360-degree approach to service management, AutoStack streamlines operations, enhances the customer experience, and improves overall Refficiency and profitability for service stations.
                        </Typography>

                        <Stack spacing={1}>
                            <Stack direction={"row"} alignItems="center" spacing={2}>
                                <Box component={"img"} src={radioBtn} width={"24px"} />
                                <Typography className={classes.mainContent}>
                                    Manage your mobile service bookings
                                </Typography>
                            </Stack>
                            <Stack direction={"row"} alignItems="center" spacing={2}>
                                <Box component={"img"} src={radioBtn} width={"24px"} />
                                <Typography className={classes.mainContent}>
                                    Mini ecommerce system for your spare part sale
                                </Typography>
                            </Stack>
                            <Stack direction={"row"} alignItems="center" spacing={2}>
                                <Box component={"img"} src={radioBtn} width={"24px"} />
                                <Typography className={classes.mainContent}>
                                    Manage your service vehicles and supervisiors
                                </Typography>
                            </Stack>
                            <Stack direction={"row"} alignItems="center" spacing={2}>
                                <Box component={"img"} src={radioBtn} width={"24px"} />
                                <Typography className={classes.mainContent}>
                                    Track the booking and service status
                                </Typography>
                            </Stack>
                            <Stack direction={"row"} alignItems="center" spacing={2}>
                                <Box component={"img"} src={radioBtn} width={"24px"} />
                                <Typography className={classes.mainContent}>
                                    Condimentum in erat praesent
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Box display={{ xs: "none", md: "block" }} component={"img"} src={carDetails} width={{ xs: "70%", sm: "50%", md: "40%" }} />
                </Stack>
            </Stack>


            <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>

                    <Box component={"img"} sx={{
                        display: { xs: "none", md: "block" },
                    }} src={carInspection} width={{ xs: "70%", sm: "50%", md: "35%" }} />

                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            Streamline Operations and Enhance Customer Experience with AutoStack
                        </Typography>
                        <Box component={"img"} sx={{
                            display: { xs: "block", md: "none" },
                            m: "0 auto !important",
                            py: 2
                        }} src={carInspection} width={{ xs: "70%", sm: "50%", md: "35%" }} />
                        <Typography className={classes.mainContent}>
                            AutoStack revolutionizes the way automobile service stations manage their operations with its comprehensive online service management application. With AutoStack, service stations can easily manage mobile service bookings, ensuring efficient scheduling and minimizing customer wait times. The application also includes a mini ecommerce system, allowing service stations to sell spare parts online, expanding their revenue streams. Furthermore, AutoStack enables service stations to effectively manage their service vehicles and supervisors, ensuring smooth coordination and timely completion of jobs. With real-time tracking of booking and service statuses, service stations can keep customers informed and provide a transparent service experience. Overall, AutoStack empowers automobile service stations to optimize their operations and deliver exceptional service to their customers.
                        </Typography>


                    </Stack>

                </Stack>
            </Stack>
        </Stack>
    )
}

export default Details