import {
  Box,
  Stack,
  Typography
} from "@mui/material";
import React from "react";
import Marquee from "react-fast-marquee";
import degree1 from "../../assets/img/products/degree1.png";
import adaptivebg from "../../assets/img/services/adaptivebg.png";
import crmbg from "../../assets/img/services/crmbg.png";
import website1 from "../../assets/img/services/website1.png";
import Techstack from "../Techstack";

import angular from "../../assets/img/services/logo/angularLogo.png";
import express from "../../assets/img/services/logo/expressLogo.png";
import mongo from "../../assets/img/services/logo/mongoLogo.png";
import mui from "../../assets/img/services/logo/muilogo.png";
import node from "../../assets/img/services/logo/nodelogo.png";
import react from "../../assets/img/services/logo/reactlogo.png";
import { useStyles } from "../../styles";

import results from "../../assets/img/services/vectors/results.png";

const SocialMediaMarketing = () => {
  const classes = useStyles();
  const icons = [
    {
      img: react
    },
    {
      img: angular
    },
    {
      img: node
    },
    {
      img: mongo
    },
    {
      img: mui
    },
    {
      img: express
    },
  ]
  return (
    <div style={{ background: "#131331" }}>
      <div
        className="adaptivebg"
        style={{
          backgroundImage: `url(${adaptivebg})`,
        }}
      >
        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={12}
          >
            <Box
              sx={{
                display: "flex",

                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}
                className={classes.mainHeader}
              >
                Social Media Marketing Services
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "0px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={degree1} alt="banner" />
              </Box>
              <Typography className={classes.mainContent}
                marginTop="10px"
              >
                We understand the power of social media in driving business growth and increasing brand visibility. Our social media marketing services are designed to help you reach a targeted audience, build relationships with customers, and drive sales. From content creation to ad campaigns, we offer a comprehensive range of services to enhance your brand's presence on various social media platforms. Social media allows businesses to target specific audiences based on demographics, interests, and behaviors. Our team at Stackroots can help you identify your target audience and develop strategies to reach them effectively. By creating and sharing valuable content that resonates with your audience, we can increase your brand's visibility and attract potential customers.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={degree1} alt="banner" />
            </Box>
          </Stack>
        </Box>


        {/*  */}

        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row-reverse" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={12}
          >
            <Box
              sx={{
                display: "flex",

                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}
              >
                Measurable Results and Analytics
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "20px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img alt="vector" src={results} />
              </Box>
              <Typography
                className={classes.mainContent}
                marginTop="20px"
              >
                One of the key benefits of social media marketing is the ability to measure and track results. Stackroots provides detailed analytics and reporting to help you understand the impact of your social media efforts. We track metrics such as engagement, reach, website traffic, and conversions to ensure that your social media strategy is delivering the desired outcomes. Engaging with your followers and building relationships with customers is crucial for business success. Our team at Stackroots can help you create a social media strategy that encourages interaction and fosters customer loyalty. By responding to comments, messages, and reviews in a timely manner, we can help you build a strong online community around your brand.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={results} />
            </Box>
          </Stack>
        </Box>

        <div
          className="adaptivebg"
          style={{
            backgroundImage: `url(${crmbg})`,
          }}
        >
          <Box sx={{ marginTop: "60px", p: { xs: 3, md: 6 } }}>
            <Typography
              className={classes.mainHeader}
              sx={{
                marginTop: { xs: "20px", md: 0, sm: 0 },

                mb: 2
              }}
            >
              Let Stackroots Elevate Your Visibility
            </Typography>
            <Typography className={classes.mainContent}
              sx={{
                p: { md: 0, xs: 0, sm: 5 },
                textAlign: { xs: "initial", md: "left", sm: "left" },
              }}
            >
              If you're looking to enhance your brand's presence on social media and drive business growth, contact Stackroots today. Our social media marketing experts will work closely with you to understand your business goals and develop a customized strategy that aligns with your brand values. Let us help you leverage the power of social media to boost your brand's visibility and engage with your target audience effectively.
            </Typography>
          </Box>
        </div>

        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: { xs: "none", md: "flex" },

                flex: 1,
              }}
            >
              <img src={website1} alt="banner" />
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                // p:10,
                flex: 1,
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <Typography
                className={classes.mainHeader}
                mb={3}
                sx={{
                  textAlign: { md: "left", sm: "center", xs: "center" },
                }}
              >
                Building Brand Visibility and Engaging with Your Audience
              </Typography>
              <Box
                sx={{
                  display: { xs: "flex", md: "none" },
                  flex: 1,
                }}
              >
                <img src={website1} alt="banner" />
              </Box>
              <Typography
                className={classes.mainContent}
                marginTop="10px"
                sx={{
                  textAlign: { md: "left", sm: "center", xs: "left" },
                }}
              >
                Social media can be a powerful tool for driving traffic to your website and improving search engine rankings. Our experts at Stackroots can optimize your social media profiles and content to improve your visibility in search engine results. By sharing valuable content that links back to your website, we can increase traffic and improve your website's ranking on search engines. Social media marketing offers a cost-effective way to reach a large audience compared to traditional advertising methods. Stackroots can help you develop a social media marketing strategy that maximizes your return on investment. By targeting the right audience and optimizing your ad campaigns, we can help you achieve your marketing goals within your budget.
              </Typography>
            </Box>
          </Stack>
        </Box>




      </div>
      <Box sx={{ marginTop: "50px", display: { xs: "none", md: "block", sm: "block" } }}>
        <Techstack />
      </Box>

      <Box p={3} sx={{ marginTop: "50px", display: { xs: "block", md: "none", sm: "none" } }} >
        <Typography
          sx={
            { "fontStyle": "normal", "fontWeight": "600", "fontSize": "24px", "lineHeight": "29px", "color": "#FFFFFF", textAlign: "center" }
          }
        >
          Expertise And Technical Skills
        </Typography>
        <Stack>
          <Marquee gradient={false}>
            {icons.map((icon) => (
              <Box component={"img"}
                sx={{
                  mt: "25px",
                  width: "105px"
                }}
                src={icon.img}
                alt={"tech stack"}
              />
            ))
            }
          </Marquee>

          <Marquee gradient={false} direction={"right"}>
            {icons.map((icon) => (
              <Box component={"img"}
                sx={{
                  mt: "25px",
                  width: "105px"
                }}
                src={icon.img}
                alt={"tech stack"}
              />
            ))
            }
          </Marquee>
        </Stack>
      </Box>
    </div>
  );
};

export default SocialMediaMarketing;
