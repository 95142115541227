import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Container } from "@mui/system";
import humanresources from "../../assets/img/products/humanresources.png";
import accounting from "../../assets/img/products/accounting.png";
import management from "../../assets/img/products/management.png";
import asset from "../../assets/img/products/asset.png";
import warehouse from "../../assets/img/products/warehouse.png";
import planner from "../../assets/img/products/planner.png";
import monitor1 from "../../assets/img/products/monitor1.png";
import { Fade } from "react-awesome-reveal";

const Details = () => {
  return (
    <div>
      <Container maxWidth="xl">
        {/* <Fade triggerOnce="true" direction="up"> */}
          <Typography
            fontSize="34px"
            textAlign="center"
            sx={{ marginTop: "50px" }}
          >
            Lorem ipsum dolor sit, consectetur{" "}
          </Typography>
          <Typography fontSize="34px" textAlign="center">
            {" "}
            adipiscing ut aliquam,{" "}
          </Typography>
          <Grid container spacing={2} sx={{ marginTop: "50px" }}>
            <Grid item md={4} sm={6}>
              <Card>
                <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                  <IconButton
                    size="large"
                    aria-label="show 4 new mails"
                    color="inherit"
                  >
                    <img
                      src={humanresources}
                      style={{ fontSize: "2.5rem", color: "#4F46E5" }}
                    />
                  </IconButton>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    textAlign="center"
                  >
                    HRMS
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    textAlign="center"
                    fontSize="16px"
                    sx={{ color: "#000000" }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Pharetra, quis mauris sed nisi, laoreet. Lorem ipsum dolor
                    sit amet, consectetur adipiscing .
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item md={4} sm={6}>
              <Card>
                <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                  <IconButton
                    size="large"
                    aria-label="show 4 new mails"
                    color="inherit"
                  >
                    <img
                      src={accounting}
                      style={{ fontSize: "2.5rem", color: "#4F46E5" }}
                    />
                  </IconButton>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    textAlign="center"
                  >
                    Accounting
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    textAlign="center"
                    fontSize="16px"
                    sx={{ color: "#000000" }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Pharetra, quis mauris sed nisi, laoreet. Lorem ipsum dolor
                    sit amet, consectetur adipiscing .
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4} sm={6}>
              <Card>
                <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                  <IconButton
                    size="large"
                    aria-label="show 4 new mails"
                    color="inherit"
                  >
                    <img
                      src={management}
                      style={{ fontSize: "2.5rem", color: "#4F46E5" }}
                    />
                  </IconButton>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    textAlign="center"
                  >
                    CRM
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    textAlign="center"
                    fontSize="16px"
                    sx={{ color: "#000000" }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Pharetra, quis mauris sed nisi, laoreet. Lorem ipsum dolor
                    sit amet, consectetur adipiscing .
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4} sm={6}>
              <Card>
                <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                  <IconButton
                    size="large"
                    aria-label="show 4 new mails"
                    color="inherit"
                  >
                    <img
                      src={asset}
                      style={{ fontSize: "2.5rem", color: "#4F46E5" }}
                    />
                  </IconButton>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    textAlign="center"
                  >
                    Assets
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    textAlign="center"
                    fontSize="16px"
                    sx={{ color: "#000000" }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Pharetra, quis mauris sed nisi, laoreet. Lorem ipsum dolor
                    sit amet, consectetur adipiscing .
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4} sm={6}>
              <Card>
                <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                  <IconButton
                    size="large"
                    aria-label="show 4 new mails"
                    color="inherit"
                  >
                    <img
                      src={warehouse}
                      style={{ fontSize: "2.5rem", color: "#4F46E5" }}
                    />
                  </IconButton>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    textAlign="center"
                  >
                    Inventory
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    textAlign="center"
                    fontSize="16px"
                    sx={{ color: "#000000" }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Pharetra, quis mauris sed nisi, laoreet. Lorem ipsum dolor
                    sit amet, consectetur adipiscing .
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4} sm={6}>
              <Card>
                <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                  <IconButton
                    size="large"
                    aria-label="show 4 new mails"
                    color="inherit"
                  >
                    <img
                      src={planner}
                      style={{ fontSize: "2.5rem", color: "#4F46E5" }}
                    />
                  </IconButton>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    textAlign="center"
                  >
                    Projects
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    textAlign="center"
                    fontSize="16px"
                    sx={{ color: "#000000" }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Pharetra, quis mauris sed nisi, laoreet. Lorem ipsum dolor
                    sit amet, consectetur adipiscing .
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        {/* </Fade> */}
      </Container>
      <Box sx={{ marginTop: "50px" }}>
        {/* <Fade triggerOnce="true" direction="up"> */}
          <Typography
            textAlign="center"
            variant="h6"
            fontWeight="bold"
            fontSize="32px"
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing
          </Typography>
          <Typography
            textAlign="center"
            variant="body1"
            fontWeight="bold"
            fontSize="16px"
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
            purus sit amet luctus{" "}
          </Typography>
          <Typography
            textAlign="center"
            variant="body1"
            fontWeight="bold"
            fontSize="16px"
          >
            venenatisaliquam, purus sit amet luctus venenatis
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              height: { xs: "200px", md: "490px" },
              marginTop: "50px",
            }}
          >
            <img src={monitor1} alt="/" />
          </Box>
          <Container maxWidth="xl">
            <Typography variant="body2" textAlign="center" fontSize="18px">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis
              id mi leo libero et diam nibh at amet. Quis urna, consectetur et
              cras. Nisi velit dolor congue fringilla quam. Nisl tristique
              tellus massa vivamus purus suspendisse. Vel, faucibus augue lectus
              aliquam mattis eros, amet, aenean
            </Typography>
          </Container>
        {/* </Fade> */}
      </Box>
    </div>
  );
};

export default Details;
