import React from "react";
import "../../components/DigitalMarketing/Banner9.css";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "../../components/WebsiteDevelopment/Banner8.css";
import { Container } from "@mui/system";
import FreeDemo from "../FreeDemo";
import humanresources from "../../assets/img/products/humanresources.png";
import accounting from "../../assets/img/products/accounting.png";
import management from "../../assets/img/products/management.png";
import asset from "../../assets/img/products/asset.png";
import warehouse from "../../assets/img/products/warehouse.png";
import planner from "../../assets/img/products/planner.png";
import rightarrow from "../../assets/img/products/rightarrow.png";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import PopUp from "../PopUp";
import { Fade } from "react-awesome-reveal";

const Banner9 = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Container maxWidth="xl">
      <Box
        className="banner7"
        sx={{
          paddingBottom: "50px",
          height: "470px",
          height: "100%",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            flex: 1,
            padding: { xs: "20px", md: "50px" },
            marginTop: "40px",
            marginLeft: { xs: "0px", md: "70px" },
          }}
        >
          <Typography
            variant="h3"
            fontWeight="bold"
            textAlign="initial"
            sx={{
              fontSize: {
                xs: 42,
                md: 64,
                sm: 32,
                color: "white",
                marginTop: "100px",
              },
            }}
          >
            Digital Marketing
          </Typography>

          <Typography
            textAlign="justify"
            variant="body1"
            sx={{ color: "white", fontSize: "20px" }}
          >
            Social media is one of the most important way to engage with clients
            and build a long lasting trust. It also the place to highlight what
            makes your brand unique & show off your sparkling personality.
          </Typography>

          <PopUp title="Digital Marketing" />
        </Box>
      </Box>
      <Container maxWidth="xl">
        {/* <Fade triggerOnce="true" direction="up"> */}
          <Grid container spacing={2} sx={{ marginTop: "50px" }}>
            <Grid item md={4} sm={6}>
              <Card>
                <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                  <IconButton
                    size="large"
                    aria-label="show 4 new mails"
                    color="inherit"
                  >
                    <img
                      src={humanresources}
                      style={{ fontSize: "2.5rem", color: "#4F46E5" }}
                    />
                  </IconButton>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    textAlign="center"
                  >
                    HRMS
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    textAlign="center"
                    fontSize="16px"
                    sx={{ color: "#000000" }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Pharetra, quis mauris sed nisi, laoreet. Lorem ipsum dolor
                    sit amet, consectetur adipiscing .
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item md={4} sm={6}>
              <Card>
                <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                  <IconButton
                    size="large"
                    aria-label="show 4 new mails"
                    color="inherit"
                  >
                    <img
                      src={accounting}
                      style={{ fontSize: "2.5rem", color: "#4F46E5" }}
                    />
                  </IconButton>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    textAlign="center"
                  >
                    Accounting
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    textAlign="center"
                    fontSize="16px"
                    sx={{ color: "#000000" }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Pharetra, quis mauris sed nisi, laoreet. Lorem ipsum dolor
                    sit amet, consectetur adipiscing .
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4} sm={6}>
              <Card>
                <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                  <IconButton
                    size="large"
                    aria-label="show 4 new mails"
                    color="inherit"
                  >
                    <img
                      src={management}
                      style={{ fontSize: "2.5rem", color: "#4F46E5" }}
                    />
                  </IconButton>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    textAlign="center"
                  >
                    CRM
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    textAlign="center"
                    fontSize="16px"
                    sx={{ color: "#000000" }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Pharetra, quis mauris sed nisi, laoreet. Lorem ipsum dolor
                    sit amet, consectetur adipiscing .
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4} sm={6}>
              <Card>
                <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                  <IconButton
                    size="large"
                    aria-label="show 4 new mails"
                    color="inherit"
                  >
                    <img
                      src={asset}
                      style={{ fontSize: "2.5rem", color: "#4F46E5" }}
                    />
                  </IconButton>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    textAlign="center"
                  >
                    Assets
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    textAlign="center"
                    fontSize="16px"
                    sx={{ color: "#000000" }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Pharetra, quis mauris sed nisi, laoreet. Lorem ipsum dolor
                    sit amet, consectetur adipiscing .
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4} sm={6}>
              <Card>
                <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                  <IconButton
                    size="large"
                    aria-label="show 4 new mails"
                    color="inherit"
                  >
                    <img
                      src={warehouse}
                      style={{ fontSize: "2.5rem", color: "#4F46E5" }}
                    />
                  </IconButton>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    textAlign="center"
                  >
                    Inventory
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    textAlign="center"
                    fontSize="16px"
                    sx={{ color: "#000000" }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Pharetra, quis mauris sed nisi, laoreet. Lorem ipsum dolor
                    sit amet, consectetur adipiscing .
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4} sm={6}>
              <Card>
                <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                  <IconButton
                    size="large"
                    aria-label="show 4 new mails"
                    color="inherit"
                  >
                    <img
                      src={planner}
                      style={{ fontSize: "2.5rem", color: "#4F46E5" }}
                    />
                  </IconButton>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    textAlign="center"
                  >
                    Projects
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    textAlign="center"
                    fontSize="16px"
                    sx={{ color: "#000000" }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Pharetra, quis mauris sed nisi, laoreet. Lorem ipsum dolor
                    sit amet, consectetur adipiscing .
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        {/* </Fade> */}
      </Container>
    </Container>
  );
};

export default Banner9;
