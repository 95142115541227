import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import Marquee from "react-fast-marquee";
import adaptivebg from "../../assets/img/services/adaptivebg.png";
import phone3 from "../../assets/img/services/phone3.png";
import Techstack from "../Techstack";

import angular from "../../assets/img/services/logo/angularLogo.png";
import express from "../../assets/img/services/logo/expressLogo.png";
import mongo from "../../assets/img/services/logo/mongoLogo.png";
import mui from "../../assets/img/services/logo/muilogo.png";
import node from "../../assets/img/services/logo/nodelogo.png";
import react from "../../assets/img/services/logo/reactlogo.png";
import { useStyles } from "../../styles";


import highRank from "../../assets/img/services/vectors/highRank.png";
import webTraffic from "../../assets/img/services/vectors/webTraffic.png";
import monitoring from "../../assets/img/services/vectors/monitoring.png";
import boosting from "../../assets/img/services/vectors/boosting.png";

const WebBoosting1 = () => {
  const classes = useStyles();
  const icons = [
    {
      img: react
    },
    {
      img: angular
    },
    {
      img: node
    },
    {
      img: mongo
    },
    {
      img: mui
    },
    {
      img: express
    },
  ]
  return (
    <div style={{ background: "#131331" }}>
      <div
        className="adaptivebg"
        style={{
          backgroundImage: `url(${adaptivebg})`,
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",

                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}
                sx={{

                  marginTop: { xs: "100px", md: "0px", sm: "100px" },
                }}
              >
                Website Boosting
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "0px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={phone3} alt="banner" />
              </Box>
              <Typography
                className={classes.mainContent}
                fontSize={{ xs: "16px", md: "18px" }}
                marginTop="10px"
              >
                If you're looking to enhance your digital marketing efforts and achieve long-term growth, contact Stackroots today. Our team of experts will work closely with you to understand your business goals and develop a customized digital marketing strategy that delivers results. Let us help you reach your marketing goals and drive business growth. We let our work do the talking and assure your website is optimized to get the best search result it deserves.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={phone3} alt="banner" />
            </Box>
          </Stack>
        </Box>

        {/*  */}

        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row-reverse" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={12}
          >
            <Box
              sx={{
                display: "flex",

                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}
              >
                Higher Search Engine Rankings
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "20px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img alt="vector" src={highRank} />
              </Box>
              <Typography
                className={classes.mainContent}
                marginTop="20px"
              >
                Our team of experts implements effective strategies to boost your website's search engine rankings. Search Engine Optimization (SEO) is a key component of our website boosting services. We conduct in-depth keyword research and optimize your website's content, meta tags, and other elements to improve its visibility on search engine result pages (SERPs). By ranking your website on the first page of search results, we increase its chances of being discovered by potential customers.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={highRank} />
            </Box>
          </Stack>
        </Box>

        {/*  */}

        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={12}
          >
            <Box
              sx={{
                display: "flex",

                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}
              >
                Increase Website Traffic
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "20px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img alt="vector" src={webTraffic} />
              </Box>
              <Typography
                className={classes.mainContent}
                marginTop="20px"
              >
                Boosting your website involves driving targeted traffic to your site. Our website boosting services focus on attracting organic traffic through various channels. We optimize your website for relevant keywords, create high-quality content, and implement link-building strategies to increase its visibility and attract more visitors. By increasing website traffic, we enhance the chances of converting visitors into customers. A boosted website not only attracts more visitors but also improves conversion rates. We analyze your website's user journey and identify areas for improvement. We optimize your website's design, layout, and call-to-action buttons to create a seamless conversion process. By providing a user-friendly experience and clear paths to conversion, we increase the likelihood of visitors taking the desired actions on your website.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={webTraffic} />
            </Box>
          </Stack>
        </Box>

        {/*  */}

        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row-reverse" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={12}
          >
            <Box
              sx={{
                display: "flex",

                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}
              >
                Continuous Monitoring and Optimization
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "20px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img alt="vector" src={monitoring} />
              </Box>
              <Typography
                className={classes.mainContent}
                marginTop="20px"
              >
                Our website-boosting services don't stop at implementation. We continuously monitor your website's performance using advanced analytics tools. This allows us to identify areas that need improvement and make data-driven decisions to optimize your site further. By regularly analyzing data and making necessary adjustments, we ensure that your website remains competitive and continues to drive business growth.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={monitoring} />
            </Box>
          </Stack>
        </Box>

        {/*  */}

        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={12}
          >
            <Box
              sx={{
                display: "flex",

                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}
              >
                BOOST YOUR WEBSITE FOR BETTER VISIBILITY
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "20px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img alt="vector" src={boosting} />
              </Box>
              <Typography
                className={classes.mainContent}
                marginTop="20px"
              >
                If you're looking to boost your website's performance and visibility, contact Stackroots today. Our team of experts will assess your website's needs and develop a customized strategy to enhance its performance. Let us help you improve your website's rankings, attract more visitors, and increase conversions.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={boosting} width={"80%"} />
            </Box>
          </Stack>
        </Box>


      </div>

      <Box sx={{ marginTop: "50px", display: { xs: "none", md: "block", sm: "block" } }}>
        <Techstack />
      </Box>

      <Box sx={{ marginTop: "50px", p: 3, display: { xs: "block", md: "none", sm: "none" } }} >
        <Typography
          sx={
            { "fontStyle": "normal", "fontWeight": "600", "fontSize": "24px", "lineHeight": "29px", "color": "#FFFFFF", textAlign: "center" }
          }
        >
          Expertise And Technical Skills
        </Typography>

        <Stack>
          <Marquee gradient={false}>
            {icons.map((icon) => (
              <Box component={"img"}
                sx={{
                  mt: "25px",
                  width: "105px"
                }}
                src={icon.img}
                alt={"tech stack"}
              />
            ))
            }
          </Marquee>

          <Marquee gradient={false} direction={"right"}>
            {icons.map((icon) => (
              <Box component={"img"}
                sx={{
                  mt: "25px",
                  width: "105px"
                }}
                src={icon.img}
                alt={"tech stack"}
              />
            ))
            }
          </Marquee>
        </Stack>

      </Box>
    </div>
  );
}

export default WebBoosting1