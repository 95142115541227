import React from 'react'
import { Helmet } from 'react-helmet'
import ContactForm from '../../components/Contact/ContactForm'
import appDevForm from "../../assets/img/services/appDevContactForm.png"
import ApplicationDev from '../../components/applicationDev/ApplicationDev'

function AppDev() {
    return (
        <>
            <Helmet>
                <title>Stackroots | Mobile Application Development</title>
            </Helmet>
            <div>
                <ApplicationDev />
                <ContactForm title="Get a quote" image={appDevForm} />
            </div>
        </>
    )
}

export default AppDev