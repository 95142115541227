import React from "react";
import { Box, Button, Typography, Stack } from "@mui/material";
import { Container } from "@mui/system";
import { Fade } from "react-awesome-reveal";
import car from "../../assets/img/products/car.png";

const Description3 = () => {
  return (
    <Container maxWidth="xl">
      {/* <Fade triggerOnce="true" direction="up"> */}
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ flexDirection: "column", marginTop: "50px", flex: 3 }}>
              <Typography
                variant="h2"
                fontWeight="bold"
                textAlign="initial"
                lineHeight="60px"
                sx={{
                  fontSize: { xs: 32, md: 34 },
                  lineHeight: "48px",
                  color: "black",
                }}
              >
                Lorem ipsum dolor sit, consectetur <br />
                adipiscing ut aliquam,
              </Typography>

              <Typography
                variant="h4"
                fontSize="18px"
                fontWeight="medium"
                marginTop="10px"
                textAlign="initial"
                sx={{ lineHeight: "31px", color: "black" }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Sagittis id mi leo libero et diam nibh at amet. Quis urna,
                consectetur et cras. Nisi velit dolor congue fringilla quam.
                Nisl tristique tellus massa vivamus purus suspendisse. Vel,
                faucibus augue lectus aliquam mattis eros,
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },

                flex: 1,
              }}
            >
              <img src={car} alt="banner" width="100%" />
            </Box>
          </Stack>
        </Box>
      {/* </Fade> */}
    </Container>
  );
};

export default Description3;
