import React from 'react'
import Banner from '../../components/mobileVehicle/Banner'
import ContactForm from '../../components/Contact/ContactForm'
import Details from '../../components/mobileVehicle/Details'
import { Helmet } from "react-helmet"

function MobileVehicle() {
    return (
        <>
            <Helmet>
                <title>Mobile Vehicle Service Solution  </title>
                <meta
                    name="description"
                    content=""
                />
            </Helmet>

            <Banner />
            <Details />
            <ContactForm title="Get a quote" />
        </>
    )
}

export default MobileVehicle