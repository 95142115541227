import React from 'react'
import { Helmet } from 'react-helmet'
import ContactForm from '../../components/Contact/ContactForm'
import WebHosting1 from '../../components/WebHosting/WebHosting1'
import hostingForm from "../../assets/img/services/hostingForm.png";
const WebHosting = () => {
  return (
    <>
      <Helmet>
        <title>Stackroots | Web Hosting</title>
      </Helmet>
      <div>
        <WebHosting1 />
        <ContactForm title="Get a quote" image={hostingForm} />
      </div>
    </>
  )
}

export default WebHosting