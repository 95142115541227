import React from "react";
import { Helmet } from "react-helmet";
import ContactForm from "../../components/Contact/ContactForm";
import DropUs from "../../components/DropUs";
import PopUp from "../../components/PopUp";
import PopupWidget from "../../components/popupWidget";
import Banner from "../../components/Stackarch/Banner";
import Banner2 from "../../components/Stackarch/Banner2";
import Descriptions1 from "../../components/Stackarch/Descriptions1";

import Software from "../../components/Stackarch/Software";

function Stackarch() {
  return (
    <>
      <Helmet>
        <title>Stackroots | Stackarch</title>
        <meta name="title" content="Fast & Visual All-in-One Project Management ERP Software for Architects" />
        <meta name="description"
          content="A complete architectural project management ERP software available in web & mobile platform for Architects" />
      </Helmet>
      <div style={{ backgroundColor: "#131331" }}>
        <Banner />
        <Banner2 />
        <Descriptions1 />
        {/* <Software /> */}
        <ContactForm title="Get a quote" />
        {/* <DropUs /> */}
        {/* <PopupWidget /> */}
      </div>
    </>

  );
}

export default Stackarch;
