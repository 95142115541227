import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { useStyles } from '../../styles'

import Card from './Card'

import lmsVector2 from "../../assets/img/new/lmsVector2.png"
import shapesVector from "../../assets/img/products/shapesVector.png"



import benifitIcon from "../../assets/img/new/benifitIcon.png"


import bookNow from "../../assets/img/services/vectors/bookNow.png"
import communication from "../../assets/img/services/vectors/communication.png"
import miniEcom from "../../assets/img/services/vectors/miniEcom.png"
import Comprehensive from "../../assets/img/services/vectors/Comprehensive.png"
import vehleMagt from "../../assets/img/services/vectors/vehleMagt.png"
import optimizeVeh from "../../assets/img/services/vectors/optimizeVeh.png"

import Benifits from './Benifits'


function Details() {
    const classes = useStyles();


    // TODO:IMG

    // TODO:CONTENT

    // const benifits = [
    //     {
    //         title: "Streamlined mobile service bookings",
    //         desc: "Showcase your culinary masterpieces with captivating images and detailed descriptions. Our visually appealing and user-friendly menu display will captivate your ",
    //         icon: benifitIcon,
    //     },
    //     {
    //         title: "Effective customer communication for offers and promotions",
    //         desc: "Showcase your culinary masterpieces with captivating images and detailed descriptions. Our visually appealing and user-friendly menu display will captivate your ",
    //         icon: benifitIcon,
    //     },
    //     {
    //         title: "Print PDC",
    //         desc: "Showcase your culinary masterpieces with captivating images and detailed descriptions. Our visually appealing and user-friendly menu display will captivate your ",
    //         icon: benifitIcon,
    //     },
    //     {
    //         title: "Comprehensive reports",
    //         desc: "Showcase your culinary masterpieces with captivating images and detailed descriptions. Our visually appealing and user-friendly menu display will captivate your ",
    //         icon: benifitIcon,
    //     },
    //     {
    //         title: "User management",
    //         desc: "Showcase your culinary masterpieces with captivating images and detailed descriptions. Our visually appealing and user-friendly menu display will captivate your ",
    //         icon: benifitIcon,
    //     },
    //     {
    //         title: "Access restriction",
    //         desc: "Showcase your culinary masterpieces with captivating images and detailed descriptions. Our visually appealing and user-friendly menu display will captivate your ",
    //         icon: benifitIcon,
    //     },

    // ]

    return (
        <Stack sx={{
            backgroundImage: `url(${shapesVector}) !important`,
            backgroundSize: "cover",
        }}>
            <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>


                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            Efficient Mobile Service Bookings
                        </Typography>

                        <Box display={{ xs: "block    ", md: "none" }} py={2} sx={{ margin: "0 auto !important" }} component={"img"} src={bookNow} alt='vector' width={{ xs: "70%", sm: "50%", md: "40%" }} />

                        <Typography className={classes.mainContent}>
                            Seamlessly manage mobile service bookings with our user-friendly interface. From scheduling appointments to assigning resources, our system streamlines the entire booking process and ensures that your customers receive the best service experience.
                        </Typography>

                    </Stack>
                    <Box display={{ xs: "none", md: "block" }} component={"img"} src={bookNow} alt='vector' width={{ xs: "70%", sm: "50%", md: "40%" }} />
                </Stack>
            </Stack>


            <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>

                    <Box component={"img"} sx={{
                        display: { xs: "none", md: "block" },
                    }} src={communication} alt='vector' width={{ xs: "70%", sm: "50%", md: "35%" }} />

                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            Enhanced Customer Communication
                        </Typography>
                        <Box component={"img"} sx={{
                            display: { xs: "block", md: "none" },
                            m: "0 auto !important",
                            py: 2
                        }} src={communication} width={{ xs: "70%", sm: "50%", md: "35%" }} />
                        <Typography className={classes.mainContent}>
                            Keep your customers informed about your best offers and promotions through our integrated communication features. Send notifications and updates directly to their mobile devices, ensuring effective and timely communication
                        </Typography>


                    </Stack>
                </Stack>
            </Stack>


            <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>



                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            Mini Ecommerce System
                        </Typography>

                        <Box display={{ xs: "block    ", md: "none" }} py={2} sx={{ margin: "0 auto !important" }} component={"img"} src={miniEcom} alt='vector' width={{ xs: "70%", sm: "50%", md: "40%" }} />

                        <Typography className={classes.mainContent}>
                            Unlock additional revenue streams through our built-in mini ecommerce system. Sell spare parts directly to your customers and streamline your spare part sales process, increasing convenience for both you and your customers
                        </Typography>

                    </Stack>
                    <Box display={{ xs: "none", md: "block" }} component={"img"} src={miniEcom} alt='vector' width={{ xs: "70%", sm: "50%", md: "40%" }} />
                </Stack>
            </Stack>


            <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>

                    <Box component={"img"} sx={{
                        display: { xs: "none", md: "block" },
                    }} src={Comprehensive} alt='vector' width={{ xs: "70%", sm: "50%", md: "35%" }} />

                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            Comprehensive Service Tracking
                        </Typography>
                        <Box component={"img"} sx={{
                            display: { xs: "block", md: "none" },
                            m: "0 auto !important",
                            py: 2
                        }} src={Comprehensive} width={{ xs: "70%", sm: "50%", md: "35%" }} />
                        <Typography className={classes.mainContent}>
                            Track your service income and monitor the service status for each booking in real-time. With StackRoots, you gain valuable insights into your service operations, enabling you to make data-driven decisions and optimize your processes
                        </Typography>


                    </Stack>
                </Stack>
            </Stack>

            <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>



                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            Efficient Fleet and Vehicle Management
                        </Typography>

                        <Box display={{ xs: "block    ", md: "none" }} py={2} sx={{ margin: "0 auto !important" }} component={"img"} src={vehleMagt} alt='vector' width={{ xs: "70%", sm: "50%", md: "40%" }} />

                        <Typography className={classes.mainContent}>
                            Successfully manage your service vehicles and supervisors with our system's robust features. Track the location and performance of your vehicles, assign tasks, and stay in control of your fleet, all within one integrated platform
                        </Typography>

                    </Stack>
                    <Box display={{ xs: "none", md: "block" }} component={"img"} src={vehleMagt} alt='vector' width={{ xs: "70%", sm: "50%", md: "40%" }} />
                </Stack>
            </Stack>


            {/* <Stack alignItems={"center"} mb={5} mt={4}>
                <Typography Typography className={classes.mainHeader}>Features of Restaurant food delivery System</Typography>
                <Grid container sx={{ width: "85%", m: "0 auto", mt: { xs: "50px", md: "80px" }, }} align="center" justify="center" rowGap={8}>
                    {
                        benifits.map((card) => (

                            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                                <Card cardData={card} />
                            </Grid>
                        ))
                    }
                </Grid>
            </Stack> */}


            {/* <Stack alignItems={"center"} mb={8} mt={10}>
                <Typography Typography className={classes.mainHeader}> Key Features of  Stackroots PDC Management System </Typography>
                <Grid container sx={{ width: "85%", m: "0 auto", marginTop: { xs: "50px", md: "80px" }, }} align="center" justify="center" alignItems={"center"} justifyContent={"center"} rowGap={8}>
                    {
                        benifits.map((benifit) => (
                            <Grid item xs={12} sm={6} md={6} xl={4}>
                                <Benifits benifit={benifit} />
                            </Grid>
                        ))
                    }
                </Grid>
            </Stack> */}

            <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>

                    <Box component={"img"} sx={{
                        display: { xs: "none", md: "block" },
                    }} src={optimizeVeh} alt='vector' width={{ xs: "70%", sm: "50%", md: "35%" }} />

                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            Optimize Your Vehicle Service Centre with Stackroots' Mobile Vehicle Service Solution
                        </Typography>
                        <Box component={"img"} sx={{
                            display: { xs: "block", md: "none" },
                            m: "0 auto !important",
                            py: 2
                        }} src={optimizeVeh} width={{ xs: "70%", sm: "50%", md: "35%" }} />
                        <Typography className={classes.mainContent}>
                            Choose Stackroots' Mobile Vehicle Service Solution and take your vehicle service center to the next level. Experience the benefits of automation, streamlined operations, and increased customer satisfaction. Get in touch with us today to explore how Stackroots can transform and optimize your fleet management.
                        </Typography>


                    </Stack>
                </Stack>
            </Stack>


        </Stack>
    )
}

export default Details