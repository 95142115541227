import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { useStyles } from '../../styles'

import socialVector from "../../assets/img/new/socialVector.png"
import socialMediaVector from "../../assets/img/new/socialMediaVector.png"
import radioBtn from "../../assets/img/new/radioBtn.png"
import shapesVector from "../../assets/img/products/shapesVector.png"
function Details() {
  const classes = useStyles();
  return (
    <Stack sx={{
      backgroundImage: `url(${shapesVector}) !important`,
      backgroundSize: "cover",
    }}>
      <Stack mt={{ xs: 3, sm: 5 }}>
        <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>



          <Stack maxWidth={"585px"} spacing={3}>
            <Typography className={classes.mainHeader}>
              Key Features of Social media application and management system
            </Typography>
            <Box sx={{ display: { xs: "block", md: "none", }, m: "0 auto !important", py: 2 }} component={"img"} src={socialVector} width={{ xs: "70%", sm: "50%", md: "40%" }} />
            <Typography className={classes.mainContent}>
              StackRoots' Social Media Application and Management System truly stands out as one of the best social media platforms due to its commitment to delivering a seamless and engaging user experience. With a wide range of comprehensive features, our Social Media Application and Management System provides
            </Typography>

            <Stack spacing={1}>
              <Stack direction={"row"} alignItems="center" spacing={2}>
                <Box component={"img"} src={radioBtn} width={"24px"} />
                <Typography className={classes.mainContent}>
                  Rich Media Sharing
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems="center" spacing={2}>
                <Box component={"img"} src={radioBtn} width={"24px"} />
                <Typography className={classes.mainContent}>
                  User-friendly interface
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems="center" spacing={2}>
                <Box component={"img"} src={radioBtn} width={"24px"} />
                <Typography className={classes.mainContent}>
                  Boosts customer satisfaction
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems="center" spacing={2}>
                <Box component={"img"} src={radioBtn} width={"24px"} />
                <Typography className={classes.mainContent}>
                  Commenting and Engagement
                </Typography>
              </Stack>

            </Stack>
          </Stack>
          <Box display={{ xs: "none", md: "block" }} component={"img"} src={socialVector} width={{ xs: "70%", sm: "50%", md: "40%" }} />
        </Stack>
      </Stack>


      <Stack mt={{ xs: 3, sm: 5 }}>
        <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>

          <Box display={{ xs: "none", md: "block" }} component={"img"} src={socialMediaVector} width={{ xs: "70%", sm: "50%", md: "35%" }} />

          <Stack maxWidth={"585px"} spacing={3}>
            <Typography className={classes.mainHeader}>
              Best Social media application and management system
            </Typography>
            <Box sx={{ display: { xs: "block", md: "none", }, m: "0 auto !important", py: 2 }} component={"img"} src={socialMediaVector} width={{ xs: "70%", sm: "50%", md: "40%" }} />
            <Typography className={classes.mainContent}>
              Stackroots' Social Media Application and Management System stands out as an exceptional platform that effortlessly combines functionality, innovation, and a delightful user experience. It provides users with a seamless and engaging experience, empowering them to express their creativity, connect with others, and discover captivating content. The platform's intuitive user interface ensures effortless navigation, while its personalized content recommendations keep users constantly intrigued. Moreover, the platform prioritizes user privacy and provides robust security measures, ensuring a safe and trusted environment for users to connect and share. Overall, StackRoots' Social Media Application and Management System represents the epitome of a user-centric social media platform.
            </Typography>


          </Stack>

        </Stack>
      </Stack>
    </Stack>
  )
}

export default Details