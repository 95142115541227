import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { useStyles } from '../../styles'

import lmsVector1 from "../../assets/img/new/lmsVector1.png"
import lmsVector2 from "../../assets/img/new/lmsVector2.png"
import radioBtn from "../../assets/img/new/radioBtn.png"
import shapesVector from "../../assets/img/products/shapesVector.png"
import Card from './Card'

import icon1 from "../../assets/img/new/icons/icon (1).png"
import icon2 from "../../assets/img/new/icons/icon (2).png"
import icon3 from "../../assets/img/new/icons/icon (3).png"
import icon4 from "../../assets/img/new/icons/icon (4).png"
import icon5 from "../../assets/img/new/icons/icon (5).png"
import icon6 from "../../assets/img/new/icons/icon (6).png"
import icon7 from "../../assets/img/new/icons/icon (7).png"
import icon8 from "../../assets/img/new/icons/icon (8).png"
import icon9 from "../../assets/img/new/icons/icon (9).png"
import icon10 from "../../assets/img/new/icons/icon (10).png"
import icon11 from "../../assets/img/new/icons/icon (11).png"
import icon12 from "../../assets/img/new/icons/icon (12).png"


import benifitIcon from "../../assets/img/new/benifitIcon.png";
import benifitIcon2 from "../../assets/img/new/benifitIcon2.png";
import benifitIcon3 from "../../assets/img/new/benifitIcon3.png";
import benifitIcon4 from "../../assets/img/new/benifitIcon4.png";
import benifitIcon5 from "../../assets/img/new/benifitIcon5.png";



import Benifits from './Benifits'

function Details() {
    const classes = useStyles();

    const cardData = [
        {
            title: "Online Classes",
            desc: "Offer various formats for online classes, such as live webinars, video conferencing, or pre-recorded lectures.",
            img: icon1,
        },
        {
            title: "Live Chat",
            desc: "A real-time chat functionality where students can interact with the instructor during live classes or discussions.",
            img: icon2,
        },
        {
            title: "Mobile-Friendly",
            desc: "A responsive design that adapts to various devices (desktops, laptops, tablets, smartphones) allows students to access classes and materials from anywhere.",
            img: icon3,
        },
        {
            title: "Customization",
            desc: "Offers features for customizing the look and feel of the learning environment to individual learners' needs.",
            img: icon6,
        },
        {
            title: "Notifications and Reminders",
            desc: "Offer various formats for online classes, such as live webinars, video conferencing, or pre-recorded lectures.",
            img: icon7,
        },
        {
            title: "Grading and Assessment",
            desc: "Provide tools for instructors to grade assignments, quizzes, and exams efficiently. Students will be able to access their grades and feedback.",
            img: icon9,
        },
        {
            title: "Attendance Tracking",
            desc: "For live classes, tracking attendance is essential. Stack-ED LMS has a feature to monitor and record student attendance during live sessions.",
            img: icon4,
        },
        {
            title: "Billing and Invoice Management",
            desc: "Simplifies the process of billing and invoice management, providing efficient solutions for financial transactions.",
            img: icon11,
        },
        {
            title: "Progress Tracking",
            desc: "Provide tools for Monitoring individual and group progress to ensure successful learning outcomes.",
            img: icon8,
        },
        {
            title: "Course Management",
            desc: "Enable instructors to create, organize, and manage courses easily. This includes adding course materials, assignments, quizzes, and other resources.",
            img: icon10,
        },
        {
            title: "Collaborative Projects",
            desc: "Foster teamwork and collaboration of students to work together on projects within platform. This allows seamless collaboration & enhance learning experience.",
            img: icon5,
        },
        {
            title: "Discussion Forums",
            desc: "Enhance interactive learning by incorporating discussion forums within the LMS platform. Students can engage in discussions, ask questions, and share insights",
            img: icon12,
        },



    ]

    const benifits = [
        {
            title: "Robust Analytics",
            desc: "Gain valuable insights into learner performance and course effectiveness, facilitating data-driven decision-making.",
            icon: benifitIcon,
        },
        {
            title: "Scalable & Flexible",
            desc: "Whether you're an educational institution, corporation, or individual instructor, our LMS adapts to your needs.",
            icon: benifitIcon2,
        },
        {
            title: "24/7 Support",
            desc: "Our dedicated support team is ready to assist you every step of the way, ensuring a smooth learning experience.",
            icon: benifitIcon3,
        },
        {
            title: "Personalized Learning",
            desc: "Tailor content and assessments to individual learning styles, promoting better retention and comprehension.",
            icon: benifitIcon4,
        },
        {
            title: "Enhanced Security",
            desc: "Rest easy knowing your data and content are protected with our state-of-the-art security measures.",
            icon: benifitIcon5,
        },
    ]

    return (
        <Stack sx={{
            backgroundImage: `url(${shapesVector}) !important`,
            backgroundSize: "cover",
        }}>
            <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>



                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            Create, Manage, and Deliver Engaging Online Courses
                        </Typography>

                        <Box display={{ xs: "block    ", md: "none" }} py={2} sx={{ margin: "0 auto !important" }} component={"img"} src={lmsVector1} width={{ xs: "70%", sm: "50%", md: "40%" }} />

                        <Typography className={classes.mainContent}>
                            With Stack-Ed LMS, you have the power to create engaging and interactive online courses that captivate your learners. Our platform offers a user-friendly interface and robust course creation tools that make it easy for you to develop high-quality content. From organizing course materials to incorporating multimedia elements, you have the flexibility to customize your courses to suit your teaching style and meet your learners' needs. Stack-Ed LMS streamlines the process of managing and delivering courses, ensuring a seamless experience for both instructors and learners.
                        </Typography>

                    </Stack>
                    <Box display={{ xs: "none", md: "block" }} component={"img"} src={lmsVector1} width={{ xs: "70%", sm: "50%", md: "40%" }} />
                </Stack>
            </Stack>


            <Stack mt={{ xs: 3, sm: 5 }}>
                <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>

                    <Box component={"img"} sx={{
                        display: { xs: "none", md: "block" },
                    }} src={lmsVector2} width={{ xs: "70%", sm: "50%", md: "35%" }} />

                    <Stack maxWidth={"585px"} spacing={3}>
                        <Typography className={classes.mainHeader}>
                            Track Learner Progress and Analyze Performance Data
                        </Typography>
                        <Box component={"img"} sx={{
                            display: { xs: "block", md: "none" },
                            m: "0 auto !important",
                            py: 2
                        }} src={lmsVector2} width={{ xs: "70%", sm: "50%", md: "35%" }} />
                        <Typography className={classes.mainContent}>
                            Stack-Ed LMS provides a comprehensive set of features that allow you to track learner progress and analyze performance data. With our intuitive analytics tools, you can gain valuable insights into how your learners are engaging with the course material. Track completion rates, monitor learner activity, and identify areas where learners may need additional support. The performance data and analytics provided by Stack-Ed LMS empower you to make data-driven decisions, optimize your teaching strategies, and enhance the overall learning experience.
                        </Typography>


                    </Stack>
                </Stack>
            </Stack>

            <Stack alignItems={"center"} mb={5} mt={4}>
                <Typography Typography className={classes.mainHeader}>Features of Stack-Ed LMS</Typography>
                <Grid container sx={{ width: "85%", m: "0 auto", mt: { xs: "50px", md: "80px" }, }} align="center" justify="center" rowGap={8}>
                    {
                        cardData.map((card) => (

                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                <Card cardData={card} />
                            </Grid>
                        ))
                    }
                </Grid>
            </Stack>


            <Stack alignItems={"center"} mb={8} mt={10}>
                <Typography Typography className={classes.mainHeader}> Benefits of Stack-Ed LMS</Typography>
                <Grid container sx={{ width: "85%", m: "0 auto", marginTop: { xs: "50px", md: "80px" }, }} align="center" justify="center" alignItems={"center"} justifyContent={"center"} rowGap={8}>
                    {
                        benifits.map((benifit) => (
                            <Grid item xs={12} sm={6} md={6} xl={4}>
                                <Benifits benifit={benifit} />
                            </Grid>
                        ))
                    }
                </Grid>
            </Stack>
        </Stack>
    )
}

export default Details