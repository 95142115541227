import {
  Hero,
  Video,
  Benefits,
  Footer,
  Testimonials,
  Cta,
  Faq,
  PopupWidget,
  Description,
  Product,
  SectionTitle,
} from "../components/_index";

import ContactForm from "../components/Contact/ContactForm";
import { Helmet } from "react-helmet";
import { useStyles } from "../styles";
import { Stack, Typography } from "@mui/material";
import GetAQuotePopUp from "../components/GetAQuotePopUp";
//import dynamic from "next/dynamic";

// const Video = dynamic(() => import("../components/video"));

// const Benefits = dynamic(() => import("../components/benefits"));
// const Footer = dynamic(() => import("../components/footer"));
// const Testimonials = dynamic(() => import("../components/testimonials"));
// const Cta = dynamic(() => import("../components/cta"));
// const Faq = dynamic(() => import("../components/faq"));

// const PopupWidget = dynamic(() => import("../components/popupWidget"));

const Products = () => {
  const classes = useStyles();
  return (
    <>
      <Helmet>
        <title>Stackroots | Products</title>
      </Helmet>

      {/* <Head>
        <title>Stackroots - Best website company</title>
        <meta name="description" content="Stackroots - Best website company" />
        <link rel="icon" href="/img/Icon.png" />
      </Head> */}
      {/* <Stack mt={6} justifyContent="center" alignItems="center" p={2}>
        <Stack maxWidth={"650px"}>
          <Typography textAlign={"center"} className={classes.mainHeader}> People Who Love Our Projects</Typography>
          <Typography mt={2} className={classes.mainContent}> Our each project is not just a projects. Each work will talk about
            the customer’s stories. So we create good stories through our
            projects. Some of the recent clients here…</Typography>
        </Stack>
      </Stack> */}
      <Product />
      {/* <Cta /> */}

      {/* <ContactForm /> */}
      <GetAQuotePopUp button={false} />


    </>
  );
};

export default Products;
