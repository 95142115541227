import React from 'react'
import { Helmet } from 'react-helmet'
import ContactForm from '../../components/Contact/ContactForm'
import Seo1 from '../../components/Seo/Seo1'
import seoForm from "../../assets/img/services/seoForm.png"

const Seo = () => {
  return (
    <>
      <Helmet>
        <title>Stackroots | SEO</title>
      </Helmet>
      <div>
        <Seo1 />
        <ContactForm title="Get a quote" image={seoForm} />
      </div>
    </>
  )
}

export default Seo