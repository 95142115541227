import React from 'react'
import { Helmet } from 'react-helmet';
import ContactForm from "../../components/Contact/ContactForm";
import PopupWidget from '../../components/popupWidget'
import Banner8 from '../../components/WebsiteDevelopment/Banner8'
import Descriptions from '../../components/WebsiteDevelopment/Descriptions'
import Detail8 from '../../components/WebsiteDevelopment/Detail8'

const WebsiteDevelopment = () => {
  return (
    <>
      <Helmet>
        <title>Stackroots | Website Development</title>
      </Helmet>
      <div>
        <Banner8 />
        <Descriptions />
        <Detail8 />
        <ContactForm title="Get a quote" />
        {/* <PopupWidget /> */}
      </div>
    </>

  )
}

export default WebsiteDevelopment