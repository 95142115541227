import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { useStyles } from '../../styles'

import ecomVector from "../../assets/img/new/ecomVector.png"
import ecomCartVector from "../../assets/img/new/ecomCartVector.png"
import radioBtn from "../../assets/img/new/radioBtn.png"
import shapesVector from "../../assets/img/products/shapesVector.png"
function Details() {
  const classes = useStyles();
  return (
    <Stack sx={{
      backgroundImage: `url(${shapesVector}) !important`,
      backgroundSize: "cover",
    }}>
      <Stack mt={{ xs: 3, sm: 5 }}>
        <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>



          <Stack maxWidth={"585px"} spacing={3}>
            <Typography className={classes.mainHeader}>
              Unleash the Best-in-Class E-Commerce Solutions with StackRoots
            </Typography>
            <Box sx={{ display: { xs: "block", md: "none", }, m: "0 auto !important", py: 2 }} component={"img"} src={ecomVector} width={{ xs: "70%", sm: "50%", md: "40%" }} />
            <Typography className={classes.mainContent}>
              When you choose StackRoots for your e-commerce website and application needs, you can expect nothing less than excellence. Our passion for creating exceptional solutions, combined with our technical prowess, makes us the ideal partner to bring your online business to new heights.
            </Typography>

            <Stack spacing={1}>
              <Stack direction={"row"} alignItems="center" spacing={2}>
                <Box component={"img"} src={radioBtn} width={"24px"} />
                <Typography className={classes.mainContent}>
                  Intuitive User Interface
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems="center" spacing={2}>
                <Box component={"img"} src={radioBtn} width={"24px"} />
                <Typography className={classes.mainContent}>
                  Analytics and Reporting
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems="center" spacing={2}>
                <Box component={"img"} src={radioBtn} width={"24px"} />
                <Typography className={classes.mainContent}>
                  SEO-Friendly Architecture
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems="center" spacing={2}>
                <Box component={"img"} src={radioBtn} width={"24px"} />
                <Typography className={classes.mainContent}>
                  Scalability and Performance
                </Typography>
              </Stack>
              {/* <Stack direction={"row"} alignItems="center" spacing={2}>
                <Box component={"img"} src={radioBtn} width={"24px"} />
                <Typography className={classes.mainContent}>
                  Condimentum in erat praesent
                </Typography>
              </Stack> */}
            </Stack>
          </Stack>
          <Box display={{ xs: "none", md: "block" }} component={"img"} src={ecomVector} width={{ xs: "70%", sm: "50%", md: "40%" }} />
        </Stack>
      </Stack>


      <Stack mt={{ xs: 3, sm: 5 }}>
        <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-around"} spacing={3}>

          <Box display={{ xs: "none", md: "block" }} component={"img"} src={ecomCartVector} width={{ xs: "70%", sm: "50%", md: "35%" }} />

          <Stack maxWidth={"585px"} spacing={3}>
            <Typography className={classes.mainHeader}>
              Choose StackRoots for Your E-commerce Exceptional Websites and Applications
            </Typography>
            <Box sx={{ display: { xs: "block", md: "none", }, m: "0 auto !important", py: 2 }} component={"img"} src={ecomCartVector} width={{ xs: "70%", sm: "50%", md: "40%" }} />
            <Typography className={classes.mainContent}>
              What sets us apart is our commitment to staying at the forefront of industry trends and technological advancements. We leverage the latest tools and frameworks to build scalable and robust e-commerce solutions that can handle high volumes of traffic and transactions. In addition to our technical expertise, we prioritize security and data protection. We implement industry-standard security measures to safeguard your customers' sensitive information, earning their trust and establishing your brand as a reliable and secure online shopping destination.
            </Typography>


          </Stack>

        </Stack>
      </Stack>
    </Stack>
  )
}

export default Details