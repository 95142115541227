import { Box, Card, Grid, Paper, Typography, Stack } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { Fade } from "react-awesome-reveal";
import CardContent from "@mui/material/CardContent";
import monitor from "../../assets/img/products/monitor.png";
import mobile from "../../assets/img/products/mobile.png";

const Descriptions = () => {
  return (
    <Box sx={{ marginTop: "50px" }}>
      {/* <Fade triggerOnce="true" direction="up"> */}
        <Typography
          textAlign="center"
          variant="h6"
          fontWeight="bold"
          sx={{ fontSize: { xs: 25, md: 32, sm: 32 } }}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing
        </Typography>
        <Typography
          textAlign="center"
          variant="body1"
          fontWeight="bold"
          fontSize="16px"
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
          purus sit amet luctus{" "}
        </Typography>
        <Typography
          textAlign="center"
          variant="body1"
          fontWeight="bold"
          fontSize="16px"
        >
          venenatisaliquam, purus sit amet luctus venenatis
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            height: { xs: "200px", md: "600px" },
          }}
        >
          <img src={monitor} alt="/" />
        </Box>
        <Container maxWidth="xl">
          <Typography variant="body2" textAlign="justify" fontSize="18px">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis id
            mi leo libero et diam nibh at amet. Quis urna, consectetur et cras.
            Nisi velit dolor congue fringilla quam. Nisl tristique tellus massa
            vivamus purus suspendisse. Vel, faucibus augue lectus aliquam mattis
            eros, amet, aenean. Consectetur tincidunt mauris consectetur aliquam
            ultrices porta dui id. Interdum arcu libero, fermentum ut vestibulum
            netus tortor elementum. Condimentum in erat praesent dignissim
            aenean. Sapien, at duis amet mi enim sit donec a. Faucibus odio
            tempor vel semper. Cursus aenean commodo ultrices ut. Rhoncus mi,
            amet et sit. Odio egestas odio purus massa dictum semper et faucibus
            in. Sollicitudin nisl feugiat tellus, vel urna, vel et.
          </Typography>
        </Container>
      {/* </Fade> */}
      <Container maxWidth="xl">
        {/* <Fade triggerOnce="true" direction="up"> */}
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Stack
              direction={{ xs: "column", md: "row" }}
              sx={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box sx={{ flexDirection: "column", marginTop: "50px", flex: 3 }}>
                <Typography
                  variant="h2"
                  fontWeight="bold"
                  textAlign="initial"
                  lineHeight="60px"
                  sx={{
                    fontSize: { xs: 32, md: 34 },
                    lineHeight: "48px",
                    color: "black",
                  }}
                >
                  Lorem ipsum dolor sit, consectetur <br />
                  adipiscing ut aliquam,
                </Typography>

                <Typography
                  variant="h4"
                  fontSize="18px"
                  fontWeight="medium"
                  marginTop="10px"
                  textAlign="initial"
                  sx={{ lineHeight: "31px", color: "black" }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Sagittis id mi leo libero et diam nibh at amet. Quis urna,
                  consectetur et cras. Nisi velit dolor congue fringilla quam.
                  Nisl tristique tellus massa vivamus purus suspendisse. Vel,
                  faucibus augue lectus aliquam mattis eros,
                </Typography>
              </Box>

              <Box
                sx={{
                  marginTop: { xs: "0px", md: "40px" },

                  flex: 1,
                }}
              >
                <img src={mobile} alt="banner" width="100%" />
              </Box>
            </Stack>
          </Box>
        {/* </Fade> */}
      </Container>
    </Box>
  );
};

export default Descriptions;
