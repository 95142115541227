
import { useNavigate } from "react-router-dom";
import { Button, Typography, Stack, Box, Grid, Container, CardActionArea } from "@mui/material";
import { useStyles } from "../styles";

import productVector from "../assets/img/new/productVector.png"
import architect from "../assets/img/new/architect.png"
import erp from "../assets/img/new/erp.png"
import gym from "../assets/img/new/gym.png"
import blockchain from "../assets/img/new/blockchain.png"
import hospistack from "../assets/img/new/hospistack.png"
import autostack from "../assets/img/new/autostack.png"
import flight from "../assets/img/new/flightBooking.png"
import social from "../assets/img/new/socialmedia.png"
import ecommerce from "../assets/img/new/ecom.png"
import attendance from "../assets/img/new/attendance.png"
import check from "../assets/img/new/radioBtn.png";
import lms from "../assets/img/new/lms.png";
import certVerify from "../assets/img/new/certverifycard.jpeg";
import pdc from "../assets/img/new/pdc.jpeg";
import restoFood from "../assets/img/new/restoFood.png";
import hrms from "../assets/img/new/hrms.jpeg";
import vehicleService from "../assets/img/new/vehicleService.jpeg";



const Product = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  const productsArray = [
    {
      title: "StackArch",
      description: "Manage your architectural projects effortlessly with StackArch, a powerful and intuitive all-in-one project management ERP software that is designed specifically for architects and architectural firms.      ",
      image: architect,
      link: "/products/stackarch",
    },
    {
      title: "Stack-Ed LMS",
      description: "Stack-Ed LMS provides a set of features that allow you to track learner progress and analyze performance data. With our intuitive analytics tools, you can gain valuable insights into how your learners are engaging with the course material.",
      image: lms,
      link: "/products/lms",
    },
    {
      title: "CertVerify",
      description: "CertVerify by StackRoots is a cutting-edge platform that revolutionizes the way certificates are verified. By harnessing the power of Blockchain technology, CertVerify ensures the utmost security and authenticity of certificates, effectively preventing any fraudulent activities.",
      // TODO:IMG
      image: certVerify,
      link: "/products/certverify",
    },
    {
      title: "E-Commerce",
      description: "StackRoots Ecommerce Application, a powerful and innovative solution designed to transform your online business operations. With our cutting-edge platform, you can create a seamless and engaging shopping experience for your customers, while efficiently managing your inventory and streamlining your sales processes.",
      image: ecommerce,
      link: "/products/ecommerce",
    },
    {
      title: "Blockchain-Based Decentralized Marketplace",
      description: "Discover the future of secure and transparent transactions with Blockchain-Based Decentralized Marketplace. Our innovative platform combines blockchain technology with trust and transparency, revolutionizing how buyers and sellers engage in secure transactions.",
      image: blockchain,
      link: "/products/blockchain",
    },
    {
      title: "PDC Management System",
      description: "PDC Management System, a cutting-edge solution designed to simplify and automate your post-dated cheque management. With our platform, you can reduce manpower costs and ensure hassle-free processing of PDCs, all while enjoying safe and secure communication.",
      image: pdc,
      link: "/products/pdc",
    },
    {
      title: "Restaurant food delivery system",
      description: "Restaurant food delivery system is designed to take your restaurant business to new heights in the online food delivery market. With our platform, you can tap into the vast potential of online ordering and transform the way your restaurant operates.",
      image: restoFood,
      link: "/products/restaurant",
    },
    {
      title: "HRMS Application",
      description: "Stackroots' HRMS Application, the ultimate solution for businesses seeking to optimize their human resource management processes.  Our comprehensive and cutting-edge Human Resource Management System is designed to streamline and elevate HR processes, aligning with the demands of the digital era.",
      image: hrms,
      link: "/products/hrm",
    },
    {
      title: "Mobile Vehicle Service Solution",
      description: "Stackroots' Mobile Vehicle Service Solution, the ultimate cloud-based solution for motor vehicle service centers. With our innovative solution, you can take control of your fleet management, mobile service bookings, service vehicle tracking, and even set up a mini ecommerce system for spare part sales.",
      image: vehicleService,
      link: "/products/mobile-vehicle",
    },
    {
      title: "ERP & CRM Software",
      description: "As a leading ERP solution provider, we prioritize productivity enhancement and automation to reduce manual tasks. Our expert team specializes in customizing and implementing top-tier systems designed to meet your unique requirements.",
      image: erp,
      link: "/products/erp",
    },
    {
      title: "Gym Anytime",
      description: "Gym Anytime is an all-in-one solution for managing clients. With Gym Anytime, you can easily manage bookings, optimize your schedule, and provide exceptional service to clients anytime and anywhere.",
      image: gym,
      link: "/products/gymanytime",
    },
    {
      title: "HospitStack",
      description: "HospitStack is an all-in-one online booking and consulting software designed specifically for healthcare facilities,that offers features to enhance efficiency and provide a seamless experience for both providers and patients.",
      image: hospistack,
      link: "/products/hospistack",
    },
    {
      title: "AutoStack",
      description: "AutoStack, a comprehensive software solution is designed specifically for service stations. AutoStack facilitates efficient management of service vehicles and supervisors, ensuring seamless coordination and timely job completion.",
      image: autostack,
      link: "/products/autostack",
    },
    {
      title: "Flight Booking",
      description: "This software simplifies the flight booking process, focusing on operational efficiency and customer satisfaction. With a user-friendly interface, customers can easily search for flights, compare prices, and make bookings seamlessly.",
      image: flight,
      link: "/products/flight",
    },
    {
      title: "  Social Media",
      description: "Social Media Application and Management System is a remarkable platform that seamlessly blends functionality, innovation, and a delightful user experience. Offers a seamless and engaging environment where users can connect with others, and explore captivating content.",
      image: social,
      link: "/products/socialmedia",
    },

    {
      title: "Attendance Management",
      description: "Attendance Management software is designed to simplify and automate employee attendance tracking for businesses. With its comprehensive set of features, this streamlines the process of managing employee attendance, ensuring accuracy and efficiency.",
      image: attendance,
      link: "/products/attendance",
    },

  ]
  return (
    <Stack gap={4}>
      <Box
        sx={{
          width: "100%",
          // height: "100vh",
          display: "flex",
          flexWrap: "wrap",
          p: { xs: 3, md: 6 },
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          sx={{
            alignItems: "center",
            justifyContent: "center",
          }}
          mt={{ xs: 5, md: 0 }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <Typography
              className={classes.mainHeader}
              textAlign={{ xs: "center", md: "initial" }}
              mb={3}
            >
              Our innovative software solutions for businesses
            </Typography>
            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "flex", md: "none" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box component="img" width={{ sm: "70%" }} src={productVector} alt="banner" />
            </Box>
            <Typography
              marginTop="10px"
              className={classes.mainContent}
            >
              At Stackroots, we take pride in offering unique and customizable products that cater to the specific needs of our clients. We understand that every client is unique, which is why we take the time to understand their needs and tailor our products accordingly. Explore our range of products and discover how we can help your business grow and succeed.
            </Typography>
          </Box>

          <Box
            sx={{
              marginTop: { xs: "0px", md: "0px" },
              flex: 1,
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={productVector} width="60%" alt="banner" />
          </Box>
        </Stack>
      </Box>


      <Stack width={"100%"}>
        <Typography textAlign={"center"} className={classes.mainHeader}>Our Products</Typography>
        <Grid container rowGap={12} columnGap={0} mt={6} align="center" justify="center" width={"100%"} mb={20}>
          {
            productsArray.map((product, index) => (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={4} sx={{ height: "auto" }}>
                <Stack sx={{
                  height: "100%",
                  background: "white",
                  maxWidth: { xs: "340px", md: "400px", lg: "500px", sm: "500px" },
                  borderRadius: "12px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}>
                  <CardActionArea onClick={() => navigate(product?.link ? product?.link : null)}>
                    <Box sx={{ borderRadius: "10px 10px 0px 0px" }} component={"img"} alt="vector" src={product?.image} width={"100%"} />
                    <Stack p={3} color={"black"} gap={2} alignItems={"flex-start"}>
                      <Typography textAlign={"left"} sx={{
                        fontSize: "25px",
                        fontWeight: "500",
                      }}>{product?.title}</Typography>
                      <Typography textAlign={"left"}>
                        {product?.description}
                      </Typography>
                    </Stack>
                  </CardActionArea>
                  <Button onClick={() => navigate(product?.link ? product?.link : null)} sx={{ "width": "130px", "height": "43px", "background": "#070769 !important", "borderRadius": "6px", mx: 3, mb: 3 }}>
                    View More
                  </Button>
                </Stack>
              </Grid>
            ))
          }
        </Grid>
      </Stack>

    </Stack>
  );
};

export default Product;
