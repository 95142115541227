import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Moment from "moment";
import { useNavigate } from "react-router-dom";
import "../../components/Blogs/BlogCard.css";
import { CustomButton } from "../../utils/Button";
import { useStyles } from "../../styles";
import { AccessTime, CalendarMonth } from "@mui/icons-material";
const BlogCard = ({ data }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  let firstParagraph = "";
  const paragraphs = data.description?.match(/<p>(.*?)<\/p>/g);

  if (paragraphs) {
    for (let i = 0; i < paragraphs.length; i++) {
      const paragraph = paragraphs[i].replace(/<\/?p>/g, "");
      if (paragraph) {
        firstParagraph = paragraph;
        break;
      }
    }
  } else {
    firstParagraph = data.description;
  }

  return (
    <Stack sx={{ padding: "10px" }}>
      <div>
        <Box sx={{ mb: 2 }} >
          <Typography
            className={classes.subMainHeader}
          >
            {data.title}
          </Typography>
          <Button variant="text"><CalendarMonth />&nbsp; {Moment(data.createdAt).fromNow()}</Button>
          <Button variant="text"><AccessTime />&nbsp; {data.readingTime} min read</Button>
        </Box>

        <Box>
          <img src={data.images[0]} width={"100%"} alt="/" className="image" />
        </Box>
        <Box mb={3}>
          <Typography
            // variant="h6"
            className="sliderMarkdown"
            dangerouslySetInnerHTML={{
              __html: firstParagraph,
            }}
            sx={{
              // textOverflow: "ellipsis",
              // overflow: "hidden",
              // whiteSpace: "nowrap",
              // // fontSize: "23px",
              my: 3,
              height: "75px",
              // display: "-webkit-box",
              // "-webkit-line-clamp": 3,
              // "-webkit-box-orient": "vertical",
              display: "-webkit-box",
              "-webkit-line-clamp": 3,
              "-webkit-box-orient": "vertical",
              overflow: "hidden",
            }}
          />
          <CustomButton
            onClick={() => navigate(`/blogs/blogdetail?id=${data._id}`)}
          >
            Read More
          </CustomButton>
        </Box>
      </div>
    </Stack>
  );
};

export default BlogCard;
