import {
  Box,
  Stack,
  Typography
} from "@mui/material";
import React from "react";
import Marquee from "react-fast-marquee";
import delivery from "../../assets/img/products/delivery.png";
import design from "../../assets/img/products/design.png";
import adaptivebg from "../../assets/img/services/adaptivebg.png";
import htmlbanner from "../../assets/img/services/htmlBanner.png";
import shapes from "../../assets/img/services/shapes2.png";
import webmethodbanner from "../../assets/img/services/webmethodbanner.png";
import webmethodology from "../../assets/img/services/webmethodology.png";

import angular from "../../assets/img/services/logo/angularLogo.png";
import express from "../../assets/img/services/logo/expressLogo.png";
import mongo from "../../assets/img/services/logo/mongoLogo.png";
import mui from "../../assets/img/services/logo/muilogo.png";
import node from "../../assets/img/services/logo/nodelogo.png";
import react from "../../assets/img/services/logo/reactlogo.png";

import custom from "../../assets/img/services/vectors/custom.png";
import shop from "../../assets/img/services/vectors/shop.png";
import webDesign from "../../assets/img/services/vectors/webDesign.png";
import seoBoost from "../../assets/img/services/vectors/seoBoost.png";
import siteSupport from "../../assets/img/services/vectors/siteSupport.png";

import { useStyles } from "../../styles";
import Techstack from "../Techstack";

const AdaptiveDesign = () => {
  const classes = useStyles();
  const icons = [
    {
      img: react
    },
    {
      img: angular
    },
    {
      img: node
    },
    {
      img: mongo
    },
    {
      img: mui
    },
    {
      img: express
    },
  ]

  return (
    <Stack sx={{ background: "#131331" }} >
      <div
        className="adaptivebg"
        style={{
          background: `url(${adaptivebg})`,
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat"
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: { xs: "inherit", md: "100vh" },
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
          // mt={12}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography mb={3} className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
              >
                Website Development Services
              </Typography>


              <Box
                sx={{
                  display: { xs: "flex", md: "none" },
                  marginTop: { xs: "0px", md: "40px" },
                  flex: 1,
                  // display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                }}
              >
                <img src={design} alt="banner" />
              </Box>

              <Typography
                mt={2} className={classes.mainContent}
              >
                At Stackroots, we offer comprehensive website development services to help businesses establish a strong online presence and achieve their digital goals. Our team of experienced developers and designers works closely with clients to create websites that are not only visually appealing but also highly functional and user-friendly. With our expertise in various web development technologies, we ensure that every website we build is tailored to meet the unique needs and requirements of our clients.
              </Typography>
            </Box>

            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={design} alt="banner" />
            </Box>
          </Stack>
        </Box>

        {/*  */}

        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row-reverse" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={12}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}
              >
                Custom Website Development
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "0px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img alt="vector" src={custom} />
              </Box>
              <Typography
                marginTop="10px"
                className={classes.mainContent}
              >
                Our custom website development services focus on creating unique and personalized websites that reflect the brand identity and values of our clients. We understand that every business is different, and we strive to develop websites that stand out from the competition. Our team takes the time to understand the specific goals and objectives of our clients, allowing us to create customized solutions that deliver exceptional results.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={custom} width={"80%"} />
            </Box>
          </Stack>
        </Box>

        {/*  */}

        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={12}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}
              >
                E-commerce Website Development
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "0px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img alt="vector" src={shop} />
              </Box>
              <Typography
                marginTop="10px"
                className={classes.mainContent}
              >
                For businesses looking to sell products or services online, our e-commerce website development services are the perfect solution. We specialize in building secure and scalable e-commerce platforms that provide a seamless shopping experience for customers. From product listings and inventory management to secure payment gateways and order processing, we ensure that every aspect of the e-commerce website is optimized for success.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={shop} />
            </Box>
          </Stack>
        </Box>

        {/*  */}

        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row-reverse" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={12}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}
              >
                Adaptive Web Design
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "0px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img alt="vector" src={webDesign} />
              </Box>
              <Typography
                marginTop="10px"
                className={classes.mainContent}
              >
                Adaptive web designs allow users to access your website with an intuitive and enjoyable interface on any device. Adaptive web design is just another term for progressive enhancement of which responsive web design can be an integral part but is more a holistic approach to web designs. Stackroots creates an adaptive web design that loads easier and faster. Don’t just settle for a good-looking website. Stackroots creates mobile-responsive and SEO-friendly websites that attract both your customers and search engines.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={webDesign} width={"100%"} />
            </Box>
          </Stack>
        </Box>

        {/*  */}

        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 3, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={12}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.mainHeader}
                textAlign={{ xs: "center", md: "initial" }}
                mb={3}
              >
                Website Maintenance and Support
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "0px", md: "40px" },
                  flex: 1,
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img alt="vector" src={siteSupport} />
              </Box>
              <Typography
                marginTop="10px"
                className={classes.mainContent}
              >
                We understand that maintaining a website can be time-consuming and technically challenging for businesses. That's why we offer ongoing website maintenance and support services to ensure that our clients' websites are always up-to-date, secure, and running smoothly. Our team is available to address any technical issues, perform regular updates, and provide guidance on optimizing website performance.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={siteSupport} width={"90%"} />
            </Box>
          </Stack>
        </Box>

      </div>

      <Stack
        className="webmethodbg"
        sx={{
          background: { xs: `url(${htmlbanner})`, md: `url(${webmethodbanner})` },
          backgroundSize: { xs: "cover", md: "none" }
        }}
      >
        <Box sx={{ marginTop: { xs: "20px", md: "60px" }, p: { xs: 0, md: 6 }, }}>
          <Typography
            className={classes.mainHeader}
            textAlign="center"
            sx={{
              marginBottom: "50px",
              marginTop: { xs: "45px", md: 0, },

            }}
          >
            Our Web Development Methodology
          </Typography>
          {/* <Grid container spacing={2} sx={{marginTop:'50px'}}>
                <Grid item md={4} sm={6} xs={12}>
                  <div className="hrms">
                    <Card>
                      <CardContent
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <IconButton
                          size="large"
                          aria-label="show 4 new mails"
                          color="inherit"
                        >
                          <img
                            src={humanresources}
                            style={{ fontSize: "2.5rem", color: "#4F46E5" }}
                          />
                        </IconButton>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          textAlign="center"
                        >
                          Defining Site Goals
                        </Typography>

                        <info>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            textAlign=""
                            fontSize="16px"
                            sx={{ color: "white" }}
                          >
                             Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                           Quam bibendum fames auctor duis phasellus sagittis. Praesent quisque leo id tortor, urna enim.
                            Adipiscing bibendum arcu mattis et amet. 
                           Volutpat, enim enim faucibus platea consectetur vitae.
                          </Typography>
                        </info>
                      </CardContent>
                    </Card>
                  </div>
                </Grid>

                <Grid item md={4} sm={6} xs={12}>
                  <div className="hrms">
                    <Card>
                      <CardContent
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <IconButton
                          size="large"
                          aria-label="show 4 new mails"
                          color="inherit"
                        >
                          <img
                            src={accounting}
                            style={{ fontSize: "2.5rem", color: "#4F46E5" }}
                          />
                        </IconButton>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          textAlign="center"
                        >
                          Strategy & Architecture
                        </Typography>

                        <info>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            textAlign=""
                            fontSize="16px"
                            sx={{ color: "white" }}
                          >
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                           Quam bibendum fames auctor duis phasellus sagittis. Praesent quisque leo id tortor, urna enim.
                            Adipiscing bibendum arcu mattis et amet. 
                           Volutpat, enim enim faucibus platea consectetur vitae.
                          </Typography>
                        </info>
                      </CardContent>
                    </Card>
                  </div>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <div className="hrms">
                    <Card>
                      <CardContent
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <IconButton
                          size="large"
                          aria-label="show 4 new mails"
                          color="inherit"
                        >
                          <img
                            src={management}
                            style={{ fontSize: "2.5rem", color: "#4F46E5" }}
                          />
                        </IconButton>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          textAlign="center"
                        >
                          Design Mock-Ups
                        </Typography>

                        <info>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            textAlign=""
                            fontSize="16px"
                            sx={{ color: "white" }}
                          >
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                           Quam bibendum fames auctor duis phasellus sagittis. Praesent quisque leo id tortor, urna enim.
                            Adipiscing bibendum arcu mattis et amet. 
                           Volutpat, enim enim faucibus platea consectetur vitae.
                          </Typography>
                        </info>
                      </CardContent>
                    </Card>
                  </div>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <div className="hrms">
                    <Card>
                      <CardContent
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <IconButton
                          size="large"
                          aria-label="show 4 new mails"
                          color="inherit"
                        >
                          <img
                            src={asset}
                            style={{ fontSize: "2.5rem", color: "#4F46E5" }}
                          />
                        </IconButton>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          textAlign="center"
                        >
                          Development
                        </Typography>

                        <info>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            textAlign=""
                            fontSize="16px"
                            sx={{ color: "white" }}
                          >
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                           Quam bibendum fames auctor duis phasellus sagittis. Praesent quisque leo id tortor, urna enim.
                            Adipiscing bibendum arcu mattis et amet. 
                           Volutpat, enim enim faucibus platea consectetur vitae.
                          </Typography>
                        </info>
                      </CardContent>
                    </Card>
                  </div>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <div className="hrms">
                    <Card>
                      <CardContent
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <IconButton
                          size="large"
                          aria-label="show 4 new mails"
                          color="inherit"
                        >
                          <img
                            src={warehouse}
                            style={{ fontSize: "2.5rem", color: "#4F46E5" }}
                          />
                        </IconButton>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          textAlign="center"
                        >
                         Quality Testing
                        </Typography>

                        <info>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            textAlign=""
                            fontSize="16px"
                            sx={{ color: "white" }}
                          >
                             Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                           Quam bibendum fames auctor duis phasellus sagittis. Praesent quisque leo id tortor, urna enim.
                            Adipiscing bibendum arcu mattis et amet. 
                           Volutpat, enim enim faucibus platea consectetur vitae.
                          </Typography>
                        </info>
                      </CardContent>
                    </Card>
                  </div>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <div className="hrms">
                    <Card>
                      <CardContent
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <IconButton
                          size="large"
                          aria-label="show 4 new mails"
                          color="inherit"
                        >
                          <img
                            src={planner}
                            style={{ fontSize: "2.5rem", color: "#4F46E5" }}
                          />
                        </IconButton>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          textAlign="center"
                        >
                          Delivery & Optimization
                        </Typography>

                        <info>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            textAlign=""
                            fontSize="16px"
                            sx={{ color: "white" }}
                          >
                           Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                           Quam bibendum fames auctor duis phasellus sagittis. Praesent quisque leo id tortor, urna enim.
                            Adipiscing bibendum arcu mattis et amet. 
                           Volutpat, enim enim faucibus platea consectetur vitae.
                          </Typography>
                        </info>
                      </CardContent>
                    </Card>
                  </div>
                </Grid>
              </Grid> */}
          <Box component={"img"}
            src={shapes}
            alt="banner"
            width="100%"
            sx={{ margin: "0 auto", p: "20px", display: { xs: "block", md: "none" } }}
          />
          <Box component={"img"}
            src={webmethodology}
            alt="banner"
            // width="100%"
            sx={{ margin: "0 auto", display: { xs: "none", md: "block" } }}
          />
        </Box>
      </Stack>

      <div
        className="adaptivebg"
        style={{
          backgroundImage: `url(${adaptivebg})`,
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 2.5, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",

                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.subMainHeader}
                mb={3}
                sx={{
                  display: { xs: "none", md: "block" },
                }}
              >
                SEO-friendly Web Development
              </Typography>

              <Typography
                className={classes.subMainHeader}
                mb={3}
                sx={{
                  display: { xs: "block", md: "none" },
                  textAlign: "center",
                  marginTop: "20px"
                }}
              >
                SEO-friendly Web Development
              </Typography>

              <Box
                sx={{
                  marginTop: "10px",
                  marginBottom: "20px",
                  flex: 1,

                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img alt="vector" src={seoBoost} />
              </Box>

              <Typography
                variant="h4"
                fontSize={{ xs: "16px", md: "18px" }}
                marginTop="10px"
                textAlign="initial"
                sx={{
                  lineHeight: "31px", color: "white",
                  fontWeight: { xs: "400" }
                }}
              >
                In today's competitive digital landscape, having a website that is search engine optimized is essential for attracting organic traffic. Our web development services include implementing SEO best practices from the ground up, including optimized site structure, clean code, fast loading speed, and mobile-friendliness. By building websites with SEO in mind, we help our clients improve their online visibility and drive more targeted traffic to their websites.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,

                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="vector" src={seoBoost} />
            </Box>
          </Stack>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexWrap: "wrap",
            p: { xs: 2.5, md: 6 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row-reverse" }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",

                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                className={classes.subMainHeader}
                mb={3}
                sx={{
                  display: { xs: "none", md: "block" },
                }}
              >
                We create the best web designs
              </Typography>

              <Typography
                className={classes.subMainHeader}
                mb={3}
                sx={{
                  display: { xs: "block", md: "none" },
                  textAlign: "center",
                  marginTop: "20px"
                }}
              >
                We create the best web designs
              </Typography>

              <Box
                sx={{
                  marginTop: "10px",
                  marginBottom: "20px",
                  flex: 1,

                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={delivery} alt="banner" />
              </Box>

              <Typography
                variant="h4"
                fontSize={{ xs: "16px", md: "18px" }}
                marginTop="10px"
                textAlign="initial"
                sx={{
                  lineHeight: "31px", color: "white",
                  fontWeight: { xs: "400" }
                }}
              >
                At Stackroots, we take pride in delivering high-quality website development services that exceed our clients' expectations. Whether you need a custom website, an e-commerce platform, or ongoing website maintenance, our team has the expertise and dedication to bring your vision to life. Contact us today to discuss your website development needs and take your online presence to the next level.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: { xs: "0px", md: "40px" },
                flex: 1,

                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={delivery} alt="banner" />
            </Box>
          </Stack>
        </Box>

        <Box sx={{ marginTop: "50px", display: { xs: "none", md: "block", sm: "block" } }}>
          <Techstack />
        </Box>

        <Box sx={{ marginTop: "50px", p: 3, mb: "60px", display: { xs: "block", md: "none", sm: "none" } }} >
          <Typography
            sx={
              { "fontStyle": "normal", "fontWeight": "600", "fontSize": "24px", "lineHeight": "29px", "color": "#FFFFFF", textAlign: "center" }
            }
          >
            Expertise And Technical Skills
          </Typography>

          <Stack>
            <Marquee gradient={false}>
              {icons.map((icon) => (
                <Box component={"img"}
                  sx={{
                    mt: "25px",
                    width: "105px"
                  }}
                  src={icon.img}
                  alt={"tech stack"}
                />
              ))
              }
            </Marquee>

            <Marquee gradient={false} direction={"right"}>
              {icons.map((icon) => (
                <Box component={"img"}
                  sx={{
                    mt: "25px",
                    width: "105px"
                  }}
                  src={icon.img}
                  alt={"tech stack"}
                />
              ))
              }
            </Marquee>
          </Stack>

        </Box>
      </div>
    </Stack>
  );
};

export default AdaptiveDesign;
