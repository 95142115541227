import Container from "./Container";
import bio from "../assets/img/products/bio.png";
import asd from "../assets/img/products/asd.png";
import userfriendly from "../assets/img/products/userfriendly.png";
import thirdparty from "../assets/img/products/thirdparty.png";
import analytical from "../assets/img/products/analytical.png";

import {
  Box,
  Card,
  Grid,
  Paper,
  Typography,
  IconButton,
  Stack,
} from "@mui/material";
import { useStyles } from "../styles";

export default function ErpFeatures() {
  const classes = useStyles();
  return (
    <>
      <div>
        <Stack direction="column">
          <Box style={{ width: "100% !important" }}>
            <Typography
              textAlign="center"
              className={classes.mainHeader}
              sx={{
                paddingTop: "100px",
              }}
            >
              What makes us different
            </Typography>
          </Box>
          <Box py={4} sx={{ display: "flex", flexDirection: "column", gap: { xs: "50px", md: "100px" } }}>
            <Stack
              sx={{
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "space-between",
                padding: "20px",
              }}
              style={{ width: "100% !important" }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="max-w-2xl mb-8">
                  <Typography
                    className={classes.subMainHeader}
                    color="#009ADF"
                    sx={{
                      textAlign: { xs: "center", md: "left" }
                    }}
                  >
                    Biometric Device integration
                  </Typography>

                  <Typography
                    variant="body2"
                    // fontSize="18px"
                    sx={{
                      color: "#ffff",
                      marginTop: "20px",
                    }}
                    className={classes.mainContent}
                  >
                    Stackroots ERP easily integrates with the third party ERP
                    which is related to the field of financial, CRM, Human
                    capital, Inventory modules. Each stages of development our
                    team create easy integration modules for existing ERP for
                    architecture institutions
                  </Typography>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="">
                  <img
                    src={bio}
                    width="300"
                    height="100"
                    alt="Hero Illustration"
                    layout="intrinsic"
                    loading="eager"
                    placeholder="blur"
                  />
                </div>
              </div>
            </Stack>

            <Stack
              sx={{
                flexDirection: { xs: "column-reverse", md: "row" },
                justifyContent: "space-between",
                padding: { xs: "20px", md: "0px" },
              }}
            >
              <div className="flex items-center justify-center w-full lg:w-1/2 mb-5">
                <div className="">
                  <img
                    src={userfriendly}
                    width="300"
                    height="100"
                    alt="Hero Illustration"
                    layout="intrinsic"
                    loading="eager"
                    placeholder="blur"
                  />
                </div>
              </div>
              <div className="flex items-center w-full lg:w-1/2 mt-5">
                <div className="max-w-2xl mb-8">
                  <Typography
                    variant="h6"
                    textAlign={{ xs: "center", md: "end" }}
                    fontWeight="bold"
                    sx={{

                      color: "#009ADF",
                    }}
                    className={classes.subMainHeader}
                  >
                    User Friendly & Flexible Interface
                  </Typography>

                  <Typography
                    variant="body2"
                    textAlign={{ xs: "left", md: "end" }}
                    fontSize="18px"
                    sx={{
                      color: "#ffff",
                      marginTop: "20px",
                    }}
                    className={classes.mainContent}
                  >
                    An integrated Stackroots ERP system automates project
                    creation by automatically capturing data from the admin &
                    user. This provides audits trails for better visibility and
                    also helps increase efficiency and accuracy.
                  </Typography>
                </div>
              </div>
            </Stack>

            <Stack
              sx={{
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "space-between",
                padding: "20px",
              }}
              style={{ width: "100% !important" }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="max-w-2xl mb-8">
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    textAlign={{ xs: "center", md: "left" }}
                    sx={{

                      color: "#009ADF",
                    }}
                    className={classes.subMainHeader}
                  >
                    Multi-User, multi-Branch/Company
                  </Typography>

                  <Typography
                    variant="body2"
                    fontSize="18px"
                    sx={{
                      color: "#ffff",
                      marginTop: "20px",
                    }}
                    className={classes.mainContent}
                  >
                    Stackroots ERP can reduce the additional cost that acquired
                    on a particular project during the life cycle of the project
                    delivery. We concentrate on each project stage while
                    consuming the least cost to timely delivery.
                  </Typography>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="">
                  <img
                    src={asd}
                    width="300"
                    height="100"
                    alt="Hero Illustration"
                    layout="intrinsic"
                    loading="eager"
                    placeholder="blur"
                  />
                </div>
              </div>
            </Stack>

            <Stack
              sx={{
                flexDirection: { xs: "column-reverse", md: "row" },
                justifyContent: "space-between",
                padding: { xs: "20px", md: "0px" },
              }}
            >
              <div className="flex items-center justify-center w-full lg:w-1/2 mb-5">
                <div className="">
                  <img
                    src={analytical}
                    width="300"
                    height="100"
                    alt="Hero Illustration"
                    layout="intrinsic"
                    loading="eager"
                    placeholder="blur"
                  />
                </div>
              </div>
              <div className="flex items-center w-full lg:w-1/2">
                <div className="max-w-2xl mb-8">
                  <Typography
                    variant="h6"
                    textAlign={{ xs: "center", md: "end" }}
                    fontWeight="bold"
                    sx={{

                      color: "#009ADF",
                    }}
                    className={classes.subMainHeader}
                  >
                    In Depth Analytical Report
                  </Typography>

                  <Typography
                    variant="body2"
                    textAlign={{ xs: "left", md: "end" }}
                    fontSize="18px"
                    sx={{
                      color: "#ffff",
                      marginTop: "20px",
                    }}
                    className={classes.mainContent}
                  >
                    Stackroots ERP can reduce the additional cost that acquired
                    on a particular project during the life cycle of the project
                    delivery. We concentrate on each project stage while
                    consuming the least cost to timely delivery.
                  </Typography>
                </div>
              </div>
            </Stack>

            <Stack
              sx={{
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "space-between",
                padding: "20px",
              }}
              style={{ width: "100% !important" }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="max-w-2xl mb-8">
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    textAlign={{ xs: "center", md: "left" }}
                    sx={{

                      color: "#009ADF",
                    }}
                    className={classes.subMainHeader}
                  >
                    Third Party Application Integration
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={{
                      color: "#ffff",
                      marginTop: "20px",
                    }}
                    className={classes.mainContent}
                  >
                    Stackroots ERP can reduce the additional cost that acquired
                    on a particular project during the life cycle of the project
                    delivery. We concentrate on each project stage while
                    consuming the least cost to timely delivery.
                  </Typography>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="">
                  <img
                    src={thirdparty}
                    width="300"
                    height="100"
                    alt="Hero Illustration"
                    layout="intrinsic"
                    loading="eager"
                    placeholder="blur"
                  />
                </div>
              </div>
            </Stack>

            <Stack
              sx={{
                flexDirection: { xs: "column-reverse", md: "row" },
                justifyContent: "space-between",
                padding: { xs: "20px", md: "0px" },
              }}
            >
              <div className="flex items-center justify-center w-full lg:w-1/2 mb-5">
                <div className="">
                  <img
                    src={userfriendly}
                    width="300"
                    height="100"
                    alt="Hero Illustration"
                    layout="intrinsic"
                    loading="eager"
                    placeholder="blur"
                  />
                </div>
              </div>
              <div className="flex items-center w-full lg:w-1/2 mt-5">
                <div className="max-w-2xl mb-8">
                  <Typography
                    variant="h6"
                    textAlign={{ xs: "center", md: "end" }}
                    fontWeight="bold"
                    sx={{

                      color: "#009ADF",
                    }}
                    className={classes.subMainHeader}
                  >
                    Timely Reminders & Notifications
                  </Typography>

                  <Typography
                    variant="body2"
                    textAlign={{ xs: "left", md: "end" }}
                    sx={{
                      color: "#ffff",
                      marginTop: "20px",
                    }}
                    className={classes.mainContent}
                  >
                    Stackroots ERP can reduce the additional cost that acquired
                    on a particular project during the life cycle of the project
                    delivery. We concentrate on each project stage while
                    consuming the least cost to timely delivery.
                  </Typography>
                </div>
              </div>
            </Stack>
          </Box>
        </Stack>
      </div >
    </>
  );
}
