import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "../../components/VehicleManagement/Banner5.css";
import { Container } from "@mui/system";
import FreeDemo from "../FreeDemo";
import PopUp from "../PopUp";
import { Fade } from "react-awesome-reveal";

const Banner5 = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Container maxWidth="xl">
      <Box
        className="banner3"
        sx={{
          // width: "100%",
          paddingBottom: "50px",
          height: "470px",
          height: "100%",
          display: "flex",
          flexWrap: "wrap",

          // margin: { xs: "10px", md: "30px" },
        }}
      >
        <Box
          sx={{
            flex: 1,
            padding: { xs: "20px", md: "50px" },
            marginTop: "40px",
            marginLeft: { xs: "0px", md: "70px" },
          }}
        >
          <Typography
            variant="h3"
            fontWeight="bold"
            textAlign="initial"
            sx={{
              fontSize: {
                xs: 42,
                md: 64,
                sm: 32,
                color: "white",
                marginTop: "100px",
              },
            }}
          >
            Vehicle Management
          </Typography>

          <Typography
            textAlign="justify"
            variant="body1"
            sx={{ color: "white", fontSize: "20px", marginTop: "25px" }}
          >
            Online service management application for Automobile service
            stations. 360Degree service management application for ease of use.
          </Typography>

          <PopUp title="Vechicle Management" />
        </Box>
      </Box>
      {/* <div style={{background:'#F9F9F9'}}> */}
      <Container
        maxWidth="xl"
        sx={{ marginTop: { xs: 1 }, background: "#F9F9F9" }}
      >
        {/* <Fade triggerOnce="true" direction="up"> */}
          <Typography
            textAlign="center"
            variant="h6"
            fontWeight="bold"
            fontSize="32px"
            sx={{ paddingTop: "50px" }}
          >
            Features of Vehicle Management
          </Typography>
          {/* <Container maxWidth="lg" sx={{ marginTop: { xs: 1, md: 2 } }}> */}
          <Grid container spacing={3}>
            <Grid item md={6} sm={6}>
              <Card sx={{ boxShadow: "none", background: "#F9F9F9" }}>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    fontSize="24px"
                  >
                    Multiple Stages of Approval level
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ color: "#000000" }}
                  >
                    Lorem ipsum dolor sit amet,consecteturadiiscing elit.Lorem
                    ipsum dolor sit amet, consectetur adipiscing elit. Sagittis
                    id mi leo libero et diam nibh at amet. Quis urna,
                    consectetur et cras.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6} sm={6}>
              <Card sx={{ boxShadow: "none", background: "#F9F9F9" }}>
                <CardContent>
                  {/* <img src="right-arrow 1.png" alt='/' /> */}
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    fontSize="24px"
                  >
                    Multiple device login
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ color: "#000000" }}
                  >
                    Lorem ipsum dolor sit amet,consecteturadiiscing elit.Lorem
                    ipsum dolor sit amet, consectetur adipiscing elit. Sagittis
                    id mi leo libero et diam nibh at amet. Quis urna,
                    consectetur et cras.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6} sm={6}>
              <Card sx={{ boxShadow: "none", background: "#F9F9F9" }}>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    fontSize="24px"
                  >
                    Best reporting tools
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ color: "#000000" }}
                  >
                    Lorem ipsum dolor sit amet,consecteturadiiscing elit.Lorem
                    ipsum dolor sit amet, consectetur adipiscing elit. Sagittis
                    id mi leo libero et diam nibh at amet. Quis urna,
                    consectetur et cras.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6} sm={6}>
              <Card sx={{ boxShadow: "none", background: "#F9F9F9" }}>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    fontSize="24px"
                  >
                    Stack management system
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ color: "#000000" }}
                  >
                    Lorem ipsum dolor sit amet,consecteturadiiscing elit.Lorem
                    ipsum dolor sit amet, consectetur adipiscing elit. Sagittis
                    id mi leo libero et diam nibh at amet. Quis urna,
                    consectetur et cras.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6} sm={6}>
              <Card sx={{ boxShadow: "none", background: "#F9F9F9" }}>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    fontSize="24px"
                  >
                    Day to day work monitoring
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ color: "#000000" }}
                  >
                    Lorem ipsum dolor sit amet,consecteturadiiscing elit.Lorem
                    ipsum dolor sit amet, consectetur adipiscing elit. Sagittis
                    id mi leo libero et diam nibh at amet. Quis urna,
                    consectetur et cras.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6} sm={6}>
              <Card sx={{ boxShadow: "none", background: "#F9F9F9" }}>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    fontSize="24px"
                  >
                    Branch & Franchise concepts
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ color: "#000000" }}
                  >
                    Lorem ipsum dolor sit amet,consecteturadiiscing elit.Lorem
                    ipsum dolor sit amet, consectetur adipiscing elit. Sagittis
                    id mi leo libero et diam nibh at amet. Quis urna,
                    consectetur et cras.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        {/* </Fade> */}
      </Container>
      {/* </div> */}
    </Container>
  );
};

export default Banner5;
