import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Moment from "moment";
// import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";
import { AccessTime, CalendarMonth } from "@mui/icons-material";

// const override: CSSProperties = {
//   display: "block",
//   margin: "0 auto",
//   borderColor: "red",
// };

const BlogTabs = ({ data }) => {
  const navigate = useNavigate();
  // let [loading, setLoading] = useState(true);
  // let [color, setColor] = useState("#ffffff");

  return (
    <div>
      <Card
        sx={{ width: "100%", marginTop: "50px" }}
        onClick={() => navigate(`/blogs/blogdetail?id=${data._id}`)}
      >
        <CardMedia
          component="img"
          image={data.images[0]}
          alt="/"
          sx={{ cursor: "pointer", }}
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              cursor: "pointer",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              width: "100%",
              color: "black",
            }}
          >
            {data.title}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" sx={{ color: "black" }}> <CalendarMonth fontSize="small" />&nbsp;
            {Moment(data.createdAt).fromNow()}
          </Button>
          <Button size="small" sx={{ color: "black" }}><AccessTime fontSize="small" /> &nbsp;
            {data.readingTime} min Read
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

export default BlogTabs;
