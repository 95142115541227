import {
    Box,
    Typography
} from "@mui/material";
import React from "react";
import banner from "../../assets/img/new/certBanner.png";
import { useStyles } from "../../styles";
import PopUp from "../PopUp";


const Banner = () => {
    const classes = useStyles();
    return (
        <>
            <Box
                sx={{
                    background: `url(${banner})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "100%",
                    paddingBottom: { xs: "150px", md: "50px" },
                    // height: "470px",
                    height: { xs: "auto", md: "100vh" },
                    display: "flex",
                    flexWrap: "wrap",
                    // margin: { xs: "10px", md: "30px" },
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        padding: { xs: "20px", md: "50px" },
                        marginTop: { xs: "100px", md: "80px" },
                        marginLeft: { xs: "0px", md: "70px" },
                        maxWidth: "900px"
                    }}
                >
                    <Typography
                        className={classes.mainHeader}
                        sx={{

                            marginTop: { md: "100px", xs: "0px" },
                        }}
                    >
                        CertVerify - Your Trusted Certificate Verification Solution
                    </Typography>

                    <Typography
                        className={classes.mainContent}
                        sx={{
                            marginTop: { md: "25px", xs: "10px" },
                        }}
                    >
                        We are the leading platform for seamless and reliable certificate verification. With our state-of-the-art blockchain-powered technology, we bring you an innovative solution to verify the authenticity of digital certificates with just one click. Say goodbye to cumbersome and time-consuming manual verification processes - with CertVerify, you can instantly validate the genuineness of any certificate issued through our system.
                    </Typography>
                    <PopUp margin={"50px"} title={"CertVerify"} background={"#e6f7ff"} color={"#4F46E5"} />
                </Box>
            </Box>

        </>
    );

};

export default Banner;
