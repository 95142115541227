import React from "react";

const ServiceCard2 = () => {
  return (
    <>
      <div className="container mx-auto px-10 m-5  sm:flex justify-between">
        <div className="max-w-sm py-10 dark:focus:bg-gray-800 mb-10 rounded overflow-hidden shadow-lg min-h-full transition duration-500 hover:scale-110 ">
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2 ">360 Degree Marketing</div>
            <p className="text-gray-700 dark:text-gray-300 text-base">
              We do marketing in all around your needs. We belives in 360 degree
              marketing.
            </p>
          </div>
        </div>

        <div className="max-w-sm py-10 dark:focus:bg-gray-800 mb-10 rounded overflow-hidden shadow-lg min-h-full transition duration-500 hover:scale-110 ">
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2 ">CRM Applications</div>
            <p className="text-gray-700 dark:text-gray-300 text-base">
              We create best CRM software ,efficient way you to handle your
              business enquires & customer data.
            </p>
          </div>
        </div>
        <div className="max-w-sm py-10 dark:focus:bg-gray-800 mb-10 rounded overflow-hidden shadow-lg min-h-full transition duration-500 hover:scale-110 ">
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2 ">
              SEO & Digital Marketing
            </div>
            <p className="text-gray-700 dark:text-gray-300 text-base">
              Best digital marketing services for your business needs. We create
              digital marketing campaigns with best digital marketing experts.
            </p>
          </div>
        </div>
      </div>

      <div className="container mx-auto  px-10 m-5  sm:flex justify-between">
        <div className="max-w-sm py-10 dark:focus:bg-gray-800 mb-10  rounded overflow-hidden shadow-lg min-h-full transition duration-500 hover:scale-110 ">
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2 ">Website Boosting</div>
            <p className="text-gray-700 dark:text-gray-300 text-base">
              Creating the website with high speed accesses in less internet
              connectivity areas.
            </p>
          </div>
        </div>

        <div className="max-w-sm py-10 dark:focus:bg-gray-800 mb-10  rounded overflow-hidden shadow-lg min-h-full transition duration-500 hover:scale-110 ">
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2 ">
              E-Commerce Application
            </div>
            <p className="text-gray-700 dark:text-gray-300 text-base">
              Changes happening to the world sales activities. We place your
              business authentic from real world to digital place
            </p>
          </div>
        </div>

        <div className="max-w-sm py-10 dark:focus:bg-gray-800 mb-10  rounded overflow-hidden shadow-lg min-h-full transition duration-500 hover:scale-110 ">
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2 ">Branding</div>
            <p className="text-gray-700 dark:text-gray-300 text-base">
              Every business need to be branded, until and unless not reach your
              customer. So we create all kind of branding for your business.
            </p>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-10 m-5  sm:flex justify-between">
        <div className="max-w-sm py-10 dark:focus:bg-gray-800 mb-10  rounded overflow-hidden shadow-lg min-h-full transition duration-500 hover:scale-110">
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2 ">
              Social Media Marketing
            </div>
            <p className="text-gray-700 dark:text-gray-300 text-base">
              Each days the number users will go through the social media , that
              shows a larges data for their daily needs. Hence we create nest
              social media marketing to reach your business to a mass market.
            </p>
          </div>
        </div>

        <div className="max-w-sm py-10 dark:focus:bg-gray-800 mb-10 rounded overflow-hidden shadow-lg min-h-full transition duration-500 hover:scale-110 ">
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2 ">Web Hosting</div>
            <p className="text-gray-700 dark:text-gray-300 text-base">
              Our customer don't need to worry about the hosting and
              technicality of the system. We take care about those to a SaaS
              model. Best Hosting plans we give out to our customers.
            </p>
          </div>
        </div>

        <div className="max-w-sm py-10 dark:focus:bg-gray-800 mb-10  rounded overflow-hidden shadow-lg min-h-full transition duration-500 hover:scale-110 ">
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2 ">
              Enterprise Applications
            </div>
            <p className="text-gray-700 dark:text-gray-300 text-base">
              We provide best ERP application for your business needs. We
              develop best custom made ERP for production & manufacturing,
              Supply chain management, Human Resource management & Finance.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceCard2;
