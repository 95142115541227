import imageHero from "../assets/img/imageHero.png";
import phone from "../assets/img/products/phone.png";
import team1 from "../assets/img/products/team1.png";
import team2 from "../assets/img/products/team2.png";
import {
  Box,
  Card,
  Grid,
  Paper,
  Typography,
  IconButton,
  Stack,
  Container,
} from "@mui/material";
import { useStyles } from "../styles";

export default function ErpCards() {
  const classes = useStyles();
  return (
    <>
      <Container maxWidth="xl" sx={{ marginTop: "50px" }} >
        <Stack spacing={{ md: 10, xs: 5 }} p={2}>
          <Stack
            sx={{
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
            }}
          >
            <div className="flex flex-1 items-center w-full lg:w-1/2">
              <div className="max-w-2xl mb-8">
                <Typography
                  className={classes.subMainHeader}
                  textAlign={{ xs: "left", md: "left" }}
                >
                  Stackroots ERP & CRM Driving Efficiency and Integration
                </Typography>

                <Typography
                  mt={2}
                  className={classes.mainContent}
                >
                  At Stackroots, we understand that every business is different and requires a personalized approach to achieve optimal efficiency and integration. Our team of seasoned professionals works closely with you to gain a deep understanding of your organization's goals, processes, and challenges. With this knowledge, we design and implement a customized ERP & CRM solution that aligns perfectly with your unique requirements, enabling seamless integration of all your business functions. Both Stackroots ERP and CRM products are highly customizable to meet the unique requirements of businesses across various industries. We offer a user-friendly interface, robust reporting and analytics capabilities, and seamless integration with other systems and applications. With Stackroots, businesses can optimize their operations, drive growth, and achieve a competitive edge in today's dynamic business landscape.
                </Typography>
              </div>
            </div>
            <div className="flex flex-1 items-center justify-center w-full lg:w-1/2 mb-5">
              <div className="">
                <img
                  src={team2}
                  width="616"
                  height="617"
                  alt="Hero Illustration"
                  layout="intrinsic"
                  loading="eager"
                  placeholder="blur"
                />
              </div>
            </div>
          </Stack>
          <Stack
            sx={{
              flexDirection: { xs: "column-reverse", md: "row" },
              justifyContent: "space-between",
            }}
          >
            <div className="flex flex-1 items-center justify-center w-full lg:w-1/2">
              <div className="">
                <img
                  src={team1}
                  width="616"
                  height="617"
                  alt="Hero Illustration"
                  layout="intrinsic"
                  loading="eager"
                  placeholder="blur"
                />
              </div>
            </div>
            <div className="flex flex-1 items-center w-full lg:w-1/2">
              <div className="max-w-2xl mb-8">
                {/* <h1 className="text-3xl font-bold leading-snug tracking-tight text-gray-800 lg:text-4xl lg:leading-tight xl:text-4xl xl:leading-tight dark:text-white">
              ERP software built for architects, architectural firms, Construction
             firms, contractors etc.
               
              </h1> */}

                {/* <Typography
                  variant="h6"
                  fontWeight="bold"
                  sx={{
                    fontSize: { xs: 22, md: 32, sm: 32 },
                    color: "#ffff",
                  }}
                >
                  Deliver every project on time
                </Typography> */}

                <Typography
                  className={classes.mainContent}

                >
                  The integrated approach of Stackroots ERP has tremendous power and potential in improving the efficiency, productivity, and competitiveness of the organisation. Stackroots ERP is broader in scope and is capable of dealing with more business functions and has a better and tighter integration with the finance and accounting functions together. Stackroots ERP is an enterprise wide set of forecasting, planning, and scheduling tools, which link customers and suppliers into a complete supply chain. The goals of Stackroots ERP include high levels of customer services, improved productivity, cost reduction, better inventory turnover, Just In Time Inventory , etc.
                </Typography>
              </div>
            </div>
          </Stack>
        </Stack>
      </Container>
    </>
  );
}
