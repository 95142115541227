import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import Container from "../Container";
import Pro1 from "../../assets/img/block3.png";
import Pro2 from "../../assets/img/block4.png";
import Pro3 from "../../assets/img/pro4.png";
import Pro4 from "../../assets/img/block5.png";
import network from "../../assets/img/network.png";
import SectionTitle from "../SectionTitle";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../../styles";

const BlockProducts = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <div
      style={{
        background: `linear-gradient(180deg, #151136 26.56%, #282559 39.58%, #161237 55.21%, #282457 70.83%, #0A0C22 95.83%, #212458 99.48%),url(${network})`,
        backgroundBlendMode: "overlay",
      }}
    >
      <Container>
        <Grid
          container
          padding="0px 0px 40px 0px"
          alignItems={"center"}
          spacing={2}
        >
          <Grid item xs={12} md={6} lg={6}>
            <Box>
              <Typography
                className={classes.subMainHeader}
              >
                E-COMMERCE APPLICATION
              </Typography>
              <Typography mt={2} className={classes.mainContent}
              >
                A platform that provides a facility for a vendor to sell
                products that help maintain people's physical and mental health,
                and GYMs & Users can buy these products using Gym coins, and
                eliminate third-party payment gateways.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={Pro1} />
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          padding="40px 0px 40px 0px"
          alignItems={"center"}
          direction={{ xs: "column-reverse", sm: "row" }}
          spacing={2}
        >
          <Grid item xs={12} md={6} lg={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "inital",
              }}
            >
              <img src={Pro2} />
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Box>
              <Typography
                className={classes.subMainHeader}
              >
                VENDOR APPLICATION
              </Typography>

              <Typography mt={2} className={classes.mainContent}
              >
                An application that helps vendors to manage their products &
                orders. Vendors can make maximum profit by eliminating middlemen
                between customers and vendors.
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          padding="40px 0px 40px 0px"
          alignItems={"center"}
          spacing={2}
        >
          <Grid item xs={12} md={6} lg={6}>
            <Box>
              <Typography
                className={classes.subMainHeader}

              >
                GYM APPLICATION
              </Typography>
              <Typography variant="body1" className={classes.mainContent} mt={2}
              >
                An application that helps the gym center manage its customers,
                staff & resources. This application provides the facility to
                receive & send payments using gym coins.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={Pro3} />
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          padding="40px 0px 40px 0px"
          alignItems={"center"}
          direction={{ xs: "column-reverse", sm: "row" }}
          spacing={2}
        >
          <Grid item xs={12} md={6} lg={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "inital",
              }}
            >
              <img src={Pro4} />
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Box>
              <Typography
                className={classes.subMainHeader}
              >
                USER APPLICATION
              </Typography>
              <Typography variant="body1" className={classes.mainContent} mt={2}
              >
                An application that helps users to connect with gym centers &
                their services, and settle bills using gym coins.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default BlockProducts;
