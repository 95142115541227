import React, { useEffect, useState } from "react";
import Hero1 from "../components/Hero1";
import Logo from "../assets/logo.png";
import Frontend from "../assets/frontend.pdf";
import Backend from "../assets/backend.pdf";
import fullstack from "../assets/fullstack.pdf";
import business from "../assets/business.pdf";
import clientsol from "../assets/client_solution.pdf";
import software from "../assets/software.pdf";
import digital from "../assets/digital.pdf";
import ui from "../assets/ui.pdf";
import { Container, Tab, Box, Grid, Stack, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import axios from "axios";
import CareerCard from "../components/CareerCard";
import PopupWidget from "../components/popupWidget";
import { Helmet } from "react-helmet";
import { useStyles } from "../styles";
import CareerEvents from "../components/CareerEvents";

const Careers = () => {
  const [jobFullInfo, setJobFullInfo] = useState([]);
  const [jobIntInfo, setJobIntInfo] = useState([]);
  const [value, setValue] = useState("1");
  const [jobExpo, setJobExpo] = useState([]);
  const [openings, setOpenings] = useState(false);

  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(newValue);

  };
  useEffect(() => {
    getFullJobs();
    getIntJobs();
    getEvents();
  }, []);

  const getEvents = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/events/jobExpo/active`)
      setJobExpo(res.data.data.records)
    } catch (e) {
      console.log(e)
    }
  }

  const getFullJobs = (type) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/jobpost/all/user?jobType=FULL_TIME&size=10&page=0 `
      )
      .then((res) => {
        setJobFullInfo(res.data.data.jobPosts)
        if (res.data.data.jobPosts.length) {
          setOpenings(true)
        }
      })
      .catch((err) => console.log(err));
  };

  const getIntJobs = (type) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/jobpost/all/user?jobType=INTERNSHIP&size=10&page=0 `
      )
      .then((res) => {
        setJobIntInfo(res.data.data.jobPosts)
        if (res.data.data.jobPosts?.length) {
          setOpenings(true)
        }

      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Helmet>
        <title>Stackroots | Careers</title>
      </Helmet>

      <div style={{ backgroundColor: "#131331", paddingTop: "10px" }}>
        <Container maxWidth="xl">
          <Hero1 openings={openings} />
          {
            jobExpo.length > 0 &&
            <Stack alignItems={"center"} gap={4}>
              <Typography className={classes.subMainHeader}>Events</Typography>
              {jobExpo.map((event) => (
                <CareerEvents title={event.title}
                  description={event.description}
                  startDate={event.startDate}
                  endDate={event.endDate}
                  jobExpoId={event._id} />
              ))
              }
            </Stack>
          }
        </Container>
        <Container maxWidth="xl" style={{ padding: "0px !important" }}>
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TabList
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                textColor="primary"
                sx={{ color: "#0580C9" }}
                indicatorColor={"primary"}
              >
                {/* <Tab label="" value="1" /> */}
                <Tab label="Fulltime" value="1" sx={{ color: "#fff", fontWeight: "600" }} />
                <Tab label="Internships" value="2" sx={{ color: "#fff", fontWeight: "600" }} />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: "24px 0px 24px 0px" }}>
              <Typography py={5}
                className={classes.mainHeader}
                textAlign={"center"}
              >
                CURRENT OPENINGS
              </Typography>
              <Grid container>
                <Grid item md={6}>
                  <Grid container>
                    {jobFullInfo
                      ?.slice(0, Math.ceil(jobFullInfo?.length / 2))
                      .map((job, index) => (
                        <Grid item md={12}>
                          <Stack m={2}>
                            <CareerCard job={job} />
                          </Stack>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>

                <Grid item md={6}>
                  <Grid container>
                    {jobFullInfo?.slice(Math.ceil(jobFullInfo.length / 2))
                      .map((job, index) => (
                        <Grid item md={12}>
                          <Stack m={2}>
                            <CareerCard job={job} />
                          </Stack>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="2" sx={{ padding: "24px 0px 24px 0px" }}>
              {jobIntInfo?.map((job, index) => (
                <CareerCard job={job} />
              ))}
            </TabPanel>
          </TabContext>
        </Container>

      </div>
    </>
  );
};

export default Careers;
