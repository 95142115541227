import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Stack, Typography } from "@mui/material";
import certverify from "../assets/img/certverify.pdf";
import circle from "../assets/img/circle.png";
import bgLogo from "../assets/img/huddleLogo.png";
import huddleBg from "../assets/img/huddlebg.png";
import stack from "../assets/img/stack.pdf";
import stacked from "../assets/img/stacked.pdf";
import Container from "../components/Container";

const Huddle = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${huddleBg})`,
        minHeight: "100vh",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50% 50%",
        backgroundSize: "cover",
      }}
    >
      <Container>
        <div className="flex flex-col justify-center items-center mt-32 gap-4">
          <img src={bgLogo} />
          <h1 className="text-white text-xl text-center">
            INDIA'S MOST COVETED STARTUP CONCLAVE
          </h1>

          <h1 className="text-white text-5xl md:text-8xl">We Are Here</h1>
          <h1 className="text-white text-xl">Grand Kerala Startup Challenge</h1>
          <div className="flex gap-4 items-center">
            <div
              class="w-[30px] h-[30px] bg-red-600 rounded-full flex 
 justify-center items-center text-white"
            >
              <p>16</p>
            </div>
            <div
              class="w-[30px] h-[30px] bg-red-600 rounded-full flex 
 justify-center items-center text-white"
            >
              <p>17</p>
            </div>
            <div
              class="w-[30px] h-[30px] bg-red-600 rounded-full flex 
 justify-center items-center text-white"
            >
              <p>18</p>
            </div>
            <h2 className="text-white">2023 December</h2>
          </div>
          <div className="text-white flex gap-2">
            <LocationOnIcon className="text-red-600" />
            <h6>Adimalathura, Azhimala, Vizhinjam, Kerala, India</h6>
          </div>
          <Stack>
            <Typography variant="h6" className="text-white text-center">
              Know more about the products
            </Typography>
            <Typography
              variant="button"
              className="text-white text-xl text-center"
            >
              click & download
            </Typography>
            <Stack direction={{ xs: "column", md: "row" }}>
              <div className="relative flex items-center justify-center">
                <img src={circle} />
                <a
                  href={stack}
                  download="StackArch Brochure"
                  class="absolute text-lg text-center text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                >
                  STACKARCH
                </a>
              </div>
              <div className="relative  flex items-center justify-center">
                <img src={circle} />
                <a
                  href={stacked}
                  download="StackEd Brochure"
                  class="absolute text-lg text-center text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                >
                  STACK ED
                </a>
              </div>
              <div className="relative  flex items-center justify-center">
                <img src={circle} />

                <a
                  href={certverify}
                  download="CertVerify Brochure"
                  class="absolute text-lg text-center text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                >
                  CERT VERIFY
                </a>
              </div>

              {/* <div className="relative  flex items-center justify-center">
                <img src={circle} />
                <a
                  href={blockchain}
                  download="Blockchain Brochure"
                  class="absolute text-xl text-center text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                >
                  BLOCKCHAIN APPLICATION
                </a>
              </div>
              <div className="relative  flex items-center justify-center">
                <img src={circle} />
                <a
                  href={auto}
                  download="Autostack Brochure"
                  class="absolute text-xl text-center text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                >
                  AUTOSTACK
                </a>
              </div> */}
            </Stack>
          </Stack>
        </div>
      </Container>
    </div>
  );
};

export default Huddle;
