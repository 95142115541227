import React from "react";
import Banner4 from "../../components/Hospistack/Banner4";
import Cards from "../../components/Hospistack/Cards";
import Description2 from "../../components/Hospistack/Description2";
import Detail from "../../components/Hospistack/Detail";
import PopupWidget from "../../components/popupWidget";
import { Fade } from "react-awesome-reveal";
import ContactForm from "../../components/Contact/ContactForm";
import { Helmet } from "react-helmet";

const Hospistack = () => {
  return (
    <>
      <Helmet>
        <title>Stackroots | Hospistack</title>
      </Helmet>
      <div>
        <Banner4 />
        <Cards />
        {/* <Detail />
        <Description2 /> */}

        <ContactForm title="Get a quote" />

        {/* <PopupWidget /> */}
      </div>
    </>

  );
};

export default Hospistack;
